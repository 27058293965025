<div class="accreditation-details-page container-fluid">
  @if (!accreditation) {
    <div class="row">
      <div class="col-6">
        <message-box type="danger" title="Not found">
          Unable to locate application
        </message-box>
      </div>
    </div>
  }

  @if (accreditation) {
    <accreditation-details [accreditation]="accreditation"
      [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
      [approveAccreditationDocumentFn]="approveAccreditationDocumentFn"
      [declineAccreditationDocumentFn]="declineAccreditationDocumentFn"
      [deleteAccreditationDocumentFn]="deleteAccreditationDocumentFn"
      [uploadAccreditationDocumentFn]="uploadAccreditationDocumentFn"
      [listAccreditationDocumentFn]="listAccreditationDocumentFn"
      [downloadAccreditationDocumentUrlFn]="downloadAccreditationDocumentUrlFn"
      [updateAccreditationDocumentTagsFn]="updateAccreditationDocumentTagsFn"
      [downloadAllAccreditationDocumentUrlFn]="downloadAllAccreditationDocumentUrlFn"
      [getAccreditationWebServicesFn]="getAccreditationWebServicesFn"
      [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
      [approveAccreditationFn]="approveAccreditationFn"
      [declineAccreditationFn]="declineAccreditationFn"
      [apiUrl]="apiUrl"
      [getUserFn]="getUserFn"
      [getAccreditationByIdFn]="getAccreditationByIdFn"
      [getAccreditationCreditFlowResultFn]="getAccreditationCreditFlowResultFn"
      [runAccreditationCreditFlowFn]="runAccreditationCreditFlowFn"
      (creditStatusUpdate)="onHandleCreditStatusUpdate()"
      [getWebServiceReportFn]="getWebServiceReportFn"
    ></accreditation-details>
  }
</div>
