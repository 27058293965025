import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../service/notification.service';
import {Notifications, } from '@portal-workspace/grow-shared-library';
import {User} from '@portal-workspace/grow-shared-library';
import {getUser } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { NotificationListComponentEvent,CustomContentLoaderComponent} from '@portal-workspace/grow-ui-library';
import {loadingFor} from '@ngneat/loadoff';
import { NotificationListComponent } from '@portal-workspace/grow-ui-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './notifications.page.html',
    styleUrls: ['./notifications.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, NgTemplateOutlet, CustomContentLoaderComponent, NgClass, ExtendedModule, NotificationListComponent, AsyncPipe]
})
export class NotificationsPage implements OnInit {

  subscriptions: Subscription[] = [];
  isLoadingMarkRead : boolean = false;
  isLoadingDelete : boolean = false;
  user: User | null = null;
  loader = loadingFor('tableLoading');

  allNotifications: Notifications = [];
  unreadNotifications: Notifications = [];

  constructor(private notificationService: NotificationService,
              private toastService: PortalHotToastService,) {
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser();
    this.reload(true);
  }

  private reload(showLoading = false) {
    if (this.user && this.user.OriginatorBusinessId) {
      const sub = this.notificationService.getAllNotifications(this.user.UserId, this.user.OriginatorBusinessId).pipe(
        this.loader.tableLoading.track(),
        // showLoading ? this.toastService.toastObserver('retrieve notifications') : tap(r => {}),
        tap(r => {
          this.allNotifications = r.payload.notifications;
          this.unreadNotifications = this.allNotifications.filter(n => !!!n.readId);
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  onNotificationEvents($event: NotificationListComponentEvent) {
    switch($event.type) {
      case 'deleteAll': {
        if (this.user && this.user.OriginatorBusinessId) {
          this.isLoadingDelete = true;
          const sub = this.notificationService.deleteAllNotifications(this.user.UserId, this.user?.OriginatorBusinessId).pipe(
            this.toastService.retryableMessage({
              successMessage: 'Notifications deleted',
              errorMessage: 'Failed to delete the notifications',
              retryFn: ()=> {
                console.log('**** retry ', this);
                this.onNotificationEvents($event);
              }
            }),
            tap(r => {
              this.reload();
              console.log('****** rrrr', r);
            })
          ).subscribe((res) =>{
            if(res){
               this.isLoadingDelete = false
            }
          }, (err) => {
            if(err){
               this.isLoadingDelete = false
            }
          });
          this.subscriptions.push(sub);
        }
        break;
      }
      case 'markAllAsRead': {
        if (this.user && this.user.OriginatorBusinessId) {
          this.isLoadingMarkRead = true;
          const sub = this.notificationService.markAllNotificationsRead(this.user.UserId, this.user.OriginatorBusinessId).pipe(
            this.toastService.retryableMessage({
              successMessage: 'Notifications marked as read',
              errorMessage: 'Failed to mark the notifications as read',
              retryFn: ()=> {
                console.log('**** retry ', this);
                this.onNotificationEvents($event);
              }
            }),
            tap(r => {
              this.reload();
              console.log('****** rrrr', r);
            })
          ).subscribe((res) =>{
            if(res){
               this.isLoadingMarkRead = false
            }
          }, (err) => {
            if(err){
              this.isLoadingMarkRead = false
            }
          });
          this.subscriptions.push(sub);
        }
        break;
      }
      case 'markAsRead': {
        if (this.user) {
          const notificationId = $event.notificationId;
          const sub = this.notificationService.markNotificationRead(this.user.UserId, notificationId).pipe(
            tap(r => {
              // this.reload();
            })
          ).subscribe();
          this.subscriptions.push(sub);
        }
        break;
      }
      case 'delete': {
        if (this.user) {
          const notificationId = $event.notificationId;
          const sub = this.notificationService.deleteNotification(notificationId, this.user.UserId).pipe(
            // this.toastService.toastObserver('deleting notification'),
            tap(r => {
              this.reload();
            })
          ).subscribe();
          this.subscriptions.push(sub);
        }
        break;
      }
    }

  }
}
