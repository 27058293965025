<div class="asset-product-selector-page">
    <div class="row">
        <div class="col-md-4 mb-1">
            <mat-card class="card-selected">
                <mat-card-content>
                    <div class="select-product">
                        Please select the product type for your asset finance application
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 mb-1">
            <mat-card class="card-selected">
                <mat-card-content>
                    <div>
                        <span class="mdi mdi-information-outline"> Note</span>
                    </div>
                </mat-card-content>
                <mat-card-content class="mt-2">
                    <div class="note">
                        We have the right to withdraw this approval at any time at our sole discretion or if we have
                        concerns on the transactions validity.
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 mb-1">
            <mat-card class="card-selected" style="cursor: pointer;" (click)="downloadFile()">
                <mat-card-content>
                    <div>
                        <span class="mdi mdi-file-multiple"> See a full product guide</span>
                    </div>
                </mat-card-content>
                <mat-card-content class="mt-2">
                    <div>
                        Download a PDF brochure with a detailed products description
                    </div>
                    <div class="icon-download">
                        <span class="mdi mdi-download"></span>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>



    <div class="row mt-3">
        <div class="col-md-4 mb-2">
            <!-- LOW DOC CARD -->
            <mat-card
                [ngClass]="{'card-selected': isProductTypeSelected('FAST DOC'), 'card-not-seelcted':!isProductTypeSelected('FAST DOC')}"
                (click)="selectProductType('FAST DOC')">
                <mat-card-content>
                    <div class="pb-2 doc-card">
                        <div>
                            @if(isProductTypeSelected('FAST DOC')){
                            <img src="/assets/images/low-doc-light.png">
                            }
                            @else{<img src="/assets/images/low-doc-dark.png">}
                        </div>
                        <div class="title-weight mt-3">
                            Low Doc
                        </div>
                        <div class="pt-2">
                          <div>No Financial Information</div>
                          <div>Automated Credit Decisioning</div>
                        </div>
                        <div class="text-loan pt-4">
                            Lends up to
                        </div>
                        <div class="pt-4 mb-4 text-amount">
                            $250k
                        </div>
                        <div class="pt-4">
                            <ul class="p-0">
                                <li class="row pb-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">$2,000 Min Loan Amount</div>
                                </li>
                                <mat-divider class="mat-divider-color"></mat-divider>
                                <li class="row pb-2 pt-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">24 months ABN/GST</div>
                                </li>
                                <li class="row pb-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">550 Min Director Score</div>
                                </li>
                                <mat-divider class="mat-divider-color"></mat-divider>
                                <li class="row pb-2 pt-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">Equifax Credit Check</div>
                                </li>
                                <li class="row col-low"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-8">Automatically switch to Easy Doc if required</div>
                                    <div class="col-md-2">
                                        <mat-slide-toggle 
                                        color="primary" 
                                        class="mr-3"
                                        [checked]="isSwitchToEasyDoc"
                                        (change)="onSwitchToEasyDoc($event.checked)">
                                      </mat-slide-toggle></div>
                                </li>
                            </ul>
                        </div>
                        <div class="row justify-content-center">
                            @if(isProductTypeSelected('FAST DOC')){
                            <button mat-flat-button class="doc-button col-md-11" (click)="onNext()">
                                <div class="d-flex justify-content-between align-items-center w-100"
                                    style="gap: 110px;">
                                    <span class="mdi mdi-check-circle icon" matPrefix></span>
                                    <span>Next</span>
                                    <span class="mdi mdi-arrow-right icon"></span>
                                </div>
                            </button>
                            }
                            @else{<button mat-flat-button class="doc-button inactive col-md-11"> Select Product</button>}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 mb-2">
            <!-- EASY DOC -->
            <mat-card
                [ngClass]="{'card-selected': isProductTypeSelected('EASY DOC'),'card-not-seelcted':!isProductTypeSelected('EASY DOC')}"
                (click)="selectProductType('EASY DOC')">
                <mat-card-content>
                    <div class="pb-2 doc-card">
                        <div>
                            @if(isProductTypeSelected('EASY DOC')){
                            <img src="/assets/images/easy-doc-light.png">
                            }
                            @else{ <img src="/assets/images/easy-doc-dark.png">}
                        </div>
                        <div class="title-weight mt-3">
                            Easy Doc
                        </div>
                        <div class=" pt-2">
                          <div>Bank Statements Assessment</div>
                          <div>Rapid Credit Decisioning</div>
                        </div>

                        <div class="text-loan pt-4">
                            Lends up to
                        </div>
                        <div class=" pt-4 mb-4 text-amount">
                            $500k
                        </div>
                        <div class="pt-4">
                            <ul class=" p-0">
                                <li class="row pb-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">$2,000 Min Loan Amount</div>
                                </li>
                                <mat-divider class="mat-divider-color"></mat-divider>
                                <li class="row pb-2 pt-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">
                                      <div>12 months ABN/GST or </div>
                                      <div>24 months ABN & 1 month GST</div>
                                    </div>
                                </li>
                                <li class="row pb-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">450 Min Director Score</div>
                                </li>
                                <mat-divider class="mat-divider-color"></mat-divider>
                                <li class="row pb-2 pt-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">Bank Statements</div>
                                </li>
                                <li class="row col-easy"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">Equifax Credit Check</div>
                                </li>
                            </ul>
                        </div>
                        <div class="row justify-content-center">
                            @if(isProductTypeSelected('EASY DOC')){
                            <button mat-flat-button class="doc-button col-md-11" (click)="onNext()">
                                <div class="d-flex justify-content-between align-items-center w-100"
                                    style="gap: 110px;">
                                    <span class="mdi mdi-check-circle icon" matPrefix></span>
                                    <span>Next</span>
                                    <span class="mdi mdi-arrow-right icon"></span>
                                </div>
                            </button>
                            }
                            @else{<button mat-flat-button class="doc-button inactive col-md-11"> Select Product</button>}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 mb-2">
            <!-- FULL DOC -->
            <mat-card
                [ngClass]="{'card-selected': isProductTypeSelected('FULL DOC'),'card-not-seelcted':!isProductTypeSelected('FULL DOC')}"
                (click)="selectProductType('FULL DOC')">
                <mat-card-content>
                    <div class="pb-2 doc-card">
                        <div>
                            @if(isProductTypeSelected('FULL DOC')){
                              <img src="/assets/images/full-doc-light.png">
                            }
                            @else{<img src="/assets/images/full-doc-dark.png">}
                        </div>
                        <div class="title-weight mt-3">
                            Full Doc
                        </div>
                        <div class=" pt-2">
                          <div>Financials Required</div>
                          <div>Approval in 1–5 business days</div>
                        </div>
                        <div class="text-loan pt-4">
                            Lends up to
                        </div>
                        <div class=" pt-4 mb-4 text-amount">
                            $1 million
                        </div>
                        <div class="pt-4">
                            <ul class=" p-0">
                                <li class="row pb-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">$20,000 Min Loan Amount</div>
                                </li>
                                <mat-divider class="mat-divider-color"></mat-divider>
                                <li class="row pb-2 pt-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">12 months ABN/GST</div>
                                </li>
                                <li class="row pb-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">450 Min Director Score</div>
                                </li>
                                <mat-divider class="mat-divider-color"></mat-divider>
                                <li class="row pb-2 pt-4"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">2-years financials or</div>
                                </li>
                                <li class="row pb-2"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">12 months Bank Statements</div>
                                </li>
                                <li class="row pb-2"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">Management accounts</div>
                                </li>
                                <li class="row pb-2"><span class="mdi mdi-check-circle col-md-1"></span>
                                    <div class="col-md-10">ATO Portal Data</div>
                                </li>
                            </ul>
                        </div>
                        <div class="row justify-content-center">
                            @if(isProductTypeSelected('FULL DOC')){
                            <button mat-flat-button  class="doc-button col-md-11" (click)="onNext()">
                                <div class="d-flex justify-content-between align-items-center w-100"
                                    style="gap: 110px;">
                                    <span class="mdi mdi-check-circle icon" matPrefix></span>
                                    <span>Next</span>
                                    <span class="mdi mdi-arrow-right icon"></span>
                                </div>
                            </button>
                            }
                            @else{<button mat-flat-button class="doc-button inactive col-md-11"> Select Product</button>}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
