import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import {SortTypes, User, isInternalUser, UserType, GetUser, ApproveOrRejectUserFn, UserandPriviledges, isSalesAM, PortalLoginUser, isSalesBDM} from '@portal-workspace/grow-shared-library';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort, MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@portal-workspace/grow-shared-library';
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, map, tap } from 'rxjs';
import { getUser} from '../component-utils';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
 
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccessLevelPipe } from "../../pipes/access-level.pipe";
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { ApplicationDialogService } from "../application-dialog-component/application-dialog.service";


export type UserTableFn = (opt: {
    page: {
        limit: number, offset: number
    },
    filter: string,
    sorts?: SortTypes,
}, type: UserType) => Observable<{ total: number, payload: GetUser[] }>;

export class InternalDataSource extends DataSource<GetUser> {

    subject = new BehaviorSubject<GetUser[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<GetUser[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
    }

    update(users: User[]) {
        this.subject.next(users);
    }
}


export type UserTableComponentEvent = UserTableComponentAddUserEvent | UserTableComponentUserClickedEvent;

export interface UserTableComponentAddUserEvent {
  kind: 'add-user',
  type: UserType,
}

export interface UserTableComponentUserClickedEvent {
  kind: 'user-click',
  type: UserType,
  user: User,
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, MatTooltipModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe, DatePipe, LooseCurrencyPipe, AccessLevelPipe, MatSlideToggleModule],
  exportAs: 'userTableComponent',
})
export class UserTableComponent implements OnInit {
    subscriptions: Subscription[] = [];
    total = 0;
    limit = DEFAULT_LIMIT;
    offset = DEFAULT_OFFSET;
    filter = '';
    constructor(
        // private adminService: AdminService,
        private formBuilder: FormBuilder,
        private toastService: PortalHotToastService,
        // private authService: AuthService,
        private dialogService: ApplicationDialogService
        ) {
        this.formControlSearch = formBuilder.control(null);
    }

    @Input({required: true}) userTableFn!: UserTableFn;
    @Input({required: false}) type: UserType = 'broker-user';
    @Input({required: true}) approveOrRejectFn!: ApproveOrRejectUserFn;
    @Input({required: false}) user!: UserandPriviledges;
    @Output() events: EventEmitter<UserTableComponentEvent> = new EventEmitter<UserTableComponentEvent>();
    isInternalUser = isInternalUser;
    loader = loadingFor('tableLoading');
    dataSource = new InternalDataSource();
    loggedInUser: PortalLoginUser | null = getUser();
    isSalesAM = isSalesAM(this.loggedInUser)
    isSalesBDM = isSalesBDM(this.loggedInUser)
    sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
    formControlSearch: FormControl<string | null>;
    displayColumns = ['name', 'email', 'customerEntityName', 'customerAccess', 'status'];
    canAccessBrokerUser = 
   this.loggedInUser?.priviledges?.includes('admin') || 
   this.loggedInUser?.priviledges?.includes('operations') || 
   this.loggedInUser?.priviledges?.includes('salesAM') || 
   this.loggedInUser?.priviledges?.includes('salesBDM') ||
   this.loggedInUser?.priviledges?.includes('companyadmin') ;
   
   canAccessCustomerUser = 
   this.loggedInUser?.priviledges?.includes('admin') || 
   this.loggedInUser?.priviledges?.includes('operations');

    // canAccess = this.loggedInUser?.priviledges?.includes('admin') || this.loggedInUser?.priviledges?.includes('operations') || this.loggedInUser?.priviledges?.includes('salesAM') 
    ngOnInit() {
        this.reload();
        const sub = this.formControlSearch.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            map(value => value ? value.trim() : value),
            tap(r => {
                if (r != null) {
                    this.filter = r;
                    this.reset();
                    this.reload();
                }
            })
        ).subscribe();
        this.subscriptions.push(sub);
    }

    onSortData($event: Sort) {
        if ($event.direction && $event.active) {
            this.sorts = { prop: $event.active, dir: $event.direction };
        } else {
            this.sorts = null;
        }
        this.reset();
        this.reload();
    }

    onPagination($event: PageEvent) {
        this.offset = $event.pageIndex;
        this.limit = $event.pageSize;
        this.reload();
    }

    addUser($event: Event) {
      this.events.emit({
        kind: 'add-user',
        type: this.type,
      });
    }

    reload() {
      this.subscriptions.push(this.userTableFn({
        page: {
          limit: this.limit, offset: this.offset,
        },
        filter: this.filter,
        sorts: this.sorts ? [this.sorts] : undefined
      }, this.type).pipe(
        this.loader.tableLoading.track(),
        tap(r => {
          this.total = r.total;
          const users = r.payload;
          this.dataSource.update(users);
        })
      ).subscribe());
    }

  // approvalChangetoggle(approvedChecked: boolean, element: GetUser) {
  //   if(approvedChecked) { // approve
  //     this.approveUser(element);
  //   } else { // reject
  //     this.rejectUser(element);
  //   }
  // }

  private reset() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  approveUser(user?: User) {
    if (user) {
      user.isDisabled = false;
      this.approveOrRejectFn(user.UserId, true).pipe(
        this.toastService.snackBarObservable(`User approved`),
        tap(r => {
        })
      ).subscribe()
    }
  }

  rejectUser(user?: User) {
    if (user) {
      user.isDisabled = true;
      this.subscriptions.push(this.approveOrRejectFn(user.UserId, false).pipe(
        this.toastService.snackBarObservable(`User rejected`),
        tap(r => {
        })
      ).subscribe());
    }
  }

  onRowClicked($event: MouseEvent, row: GetUser) {
    if(this.isSalesAM || this.isSalesBDM){
    if(row.AccessLevel !== 'externalbroker' && row.AccessLevel !== 'companyadmin' && row.AccessLevel !== 'companyoperator' && row.AccessLevel !== 'salesAM' && row.AccessLevel !== 'salesBDM'){
      this.dialogService.openAlertDialog({
        message: `Invalid Access`,
        subMessage: `You do not have this access.`,
      }).afterClosed().pipe(
        tap()
      ).subscribe();
    }else{
    this.events.emit({
      kind: 'user-click',
      type: this.type,
      user: row,
    });
  }
}else{
  this.events.emit({
    kind: 'user-click',
    type: this.type,
    user: row,
  
  });
}
  }
}
