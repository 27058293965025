import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder} from '@angular/forms';
import {
  ApiResponse,
  ConfirmationDialogResult,
  constants,
  ContractStatusDialogData,
  ContractStatusDialogResult, ContractStatusResponse, DeclineContractFn, displayApplicationDateTime, DocusignStatusKey, GetContractStatusFn, ResendContractFn, SignerStatus
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationDialogService, PortalHotToastService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import moment from 'moment';
import {tap} from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';

import { FlexModule } from '@angular/flex-layout/flex';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

export class InternalDataSource extends DataSource<SignerStatus> {

  subject = new BehaviorSubject<SignerStatus[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<SignerStatus[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(users: SignerStatus[]) {
    this.subject.next(users);
  }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './contract-status.dialog.html',
    styleUrls: ['./contract-status.dialog.scss'],
    standalone: true,
    imports: [MatTableModule, TagBoxComponent, FlexModule, MatButtonModule, MatDialogModule,  MatTooltipModule]
})
export class ContractStatusDialog implements OnInit {

  subs: Subscription[] = [];
  getContractStatusFn!: GetContractStatusFn;
  declineContractFn!: DeclineContractFn;
  resendContractFn!: ResendContractFn;
  envelopeId!: string;
  applicationId!: number;
  dataSource = new InternalDataSource();
  displayedColumns = ["name", "email", "status", "time", "actions"];
  userName!: string;
  createdTime!: string;
  presentDocusignStatus!: DocusignStatusKey[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ContractStatusDialogData,
    private dialogRef: MatDialogRef<ContractStatusDialog, ContractStatusDialogResult>,
    private formBuilder: UntypedFormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.getContractStatusFn = this.data.getContractStatusFn;
    this.declineContractFn = this.data.declineContractFn;
    this.resendContractFn = this.data.resendContractFn;
    this.envelopeId = this.data.envelopeId;
    this.applicationId = this.data.applicationId;
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.reload();
  }

  reload() {
    this.subs.push(
      this.getContractStatusFn(this.applicationId).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((response: ContractStatusResponse) => {
        console.log(response)
        if (response.success) {
          this.dataSource.update(response.signers);
          let uniqueSigners = response.signers.map((signer)=>signer.status);
          this.presentDocusignStatus = new Array(...new Set([...uniqueSigners]));
          this.userName = response.userName;
          this.createdTime = response.createdTime;
        } else {
          if (response.statusCode === 401) {
            this.applicationDialogService.openAlertDialog({
              message: `Authorization Error`,
              subMessage: response.error
            });
          } else if (response.statusCode === 204) {
            this.applicationDialogService.openAlertDialog({
              message: `Not found`,
              subMessage: response.error
            });
          } else {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: `An unexpected error occurs when getting the contract status.`,
            });
          }
        }
      })
    )
  }

  close() {
    this.dialogRef.close();
  }

  getSignerStatus(signer: SignerStatus) {
    switch (signer.status) {
      case 'autoresponded':
        return "Autoresponded";
      case 'completed':
        return "Signed";
      case 'created':
        return "Not sent";
      case 'declined':
        return "Declined";
      case 'delivered':
        return "Opened";
      case 'faxpending':
        return "Faxpending";
      case 'sent':
        return "Sent";
      case 'signed':
        return "Signed";
    }
    return "Unknown";
  }

  getLastUpdatedTime(signer: SignerStatus) {
    const offset = new Date().getTimezoneOffset();
    switch (signer.status) {
      case 'declined':
        return displayApplicationDateTime(moment(signer.declinedDateTime).utcOffset(-offset));
      case 'delivered':
        return displayApplicationDateTime(moment(signer.deliveredDateTime).utcOffset(-offset));
      case 'sent':
        return displayApplicationDateTime(moment(signer.sentDateTime).utcOffset(-offset));
      case 'completed':
      case 'signed':
        return displayApplicationDateTime(moment(signer.signedDateTime).utcOffset(-offset));
    }
    return "";
  }

  getContractSentTime() {
    const offset = new Date().getTimezoneOffset();
    return displayApplicationDateTime(moment(this.createdTime).utcOffset(-offset));
  }

  declineContract() {
    this.subs.push(
      this.applicationDialogService.openConfirmationDialog({
        message: 'Please confirm',
        subMessage: 'Are you sure you want to cancel the contract?'
      }).afterClosed().pipe(
        tap(async (r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            this.declineContractFunc();
          }
        })
      ).subscribe()
    )
  }

  private declineContractFunc() {
    this.subs.push(
      this.declineContractFn({
        envelopeId: this.envelopeId
      }).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Contract cancelled',
          errorMessage: 'Failed to decline the contract',
          retryFn: ()=> {
            this.declineContractFunc();
          }
        }),
      ).subscribe(() => {
        this.close();
      })
    )
  }

  getStatusInfo(status: DocusignStatusKey) {
    return {...constants.docusignStatus[status], title: constants.docusignStatus[status].title.toUpperCase()};
  }

  resend(signer: SignerStatus) {
    this.subs.push(
      this.resendContractFn({
        signerStatus: signer,
        envelopeId: this.envelopeId,
        applicationId: this.applicationId,
      }).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response.status) {
          this.applicationDialogService.successDialog({
            message: "Success",
            subMessage: response.message
          }).afterClosed().subscribe(() => {
            this.reload();
          })
        } else {
          this.applicationDialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message
          }).afterClosed().subscribe()
        }
      })
    )
  }
}
