import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule, MatSelectTrigger } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UntilDestroy } from "@ngneat/until-destroy";
import { compareMatch } from "@portal-workspace/grow-shared-library";
import {
  SearchableSelectItem,
  setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import { MARK } from "@portal-workspace/grow-ui-library/mark";
import { Subscription } from "rxjs";
import { delay, distinctUntilChanged, tap } from "rxjs/operators";
import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatSelectTrigger
  ],
  providers:[
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>SearchableSelectComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>SearchableSelectComponent)}
  ],
})
export class SearchableSelectComponent extends AbstractControlValueAccessor<SearchableSelectItem[]> implements OnInit, OnChanges {

  subscriptions: Subscription[] = [];
  
  @Input({required: true}) formControl!: FormControl;
  @Input({required: true}) items!: SearchableSelectItem[];
  @Input({required: false}) multi: boolean = false;
  @Input({required: false}) placeholder: string = 'Select...';

  @Output() selectionChange = new EventEmitter<SearchableSelectItem[]>();
  
  searchControl = new FormControl<string>('');
  filteredItems: SearchableSelectItem[] = [];
  displayValue: string = '';

  _selectionChange(item: SearchableSelectItem) {
    this.selectionChange.emit([item]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.filteredItems = [...(this.items ?? [])];
      this.filteredItems = this.sortItems(this.filteredItems);
    }
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.displayValue = this.getTriggerValue();
        this.propagateChange(r);
        this.filteredItems = this.sortItems(this.filteredItems);
      })
    ).subscribe());
    this.subscriptions.push(this.searchControl.valueChanges.pipe(
      tap(v => {
        this.filteredItems = this.sortItems(this.filterItems(v));
      })
    ).subscribe());
  }

  sortItems(items: SearchableSelectItem[]): SearchableSelectItem[] {
    return items.sort((a, b) => {
      // Sort by count in descending order
      if (this.formControl.value.includes(a.value) == this.formControl.value.includes(b.value)) { // i.e. if both are selected or not selected
        const countComparison = (b.count ?? 0) - (a.count ?? 0); // Handle potential undefined counts
        return countComparison;
      }
      else if (this.formControl.value.includes(a.value)) return -1;
      else if (this.formControl.value.includes(b.value)) return 1;
      else return 0;
    });
  }

  onSearch(event: Event): void {
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    this.items = this.filterItems(searchText);
  }

  private filterItems(searchText: string | null): SearchableSelectItem[] {
    if (!searchText) {
      return this.items;
    }
    return this.items.filter(item => 
      item.searchTerm?.toLowerCase()?.includes(searchText) || item.label.toLowerCase().includes(searchText)
    );
  }

  doWriteValue(v: SearchableSelectItem[]) {
    const ids = this.formControl.value.map((item: SearchableSelectItem) => item.id);
    for (const item of v) {
      if (!ids.includes(item.id)) {
        this.formControl.setValue(v);
      }
    }
  }

  getTriggerValue(): string {
    return this.formControl.value.map((v: string) => this.filteredItems.find((i) => i.value === v)?.label).filter((v: string | undefined) => !!v).join(', ');
  }
}
