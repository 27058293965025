import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatCardModule } from '@angular/material/card';
import {Chart, ChartConfiguration} from 'chart.js';
import { GridModule } from '@angular/flex-layout/grid';
import { getCssVariableContent, TagBoxComponent } from '@portal-workspace/grow-ui-library';
import {
  ApplicationService,
} from '../../service/application.service';
import { OverdraftProductApplicationStatistics, PayloadApiResponse } from '@portal-workspace/grow-shared-library';
import { Subscription } from 'rxjs';
import {PercentagePipe, AbbreviateNumberPipe} from '@portal-workspace/grow-ui-library';
import { CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import _ from 'lodash';
import { loadingFor } from '@ngneat/loadoff';
import { AsyncPipe, NgClass, DecimalPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './overdraft-product.page.html',
    styleUrls: ['./overdraft-product.page.scss'],
    standalone: true,
    imports: [MatCardModule, GridModule, TagBoxComponent, PercentagePipe, CustomContentLoaderComponent, AsyncPipe, NgClass, AbbreviateNumberPipe, DecimalPipe, FlexModule]
})

export class OverdraftProductPage implements OnInit{

    @ViewChild('last30daysTotalTransactionsVolumeChart') last30daysTotalTransactionsVolumeChartRef!: ElementRef;
    @ViewChild('prior30daysTotalTransactionsVolumeChart') prior30daysTotalTransactionsVolumeChartRef!: ElementRef;
    loader = loadingFor('DataLoading');
    chart!: Chart;
    accountChart: any;
    totalLimitChart: any;
    totalBalanceChart: any;
    spendChart: any;
    chartOptions: any;
    public dynamoneyBlue =  getCssVariableContent('--portal2-sg-dynamoney-blue'); // '#211747';
    public dynamoneyTeal =  getCssVariableContent('--portal2-sg-overdraft-teal'); // '#009688';
    public dynamoneyDarkBlue = getCssVariableContent('--portal2-sg-overdraft-blue'); // #0D47A1
    subscriptions: Subscription[] = [];
    public totalBalance: number = 0;
    public totalLimit: number = 0;
    public totalActiveAccounts: number = 0;
    public totalActiveCards: number = 0;
    public totalBalanceArrears: number = 0;
    public numberAccountsArrears: number = 0;
    public get30DayBankTransactionVolume:number = 0
    public get30DaysCardTransactionVolume:number = 0
    public get60DayBankTransactionVolume:number = 0
    public get60DaysCardTransactionVolume:number = 0
    public get90DayBankTransactionVolume:number = 0
    public get90DaysCardTransactionVolume:number = 0
    public totalLast30DaysVolume:number = 0
    public totalPrior30DaysVolume:number = 0
    public total90DaysVolume:number = 0
    public totalSpendLast30Days:number = 0
    public totalSpendPrior30Days:number = 0
    public totalSpend90Days:number = 0
    public last30DaysNewAccountCount:number = 0
    public prior30DaysNewAccountCount:number = 0
    public last30DaysNewAccountPercentageChange:number = 0
    public last30DaysNewAccountPercentageAbsolute:number = 0
    public currentMonthNewAccountCount:number = 0
    public lastMonthNewAccountCount:number = 0
    public last30DaysTotalAccountPercentageChange:number = 0
    public last30DaysTotalAccountPercentageAbsolute:number = 0
    public last30DaysCardCountResult:number = 0
    public prior30DaysCardCountResult:number = 0
    public last30DaysCardCountPercentageChange:number = 0
    public last30DaysCardCountPercentageAbsolute:number = 0
    public currentMonthCardCountResult:number = 0
    public lastMonthCardCountResult:number = 0
    public last30DaysTotalCardPercentageChange:number = 0
    public last30DaysTotalCardPercentageAbsolute:number = 0
    public last30DaysVolumePercentageChange:number = 0
    public last30DaysSpendPercentageChange:number = 0
    public last30DaysSpendPercentageAbsolute:number = 0
    public prior30DaysSpendPercentageChange:number = 0
    public prior30DaysSpendPercentageAbsolute:number = 0
    public prior30DaysVolumePercentageChange:number = 0
    public last30DaysVolumePercentageAbsolute:number = 0
    public newAccountMonthlyCount: number[] = [];
    public newAccountMonthlyDate: string[] = [];
    public balanceArrearsSum:number = 0
    public spend12MonthlyCount: number[] = [];
    public spend12MonthlyDate: string[] = [];

    public totalLimit12MonthlyCount: number[] = [];
    public totalLimit12MonthlyDate: string[] = [];

    public totalBalance12MonthlyCount: number[] = [];
    public totalBalance12MonthlyDate: string[] = [];

    public prior30DaysVolumePercentageAbsolute:number = 0
    constructor(
      private applicationService: ApplicationService,
    ) {}
  
    ngOnInit() {
      this.dynamoneyBlue =  getCssVariableContent('--portal2-sg-dynamoney-blue')
      this.dynamoneyTeal =  getCssVariableContent('--portal2-sg-overdraft-teal')
      this.dynamoneyDarkBlue = getCssVariableContent('--portal2-sg-overdraft-blue');
      this.getTotalBalanceStatistics()
    }

    loadDoughnutChart() {
      if (this.last30daysTotalTransactionsVolumeChartRef) {
        const canvasElement: HTMLCanvasElement = this.last30daysTotalTransactionsVolumeChartRef.nativeElement;
        const context = canvasElement.getContext('2d');
        const chartConfig: ChartConfiguration<'doughnut', number[], never> = {
          type: 'doughnut' as const,
          options: {
            cutout: '50%',
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
          },
          data: {
            labels: [],
            datasets: [{
              label: 'Payment',
              data: [this.get30DaysCardTransactionVolume, this.get30DayBankTransactionVolume],
              backgroundColor: [
                this.dynamoneyTeal,
                this.dynamoneyBlue,
              ],
              hoverOffset: 4,
            }]
          }
        };
        this.chart = new Chart(context!, chartConfig as any);
      }

      if (this.prior30daysTotalTransactionsVolumeChartRef) {
        const canvasElement: HTMLCanvasElement = this.prior30daysTotalTransactionsVolumeChartRef.nativeElement;
        const context = canvasElement.getContext('2d');
        const chartConfig: ChartConfiguration<'doughnut', number[], never> = {
          type: 'doughnut' as const,
          options: {
            cutout: '50%',
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
          },
          data: {
            labels: [],
            datasets: [{
              label: 'Payment',
              data: [this.get60DaysCardTransactionVolume, this.get60DayBankTransactionVolume],
              backgroundColor: [
                this.dynamoneyTeal,
                this.dynamoneyBlue,
              ],
              hoverOffset: 4,
            }]
          }
        };
        this.chart = new Chart(context!, chartConfig as any);
      }
    }

    loadBarChart(){
        if (this.accountChart) {
            this.accountChart.destroy();
        }

        if (this.spendChart) {
          this.spendChart.destroy();
        }

        if (this.totalLimitChart) {
          this.totalLimitChart.destroy();
        }

        if (this.totalBalanceChart) {
          this.totalBalanceChart.destroy();
        }

        const accountBarChart = document.getElementById('account-chart') as any;
        if(accountBarChart){
          this.accountChart = new Chart(accountBarChart, {
              type: 'bar',
              options:{
                scales: {
                  y: {
                    display: false, // Hide the y-axis
                    grid: {
                      display: false // Remove the grid lines
                    }                   
                  },
                  x: {
                    grid: {
                      display: false // Remove the grid lines
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false // Remove the legend
                  }
                }
              },
              data: {
                labels: this.newAccountMonthlyDate,
                datasets: [
                  {
                    label: 'Sales',
                    borderColor: this.dynamoneyDarkBlue,
                    backgroundColor: this.dynamoneyDarkBlue,
                    data: this.newAccountMonthlyCount
                  }
                ]
              }
            });
        }
  
        const spendBarChart = document.getElementById('spend-chart') as any;
        this.spendChart = new Chart(spendBarChart, {
            type: 'bar',
            options:{
              scales: {
                y: {
                  display: false, 
                  min: 0, 
                  grid: {
                    display: false 
                  },
                  ticks: {
                    callback: function(value, index, ticks) {
                      return '$' + value + 'M';
                    }
                  }
                },
                x: {
                  min: 0,  
                  grid: {
                    display: false  
                  }
                }
              },
              plugins: {
                legend: {
                  display: false // Remove the legend
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `Spend $${context.formattedValue}M`;
                    }
                  }
                }
              }
            },
            data: {
              labels: this.spend12MonthlyDate,
              datasets: [
                {
                  label: 'Sales',
                  borderColor: this.dynamoneyDarkBlue,
                  backgroundColor: this.dynamoneyDarkBlue,
                  data: this.spend12MonthlyCount
                }
              ]
            }
          });

        const limitBarChart = document.getElementById('limit-chart') as any;
        this.totalLimitChart = new Chart(limitBarChart, {
            type: 'bar',
            options:{
              scales: {
                y: {
                  display: false, // Hide the y-axis
                  grid: {
                    display: false // Remove the grid lines
                  },
                },
                x: {
                  grid: {
                    display: false // Remove the grid lines
                  }
                }
              },
              plugins: {
                legend: {
                  display: false // Remove the legend
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `Limit $${context.formattedValue}M`;
                    }
                  }
                }
              },
            },
            data: {
              labels: this.totalLimit12MonthlyDate,
              datasets: [
                {
                  label: 'Sales',
                  borderColor: this.dynamoneyDarkBlue,
                  backgroundColor: this.dynamoneyDarkBlue,
                  data: this.totalLimit12MonthlyCount              
                }
              ]
            }
          });

        const balanceBarChart = document.getElementById('balance-chart') as any;
        this.totalBalanceChart = new Chart(balanceBarChart, {
            type: 'bar',
            options:{
              scales: {
                y: {
                  display: false, // Hide the y-axis
                  grid: {
                    display: false // Remove the grid lines
                  },
                },
                x: {
                  grid: {
                    display: false // Remove the grid lines
                  }
                }
              },
              plugins: {
                legend: {
                  display: false // Remove the legend
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `Balance $${context.formattedValue}M`;
                    }
                  }
                }
              },
            },
            data: {
              labels: this.totalBalance12MonthlyDate,
              datasets: [
                {
                  label: 'Sales',
                  borderColor: this.dynamoneyDarkBlue,
                  backgroundColor: this.dynamoneyDarkBlue,
                  data: this.totalBalance12MonthlyCount              
                }
              ]
            }
        });
    }

     
    
    getTotalBalanceStatistics() {
     
      this.subscriptions.push(this.applicationService.getOverdraftProductTotalBalanceStatistics()
      .pipe(
        this.loader.DataLoading.track()
      )
      .subscribe((r: PayloadApiResponse<OverdraftProductApplicationStatistics>) => {
        const res: OverdraftProductApplicationStatistics = r.payload;
        console.log("res",res)
        this.totalBalance = res.totalBalance
        this.totalLimit = res.totalLimit
        this.totalActiveAccounts = res.totalActiveAccounts
        this.totalActiveCards = res.totalActiveCards
        this.totalBalanceArrears = res.totalBalanceArrears
        this.balanceArrearsSum = res.balanceArrearsSum
        this.numberAccountsArrears = res.numberAccountsArrears
        this.get30DayBankTransactionVolume = res.get30DayBankTransactionVolume
        this.get30DaysCardTransactionVolume = res.get30DaysCardTransactionVolume
        this.get60DayBankTransactionVolume = res.get60DayBankTransactionVolume
        this.get60DaysCardTransactionVolume = res.get60DaysCardTransactionVolume
        this.get90DayBankTransactionVolume = res.get90DayBankTransactionVolume
        this.get90DaysCardTransactionVolume = res.get90DaysCardTransactionVolume
         
        this.totalSpendLast30Days = res.totalSpendLast30Days
        this.totalSpendPrior30Days = res.totalSpendPrior30Days
        this.totalSpend90Days = res.totalSpend90Days
        if(this.totalSpendPrior30Days != 0){
          this.last30DaysSpendPercentageChange = _.round (((this.totalSpendLast30Days - this.totalSpendPrior30Days) / this.totalSpendPrior30Days) * 100,2);
          this.last30DaysSpendPercentageAbsolute = parseFloat(Math.abs(this.last30DaysSpendPercentageChange).toFixed(2))
        } else{
          this.last30DaysSpendPercentageChange = 0
          this.last30DaysSpendPercentageAbsolute = 0
        }

        if(this.totalSpend90Days != 0){
          this.prior30DaysSpendPercentageChange = _.round (((this.totalSpendPrior30Days - this.totalSpend90Days) / this.totalSpend90Days) * 100,2);
          this.prior30DaysSpendPercentageAbsolute = parseFloat(Math.abs(this.prior30DaysSpendPercentageChange).toFixed(2))
        } else{
          this.prior30DaysSpendPercentageChange = 0
          this.prior30DaysSpendPercentageAbsolute = 0
        }

        this.last30DaysNewAccountCount = res.last30DaysNewAccountCount
        this.prior30DaysNewAccountCount = res.prior30DaysNewAccountCount
        if(this.prior30DaysNewAccountCount != 0){
          this.last30DaysNewAccountPercentageChange = _.round (((this.last30DaysNewAccountCount - this.prior30DaysNewAccountCount) / this.prior30DaysNewAccountCount) * 100,2);
          this.last30DaysNewAccountPercentageAbsolute = parseFloat(Math.abs(this.last30DaysNewAccountPercentageChange).toFixed(2));
        } else {
          this.last30DaysNewAccountPercentageChange = 0;
          this.last30DaysNewAccountPercentageAbsolute = 0;
        }
        this.currentMonthNewAccountCount = res.currentMonthNewAccountCount
        this.lastMonthNewAccountCount = res.lastMonthNewAccountCount
        if(this.lastMonthNewAccountCount != 0){
          this.last30DaysTotalAccountPercentageChange = _.round (((this.currentMonthNewAccountCount - this.lastMonthNewAccountCount) / this.lastMonthNewAccountCount) * 100,2);
          this.last30DaysTotalAccountPercentageAbsolute = parseFloat(Math.abs(this.last30DaysTotalAccountPercentageChange).toFixed(2));
        } else{
          this.last30DaysTotalAccountPercentageChange = 0;
          this.last30DaysTotalAccountPercentageAbsolute = 0;
        }
        this.last30DaysCardCountResult = res.last30DaysCardActiveCount
        this.prior30DaysCardCountResult = res.prior30DaysCardActiveCount
        if(this.prior30DaysCardCountResult != 0){
          this.last30DaysCardCountPercentageChange = _.round (((this.last30DaysCardCountResult - this.prior30DaysCardCountResult) / this.prior30DaysCardCountResult) * 100,2);
          this.last30DaysCardCountPercentageAbsolute = parseFloat(Math.abs(this.last30DaysCardCountPercentageChange).toFixed(2));
        } else{
          this.last30DaysCardCountPercentageChange = 0;
          this.last30DaysCardCountPercentageAbsolute = 0;
        }
        this.currentMonthCardCountResult = res.currentMonthCardActiveCount
        this.lastMonthCardCountResult = res.lastMonthCardActiveCount
        if(this.lastMonthCardCountResult != 0){
          this.last30DaysTotalCardPercentageChange = _.round (((this.currentMonthCardCountResult - this.lastMonthCardCountResult) / this.lastMonthCardCountResult) * 100,2);
          this.last30DaysTotalCardPercentageAbsolute = parseFloat(Math.abs(this.last30DaysTotalCardPercentageChange).toFixed(2));
        } else{
          this.last30DaysTotalCardPercentageChange = 0;
          this.last30DaysTotalCardPercentageAbsolute = 0;
        }

        this.totalLast30DaysVolume = _.round(this.get30DaysCardTransactionVolume + this.get30DayBankTransactionVolume,2);
        this.totalPrior30DaysVolume = _.round(this.get60DaysCardTransactionVolume + this.get60DayBankTransactionVolume,2);
        this.total90DaysVolume = _.round(this.get90DaysCardTransactionVolume + this.get90DayBankTransactionVolume,2);
        if(this.totalPrior30DaysVolume != 0){
          this.last30DaysVolumePercentageChange = _.round (((this.totalLast30DaysVolume - this.totalPrior30DaysVolume) / this.totalPrior30DaysVolume) * 100,2);
          this.last30DaysVolumePercentageAbsolute = parseFloat(Math.abs(this.last30DaysVolumePercentageChange).toFixed(2))
        } else{
          this.last30DaysVolumePercentageChange= 0;
          this.last30DaysVolumePercentageAbsolute= 0;
        }

        if(this.total90DaysVolume != 0){
          this.prior30DaysVolumePercentageChange = _.round (((this.totalPrior30DaysVolume - this.total90DaysVolume) / this.total90DaysVolume) * 100,2);
          this.prior30DaysVolumePercentageAbsolute = parseFloat(Math.abs(this.prior30DaysVolumePercentageChange).toFixed(2))
        } else {
          this.prior30DaysVolumePercentageChange = 0;
          this.prior30DaysVolumePercentageAbsolute = 0;
        }
         
        res.last12MonthNewAccountActiveCount.forEach(data => {
          this.newAccountMonthlyCount.push(data.value);
          this.newAccountMonthlyDate.push(data.month_name);
        });

        res.last12MonthTotalLimit.forEach(data => {
          this.totalLimit12MonthlyCount.push(data.value);
          this.totalLimit12MonthlyDate.push(data.month_name);
        });

        res.last12MonthSpentAmount.forEach(data => {
           
          this.spend12MonthlyCount.push(data.value);
          this.spend12MonthlyDate.push(data.month_name);
        });

        res.last12MonthTotalBalance.forEach(data => {
           
          this.totalBalance12MonthlyCount.push(data.value);
          this.totalBalance12MonthlyDate.push(data.month_name);
        });

        this.loadDoughnutChart()
        this.loadBarChart()

      }));
    }

}