import { Component, Input, OnInit } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { map, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TradeAdminService } from '../../service/trade-admin.service';
import { CustomPaginatorComponent, openWindowAndDownloadWithFilename, PortalHotToastService, setupUntilDestroy, TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgTemplateOutlet, NgClass, NgStyle } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { OverdraftCustomerService } from '../../service/overdraft-customer.service';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportingService } from '../../service/reporting.service';
import { DateRangeDialogOkResult, ReportRecord, RequestReportFn, TradeReportingEntry, getPismoProgramsFn, ReportStatus, ReportType, DEFAULT_LIMIT, ReportSortType, DEFAULT_OFFSET} from '@portal-workspace/grow-shared-library';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import {loadingFor} from '@ngneat/loadoff';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { Sort, MatSortModule } from '@angular/material/sort';

const ALL_REPORTING_ENTRIES: TradeReportingEntry[] = [
  {type: 'reporting-banking-transactions', description: 'Banking Transaction Listing'},
  {type: 'reporting-brokerage-transactions', description: 'Brokerage Transaction Listing'},
  {type: 'reporting-pismo-accounting-events', description: 'Pismo Accounting Events'},
  {type: 'reporting-pismo-accounts-balances', description: 'Pismo Accounts Balances'},
  {type: 'reporting-pismo-accounts-limits', description: 'Pismo Accounts Limits'},
  {type: 'reporting-pismo-transactions-balance', description: 'Pismo Transactions Balance'},
  {type: 'reporting-pismo-transactions-denied', description: 'Pismo Transactions Denied'},
  {type: 'reporting-pismo-portfolio-balance', description: 'Pismo Portfolio Balance'},
  {type: 'reporting-pismo-arrears', description: 'Pismo Arrears Report'},
  {type: 'reporting-pismo-accounting-raw', description: 'Pismo Raw Accounting Report'},
  {type: 'reporting-pismo-accounting-aggregated', description: 'Pismo Aggregated Accounting Report'},
  {type: 'reporting-pismo-internal-accounts', description: 'Pismo Internal Accounts Report'},
  {type: 'reporting-authorization-clearing', description: 'Mastercard Transactions Report'},
  { type: 'reporting-pismo-applepay', description: 'Pismo Apple Pay Report' },
];

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './reporting.page.html',
    styleUrls: ['./reporting.page.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', display: 'block' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatTableModule, MatTooltipModule, NgTemplateOutlet, NgClass, ExtendedModule, NgStyle, MatTabsModule, MatIconModule, DatePipe, TagBoxComponent, CustomPaginatorComponent, MatSelectModule, ReactiveFormsModule, MatSortModule],
    providers: [ReportingService, TradeAdminService, OverdraftCustomerService, FormBuilder]
})
export class ReportingPage implements OnInit {

  @Input({required: false}) paywayCheckbox = false
  // expandedElement: TradeReportingEntry | null = null;

  // dataSource = new TradeReportingPageDataSource();
  loader = loadingFor('tableLoading');

  subscriptions: Subscription[] = [];
  pastRequests = new MatTableDataSource<ReportRecord>([]);
  requestReportFn!: RequestReportFn;
  total = 0;
  offset = 0;
  limit = DEFAULT_LIMIT;
  sorts: ReportSortType = [{prop: 'requestTime', dir: 'DESC'}];
  statusFilter: ReportStatus | 'allStatuses' = 'allStatuses';
  reportTypeFilter: ReportType | 'allReportTypes' = 'allReportTypes';
  statusTypes: (ReportStatus | 'allStatuses')[] = ['allStatuses', 'pending', 'completed', 'created', 'failed'];
  reportTypes: (ReportType | 'allReportTypes')[] = ['allReportTypes', 'reporting-banking-transactions', 'reporting-brokerage-transactions', 'reporting-pismo-accounting-events', 'reporting-pismo-accounts-balances', 'reporting-pismo-accounts-limits', 'reporting-pismo-transactions-balance', 'reporting-pismo-transactions-denied', 'reporting-pismo-portfolio-balance', 'reporting-pismo-arrears', 'reporting-pismo-accounting-raw', 'reporting-pismo-accounting-aggregated', 'reporting-pismo-internal-accounts', 'reporting-authorization-clearing', 'reporting-pismo-applepay'];
  pastRequestsColumnsToDisplay = ['reportType', 'startDateEndDate', 'status', 'programId', 'requestTime', 'completedTime', 'action'];


  formControlStatusFilter: FormControl<ReportStatus | 'allStatuses' | null>;
  formControlReportTypeFilter: FormControl<ReportType | 'allReportTypes' | null>;
  getPismoProgramsFn!: getPismoProgramsFn;
  
  

  constructor(private tradeAdminService: TradeAdminService,
    private overdraftCustomerService: OverdraftCustomerService,
    private reportingService: ReportingService,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private formBuilder: FormBuilder
  ) {
      this.requestReportFn = this.reportingService.requestReportFn;
      this.formControlStatusFilter = this.formBuilder.control('allStatuses');
      this.formControlReportTypeFilter = this.formBuilder.control('allReportTypes');
      this.getPismoProgramsFn = this.overdraftCustomerService.getPismoPrograms;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
    this.subscriptions.push(this.formControlStatusFilter.valueChanges.subscribe(() => {
      this.statusFilter = this.formControlStatusFilter.value ?? 'allStatuses';
      this.reload();
      this.reset();
    }));
    this.subscriptions.push(this.formControlReportTypeFilter.valueChanges.subscribe(() => {
      this.reportTypeFilter = this.formControlReportTypeFilter.value ?? 'allReportTypes';
      this.reload();
      this.reset();
    }));
  }

  reset() {
    this.offset = DEFAULT_OFFSET;
  }

  reload() {
    console.log('***** reload', this.sorts);
    this.subscriptions.push(this.reportingService.fetchReports(
      {
        page: {
          limit: this.limit, offset: this.offset,
        },
        sorts: this.sorts,
      },
      this.reportTypeFilter,
      this.statusFilter
    ).pipe(
      this.loader.tableLoading.track(),
      this.toastService.loadingWithMessage('Loading...'),
      tap(r => {
        this.total = r.total;
        this.pastRequests.data = r.payload;
      })
    ).subscribe());
  }
  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }

  downloadReport(report: ReportRecord) {
    if (report.reportPath) {
      this.reportingService.downloadReport(report.reportPath)
      .pipe(
        this.toastService.spinnerObservable(),
        tap(response => {
          openWindowAndDownloadWithFilename(`${report.reportType}-${new Date().getTime()}`, response);
        })
      ).subscribe();
    } else {
      this.toastService.error('Report not found');
    }
  }

  cancelReportRequest(report: ReportRecord) {
    this.applicationDialogService.openConfirmationDialog({
      message: 'Please confirm',
      subMessage: 'Are you sure you want to cancel this report request?'
    }).afterClosed().pipe(
      tap(r => {
        if (r && r.readyForSubmission) {
          this.reportingService.deleteReport(report.id).subscribe(response => {
            this.reload();
          }, error => {
            console.error('***** ERROR cancel report request', error);
          });
        }
      })
    ).subscribe();
  }

  deleteReport(report: ReportRecord) {
    this.applicationDialogService.openConfirmationDialog({
      message: 'Please confirm',
      subMessage: 'Are you sure you want to delete this report?'
    }).afterClosed().pipe(
      tap(r => {
        if (r && r.readyForSubmission) {
          this.reportingService.deleteReport(report.id).subscribe(response => {
            this.reload();
          }, error => {
            console.error('***** ERROR deleteReport', error);
          });
        }
      })
    ).subscribe();
  }

  parseReportTypeNames(reportType: string) {
    return reportType === 'allReportTypes' ? 'All Report Types' : ALL_REPORTING_ENTRIES.find(entry => entry.type === reportType)?.description ?? reportType;
  }
  parseStatusNames(status: string) {
    return status === 'allStatuses' ? 'All Statuses' : status.charAt(0).toUpperCase() + status.slice(1);
  }

  addNewRequest() {
    this.applicationDialogService.openNewReportDialog({
      requestReportFn: this.requestReportFn,
      getPismoProgramsFn: this.getPismoProgramsFn,
    }).afterClosed().subscribe(() => {
      this.reload()
    })
  }

  onSortData($event: Sort) {
    console.log('***** onSortData', $event);
    if ($event.direction && $event.active) {
      this.sorts = [{prop: $event.active, dir: $event.direction}] as ReportSortType;
    } else {
      this.sorts = null;
    }
    this.reload();
    this.reset();
  }
}
