import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {NewReportDialogData, NewReportDialogResult, ReportRequestDialogResult, RequestReportFn, TradeReportingEntry, getPismoProgramsFn } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { DateRangeDialogOkResult, ReportRecord } from '@portal-workspace/grow-shared-library';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import moment from 'moment';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApplicationDialogService } from './application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { map, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';


export class TradeReportingPageDataSource extends DataSource<TradeReportingEntry> {

  subject: BehaviorSubject<TradeReportingEntry[]> = new BehaviorSubject<TradeReportingEntry[]>([]);

  expandedElement: TradeReportingEntry | null = null;

  connect(collectionViewer: CollectionViewer): Observable<TradeReportingEntry[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(entries: TradeReportingEntry[]) {
    this.subject.next(entries);
  }
}

const ALL_REPORTING_ENTRIES: TradeReportingEntry[] = [
  {type: 'reporting-banking-transactions', description: 'Banking Transaction Listing'},
  {type: 'reporting-brokerage-transactions', description: 'Brokerage Transaction Listing'},
  {type: 'reporting-pismo-accounting-events', description: 'Pismo Accounting Events'},
  {type: 'reporting-pismo-accounts-balances', description: 'Pismo Accounts Balances'},
  {type: 'reporting-pismo-accounts-limits', description: 'Pismo Accounts Limits'},
  {type: 'reporting-pismo-transactions-balance', description: 'Pismo Transactions Balance'},
  {type: 'reporting-pismo-transactions-denied', description: 'Pismo Transactions Denied'},
  {type: 'reporting-pismo-portfolio-balance', description: 'Pismo Portfolio Balance'},
  {type: 'reporting-pismo-arrears', description: 'Pismo Arrears Report'},
  // {type: 'reporting-pismo-accounting-raw', description: 'Pismo Raw Accounting Report'},
  {type: 'reporting-pismo-accounting-aggregated', description: 'Pismo Aggregated Accounting Report'},
  {type: 'reporting-pismo-internal-accounts', description: 'Pismo Internal Accounts Report'},
  {type: 'reporting-authorization-clearing', description: 'Mastercard Transactions Report'},
  { type: 'reporting-pismo-applepay', description: 'Pismo Apple Pay Report' },
];

@Component({
    templateUrl: './new-report.dialog.html',
    styleUrls: ['./new-report.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule, MatIconModule, NgTemplateOutlet, NgStyle, DatePipe, MatTableModule, NgClass, MatTooltipModule],
    animations: [
      trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
          state('expanded', style({ height: '*', display: 'block' })),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
  ],
})
export class NewReportDialog implements OnInit {
  columnsToDisplay = ['dir', 'description', 'action'];
  expandedElement: TradeReportingEntry | null = null;

  dataSource = new TradeReportingPageDataSource();
  requestReportFn!: RequestReportFn;
  getPismoProgramsFn!: getPismoProgramsFn;

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewReportDialogData,
              private dialogRef: MatDialogRef<NewReportDialog, NewReportDialogResult>,
              private formBuilder: FormBuilder,
              private applicationDialogService: ApplicationDialogService,
              private toastService: PortalHotToastService) {
    this.requestReportFn = this.data.requestReportFn;
    this.getPismoProgramsFn = this.data.getPismoProgramsFn;
  }

  ngOnInit() {
    this.dataSource.update(ALL_REPORTING_ENTRIES);
  }

  isTableRowExpanded(element: TradeReportingEntry): boolean {
    return (this.expandedElement === element);
  }

  expandTableRow(element: TradeReportingEntry) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  getActionButtonText(element: TradeReportingEntry): string {
    return  'Request Report';
  }
  generateReport(event: Event, element: TradeReportingEntry) {
    event.stopPropagation();
    let message = ``;
    let mandatory = true;
    if (element.type === 'reporting-banking-transactions' || element.type === 'reporting-brokerage-transactions') {
      message = `Without date range it will download all transactions`;
      mandatory = false;
    }

    if (element.type === 'reporting-pismo-arrears') {
      this.requestReportFn(element.type, '', '', -1)
      .subscribe({
        next: (response: any) => {
          if (response.status) {
            this.applicationDialogService.successDialog({
              message: 'Your report request has been received.',
              subMessage: 'You will receive an email once it is available. In the meantime, you can track its status on the Report Requests page.',
            })
          } else {
            this.toastService.error('An error occurred while requesting the report. Please try again later.');
          }
        },
        error: (error: any) => {
          this.toastService.error('An error occurred while requesting the report. Please try again later.');
          console.error('***** ERROR', error);
        }
      });
      return;
    }

    const programSelectable = ![
      'reporting-banking-transactions',
      'reporting-brokerage-transactions',
      'reporting-pismo-arrears',
      'reporting-pismo-applepay',
      'reporting-pismo-transactions-denied',
      'reporting-pismo-internal-accounts'
    ].includes(element.type);

    this.applicationDialogService.openReportRequestDialog({
      message,
      mandatory,
      getPismoProgramsFn: this.getPismoProgramsFn,
      programSelectable: programSelectable
    })
      .afterClosed().pipe(
        filter((r): r is ReportRequestDialogResult => r?.type === 'ok'),
        map(r => ({
          startDateNew: element.type === 'reporting-banking-transactions' || element.type === 'reporting-brokerage-transactions' 
          ? `${moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ss")}.000` 
          : moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD"),
          endDateNew: element.type === 'reporting-banking-transactions' || element.type === 'reporting-brokerage-transactions' 
          ? `${moment(r.endDate ?? moment(), 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ss")}.000`
          : moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD"),
          programId: r?.pismoProgram ? r.pismoProgram.programId : -1
        })),
        switchMap(({startDateNew, endDateNew, programId}) => 
        this.requestReportFn(element.type, startDateNew, endDateNew, programId).pipe(
            tap(() => this.toastService.loadingWithMessage('Requesting report...')),
            catchError((error: any) => {
              this.toastService.error('An error occurred while requesting the report. Please try again later.');
              console.error('***** ERROR', error, element.type, startDateNew, endDateNew);
              return throwError(() => error);
            })
          )
        ))
        .subscribe({
          next: (response: any) => {
            if (response.status) {
              this.applicationDialogService.successDialog({
                message: 'Your report request has been received.',
                subMessage: 'You will receive an email once it is available. In the meantime, you can track its status on the Report Requests page.',
              })
              .afterClosed().subscribe();
            } else {
              this.toastService.error('An error occurred while requesting the report. Please try again later.');
            }
          },
          error: (error: any) => {
            this.toastService.error('An error occurred while requesting the report. Please try again later.');
            console.error('***** ERROR', error);
          }
        })
  }

  parseTypeNames(reportType: string) {
    return ALL_REPORTING_ENTRIES.find(entry => entry.type === reportType)?.description ?? reportType;
  }

  onClose() {
    this.dialogRef.close();
  }
}
