import { Component, Input, OnInit } from '@angular/core';
import {AllApplicationTypes, ApplicationTypes} from '@portal-workspace/grow-shared-library';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'application-type-icon',
    templateUrl: './application-type-icon.component.html',
    styleUrls: ['./application-type-icon.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, NgClass]
})
export class ApplicationTypeIconComponent implements OnInit {
  @Input({required: true}) applicationType!: AllApplicationTypes | 'Accreditation';

  constructor() { }

  ngOnInit(): void {
  }

}
