<div class="asset-application-details-component">
  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <div class="separator-bottom row">
      <!-- <div class="col-md-12" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-flat-button class="mat-flat-button-custom">
        <span class="mdi mdi-truck" matPrefix></span>
        Asset Finance
      </button>
      <span *ngIf="accreditation.Source == 'external'" class="ml-2 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
      <accreditation-stage-icon [stage]="getApplicationStage(accreditation)"></accreditation-stage-icon>
    </div> -->
  </div>
  @if (currentSection !== 'report') {
    <mat-tab-group
      [mat-stretch-tabs]="false"
      mat-align-tabs="start"
      [(selectedIndex)]="currentSelectionIndex"
      (selectedTabChange)="onSelectedTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi mdi-view-grid-outline"></span>
          ACCREDITATION
        </ng-template>
      </mat-tab>
      @if (accreditation.natureofBusiness != 'supplier') {
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="mdi mdi-certificate-outline"></span>
            DRIVING LICENCE
          </ng-template>
        </mat-tab>
      }
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi mdi-folder-outline"></span>
          DOCUMENTS
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi mdi-credit-card-lock-outline"></span>
          CREDIT
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  }
</div>

<div>
  @if (currentSection === 'acc') {
    <accreditation-acc
      [accreditation]="accreditation">
    </accreditation-acc>
  }

  @if (currentSection === 'documents') {
    <accreditation-documents
      [accreditation]="accreditation"
      [approveAccreditationDocumentFn]="approveAccreditationDocumentFn"
      [declineAccreditationDocumentFn]="declineAccreditationDocumentFn"
      [deleteAccreditationDocumentFn]="deleteAccreditationDocumentFn"
      [uploadAccreditationDocumentFn]="uploadAccreditationDocumentFn"
      [listAccreditationDocumentFn]="listAccreditationDocumentFn"
      [downloadAccreditationDocumentUrlFn]="downloadAccreditationDocumentUrlFn"
      [updateAccreditationDocumentTagsFn]="updateAccreditationDocumentTagsFn"
      [downloadAllAccreditationDocumentUrlFn]="downloadAllAccreditationDocumentUrlFn"
    ></accreditation-documents>
  }

  @if (currentSection === 'drivingLicence') {
    <accreditation-driving-licence
      [accreditation]="accreditation">
    </accreditation-driving-licence>
  }

  @if (currentSection === 'credit') {
    <accreditation-credit
      [accreditation]="accreditation"
      [webServices]="[]"
      [getWebServiceReportFn]="getWebServiceReportFn"
      [getAccreditationWebServicesFn]="getAccreditationWebServicesFn"
      [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
      [approveAccreditationFn]="approveAccreditationFn"
      [declineAccreditationFn]="declineAccreditationFn"
      [apiUrl]="apiUrl"
      [getUserFn]="getUserFn"
      [getAccreditationByIdFn]="getAccreditationByIdFn"
      [getAccreditationCreditFlowResultFn]="getAccreditationCreditFlowResultFn"
      [runAccreditationCreditFlowFn]="runAccreditationCreditFlowFn"
      (creditStatusUpdate)="onHandleCreditStatusUpdate()"
      (viewReport)="onViewReport($any($event))"
    ></accreditation-credit>
  }

  @if (currentSection === 'report') {
    <accreditation-report
      [getWebServiceReportFn]="getWebServiceReportFn"
      [apiUrl]="apiUrl"
      (leaveReport)="onLeaveReport()"
    ></accreditation-report>
  }
</div>
</div>

