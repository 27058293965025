<div class="graph-container">
<div class="applications-graph">
  <div class="columns">
    @for (column of data; track column.id) {
      <div class="column" [matTooltip]="getTooltip(column)" matTooltipShowDelay="500" matTooltipPosition="right" matTooltipClass="applications-graph-tooltip">
        <div class="segments-container">
          <div class="segments" [style.height.%]="(column.total / biggest) * 100" (click)="_onColumnClick(column)">
            <div class="segment under-review" [style.height.%]="(column.underReview / column.total) * 100">
            </div>
            <div class="segment approved" [style.height.%]="(column.approved / column.total) * 100">
            </div>
            <div class="segment in-settlement" [style.height.%]="(column.inSettlement / column.total) * 100">
            </div>
            <div class="segment closed-won" [style.height.%]="(column.closedWon / column.total) * 100">
            </div>
            <div class="segment closed-lost" [style.height.%]="(column.closedLost / column.total) * 100">
            </div>
            
          </div>
        </div>
        <div class="label">{{ column.label }}</div>
      </div>
    }
  </div>
</div>
</div>