import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Accreditation, AccreditationCreditFlow, AccreditationWebService, ApproveAccreditationFn, CreditDecisioningReasonDialogResult, CreditDeclineDialogResult, DeclineAccreditationFn, DownloadCreditorWatchReportFn, GetAccreditationByIdFn, GetAccreditationCreditFlowResultFn, GetAccreditationWebServicesFn, GetUserFn, RunAccreditationCreditFlowFn, ApplicationWebService } from '@portal-workspace/grow-shared-library';
import { DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { AccreditationWebServicesComponent } from './accreditation-web-services.component';
import { Subscription, combineLatest } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { AccreditationAlertsComponent } from './accreditation-alerts.component';
import moment from 'moment';
import { MatExpansionModule } from "@angular/material/expansion";
import { CreditFlowDetailsComponent } from '../credit-component/credit-flow-details.component';

@Component({
    selector: 'accreditation-credit',
    templateUrl: './accreditation-credit.component.html',
    styleUrls: ['./accreditation-credit.component.scss'],
    standalone: true,
    imports: [
      CellComponent, 
      MatDividerModule, 
      FlexModule, 
      MatButtonModule, 
      AccreditationWebServicesComponent,
      MessageBoxComponent,
      DatePipe,
      TagBoxComponent,
      AccreditationAlertsComponent,
      MatExpansionModule,
      CreditFlowDetailsComponent,
    ]
})
export class AccreditationCreditComponent implements OnInit {
  subscriptions: Subscription[] = [];
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) accreditation!: Accreditation;
  @Input({required: true}) getAccreditationWebServicesFn!: GetAccreditationWebServicesFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) approveAccreditationFn!: ApproveAccreditationFn;
  @Input({required: true}) declineAccreditationFn!: DeclineAccreditationFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getAccreditationByIdFn!: GetAccreditationByIdFn;
  @Input({required: true}) getAccreditationCreditFlowResultFn!: GetAccreditationCreditFlowResultFn;
  @Input({required: true}) runAccreditationCreditFlowFn!: RunAccreditationCreditFlowFn;
  @Output() creditStatusUpdate = new EventEmitter<void>();
  @Output() viewReport = new EventEmitter<AccreditationWebService>();

  
  webServices: AccreditationWebService[] = [];
  creditFlowResult: AccreditationCreditFlow[] = [];
  creditOfficer: string = '';

  constructor(
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {

  }

  ngOnInit() {
    console.log(this.accreditation);

    this.loadWebServices();

    this.subscriptions.push(
      this.getAccreditationCreditFlowResultFn(this.accreditation.AccreditationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(creditFlowResult => {
        this.creditFlowResult = creditFlowResult;
        console.log('creditFlowResult: ', creditFlowResult);
      })
    )

    if (this.accreditation.CreditOfficerStatusUpdatedBy) {
      this.subscriptions.push(
        this.getUserFn(this.accreditation.CreditOfficerStatusUpdatedBy, true).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(result => {
          if (result) {
            this.creditOfficer = `${result?.GivenName ?? ''} ${result?.FamilyName ?? ''}`;
          }
        })
      )
    } else if (this.accreditation.SystemCreditStatus) {
      this.creditOfficer = `System`;
    }
  }

  reload() {
    this.subscriptions.push(
      combineLatest([
        this.getAccreditationByIdFn(this.accreditation.AccreditationId),
        this.getAccreditationWebServicesFn(this.accreditation.AccreditationId),
        this.getAccreditationCreditFlowResultFn(this.accreditation.AccreditationId)
      ]).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(([accreditation, webServices, creditFlowResult]) => {
        this.accreditation = accreditation;
        this.webServices = webServices.map(ws => ({
          ...ws,
          applicationId: this.accreditation.AccreditationId,
          level: 'entity' as const,
          individualId: null
        }));
        console.log('webServices: ', webServices);
        this.creditFlowResult = creditFlowResult;
        console.log('creditFlowResult: ', creditFlowResult);
      })
    )
  }

  runCreditFlow() {
    this.subscriptions.push(
      this.runAccreditationCreditFlowFn(this.accreditation.AccreditationId, false).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(result => {
        if (result.status) {
          this.applicationDialogService.successDialog({
            message: 'Success',
            subMessage: result.message
          }).afterClosed().subscribe(() => {
            this.reload();
          })
        } else {
          this.applicationDialogService.openAlertDialog({
            message: 'Alert',
            subMessage: result.message
          }).afterClosed().subscribe()
        }
      })
    )
  }

  resumeCreditFlow() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Resume Credit Flow",
        subMessage: "This action will resume the credit flow from the point it failed last time. Are you sure to continue?"
      }).afterClosed().subscribe((result) => {
        if (result?.readyForSubmission) {
          this.runAccreditationCreditFlowFn(this.accreditation.AccreditationId, true).pipe(
            this.toastService.spinnerObservable()
          ).subscribe(result => {
            if (result.status) {
              this.applicationDialogService.successDialog({
                message: 'Success',
                subMessage: result.message
              }).afterClosed().subscribe(() => {
                this.reload();
              })
            } else {
              this.applicationDialogService.openAlertDialog({
                message: 'Alert',
                subMessage: result.message
              }).afterClosed().subscribe()
            }
          })
        }
      })
    )
  }

  onApprove() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Credit Approve",
        subMessage: "Are you sure to approve this accreditation?",
      }).afterClosed().subscribe(result => {
        if (result && result.readyForSubmission) {
          this.approveAccreditationFn({
            accreditationId: this.accreditation.AccreditationId,
            creditApprovalDate: moment().format('YYYY-MM-DD'),
            creditApprovalTime: moment().format('YYYY-MM-DD hh:mm a'),
          }).pipe(
            this.toastService.spinnerObservable(),
            this.toastService.snackBarObservable(`Accreditation Approved`),
          ).subscribe(() => {
            this.creditStatusUpdate.emit();
          })
        }
      })
    )
  }

  onDecline() {
    this.subscriptions.push(
      this.applicationDialogService.openCreditDeclineDialog({})
      .afterClosed().subscribe((result: CreditDeclineDialogResult | undefined) => {
          if (result && result.readyForSubmission) {
            this.declineAccreditationFn({
              accreditationId: this.accreditation.AccreditationId,
              reasonSelection: result.reasonSelection,
              reason: result.reason,
              category: result.category,
              closedLostTime: moment().format('YYYY-MM-DD hh:mm a'),
            }).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Accreditataion Declined`),
            ).subscribe(() => {
              this.creditStatusUpdate.emit();
            })
          }
        }
      )
    )
  }

  loadWebServices() {
    if (this.getAccreditationWebServicesFn) {
      this.subscriptions.push(
        this.getAccreditationWebServicesFn(this.accreditation.AccreditationId).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(webServices => {
          if (webServices) {
            this.webServices = webServices.map(ws => ({
              ...ws,
              applicationId: this.accreditation.AccreditationId,
              level: 'entity' as const,
              individualId: null
            }));
          }
        })
      );
    }
  }
}
