<div class="edit-credit-flow-node-dialog">
  <mat-dialog-content>
    <h3 class="header">Credit Flow Node</h3>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input [formControl]="formControlName" matInput placeholder="Enter credit flow node name" type="text">
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select [formControl]="formControlType">
            <mat-option [value]="'web service'">Web Service</mat-option>
            <mat-option [value]="'rule set'">Rule Set</mat-option>
            <mat-option [value]="'route'">Route</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Is this a start node?</mat-label>
          <mat-select [formControl]="formControlIsStart">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Is this an end node?</mat-label>
          <mat-select [formControl]="formControlIsEnd">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
    @if (formControlType.value === 'rule set') {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Next Node</mat-label>
            <mat-select [formControl]="formControlNextNode">
              @for (option of nextNodeOptions; track option) {
                <mat-option [value]="option.id">{{option.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }

    @if (formControlType.value === 'web service') {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Web Service</mat-label>
            <mat-select [formControl]="formControlWebService">
              @for (option of webServiceOptions; track option) {
                <mat-option [value]="option.name">{{option.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Next Node</mat-label>
            <mat-select [formControl]="formControlNextNode">
              @for (option of nextNodeOptions; track option) {
                <mat-option [value]="option.id">{{option.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }

    @if (formControlType.value === 'web service') {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Level</mat-label>
            <mat-select [formControl]="formControlLevel">
              <mat-option [value]="'Entity'">Entity</mat-option>
              <mat-option [value]="'Individual'">Individual</mat-option>
              <mat-option [value]="'Accreditation'">Accreditation</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        @if (formControlLevel.value === 'Entity' || formControlLevel.value === 'Individual' ||  formControlLevel.value === 'Accreditation') {
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Condition</mat-label>
              <mat-select [formControl]="formControlConditionVariable">
                @for (option of creditRuleItemOptions; track option) {
                  <mat-option [value]="option.id">{{option.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
    }

    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onCancel()" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formControlName.valid" (click)="onSubmit()" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
