<div class="data-box-component" [ngClass]="{'cursor-pointer': clickable && !disabled, 'selected': selected, 'disabled': disabled }" (click)="onClick($event)">
  @if (icon) {
    <span class="mdi mdi-{{icon}}"></span>
  }
  @if (title) {
    <span class="title">{{title}}</span>
  }
  @if (data) {
    <span class="content {{type}}">{{data}}</span>
  }
</div>
