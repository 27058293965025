<div class="faq-page container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="faq-title separator-bottom ml-2" fxLayout="row" fxLayoutAlign="center center">
            <span class="mdi mdi-lifebuoy"></span>
            <div class="mat-h2">Frequently Asked Questions</div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>
                <span style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i class="mdi mdi-file-document-check-outline"></i></span>
                How do I submit an application?
              </div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <ol class="pl-4">
              <li>Select the <a routerLink="/s/new-application"><u>New Application</u></a> tab from the left sidebar menu.</li>
              <li>Select the product you wish to apply for.</li>
              <li>Follow the prompts and complete all required fields.</li>
              <li>Review the summarised quotation provided based on your details. If you agree, click to accept the quotation.</li>
              <li>Once you've reviewed the final application summary, click the <b>Submit</b> button to process your application.</li>

            </ol>
          </ng-container>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>
                <span style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i class="mdi mdi-phone"></i>
                </span>
                How do I complete mobile and email verification?
              </div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
              <h4 style="color: var(--portal2-primary-default); font-style: italic;">Verify your phone number:</h4>
              <ol class="pl-4">
                <li>After signing up, a verification code will be sent to your phone number via text message.</li>
                <li>Enter the verification code from the text message in the Dynamoney app to verify your phone number.</li>
              </ol>
              <h4 style="color: var(--portal2-primary-default); font-style: italic;">Verify your email address:</h4>
              <ol class="pl-4">
                <li>After signing up, and any other time your update your email address, we’ll send a message to the email
                  address that you entered.</li>
                <li>Click the link in the email to verify ownership of your email address.</li>
              </ol>
              <h4 style="color: var(--portal2-primary-default); font-style: italic;">Having trouble with phone or email verification?</h4>
                We’re sorry that you experienced an issue with our portal. To report a technical problem or get any
                help, please <a href="https://www.dynamoney.com/contact-us" target="_blank" rel="noopener"><u>contact our support team
                  directly.</u></a>
                  <p style="margin-bottom: 10px;"></p>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div><span style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i class="mdi mdi-account-plus-outline"></i></span>
                How do I add a new user?
              </div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <ol class="pl-4">
              <li>Click on the Admin tab under your organisation on the left sidebar menu.</li>
              <li>A drop-down menu will appear, click <a routerLink="/s/users"><u>Users</u></a>.</li>
              <li>On the top right of the page, click the <b>Add Broker User</b> button.</li>
              <li>A pop-up form will appear. Fill in the required fields and <b>Submit</b> a new user.</li>

            </ol>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>
                <span style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i class="mdi mdi-list-status"></i></span>
                What is the status of my application?
              </div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <p>The status bar helps you understand where your application is in the queue. The categories and their
              definitions are as follows:</p>
            <div class="container">
              <div fxLayout="row wrap" fxLayout.lt-md="column">
                <div fxFlex="50" fxFlex.lt-md="100">
                  <div class="d-flex align-items-center mb-3">
                    <div class="col-md-4 text-center">
                      <tag-box state="draft"></tag-box>
                    </div>
                    <div class="col-md-8">
                      <p class="mb-0">Your application has not been submitted yet. All changes made have been saved.</p>
                    </div>
                  </div>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100">
                  <div class="d-flex align-items-center mb-3">
                    <div class="col-md-4 text-center">
                      <tag-box state="under Review"></tag-box>
                    </div>
                    <div class="col-md-8">
                      <p class="mb-0">Your application has been successfully submitted to our credit team and has joined the queue.</p>
                    </div>
                  </div>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100">
                  <div class="d-flex align-items-center mb-3">
                    <div class="col-md-4 text-center">
                      <tag-box state="settlement"></tag-box>
                    </div>
                    <div class="col-md-8">
                      <p class="mb-0">Your application has been credit approved. The outcome of your application will be revealed shortly.</p>
                    </div>
                  </div>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100">
                  <div class="d-flex align-items-center mb-3">
                    <div class="col-md-4 text-center">
                      <tag-box state="Settled"></tag-box>
                    </div>
                    <div class="col-md-8">
                      <p class="mb-0">Congratulations, your application has been successful! Your loan has been settled and funds have been disbursed.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>
                <span style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i class="mdi mdi-file-document-plus-outline"></i></span>
                How do I upload additional documents for an application?
              </div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
              <p>You can upload documents both by using the Drag & Drop feature or by selecting files from your
                computer.</p>
              <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-top: 15px;">Drag and Drop:</h4>
              <ol class="pl-4">
                <li>Select the file/s on your computer you want to upload.</li>
                <li>Drag them to the Drag and Drop zone, stroked by a dashed line.</li>
                <li>Release / Drop the file/s.</li>
                <li>Your file/s will be uploaded automatically.</li>
              </ol>
              <h4 style="font-style: italic; color: var(--portal2-primary-default);">Select and upload:</h4>
              <ol class="pl-4">
                <li>Click on the Drag and Drop zone, stroked by a dashed line.</li>
                <li>Select the file/s on your computer you want to upload.</li>
                <li>Submit your selection.</li>
                <li>Your file/s will be uploaded automatically.</li>
              </ol>
              <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-bottom: 10px;">Please note, the allowed format of files you are able to upload are listed inside the Drag and Drop
                zone (e.g. PNG, JPG and PDF are allowed). Different applications may require different documents,
                so allowed formats might vary from case to case.</h4>
          </ng-container>
        </mat-expansion-panel>


  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <div fxLayout="column" class="supplier-name">
        <div>
          <span style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i class="mdi mdi-alert-circle-outline"></i></span>
          How do I report a technical problem?
        </div>
      </div>
    </mat-expansion-panel-header>
    <ng-container>
      <p>We’re sorry that you experienced an issue with our portal. To report a technical problem or get any help,
        please <a href="https://www.dynamoney.com/contact-us" target="_blank" rel="noopener"><u>contact our support team directly</u></a>.
      </p>
      </ng-container>
    </mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <div fxLayout="column" class="supplier-name">
      <div><span
          style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i
            class="mdi mdi-lock-reset"></i></span>
          How do I reset my password?
      </div>
    </div>
  </mat-expansion-panel-header>
  <ng-container>
    <ol class="pl-4">
      <li>Select <a routerLink="/s/profile"><u>Your Name</u></a> under Account on the left sidebar menu.</li>
      <li>Scroll to the bottom of the page and select <b>Change password</b>.</li>
      <li>Enter your current password.</li>
      <li>Enter your new password and confirm it by entering it again.</li>
      <li>Select <b>Submit</b> to save your changes.</li>
    </ol>
    <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-bottom: 15px; margin-top: 15px;">Forgot your password?</h4>
    <ol class="pl-4">
    <li>Click <a routerLink="/m/forgot-password"><u>Forgot password?</u></a> at the bottom of the dialog box.</li>
    <li>Follow the on-screen instructions to reset your password.</li>
  </ol>
  <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-bottom: 10px; margin-top: 15px;">Tip: Your password must be at least 8 characters long and include atleast one numeric digit, one uppercase and one lowercase.</h4>

  </ng-container>
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <div fxLayout="column" class="supplier-name">
      <div><span
          style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i
            class="mdi mdi-card-account-details-outline"></i></span>
          What are the supported browsers and devices for accessing the Dynamoney Portal?
      </div>
    </div>
  </mat-expansion-panel-header>
  <ng-container>
    <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-bottom: 15px; margin-top: 5px;">Supported browsers:</h4>
    <ul style="padding-left: 20px; margin: 0;">
      <li>Google Chrome</li>
      <li>Mozilla Firefox</li>
      <li>Safari</li>
      <li>Microsoft Edge</li>
    </ul>
    <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-bottom: 15px; margin-top: 15px;">Supported devices:</h4>
    <ul style="padding-left: 20px; margin: 0;">
      <li>Desktop</li>
      <li>Laptop</li>
      <li>Tablet</li>
      <li>Mobile</li>
    </ul>
    <h4 style="font-style: italic; color: var(--portal2-primary-default); margin-bottom: 5px; margin-top: 15px;">Tip: Keep your browser updated to ensure optimal performance and security.</h4>
  </ng-container>
</mat-expansion-panel>
<!-- <mat-expansion-panel>
  <mat-expansion-panel-header>
    <div fxLayout="column" class="supplier-name">
      <div><span
          style="display: inline-flex; align-items: center; justify-content: center; width: 30px; height: 30px; background-color: var(--portal2-primary-default); color: white; border-radius: 50%; margin-right: 8px; text-align: center;"><i
            class="mdi mdi-card-account-details-outline"></i></span>
          How do I update my business information?
      </div>
    </div>
  </mat-expansion-panel-header>
  <ng-container>
    <ol class="pl-4">
      <li>TBD</li>
    </ol>
  </ng-container>
</mat-expansion-panel> -->
</mat-accordion>

</div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <div class="more-faq" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center center">
      <button mat-flat-button color="primary" class="w-lt-md-100 w-50" (click)="onStillNeedHelpClick($event)">
        Still need help?
        <span class="mdi mdi-lifebuoy"></span>
      </button>
    </div>
  </div>
</div>
</div>
