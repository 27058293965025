import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Subject} from 'rxjs';
import {navigationUrlForApplicationErrorPage, navigationUrlForDirectSaleApplicationErrorPage} from '../service/navigation-urls';
import {catchError, tap} from 'rxjs/operators';
import {ApplicationService, } from '../service/application.service';
import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
import {inject} from '@angular/core';
import { Buffer } from 'buffer';

export const applicationErrorPageNavigation = (router: Router,applicationId: number,queryParams: { [key: string]: string | string[] | undefined; } | undefined)  => {
  return router.createUrlTree(navigationUrlForApplicationErrorPage(applicationId),{queryParams});
}

export const directSaleApplicationErrorPageNavigation = (router: Router,applicationId: number,queryParams: { [key: string]: string | string[] | undefined; } | undefined)  => {
  return router.createUrlTree(navigationUrlForDirectSaleApplicationErrorPage(applicationId,queryParams));
}


export const validApplicationTypeGuard = (
  applicationType: ApplicationTypes,
  applicationErrorCode: 'e-not-business' | 'e-not-trade' | 'e-not-invoice' | 'e-not-insurance' | 'e-not-floorplan' | 'e-not-corporate' | 'e-not-consumer' | 'e-not-commercial' | 'e-not-asset',
): CanActivateFn => {



  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const applicationService = inject(ApplicationService);
    const router = inject(Router);
    const applicationId = route.params.applicationId;
     
    if (!!!applicationId) {
      return applicationErrorPageNavigation(router,applicationId,{type: 'e1'});
    }

    const subject = new Subject<boolean | UrlTree>();
    const sub = applicationService.getApplicationById(applicationId).pipe(
      tap(r => {
        const application = r.payload;
        if (!application) {
          subject.next(applicationErrorPageNavigation(router,applicationId,{type: 'e-general'}));
          subject.complete();
           
        } else if (application && application.ApplicationType !== applicationType) {
          subject.next(applicationErrorPageNavigation(router,applicationId,{type: applicationErrorCode}));
          subject.complete();
        } else if (application && application.Status !== 'Draft') {
          subject.next(applicationErrorPageNavigation(router,applicationId,{type: 'e-not-draft'}));
          subject.complete();
        } else {
          subject.next(true);
          subject.complete();
        }
      }),
      catchError(e => {
        console.log(e);
        subject.next(applicationErrorPageNavigation(router,applicationId,{type: 'e-general'}));
        subject.complete();
        throw e;
      })
    ).pipe().subscribe();
    return subject;
  }
}

export const validDirectSaleApplicationTypeGuard = (
  applicationType: ApplicationTypes,
  applicationErrorCode: 'e-not-business' | 'e-not-trade' | 'e-not-invoice' | 'e-not-insurance' | 'e-not-floorplan' | 'e-not-corporate' | 'e-not-consumer' | 'e-not-commercial' | 'e-not-asset' | 'e-invalid-link',
): CanActivateFn => {


  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const applicationService = inject(ApplicationService);
    const router = inject(Router);
    const encodedApplicationId = route.params.encodedApplicationId;
    if (!!!encodedApplicationId) {
      return  directSaleApplicationErrorPageNavigation(router,encodedApplicationId,{type: 'e1'});
    }

    const subject = new Subject<boolean | UrlTree>();
    const code = Buffer.from(encodedApplicationId, 'base64').toString('binary');
    const codeSplits = code.split('/');
    if (codeSplits.length == 4 && !isNaN(Number(codeSplits[3]))) {
      const applicationId = Number(codeSplits[3]);
      const firstName = codeSplits[0];
      const lastName = codeSplits[1];
      const email = codeSplits[2];
      const token = Buffer.from(`${applicationId}=${firstName}=${lastName}=${email}`).toString('base64');
      const sub = applicationService.getApplicationByIdForDirectSale(applicationId, token).pipe(
        tap(r => {
          const application = r.payload;
          if (application.ApplicationType !== applicationType) {
            subject.next(directSaleApplicationErrorPageNavigation(router,applicationId,{type: applicationErrorCode}));
            subject.complete();
          } else if (application.Status !== 'Draft') {
            subject.next(
              directSaleApplicationErrorPageNavigation(router,applicationId,{type: 'e-not-draft'}));
            subject.complete();
          } else {
            subject.next(true);
            subject.complete();
          }
        }),
        catchError(e => {
          console.log(e);
          subject.next(
            directSaleApplicationErrorPageNavigation(router,applicationId,{type: 'e-general'}));
          subject.complete();
          throw e;
        })
      ).pipe().subscribe();
    } else {
      return directSaleApplicationErrorPageNavigation(router,0,{type: 'e-invalid-link'});
    }
    return subject;
  }
}


// NOTE: Deprecated since Angular 16
// @UntilDestroy({arrayName: 'subscription'})
// @Injectable()
// export abstract class AbstractApplicationGuard implements OnInit  {
//
//   subscriptions: Subscription[] = [];
//
//   constructor(private applicationService: ApplicationService,
//               private router: Router) {
//   }
//
//   abstract getApplicationType(): ApplicationTypes;
//   abstract getApplicationTypeErrorCode(): string;
//
//
//   ngOnInit() {
//     setupUntilDestroy(this);
//   }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     const applicationId = route.params.applicationId;
//     if (!!!applicationId) {
//       return  this.router.createUrlTree(navigationUrlForApplicationErrorPage(applicationId), {
//         queryParams: {
//           type: 'e1'
//         }
//       });
//     }
//
//     const subject = new Subject<boolean | UrlTree>();
//     const sub = this.applicationService.getApplicationById(applicationId).pipe(
//       tap(r => {
//         const application = r.payload;
//         if (application.ApplicationType !== this.getApplicationType()) {
//           subject.next(this.router.createUrlTree(navigationUrlForApplicationErrorPage(applicationId), {
//             queryParams: {
//               type: this.getApplicationTypeErrorCode(),
//             }
//           }));
//           subject.complete();
//         } else if (application.Status !== 'Draft') {
//           subject.next(this.router.createUrlTree(navigationUrlForApplicationErrorPage(applicationId), {
//             queryParams: {
//               type: 'e-not-draft'
//             }
//           }));
//           subject.complete();
//         } else {
//           subject.next(true);
//           subject.complete();
//         }
//       }),
//       catchError(e => {
//         console.log(e);
//         subject.next(this.router.createUrlTree(navigationUrlForApplicationErrorPage(applicationId), {
//           queryParams: {
//             type: 'e-general'
//           }
//         }));
//         subject.complete();
//         throw e;
//       })
//     ).subscribe();
//     this.subscriptions.push(sub);
//
//     return subject;
//   }
//
// }
