<div class="date-range-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col">
        <daterange [formControl]="formControlDateRangePicker" [mandatory]="data.mandatory"></daterange>
      </div>
    </div>
    <br>
      @if (data.message) {
        <div class="row">
          <div class="col">
            <mat-hint class="ml-3">{{data.message}}</mat-hint>
          </div>
        </div>
      }
      <br>
        <div class="row">
          <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-stroked-button color="primary" class="ml-3" (click)="cancel($event)">cancel</button>
            <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Request Report</button>
          </div>
        </div>
      </mat-dialog-content>
    </div>
