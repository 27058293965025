import {AfterViewInit, Component, ElementRef, forwardRef, Inject, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Address2ComponentValue, compareMatch, getStates, getStreetTypes, parseRawAddress, parseAddressComponentsToString} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {CustomErrorStateMatcher, formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '../component-utils';
import PlaceResult = google.maps.places.PlaceResult;
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest, Subscription} from 'rxjs';


const DEFAULT_STREET_TYPE = getStreetTypes().find(st => st.name == 'street')?.type;

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'custom-address',
  standalone: true,
  templateUrl: './custom-address.component.html',
  styleUrls: ['./custom-address.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomAddressComponent), multi: true},
    { provide: MARK, useExisting: forwardRef(() => CustomAddressComponent)},
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule
],
})
export class CustomAddressComponent extends AbstractControlValueAccessor<Address2ComponentValue> implements OnInit, AfterViewInit, Mark {

  subscriptions: Subscription[] = [];

  @Input({required: false}) title: string = 'Business address';
  @Input({required: false}) localOnly: boolean = true;
  @Input({required: false}) types: ('establishment' | 'address' | 'geocode')[] = ['address'];

  @ViewChild('mapInput') mapInput?: ElementRef<any>;

  streetTypes = getStreetTypes();
  states = getStates();
  errorStateMatcher = new CustomErrorStateMatcher();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup: FormGroup<{
    address: FormControl<string | null>,
    UnitNumber: FormControl<string | null>,
    StreetNumber: FormControl<string | null>,
    StreetName: FormControl<string | null>,
    StreetType: FormControl<string | null>,
    Suburb: FormControl<string | null>,
    State: FormControl<string | null>,
    Postcode: FormControl<string | null>,
  }>;
  formControlAddress: FormControl<string | null>;
  formControlUnitNumber: FormControl<string | null>;
  formControlStreetNumber: FormControl<string | null>;
  formControlStreetName: FormControl<string | null>;
  formControlStreetType: FormControl<string | null>;
  formControlSuburb: FormControl<string | null>;
  formControlState: FormControl<string | null>;
  formControlPostcode: FormControl<string | null>;
  streetTypeCompareWith: (a: string, b: string) => boolean;
  stateCompareWith: (a: string, b: string) => boolean;

  inputPattern = /^(?!\s)[a-zA-Z0-9_'\s\-/,-]+$/;


  constructor(private formBuilder: FormBuilder,
              @Inject(DOCUMENT) private document: Document,
              private  renderer2: Renderer2) {
    super();
    this.streetTypeCompareWith = (a: string, b: string) => {
      const r = ((a ?? '').toLowerCase().trim() === (b ?? '').toLowerCase().trim());
      return r;
    };
    this.stateCompareWith = (a: string, b: string) => {
      return ((a ?? '').toLowerCase().trim() === (b ?? '').toLowerCase().trim());
    }
    this.formControlAddress = formBuilder.control(null, [Validators.required]);
    this.formControlUnitNumber = formBuilder.control(null,Validators.pattern(this.inputPattern));
    this.formControlStreetNumber = formBuilder.control(null, [Validators.required,Validators.pattern(this.inputPattern)]);
    this.formControlStreetName = formBuilder.control(null, [Validators.required,Validators.pattern(this.inputPattern)]);
    this.formControlStreetType = formBuilder.control(null, [Validators.required]);
    this.formControlSuburb = formBuilder.control(null, [Validators.required,Validators.pattern(this.inputPattern)]);
    this.formControlState = formBuilder.control(null, [Validators.required]);
    this.formControlPostcode = formBuilder.control(null, [Validators.required, Validators.pattern('^[0-9]{4}$')]);
    this.formGroup = formBuilder.group({
      address: this.formControlAddress,
      UnitNumber: this.formControlUnitNumber,
      StreetNumber: this.formControlStreetNumber,
      StreetName: this.formControlStreetName,
      StreetType: this.formControlStreetType,
      Suburb: this.formControlSuburb,
      State: this.formControlState,
      Postcode: this.formControlPostcode,
    });
  }

  override setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
    if (isDisabled) {
      this.formControlAddress.disable();
      this.formControlUnitNumber.disable();
      this.formControlStreetNumber.disable();
      this.formControlStreetName.disable();
      this.formControlStreetType.disable();
      this.formControlSuburb.disable();
      this.formControlState.disable();
      this.formControlPostcode.disable();
    } else {
      this.formControlAddress.enable();
      this.formControlUnitNumber.enable();
      this.formControlStreetNumber.enable();
      this.formControlStreetName.enable();
      this.formControlStreetType.enable();
      this.formControlSuburb.enable();
      this.formControlState.enable();
      this.formControlPostcode.enable();
    }
  }
  doWriteValue(v: Address2ComponentValue | null | undefined): void | Address2ComponentValue {
    if (v) {
      // HACK: to repopulate google address input text field
      setTimeout(() => {
        const inputElement = this.mapInput?.nativeElement;
        if (inputElement) {
          inputElement.value = v ? v.address : '';
        }
        this.formControlUnitNumber.setValue(v.UnitNumber ?? '');
        this.formControlStreetNumber.setValue(v.StreetNumber);
        this.formControlStreetName.setValue(v.StreetName);
        this.formControlStreetType.setValue(v.StreetType);
        this.formControlPostcode.setValue(v.Postcode);
        this.formControlSuburb.setValue(v.Suburb);
        this.formControlState.setValue(v.State);
      });
    }
    return undefined;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  async ngAfterViewInit() {
    // await this.loadGoogleScript();
    const autocomplete = new google.maps.places.Autocomplete(
      this.mapInput?.nativeElement,
      {
        componentRestrictions: {country: this.localOnly ? ['au'] : []},
        types: this.types
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const placeResult: PlaceResult = autocomplete.getPlace();
      this.onPlaceSelected(placeResult);
    });

    setTimeout(() => {
      // let address = this.formControlUnitNumber.value ? `${this.formControlUnitNumber.value}/${this.formControlStreetNumber.value ?? ''}` : `${this.formControlStreetNumber.value ?? ''}`;
      // address += ` ${this.formControlStreetName.value ?? ''} ${this.formControlStreetType.value ?? ''}`
      // address += `${this.formControlSuburb.value ? ', ' + this.formControlSuburb.value : ''}`;
      // address += `${this.formControlState.value ? ', ' + this.formControlState.value : ''}`;
      // address += `${this.formControlPostcode.value ? ' ' + this.formControlPostcode.value : ''}`;
      const address = parseAddressComponentsToString(
        this.formControlUnitNumber.value,
        this.formControlStreetNumber.value,
        this.formControlStreetName.value,
        this.formControlStreetType.value,
        this.formControlSuburb.value,
        this.formControlState.value,
        this.formControlPostcode.value,
      );
      this.formControlAddress.setValue(address)
    })
  }


  // loadGoogleScript() {
  //   return new Promise((res, rej) => {
  //     const existingScript = this.document.querySelector('#googlemapcustomaddresscomponent');
  //     console.log('**** existingScript', existingScript);
  //     if (!existingScript) {
  //       const script = this.renderer2.createElement('script');
  //       script.id = `googlemapcustomaddresscomponent`;
  //       script.type = `text/javascript`;
  //       script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAcitFyUjFmkdY4racefrQNgl2YkCInu7M`;
  //       script.async = true;
  //       script.defer = true;
  //       script.onload = res;
  //       script.onerror = rej;
  //       this.renderer2.appendChild(this.document.body, script);
  //     } else {
  //       res(null);
  //     }
  //   });
  // }

  async ngOnInit() {

    // <script src=”https://maps.googleapis.com/maps/api/js?libraries=places&key=GOOGLE_KEY"></script>
    // const googlemapscript = document.querySelector('#googlemapid');
    // if (!googlemapscript) {
    //   const scriptElement = document.createElement('script');
    //   scriptElement.src =`https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.}`
    // }

    // (google as any).load('maps', '3', {other_params:`libraries=places`});


    setupUntilDestroy(this);


    // formgroup changes
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (r && (this.formGroup.valid || this.formGroup.disabled)) {
          // note: r and r.* should never be falsy anyway, cause then the formGroup will not be valid
          // let address = this.formControlUnitNumber.value ? `${this.formControlUnitNumber.value}/${this.formControlStreetNumber.value ?? ''}` : `${this.formControlStreetNumber.value ?? ''}`;
          // address += ` ${this.formControlStreetName.value ?? ''} ${this.formControlStreetType.value ?? ''}`
          // address += `${this.formControlSuburb.value ? ', ' + this.formControlSuburb.value : ''}`;
          // address += `${this.formControlState.value ? ', ' + this.formControlState.value : ''}`;
          // address += `${this.formControlPostcode.value ? ' ' + this.formControlPostcode.value : ''}`;
          const address = parseAddressComponentsToString(
            this.formControlUnitNumber.value,
            this.formControlStreetNumber.value,
            this.formControlStreetName.value,
            this.formControlStreetType.value,
            this.formControlSuburb.value,
            this.formControlState.value,
            this.formControlPostcode.value,
          );
          this.propagateChange({
            address: address ?? '',
            UnitNumber: this.formControlUnitNumber.value ?? '',
            StreetNumber: this.formControlStreetNumber.value ?? '',
            StreetName: this.formControlStreetName.value ?? '',
            StreetType: this.formControlStreetType.value ?? '',
            Suburb: this.formControlSuburb.value ?? '',
            State: this.formControlState.value ?? '',
            Postcode: this.formControlPostcode.value ?? '',
          });
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());

    this.subscriptions.push(combineLatest([
      this.formControlUnitNumber.valueChanges,
      this.formControlStreetNumber.valueChanges,
      this.formControlStreetName.valueChanges,
      this.formControlStreetType.valueChanges,
      this.formControlSuburb.valueChanges,
      this.formControlState.valueChanges,
      this.formControlPostcode.valueChanges,
    ]).pipe(tap(r => {
      const address = parseAddressComponentsToString(
        r[0], // this.formControlUnitNumber.value,
        r[1], // this.formControlStreetNumber.value,
        r[2], // this.formControlStreetName.value,
        r[3], // this.formControlStreetType.value,
        r[4], // this.formControlSuburb.value,
        r[5], // this.formControlState.value,
        r[6], // this.formControlPostcode.value,
      );
      this.formControlAddress.setValue(address);
    })).subscribe());
  }

  onPlaceSelected($event: google.maps.places.PlaceResult) {
    const placeSelected = $event;
    if (placeSelected && placeSelected.formatted_address) {
      const address = placeSelected.formatted_address;
      this.parseAndPopulate(address, placeSelected);
    }
  }

  private parseAndPopulate(rawAddress: string, placeResult?: PlaceResult) {
    const parsedAddress = parseRawAddress(rawAddress);
    console.log('[ADDRESS COMPONENT] placeResult', placeResult);
    console.log('[ADDRESS COMPONENT] parsed result', parsedAddress);
    if (placeResult) {
      // subpremise  - unit number
      // street_number - street number
      // route  - street name & street type
      // locality - suburb
      // administrative_area_level_1 - region, state
      // postal_code - postcode

      let unitNumberAddComp = (placeResult.address_components ?? []).find(c => c.types.includes('subpremise'));
      if (unitNumberAddComp && unitNumberAddComp.short_name && unitNumberAddComp.short_name.length <= 8) {
        this.formControlUnitNumber.setValue(unitNumberAddComp.short_name ?? '');
      } else {
        unitNumberAddComp = (placeResult.address_components ?? []).find(c => c.types.includes('premise'));
        if (unitNumberAddComp && unitNumberAddComp.short_name && unitNumberAddComp.short_name.length <= 8) {
          this.formControlUnitNumber.setValue(unitNumberAddComp.short_name ?? '');
        } else {
          this.formControlUnitNumber.setValue(''); // can't find unit number
        }
      }
      const streetNumberAddComp = (placeResult.address_components ?? []).find(c => c.types.includes('street_number'));
      if (streetNumberAddComp) {
        this.formControlStreetNumber.setValue(streetNumberAddComp.short_name);
      }
      const streetNameAndTypeComp = (placeResult.address_components ?? []).find(c => c.types.includes('route'))
      if (streetNameAndTypeComp) {
        const streetNameAndType = streetNameAndTypeComp.short_name;
        if (parsedAddress && parsedAddress.street_type) {  // if we manage to manually parsed a street type
          const i = streetNameAndType.lastIndexOf(' ');
          const streetName = i > -1 ? streetNameAndTypeComp.short_name.substring(0, i) : streetNameAndType;
          this.formControlStreetName.setValue(streetName);
        } else { // this means there is no street type
          this.formControlStreetName.setValue(streetNameAndType);
        }
      }
      if (parsedAddress) { // google address returned street name and type as a whole lot ( as route route)
        if (parsedAddress.street_type) { // if we manage to get a street type
          this.formControlStreetType.setValue(parsedAddress.street_type);
        } else { // else we default to a default street type (cause inteflow needs a street type)
          this.formControlStreetType.setValue(DEFAULT_STREET_TYPE ?? null);
        }
      }
      const localityAddComp = (placeResult.address_components ?? []).find(c => c.types.includes('locality'));
      if (localityAddComp) {
        this.formControlSuburb.setValue(localityAddComp.short_name);
      }
      const stateAddComp = (placeResult.address_components ?? []).find(c => c.types.includes('administrative_area_level_1'));
      if (stateAddComp) {
        this.formControlState.setValue(stateAddComp.short_name);
      }
      const postcodeAddComp = (placeResult.address_components ?? []).find(c => c.types.includes('postal_code'));
      if (postcodeAddComp) {
        this.formControlPostcode.setValue(postcodeAddComp.short_name);
      }

      parseAddressComponentsToString(
        this.formControlUnitNumber.value,
        this.formControlStreetNumber.value,
        this.formControlStreetName.value,
        this.formControlStreetType.value,
        this.formControlSuburb.value,
        this.formControlState.value,
        this.formControlPostcode.value,
        // this.formControlAddress
      );

    } else if (parsedAddress) {
      this.formControlUnitNumber.setValue(parsedAddress.unit_number ?? null);
      this.formControlStreetName.setValue(parsedAddress.street_name);
      this.formControlStreetType.setValue(parsedAddress.street_type);
      this.formControlStreetNumber.setValue(parsedAddress.street_number);
      this.formControlState.setValue(parsedAddress.region);
      this.formControlSuburb.setValue(parsedAddress.locality);
      this.formControlPostcode.setValue(parsedAddress.postal_code);
    }
  }


}
