<div class="credit-decisioning-reason-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Credit Approve</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Deal Status</mat-label>
          <mat-select [formControl]="formControlDealStatus">
            @for (option of dealStatusOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Exception to Policy</mat-label>
          <mat-select [formControl]="formControlExceptionToPolicy">
            @for (option of yesNoOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Credit Reference</mat-label>
          <mat-select [formControl]="formControlCreditReference">
            @for (option of creditReferenceOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Financials</mat-label>
          <mat-select [formControl]="formControlFinancials">
            @for (option of yesNoOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Valuation Source</mat-label>
          <mat-select [formControl]="formControlValuationSource">
            @for (option of valuationSourceOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Bank Statements</mat-label>
          <mat-select [formControl]="formControlBankStatements">
            @for (option of yesNoOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Product</mat-label>
          <mat-select [formControl]="formControlProduct">
            @for (option of productTypeOptions; track option) {
              <mat-option  [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <currency-input title="LVR" [required]="true" [min]="0" [formControl]="formControlLvr" [prefix]="false">
        </currency-input>
      </div>
      <div class="col-lg-6 col-md-12">
        <currency-input title="DSCR" [required]="true" [min]="0" [formControl]="formControlDscr" [prefix]="false">
        </currency-input>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Approval Conditions</mat-label>
          <textarea matInput rows="5" wrap="hard" maxlength="7000" [formControl]="formControlApprovalConditions"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Cancel</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
