import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
 getApplicationStage, Accreditation, AccreditationSelectionType, GetAccreditationWebServicesFn, DownloadCreditorWatchReportFn, DeclineAccreditationFn, ApproveAccreditationFn, GetUserFn, GetAccreditationByIdFn, GetAccreditationCreditFlowResultFn, RunAccreditationCreditFlowFn, ApplicationWebService, CreditorWatchReportData, GetWebServiceReportFn
} from '@portal-workspace/grow-shared-library';
import {
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  getUser,
  UpdateApplicationDocumentTagsFn,
  UploadAccreditationDocumentFn, ListAccreditationDocumentFn,
  DownloadAccreditationDocumentUrlFn,
  DownloadAllAccreditationDocumentUrlFn, setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import {
  booleanToYesNo,
  filesToBase64Files,
  yesNoToBoolean,
  isInternalUser,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent } from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '@portal-workspace/grow-shared-library';
import { AccreditationDrivingLicenceComponent } from './accreditation-driving-licence.component';
import { AccreditationDocumentsComponent } from './accreditation-documents.component';
import { AccreditationAccComponent } from './accreditation-acc.component';
import { AccreditationCreditComponent } from './accreditation-credit.component';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { BreadcrumbComponent } from '../breadcrumb-component/breadcrumb.component';
import { FlexModule } from '@angular/flex-layout/flex';
import {Subscription} from "rxjs";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AccreditationReportComponent } from './accreditation-report.component';
import { AccreditationWebServicesComponent } from './accreditation-web-services.component';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { navigationUrlForAccreditationReport, navigationUrlForAccreditationTabbing, navigationUrlForAccreditation } from 'apps/portal2/src/app/service/navigation-urls';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'accreditation-details',
    templateUrl: './accreditation-details.component.html',
    styleUrls: ['./accreditation-details.component.scss'],
    standalone: true,
    imports: [
      FlexModule, 
      BreadcrumbComponent, 
      MatTabsModule, 
      AccreditationAccComponent, 
      AccreditationDocumentsComponent, 
      AccreditationDrivingLicenceComponent, 
      AccreditationCreditComponent,
      AccreditationReportComponent,
      AccreditationWebServicesComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccreditationDetailsComponent implements OnInit{

  subscriptions: Subscription[] = [];

  getApplicationStage = getApplicationStage;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  currentSection: AccreditationSelectionType = 'acc';
  currentSelectionIndex: number = 0;
  isSupplier = false;
  breadcrumbTrails: string[] = ['Accreditations'];
  @Input({required: true}) accreditation!: Accreditation;
  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};
  @Input({required: true}) approveAccreditationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineAccreditationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteAccreditationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) uploadAccreditationDocumentFn!: UploadAccreditationDocumentFn;
  @Input({required: true}) listAccreditationDocumentFn!: ListAccreditationDocumentFn;
  @Input({required: true}) downloadAccreditationDocumentUrlFn!: DownloadAccreditationDocumentUrlFn;
  @Input({required: true}) updateAccreditationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) downloadAllAccreditationDocumentUrlFn!: DownloadAllAccreditationDocumentUrlFn;
  @Input({required: true}) getAccreditationWebServicesFn!: GetAccreditationWebServicesFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) approveAccreditationFn!: ApproveAccreditationFn;
  @Input({required: true}) declineAccreditationFn!: DeclineAccreditationFn;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getAccreditationByIdFn!: GetAccreditationByIdFn;
  @Input({required: true}) getAccreditationCreditFlowResultFn!: GetAccreditationCreditFlowResultFn;
  @Input({required: true}) runAccreditationCreditFlowFn!: RunAccreditationCreditFlowFn;
  @Input({required: true}) getWebServiceReportFn!: GetWebServiceReportFn;
  @Output() creditStatusUpdate = new EventEmitter;
  // @Output() selectionEvent: EventEmitter<AccreditationSelectionType> = new EventEmitter<AccreditationSelectionType>();

  showReport = false;
  selectedWebService: ApplicationWebService | null = null;
  reportPdf: Uint8Array | null = null;
  reportHtml: SafeHtml | null = null;
  webServiceId: string | null = null;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private toastService: PortalHotToastService,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,

  ) {}

  ngOnInit() {
    setupUntilDestroy(this);
    const user = this.loggedInUser;
    const companyName = this.accreditation.companyName;
    if (companyName) {
      this.breadcrumbTrails.push(companyName);
    }
    this.isSupplier = this._isSupplier(this.accreditation.natureofBusiness);

    const innerTab = this.route.snapshot.params['innerTab'];
    if (innerTab === 'report') {
      this.currentSection = 'report';
    } else if (innerTab === 'credit') {
      this.currentSection = 'credit';
      this.currentSelectionIndex = 3;
    } else if (innerTab === 'documents') {
      this.currentSection = 'documents';
      this.currentSelectionIndex = 2;
    } else if (innerTab === 'drivingLicence') {
      this.currentSection = 'drivingLicence';
      this.currentSelectionIndex = 1;
    } else if (innerTab === 'acc') {
      this.currentSection = 'acc';
      this.currentSelectionIndex = 0;
    }
    this.webServiceId = this.route.snapshot.params['innerTabParam'];


  }

  private _isSupplier(natureOfBusiness: string){
    if(natureOfBusiness == 'supplier') return true
    else return false
  }

  onViewReport(webService: ApplicationWebService): void {
    console.log('Details Component: View report clicked', {
      webService,
      type: webService.type,
      id: webService.id,
      accreditationId: this.accreditation.AccreditationId
    });

    this.currentSection = 'report';
    this.router.navigate(navigationUrlForAccreditationTabbing(this.accreditation.AccreditationId.toString(), 'report', webService.id.toString()));

  }

  async onSelectedTabChange(event: MatTabChangeEvent) {

    let selection: AccreditationSelectionType = 'acc';
    const tabIndex = event.index;
    switch (tabIndex) {
      case 0:
        selection = 'acc';
        break;
      case 1:
        selection = this.isSupplier ? 'documents' : 'drivingLicence';
        break;
      case 2:
        selection = this.isSupplier ? 'credit' : 'documents';
        break;
      case 3:
        selection = 'credit';
    }
    console.log('Tab changed:', { selection, tabIndex });
    this.currentSection = selection;
    this.router.navigate(navigationUrlForAccreditationTabbing(this.accreditation.AccreditationId.toString(), selection, ''));

  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  onHandleCreditStatusUpdate() {
    this.creditStatusUpdate.emit();
  }

  onLeaveReport(): void {
    console.log('Details Component: Leaving report');
    this.showReport = false;
    this.selectedWebService = null;
    this.reportPdf = null;
    this.reportHtml = null;
    this.currentSection = 'credit';

    this.router.navigate(navigationUrlForAccreditationTabbing(this.accreditation.AccreditationId.toString(), 'credit', ''));
  }

  onDownloadReport(): void {
    if (this.selectedWebService) {
      console.log('Downloading report:', {
        webServiceId: this.selectedWebService.id,
        accreditationId: this.accreditation.AccreditationId,
        webServiceType: this.selectedWebService.type
      });

      if (this.selectedWebService.type === 'CreditorWatch') {
        const pdfId = (this.selectedWebService.json as CreditorWatchReportData).pdfRequestId;
        const url = `${this.apiUrl}/credit/download-creditorwatch-report/${pdfId}`;
        console.log('Opening CreditorWatch report URL:', { url, pdfId });
        window.open(url);
      } else if (this.selectedWebService.type === 'EquifaxConsumer') {
        const url = `${this.apiUrl}/credit/download-equifax-consumer-report/${this.selectedWebService.id}`;
        console.log('Opening EquifaxConsumer report URL:', { url });
        window.open(url);
      } else if (this.selectedWebService.type === 'EquifaxFraudCheck') {
        const url = `${this.apiUrl}/credit/download-equifax-fraud-check-report/${this.selectedWebService.id}`;
        console.log('Opening EquifaxFraudCheck report URL:', { url });
        window.open(url);
      }
    }
  }

  private base64ToArrayBuffer(base64: string): Uint8Array {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer as Uint8Array;
  }

}
