import { Component, OnInit } from '@angular/core';
import {Router, Event, NavigationEnd, ResolveEnd, RouterOutlet, RouterEvent} from '@angular/router';
import {Chart, registerables} from 'chart.js';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  getUser,
  PortalHotToastService,
  storeLastVisit,
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';
import { UpdateService } from './service/update.service';
import { tap } from 'rxjs/operators';
import {AppInsightService} from './service/app-insight.service';
import {OfflineDetectionService} from './service/offline-detection.service';
import {CreateHotToastRef} from '@ngneat/hot-toast';
import _ from 'lodash';
import {WhitelabelService} from "./service/whitelabel.service";

Chart.register(...registerables);

const WHITELIST = [
  'login',
  'force-reset-password',
  'mobile-verification',
  'application-error',
  'signup',
  'forgot-password',
  'reset-password',
  'accreditation',
  'registration',
  'unactivated',
  'unverified',
];

@UntilDestroy({
  arrayName: 'subscriptions',
})
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit {

  existingWindowOnError: OnErrorEventHandler = null;

  offlineHotToastRef: CreateHotToastRef<any> | null = null;

  subscriptions: Subscription[] = [];


  constructor(private router: Router,
              private offlineService: OfflineDetectionService,
              private hotToastService: PortalHotToastService,
              private appInsightService: AppInsightService,
    // this.updateService.checkForUpdates();
              private whitelabelService: WhitelabelService,
              private updateService: UpdateService,) {
  }

  ngOnInit(): void {

    document.addEventListener("DOMContentLoaded", event => {
      // if PWA standalone application, maximize
      const isStandalone = window.matchMedia("(display-mode: standalone)").matches ?? false;
      if (isStandalone) {
        const width = _.min([1539, screen.width]) ?? screen.width;
        const height= _.min([884, screen.height]) ?? screen.height;
        window.resizeTo(width, height);
      }
    });

    setupUntilDestroy(this);
    this.existingWindowOnError = window.onerror;
    window.onerror = (message, source, lineno, colno, error) => {
      if (this.existingWindowOnError) {
        this.existingWindowOnError(message, source, lineno, colno, error);
      }
      this.appInsightService.logException(error ?? new Error(`Unidentified error at source ${source} lineno ${lineno} colno ${colno} with message ${message}`), 5)
    }
    const sub = this.router.events.pipe(
      tap((r: Event | RouterEvent) => {
        if (r instanceof NavigationEnd) {
          const url = (r as NavigationEnd).urlAfterRedirects;
          if (this.isWhitelistUrl(url)) {
            const user = getUser();
            storeLastVisit(user, url);
          }
        } else if (r instanceof ResolveEnd) {
          const url =  (r as ResolveEnd).urlAfterRedirects;
          if (this.isWhitelistUrl(url)) {
            const user = getUser();
            storeLastVisit(user, url);
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    this.subscriptions.push(this.offlineService.subject.subscribe(evt => {
      switch(evt.type) {
        case 'online': {
          if (this.offlineHotToastRef) {
            this.offlineHotToastRef.close();
            this.offlineHotToastRef = null;
          }
          break;
        }
        case 'offline': {
          if (this.offlineHotToastRef == null) {
            this.offlineHotToastRef = this.hotToastService.uniqueOfflineInfo();
          }
          break;
        }
      }
    }));

    this.subscriptions.push(this.whitelabelService.subject.asObservable().pipe(
      tap(event => {
        if (event) {
          switch(event.type) {
            case 'apply-whitelabel': {
              this.whitelabelService.updateStyles(event.payload.styles);
              break;
            }
            case 'whitelabel-change': {
              if (event.payload && event.payload.styles) {
                this.whitelabelService.updateStyles(event.payload.styles);
              } else {
                this.whitelabelService.clearStyles();
              }
              break;
            }
          }
        }
      })
    ).subscribe());
  }


  isWhitelistUrl(url: string): boolean {
    if (url) {
      const urlTree = this.router.parseUrl(url);
      if (urlTree) {
        const segments = urlTree.root.segments;
        if (segments && segments.length) {
          const lastSegment = segments[segments.length - 1];
          if (WHITELIST.includes(lastSegment.path)) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
