import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AsyncPipe, JsonPipe, NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { createAsyncStore, loadingFor } from '@ngneat/loadoff';
import { UntilDestroy } from '@ngneat/until-destroy';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  Application, ApplicationSort, ApplicationTypes, ConfirmationDialogResult, CopyApplicationDialogResult,
  CopyApplicationFn, DEFAULT_LIMIT, DEFAULT_OFFSET, displayApplicationDateTime, GetGeoLocationFn,
  isAssociatedWithAggregator, isInternalUser, PortalLoginUser, PromotionResponse, SaveApplicationResult,
  SimplifiedApplication, STAGE_NAMES_ALL, STAGE_NAMES_APPROVED, STAGE_NAMES_CLOSED_WON, STAGE_NAMES_IN_SETTLEMENT,
  STAGE_NAMES_UNDER_REVIEW, StageNameType, User
} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService, ApplicationTypeIconComponent, CustomContentLoaderComponent, CustomPaginatorComponent,
  DataBoxComponent, getUser, MessageBoxComponent, MessageBoxComponentEvent, PortalHotToastService,
  SearchableSelectItem,
  SearchableSelectModule, setupUntilDestroy, TagBoxComponent, TopMenuService
} from '@portal-workspace/grow-ui-library';
import _ from 'lodash';
import moment from 'moment';
import { emit, nextTick } from 'process';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AdminService } from '../../service/admin.service';
import { ApplicationService, } from '../../service/application.service';
import { HeartbeatService } from '../../service/heartbeat.service';
import { ApplicationStage, ApplicationType, CreditOfficer, LocalApplicationsDbService, OpportunityOwner, SettlementOfficer } from '../../service/local-applications-db.service';
import {
  navigationUrlForAllApplication,
  navigationUrlForApplicationWithQueryParams,
  navigationUrlForAssetProductSelectorWithQueryParams,
  navigationUrlForBusinessFinanceWithQueryParams,
  navigationUrlForBusinessOverdraftWithQueryParams,
  navigationUrlForCommercialFinanceWithQueryParams,
  navigationUrlForConsumerAssetFinanceWithQueryParams,
  navigationUrlForConsumerFinanceWithQueryParams,
  navigationUrlForCorporateLoanWithQueryParams,
  navigationUrlForInsurancePremiumWithQueryParams
} from '../../service/navigation-urls';
import { ApplicationsGraphComponent, ApplicationsGraphData } from './applications-graph';

export class InternalDataSource extends DataSource<SimplifiedApplication> {

  subject: BehaviorSubject<SimplifiedApplication[]> = new BehaviorSubject<SimplifiedApplication[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<SimplifiedApplication[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(applications: SimplifiedApplication[]) {
    this.subject.next(applications);
  }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './applications.page.html',
    styleUrls: ['./applications.page.scss'],
    standalone: true,
    imports: [
      MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MessageBoxComponent, 
      MatIconModule, MatMenuModule, FlexModule, NgClass, CustomContentLoaderComponent, DataBoxComponent, 
      MatFormFieldModule, JsonPipe, MatInputModule, MatSelectModule, MatOptionModule, ApplicationTypeIconComponent, 
      MatTooltipModule, MatTableModule, MatSortModule, TagBoxComponent, CustomPaginatorComponent, AsyncPipe, 
      SearchableSelectModule, MatProgressBarModule, MatCardModule, ApplicationsGraphComponent, MatSliderModule, 
      MatButtonModule, MatCheckboxModule, MatListModule
    ]
})
export class ApplicationsPage implements OnInit, AfterViewInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    localStorage.setItem('needToRefresh', 'true');
  }

  STAGE_NAMES_UNDER_REVIEW = STAGE_NAMES_UNDER_REVIEW;
  STAGE_NAMES_IN_SETTLEMENT = STAGE_NAMES_IN_SETTLEMENT;
  STAGE_NAMES_CLOSED_WON = STAGE_NAMES_CLOSED_WON;
  STAGE_NAMES_APPROVED = STAGE_NAMES_APPROVED;

  subscriptions: Subscription[] = [];
  loader = loadingFor('tableLoading');
  displayColumns = ['appId', 'appName', 'status', 'stage', 'actions'];

  totalApplicationsUnderReview = 0;
  totalApplicationsInSettlement = 0;
  totalApplicationsClosedWon = 0;
  totalApplicationsApproved = 0;
  isProgressBarVisible: boolean = false;
  lastUpdatedMessage = '';
  limit: number = DEFAULT_LIMIT;
  offset: number = DEFAULT_OFFSET;
  total: number = 0;
  sorts?: ApplicationSort = undefined;

  application!: Application;
  getGeoLocationFn!: GetGeoLocationFn;
  copyApplicationFn!: CopyApplicationFn;
  isInternalUser = isInternalUser;

  loggedInUser: User | null = getUser();
  ip: string = '';
  completedInitialLoad = false;

  user: PortalLoginUser | null = null;
  store = createAsyncStore();
  errorTitle = 'Error Occurred!';
  errorMessage = 'Please try again.';

  promo?: PromotionResponse;
  showFilters = false;
  toggleMyApplicationsBtn = false;
  showMyApplications = false;
  disabled = false;
  selectedFiltersCount = 0;

  opportunityOwners: SearchableSelectItem[] = [];
  creditOfficers: SearchableSelectItem[] = [];
  settlementOfficers: SearchableSelectItem[] = [];
  applicationStages: SearchableSelectItem[] = STAGE_NAMES_ALL.map((stage) => ({
    id: stage,
    label: stage,
    value: stage,
  }));
  applicationTypes: SearchableSelectItem[] = [
    { id: 'AssetFinance', label: 'Asset Finance', value: 'AssetFinance' },
    { id: 'BusinessLoans', label: 'Business Term Loan', value: 'BusinessLoans' }, 
    { id: 'BusinessOverdraft', label: 'Business Overdraft', value: 'BusinessOverdraft' }, 
    { id: 'InsurancePremium', label: 'Insurance Premium', value: 'InsurancePremium' },
    { id: 'Consumer', label: 'Consumer Asset Finance', value: 'Consumer' },
    { id: 'CorporateLoans', label: 'Corporate Loan', value: 'CorporateLoans' },
  ];

  selectedList: string = '';
  graphDataFormControl = new FormControl<ApplicationsGraphData[]>([]);

  getDateFromSliderValue = (value: number) => moment().add(value, 'days').toDate();
  getSliderValueFromDate = (date: Date) => moment(date).diff(moment(), 'days');
  sliderDisplayWith = (value: number) => moment().add(value, 'days').format('DD/MM/YY');
  minDateFormControl = new FormControl<number>(moment().subtract(1, 'year').isLeapYear() ? -366 : -365);
  startDateFormControl = new FormControl<number>(this.minDateFormControl.value ?? 0);
  endDateFormControl = new FormControl<number>(0);
  dateRangeFormGroup = new FormGroup({
    start: this.startDateFormControl,
    end: this.endDateFormControl,
    minDate: this.minDateFormControl
  });

  searchControl = new FormControl<string>('');
  applicationTypeControl = new FormControl<ApplicationTypes[]>([]);
  applicationStageControl = new FormControl<StageNameType[]>([]);
  filterOpportunityOwnerControl = new FormControl<string[]>([]);
  filterCreditOfficerControl = new FormControl<string[]>([]);
  filterSettlementOfficerControl = new FormControl<string[]>([]);
  
  filtersFormGroup = new FormGroup({
    searchControl: this.searchControl,
    applicationTypeControl: this.applicationTypeControl,
    applicationStageControl: this.applicationStageControl,
    filterOpportunityOwnerControl: this.filterOpportunityOwnerControl,
    filterCreditOfficerControl: this.filterCreditOfficerControl,
    filterSettlementOfficerControl: this.filterSettlementOfficerControl,
  });

  retry() {
    this.reload();
  }

  dataSource = new InternalDataSource();

  constructor(
    private applicationService: ApplicationService,
    private portalHotToastService: PortalHotToastService,
    private localApplicationDbService: LocalApplicationsDbService,
    private adminService: AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private topMenuService: TopMenuService,
    private heartbeatService: HeartbeatService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.getGeoLocationFn = this.applicationService.getGeoLocationFn;
    this.copyApplicationFn = this.applicationService.copyApplicationFn;
  }

  private resetPagination() {
    this.offset = DEFAULT_OFFSET;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.startMestageUpdate();

    this.reloadPromos();
    this.user = getUser();

    this.applicationService
      .getIpAddress()
      .pipe(
        map((r) => {
          if (r.status) {
            this.ip = r.payload;
          }
        })
      )
      .subscribe();

    this.subscriptions.push(this.heartbeatService.subject.pipe(
      tap(r => {
        console.log('heartbeat event (applications page)', r);
        if (r.type === 'HeartbeatAccessLevelChangedEvent') {
          this.user = getUser();
          this.refresh();
          this.changeDetectorRef.detectChanges();
        }
      })
    ).subscribe());

    // this subscription is only called after retrieving data from db, i.e. refresh(true)
    this.subscriptions.push(this._applicationService().dataUpdates$.subscribe(r => {
      this.handleReloadData(r);
      this.isProgressBarVisible = false;
      this.completedInitialLoad = true;
      this.checkShowMyApplications();
    }));

    this.subscriptions.push(this.dateRangeFormGroup.valueChanges.pipe(
      distinctUntilChanged(),
      tap(r => {
        this.selectedFiltersCount = Object.values(r).filter(v => (Array.isArray(v) ? v?.length ?? 0 : 0) > 0).length;
        if (this.searchControl.value) this.selectedFiltersCount++;
        if (this.startDateFormControl.value !== this.minDateFormControl.value || this.endDateFormControl.value !== 0) this.selectedFiltersCount++;
      })
    ).subscribe());

    this.subscriptions.push(this.filtersFormGroup.valueChanges.pipe(
      distinctUntilChanged(),
      tap((r: any) => {
         
        setTimeout(() => {
          this.selectedFiltersCount = Object.values(r).filter(v => (Array.isArray(v) ? v?.length ?? 0 : 0) > 0).length;
          if (this.searchControl.value) this.selectedFiltersCount++;
          if (this.startDateFormControl.value !== this.minDateFormControl.value || this.endDateFormControl.value !== 0) this.selectedFiltersCount = this.selectedFiltersCount + 1;
          if (this.completedInitialLoad) {
            this.refresh(false);
          }
        }, 10);
      })
    ).subscribe());

    this.subscriptions.push(
      this.filtersFormGroup.valueChanges.subscribe(() => {
        if (this.loggedInUser?.Email) {
          const userInSettlement = (this.filterSettlementOfficerControl?.value ?? []).includes(this.loggedInUser.Email);
          const userInCredit = (this.filterCreditOfficerControl.value ?? []).includes(this.loggedInUser.Email);
          const userInOpportunity = (this.filterOpportunityOwnerControl.value ?? []).includes(this.loggedInUser.Email);
          this.updateQueryParams();
          if (!userInSettlement && !userInCredit && !userInOpportunity) {
            this.toggleMyApplicationsBtn = false;
          } else {
            this.toggleMyApplicationsBtn = true;
          }
        }
      })
    );

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if(!_.isEmpty(queryParams.search)  || !_.isEmpty(queryParams.filterOpportunityOwner)  || !_.isEmpty(queryParams.filterCreditOfficer)  || !_.isEmpty(queryParams.filterSettlementOfficer)  || !_.isEmpty(queryParams.applicationType)  || !_.isEmpty(queryParams.applicationStage) ){
        this.showFilters = true
    }
   
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const forceReload = queryParams?.reload;
    const limit = isNaN(_.toNumber(queryParams.limit)) ? DEFAULT_LIMIT : _.toNumber(queryParams.limit);
    const offset = isNaN(_.toNumber(queryParams.offset)) ? DEFAULT_OFFSET : _.toNumber(queryParams.offset);
    
    const search = queryParams.search ?? '';

    const filterOpportunityOwner: string[] = [].concat(
      queryParams.filterOpportunityOwner || []
    );
    const filterCreditOfficer: string[] = [].concat(
      queryParams.filterCreditOfficer || []
    );
    const filterSettlementOfficer: string[] = [].concat(
      queryParams.filterSettlementOfficer || []
    );
    const applicationType: ApplicationTypes[] = [].concat(
      queryParams.applicationType || []
    );
    const applicationStage: StageNameType[] = [].concat(
      queryParams.applicationStage || []
    );

    setTimeout(() => {
      this.offset = offset;
      this.limit = limit;
      this.sorts = queryParams.sorts ?? undefined;
     
      this.filtersFormGroup.setValue({
        searchControl: search,
        applicationTypeControl: applicationType,
        applicationStageControl: applicationStage,
        filterOpportunityOwnerControl: filterOpportunityOwner,
        filterCreditOfficerControl: filterCreditOfficer,
        filterSettlementOfficerControl: filterSettlementOfficer,
      });
       
      const userNeedsRefresh = !['admin', 'analyst', 'operations', 'operations24', 'credit', 'settlement', 'salesam', 'salesbdm'].includes(this.user?.AccessLevel ?? '');
      if (forceReload === true || forceReload === "true" || userNeedsRefresh || !!localStorage.getItem('needToRefresh')) {
        localStorage.removeItem('needToRefresh');
        this.refresh(true);
      } else {
        this.refresh(false);
      }
    });
  }

  refresh(forceReload: boolean = true) {
    if(forceReload){
      if (this.isProgressBarVisible) return; // prevent multiple calls to refresh
      this.resetPagination();
    }
    this.reload(
      forceReload,
      this.offset,
      this.limit,
      this.sorts,
      this.searchControl.value ?? undefined,
      this.applicationTypeControl.value ?? undefined, 
      this.applicationStageControl.value ?? undefined, 
      this.filterOpportunityOwnerControl.value ?? undefined, 
      this.filterCreditOfficerControl.value ?? undefined, 
      this.filterSettlementOfficerControl.value ?? undefined,
      this.startDateFormControl.value ?? undefined, 
      this.endDateFormControl.value ?? undefined
    );
  }

  private reloadPromos() {
    this.subscriptions.push(
      this.adminService
        .getPromotionByType('application')
        .pipe(
          tap((r) => {
            this.promo = r.payload;
          })
        )
        .subscribe()
    );
  }

  getApplicantName(app: SimplifiedApplication) {
    const entityLegalName = (app as any)['CompanyName'];
    if (entityLegalName) {
      return entityLegalName;
    } else if (app.ApplicationType === 'Consumer') {
      const givenName = (app as any)['IndividualGivenName'];
      const surName = (app as any)['IndividualSurName'];
      if (givenName || surName) {
        return `${givenName ?? ''} ${surName ?? ''}`.trim();
      }
    }
    return '';
  }

  getApplicationStatus(app: SimplifiedApplication) {
    const stage = (app as any).StageName;
    const salesforceId = (app as any).SalesforceId;
    return this.applicationService.getApplicationStatus2(stage, salesforceId);
  }

  getApplicationStage(app: SimplifiedApplication) {
    const stage = (app as any).StageName;
    return this.applicationService.getApplicationStage2(stage);
  }

  getApplicationCreateTime(app: SimplifiedApplication) {
    return displayApplicationDateTime(moment(app.CreateTime));
  }

  reload(
    forceReload: boolean = false,
    offset: number = DEFAULT_OFFSET,
    limit: number = DEFAULT_LIMIT,
    sorts?: ApplicationSort,
    search?: string,
    applicationType?: ApplicationTypes[],
    applicationStage?: StageNameType[],
    filterOpportunityOwner?: string[],
    filterCreditOfficer?: string[],
    filterSettlementOfficer?: string[],
    startDate?: number,
    endDate?: number,
    minDate?: number
  ) {
    if (forceReload) this.isProgressBarVisible = true
    const start = this.getDateFromSliderValue(startDate ?? this.minDateFormControl.value ?? 0);
    const end = this.getDateFromSliderValue(endDate ?? 0);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
  
    this.store = createAsyncStore();
    this.subscriptions.push(this._applicationService().reload({
      forceReload,
      page: {offset, limit},
      filter: search,
      applicationStage,
      applicationType,
      sorts,
      filterOpportunityOwner,
      filterCreditOfficer,
      filterSettlementOfficer,
      dateFilter: { startDate: start, endDate: end },
      minDate
    }).pipe(
      this.loader.tableLoading.track(),
      this.store.track(),
      this.portalHotToastService.topMenuLoadingObservable(),
      this.portalHotToastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle, errorMessage: this.errorMessage, retryFn: this.retry.bind(this),
      }),
      tap((r: any) => {
        this.handleReloadData(r);
        if (!forceReload && !this.completedInitialLoad) {
          this.completedInitialLoad = true;
          this.checkShowMyApplications();
        }
      }),
    ).subscribe());
  }

  handleReloadData(r: any) {     
    this.totalApplicationsUnderReview = r.totals.underReview;
    this.totalApplicationsInSettlement = r.totals.inSettlement;
    this.totalApplicationsClosedWon = r.totals.closedWon;
    this.totalApplicationsApproved = r.totals.approved;

    this.opportunityOwners = this.mapOfficerToSelectItems(r.opportunityOwners, this.filterOpportunityOwnerControl.value ?? []);
    this.creditOfficers = this.mapOfficerToSelectItems(r.creditOfficers, this.filterCreditOfficerControl.value ?? []);
    this.settlementOfficers = this.mapOfficerToSelectItems(r.settlementOfficers, this.filterSettlementOfficerControl.value ?? []);
    this.applicationStages = this.mapApplicationFieldToSearchableSelectItems(r.applicationStages);
    this.applicationTypes = this.mapApplicationFieldToSearchableSelectItems(r.applicationTypes);

    this.isMyApplicationsDisabled();
    this.limit = r.limit;
    this.offset = r.offset;
    this.total = r.total;

    this.dataSource.update(r.applications);
    this.graphDataFormControl.setValue(r.graphData);
    this.getLastUpdatedMessage();
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex; // Only update offset from pagination event
  
    this.updateQueryParams()
  }
  
  updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { 
        offset: this.offset,
        limit: this.limit,
        filter: this.searchControl.value ?? undefined,  // Ensure filters are included
        applicationType: this.applicationTypeControl.value ?? undefined,
        applicationStage: this.applicationStageControl.value ?? undefined,
        filterOpportunityOwner: this.filterOpportunityOwnerControl.value ?? undefined,
        filterCreditOfficer: this.filterCreditOfficerControl.value ?? undefined,
        filterSettlementOfficer: this.filterSettlementOfficerControl.value ?? undefined,
        startDate: this.startDateFormControl.value ?? undefined,
        endDate: this.endDateFormControl.value ?? undefined,
      },
      queryParamsHandling: 'merge'
    });
  
    this.reload(
      false,
      this.offset,
      this.limit,
      this.sorts,
      this.searchControl.value ?? undefined,
      this.applicationTypeControl.value ?? undefined, 
      this.applicationStageControl.value ?? undefined, 
      this.filterOpportunityOwnerControl.value ?? undefined, 
      this.filterCreditOfficerControl.value ?? undefined, 
      this.filterSettlementOfficerControl.value ?? undefined,
      this.startDateFormControl.value ?? undefined,
      this.endDateFormControl.value ?? undefined
    );
  }
  

  toClass(applicationStatus: string | null) {
    return applicationStatus ? applicationStatus.replace(/\s/g, '') : '';
  }

  getLastUpdatedMessage() {
    const lastUpdatedTime = localStorage.getItem('lastUpdatedTime');
    if (lastUpdatedTime) {
      const lastUpdatedDate = new Date(lastUpdatedTime);
      const currentTime = new Date();

      const diffInMs = currentTime.getTime() - lastUpdatedDate.getTime(); // Difference in milliseconds
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Convert to minutes

      if (diffInMinutes === 0) {
        this.lastUpdatedMessage = 'Last updated just now';
      } else if (diffInMinutes === 1) {
        this.lastUpdatedMessage = 'Last updated 1 minute ago';
      } else if (diffInMinutes < 60) {
        this.lastUpdatedMessage = `Last updated ${diffInMinutes} minutes ago`;
      } else if (diffInMinutes >= 60 && diffInMinutes < 120) {
        this.lastUpdatedMessage = 'Last updated 1 hour ago';
      } else if (diffInMinutes >= 120 && diffInMinutes < 1440) {
        this.lastUpdatedMessage = `Last updated ${Math.floor(
          diffInMinutes / 60
        )} hours ago`;
      } else {
        this.lastUpdatedMessage = `Last updated ${Math.floor(
          diffInMinutes / 1440
        )} days ago`;
      }
    } else {
      this.lastUpdatedMessage = 'List has not been updated yet';
    }
  }

  startMestageUpdate() {
    this.getLastUpdatedMessage();
    setInterval(() => {
      this.getLastUpdatedMessage();
    }, 60000);
  }

  onSort($event: Sort) {
    if ($event.direction) {
      const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
      switch ($event.active) {
        case 'appId':
          this.sorts = [{ prop: 'BrokerAppId', dir }];
          break;
        case 'appName':
          this.sorts = [{ prop: 'CompanyName', dir }];
          break;
        case 'status':
          this.sorts = [{ prop: 'Status', dir }];
          break;
        case 'stage':
          this.sorts = [{ prop: 'AppInfoStageName', dir }];
          break;
      }
    } else {
      this.sorts = undefined;
    }
    this.refresh(false);
  }

  clearFilters() {
    this.dateRangeFormGroup.setValue({
      start: moment().subtract(1, 'year').isLeapYear() ? -366 : -365, end: 0, minDate: moment().subtract(1, 'year').isLeapYear() ? -366 : -365
    });
    this.filtersFormGroup.setValue({
      searchControl: '',
      applicationTypeControl: [],
      applicationStageControl: [],
      filterOpportunityOwnerControl: [],
      filterCreditOfficerControl: [],
      filterSettlementOfficerControl: [],
    });
    this.refresh(false);

  }

  async onApplicationClick(
    $event: MouseEvent,
    application: SimplifiedApplication,
    newTab: boolean = false
  ) {
    const status = this.getApplicationStatus(application);
    const applicationId = application.ApplicationId;
    const queryParams = {
      reload: false,
      limit: this.limit,
      offset: this.offset,
      search: this.searchControl.value ?? undefined,
      applicationType: this.applicationTypeControl.value ?? undefined,
      applicationStage: this.applicationStageControl.value ?? undefined,
      filterOpportunityOwner:
        this.filterOpportunityOwnerControl.value ?? undefined,
      filterCreditOfficer: this.filterCreditOfficerControl.value ?? undefined,
      filterSettlementOfficer:
        this.filterSettlementOfficerControl.value ?? undefined,
      sorts: this.sorts ?? undefined,
    };
    let urlTree: UrlTree;
    if (status === 'Draft') {
      switch (application.ApplicationType) {
        default:
        case 'AssetFinance':
          urlTree = navigationUrlForAssetProductSelectorWithQueryParams(
            this.router,
            applicationId,
            queryParams
          );
          break;
        case 'BusinessLoans':
          urlTree = navigationUrlForBusinessFinanceWithQueryParams(
            this.router,
            applicationId,
            queryParams
          );
          break;
        case 'BusinessOverdraft':
          urlTree = navigationUrlForBusinessOverdraftWithQueryParams(
            this.router,
            applicationId,
            queryParams
          );
          break;
        case 'Commercial':
          urlTree = navigationUrlForCommercialFinanceWithQueryParams(
            this.router,
            applicationId,
            queryParams
          );
          break;
        case 'Consumer':
          if (getUser()!.priviledges.includes('lg' as any)) {
            urlTree = navigationUrlForConsumerFinanceWithQueryParams(
              this.router,
              applicationId
            );
          } else {
            urlTree = navigationUrlForConsumerAssetFinanceWithQueryParams(
              this.router,
              applicationId,
              queryParams
            );
          }
          break;
        case 'InsurancePremium':
          urlTree = navigationUrlForInsurancePremiumWithQueryParams(
            this.router,
            applicationId,
            queryParams
          );
          break;
        case 'CorporateLoans':
          urlTree = navigationUrlForCorporateLoanWithQueryParams(
            this.router,
            applicationId,
            queryParams
          );
          break;
      }
    } else {
      urlTree = navigationUrlForApplicationWithQueryParams(
        this.router,
        String(application.ApplicationId),
        'app',
        queryParams
      );
    }
    if (newTab) {
      window.open(this.router.serializeUrl(urlTree), '_blank');
    } else {
      this.router.navigateByUrl(urlTree);
    }
  }

  onApplicationStageFilter(applicationStages: StageNameType[]) {
    let newAppStageList: StageNameType[] = [
      ...(this.applicationStageControl.value ?? []),
    ];
    for (const applicationStage of applicationStages) {
      if (newAppStageList.includes(applicationStage)) {
        newAppStageList = newAppStageList.filter(
          (stage) => stage !== applicationStage
        );
      } else {
        newAppStageList.push(applicationStage);
      }
    }
    this.applicationStageControl.setValue(newAppStageList);
    this.refresh(false);
  }

  showBrokerName(): boolean {
    return isInternalUser(this.user) || isAssociatedWithAggregator(this.user);
  }

  closePromo($event: MessageBoxComponentEvent) {
    $event.close();
  }

  _applicationService(): LocalApplicationsDbService {
    return this.localApplicationDbService;
  }

  onDeleteByAppId(applicationId: number) {
    this.subscriptions.push(this.applicationService.deleteApplicationByAppId(applicationId).pipe(
      //this.portalHotToastService.spinnerObservable(),
      this.portalHotToastService.snackBarObservable('Application deleted'),
      tap((r: any) => {
        if (r.status) { // deleted successfully
          this._applicationService().removeApplicationLocally(applicationId);
          this.refresh();
        }
      }),
    ).subscribe());
  }

  onDelete(applicationId: number) {
    this.subscriptions.push(
      this.applicationDialogService
        .openConfirmationDialog({
          message: 'Please confirm',
          subMessage: 'Do you want to delete this application?',
        })
        .afterClosed()
        .subscribe((r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            if (applicationId) {
              this.onDeleteByAppId(applicationId);
            }
          }
        })
    );
  }

  onCopyApplication(ApplicationId: number) {
    this.subscriptions.push(this.applicationService.getApplicationById(ApplicationId).pipe(
      tap(r => {
        this.application=r.payload
      },
      catchError((err) => {
        console.log(`error resolving application with id ${ApplicationId}`, err);
        return of(null);
      })),
    ).subscribe());
    this.subscriptions.push(
      this.applicationDialogService.openCopyApplicationDialog({
        application: this.application
      }).afterClosed().subscribe(async (r: CopyApplicationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const applicationType = r.applicationType as ApplicationTypes;
          let geoLocation = {};
          try {
            geoLocation = await this.getGeoLocationFn();
          } catch (error) {
            console.log(error)
          }
          this.copyApplicationFn({
            newApplicationType: applicationType,
            application: this.application,
            user: this.loggedInUser as User,
            ip: this.ip,
            geoLocation: geoLocation
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((r: SaveApplicationResult) => {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: `Application ${r.BrokerApplicationId} has been created for you.`
            }).afterClosed().subscribe(() => {
              this.resetPagination();
              this.clearFilters();
              this.refresh(true);
            })
          })
        }
      })
    );
  }


  async onClickAllApplication($event: Event) {
    await this.router.navigate(navigationUrlForAllApplication());
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  selectionChange(field: string) {
    this.selectedList = field;
  }

  toggleMyApplications() {
    this.toggleMyApplicationsBtn = !this.toggleMyApplicationsBtn;
    if(this.toggleMyApplicationsBtn) {
      if (this.loggedInUser?.Email) {
        const newSettlementOfficers: string[] = [];
        const newCreditOfficers: string[] = [];
        const newOpportunityOwners: string[] = [];

        if (this.settlementOfficers.find(o => o.value === this.loggedInUser?.Email))
          newSettlementOfficers.push(this.loggedInUser?.Email);
        else if (this.creditOfficers.find(o => o.value === this.loggedInUser?.Email))
          newCreditOfficers.push(this.loggedInUser?.Email);
        else if (this.opportunityOwners.find(o => o.value === this.loggedInUser?.Email))
          newOpportunityOwners.push(this.loggedInUser?.Email);
        else this.dataSource.update([]);

        this.filtersFormGroup.setValue({
          filterSettlementOfficerControl: newSettlementOfficers,
          filterCreditOfficerControl: newCreditOfficers,
          filterOpportunityOwnerControl: newOpportunityOwners,
          searchControl: this.searchControl.value,
          applicationTypeControl: this.applicationTypeControl.value,
          applicationStageControl: this.applicationStageControl.value
        });
        this.refresh();
      }
    } else {
      this.filtersFormGroup.setValue({
        filterSettlementOfficerControl: this.settlementOfficers.filter(o => o.value !== this.loggedInUser?.Email && this.filterSettlementOfficerControl.value?.includes(o.value)).map(o => o.value),
        filterCreditOfficerControl: this.creditOfficers.filter(o => o.value !== this.loggedInUser?.Email && this.filterCreditOfficerControl.value?.includes(o.value)).map(o => o.value),
        filterOpportunityOwnerControl: this.opportunityOwners.filter(o => o.value !== this.loggedInUser?.Email && this.filterOpportunityOwnerControl.value?.includes(o.value)).map(o => o.value),
        searchControl: this.searchControl.value,
        applicationTypeControl: this.applicationTypeControl.value,
        applicationStageControl: this.applicationStageControl.value
      });

    }
  }

  mapOfficerToSelectItems(
    items: OpportunityOwner[] | CreditOfficer[] | SettlementOfficer[],
    selectedList: string[]
  ) {
    const me = this.loggedInUser?.Email;
    
    items =  items.sort((a, b) => {
      // Sort by count in descending order
      const aSelected = selectedList.includes(a.email ?? '');
      const bSelected = selectedList.includes(b.email ?? '');

      if (aSelected == bSelected) return (b.count ?? 0) - (a.count ?? 0); // Handle potential undefined counts
      else if (aSelected) return -1;
      else return 1;
    });
 
    return items.map(item => ({
      id: item.email ?? '',
      value: item.email ?? '',
      label: `${item.firstName} ${item.lastName}`,
      searchTerm: `${item.firstName} ${item.lastName} (${item.email})`,
      priority: item.email === me,
      count: item.count
    }));
  }

  mapApplicationFieldToSearchableSelectItems(items: ApplicationStage[] | ApplicationType[]) {
    return items.map(item => {
      const label = () => {
        switch (item.name) {
          case 'AssetFinance':
            return 'Asset Finance';
          case 'BusinessLoans':
            return 'Business Term Loan';
          case 'BusinessOverdraft':
            return 'Business Overdraft';
          case 'Commercial':
            return 'Commercial Finance';
          case 'Consumer':
            return 'Consumer Asset Finance';
          case 'InsurancePremium':
            return 'Insurance Premium';
          case 'CorporateLoans':
            return 'Corporate Loan';
          case 'InvoiceFinance':
            return 'Invoice Finance';
          default:
            return item.name;
        }
      };
      return {
        id: item.name ?? '',
        value: item.name ?? '',
        label: label() ?? '',
        searchTerm: label() ?? '',
        count: item.count
      }
    });
  }

  onDateSelect = (event: any) => {
    this.refresh(false);
  }

  async expandDateRange() {
    const oldMinDate = this.minDateFormControl.value;
    this.minDateFormControl.setValue((oldMinDate ?? 0) + (moment().subtract(oldMinDate ?? 0, 'days').subtract(12, 'month').isLeapYear() ? -366 : -365));
    await new Promise(resolve => nextTick(resolve)); // wait for the minDateFormControl to update
    if (this.startDateFormControl.value === oldMinDate) {
      this.startDateFormControl.setValue(this.minDateFormControl.value ?? 0);
    }
    this.reload(
      true, this.offset, this.limit, this.sorts,
      this.searchControl.value ?? undefined,
      this.applicationTypeControl.value ?? undefined, 
      this.applicationStageControl.value ?? undefined, 
      this.filterOpportunityOwnerControl.value ?? undefined, 
      this.filterCreditOfficerControl.value ?? undefined, 
      this.filterSettlementOfficerControl.value ?? undefined,
      this.startDateFormControl.value ?? undefined,
      this.endDateFormControl.value ?? undefined,
      this.minDateFormControl.value ?? undefined,
    )
  }

  onColumnClick(column: ApplicationsGraphData) {
    this.startDateFormControl.setValue(this.getSliderValueFromDate(column.segmentStart.toDate()));
    this.endDateFormControl.setValue(this.getSliderValueFromDate(column.segmentEnd.toDate()) - 1);
    this.refresh(false);

  }
  
  isMyApplicationsDisabled(): void {
    const isSettlementOfficer = this.settlementOfficers.find(
      (officer) => officer.value === this.loggedInUser?.Email
    );
    const isCreditOfficer = this.creditOfficers.find(
      (officer) => officer.value === this.loggedInUser?.Email
    );
    const isOpportunityOwner = this.opportunityOwners.find(
      (officer) => officer.value === this.loggedInUser?.Email
    );
    
    this.disabled = !isSettlementOfficer && !isCreditOfficer && !isOpportunityOwner;
  }

  checkShowMyApplications() {
    const {settlementOfficers, creditOfficers, opportunityOwners} = this.localApplicationDbService.getUnfilteredContacts();

    const isSettlementOfficer = settlementOfficers.find(
      (officer: any) => officer.email === this.loggedInUser?.Email
    );
    const isCreditOfficer = creditOfficers.find(
      (officer: any) => officer.email === this.loggedInUser?.Email
    );
    const isOpportunityOwner = opportunityOwners.find(
      (officer: any) => officer.email === this.loggedInUser?.Email
    );
    
    this.showMyApplications = !isSettlementOfficer && !isCreditOfficer && !isOpportunityOwner;
  }
}
