<mat-form-field class="select" subscriptSizing="dynamic">
  <mat-select [formControl]="formControl" multiple="{{multi}}" [placeholder]="placeholder" fxFlex="1 1 auto">
    <mat-form-field subscriptSizing="dynamic" class="search-container" fxFlex="1 1" fxFlex.lt-md="1 1 auto" fxFlexAlign="start">
      <input [formControl]="searchControl" matInput #searchText class="ml-1" type="text" placeholder="Search">
      <span class="mdi mdi-magnify" matPrefix></span>
    </mat-form-field>
    <mat-select-trigger>
      {{displayValue}}
    </mat-select-trigger>
    @if (filteredItems.length > 0) {
      @for (item of filteredItems; track item.id) {
        <mat-option [value]="item.value" class="select-option" (click)="_selectionChange(item)">
          <div class="option-label">
            {{item.label}}
            @if (item.count) {
              <span class="option-count">
                {{item.count}}
              </span>
            }
          </div>
        </mat-option>
      }
    } @else {
      <mat-option [value]="null" class="select-option" disabled>
        <div class="option-label">
          No results found
        </div>
      </mat-option>
    }
  </mat-select>
</mat-form-field>