<div class="reporting-page container-fluid">
  <div class="row mb-4">
    <div class="col-12">
      <div class="mat-h3">Report Requests</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="select-header w-100"
        fxLayout="row wrap"
        fxLayoutGap="16px"
        fxLayoutAlign="start center">
        <div class="select"
          fxFlex="0 1 auto"
          fxLayout="row"
          fxLayoutAlign="start center">
          <div class="label me-2">Report Status</div>
          <mat-form-field>
            <mat-select [formControl]="formControlStatusFilter">
              @for (type of statusTypes; track type) {
                <mat-option [value]="type">{{parseStatusNames(type)}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="select"
          fxFlex="0 1 auto"
          fxLayout="row"
          fxLayoutAlign="start center">
          <div class="label me-2">Report Type</div>
          <mat-form-field>
            <mat-select [formControl]="formControlReportTypeFilter">
              @for (type of reportTypes; track type) {
                <mat-option [value]="type">{{parseReportTypeNames(type)}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <button class="custom-button" 
          fxFlex="0 1 auto"
          (click)="addNewRequest()">
          <span class="mdi mdi-plus icon"></span>
          <ng-container class="text">New Report</ng-container>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div [ngClass]="{'table-section': true, 'pb-3': total > 0}">
        <table mat-table [dataSource]="pastRequests" class="width-100" matSort (matSortChange)="onSortData($event)">
          <ng-container matColumnDef="reportType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="reportType">Report Type</th>
            <td mat-cell *matCellDef="let element" class="ellipsis td-width">
              <div>{{parseReportTypeNames(element.reportType)}}</div>
              <div class="date-text">Requested by {{element.userGivenName}} {{element?.userFamilyName ? element.userFamilyName[0] : ''}}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="startDateEndDate">
              <div>
                <th mat-header-cell *matHeaderCellDef>Start / End Date</th>
              </div>
            <td mat-cell *matCellDef="let element" class="td-width ellipsis">
              @if (element.startDate || element.endDate) {
                <div>{{element.startDate.slice(0, 19)}} {{element.reportType == 'reporting-banking-transactions' || element.reportType == 'reporting-brokerage-transactions' ? '(UTC)' : ''}}</div>
                <div>{{element.endDate.slice(0, 19)}} {{element.reportType == 'reporting-banking-transactions' || element.reportType == 'reporting-brokerage-transactions' ? '(UTC)' : ''}}</div>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="requestTime">
            <div>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="requestTime">Request Time</th>
            </div>
          <td mat-cell *matCellDef="let element" class="td-width ellipsis">
            {{element.requestTime | date:'dd/MM/yyyy HH:mm:ss'}}
          </td>
        </ng-container>
          <ng-container matColumnDef="programId">
            <div>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="programId">Program</th>
            </div>
          <td mat-cell *matCellDef="let element" class="td-width ellipsis">
            {{element?.programId && element?.programId > 0 ? element?.programId : 'All'}}
          </td>
        </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element" class="ellipsis td-width">
              <tag-box [state]="element.status"></tag-box>
            </td>
          </ng-container>
          <ng-container matColumnDef="completedTime">
            <div>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="completedTime">Completed Time</th>
            </div>
          <td mat-cell *matCellDef="let element" class="td-width ellipsis">
            {{element.completedTime | date:'dd/MM/yyyy HH:mm:ss'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <div>
              <th mat-header-cell *matHeaderCellDef>Actions</th>
            </div>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">
                @if (element.status == 'completed') {
                  <span class="mdi mdi-download cursor-pointer icon-margin" matTooltip="Download" (click)="downloadReport(element)"></span>
                }
                @if (element.status == 'created') {
                  <span class="mdi mdi-cancel cursor-pointer icon-margin" matTooltip="Cancel" (click)="cancelReportRequest(element)"></span>
                }
                @if (element.status == 'completed') {
                  <span class="mdi mdi-trash-can-outline cursor-pointer icon-margin" matTooltip="Delete" (click)="deleteReport(element)"></span>
                }
              </td>
                
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="pastRequestsColumnsToDisplay"></tr>
          <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: pastRequestsColumnsToDisplay"></tr>
          
        </table>
      </div>

    </div>
  </div>
  @if (total) {
    <div class="row">
      <div class="col-12">
        <app-custom-paginator class="mt-4" [length]="total" [pageIndex]="offset" [pageSize]="limit"
        [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
      </div>
    </div>
  }
  @if (total<1) {
    <table class="width-100">
      <tr>
        <td class="not-found"> No Report Requests Found</td>
      </tr>
    </table>
  }
</div>