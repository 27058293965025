import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {httpOptions, paginationUrl, toPaginationInfo} from '@portal-workspace/grow-ui-library';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
  AggregatorSearchResult,
  AllBusinessTypes,
  Accreditation,
  DriverLicenceDetails,
  NextAccreditationNumber,
  PaginablePayloadApiResponse,
  PaginationInfo,
  PayloadApiResponse, SubmitAccreditationResult, Base64File, ApiResponse,
  AzureStorageDocument, Metadata, AccreditationListingSortType, SubmitAccreditationInput, AccreditationWebService, ApproveAccreditationBody, DeclineAccreditationBody, AccreditationCreditFlow,
  GetWebServiceReportResponse,
} from '@portal-workspace/grow-shared-library';
import {AggregatorSearchFn} from '@portal-workspace/grow-ui-library';
import { Router } from '@angular/router';
import { navigationUrlForAccreditationTabbing } from './navigation-urls';



const URL_GET_LATEST_ACCREDITATION = ()=>`${environment.api2Host}/api2/getAccreditation`; // get
const URL_AGGREGATOR_SEARCH_BY_NAME = (name: string) => `${environment.api2Host}/api2/aggregator-search-by-name/${name}`; // get
const URL_SUBMIT_ACCREDITATION = ()=> `${environment.api2Host}/api2/submitAccreditation2`; // post
// const URL_SEND_ACCREDITATION_SUBMISSION_MAIL = ()=> `${environment.apiHost}/api/main-accreditation-form`;  // post
const URL_FETCH_DRIVER_LICENCE =()=>`${environment.api2Host}/api2/get-driver-licence`; //post
const URL_GET_ACCREDITATIONS_LIST =(businessType:AllBusinessTypes,paginationInfo:PaginationInfo)=>paginationUrl(`${environment.api2Host}/api2/accreditation-list?type=${businessType}&`,paginationInfo); //get
const URL_GET_ACCREDITATION = (accreditationId: number)=> `${environment.api2Host}/api2/accreditation/${accreditationId}`; //get
const URL_UPDATE_ACCREDITATION_DOCUMENT_TO_AZURE_STORAGE = (salesforceId: string) => `${environment.api2Host}/api2/document/update-accreditation-document-to-azure-storage/${salesforceId}`; // post
const URL_GET_ACCREDITATION_DOCUMENTS = (salesforceId: string)=> `${environment.api2Host}/api2/get-accreditation-documents/${salesforceId}`; //get
const URL_UPDATE_DOCUMENT_METADATA_IN_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/update-document-metadata-in-azure-storage`; // post
const URL_DELETE_DOCUMENT_FROM_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/delete-document-from-azure-storage`; // post
const URL_DOWNLOAD_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/download-application-document-from-azure-storage/0`; // get
const URL_UPDATE_DOCUMENT_TAGS_IN_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/update-document-tags-in-azure-storage`; // post
const URL_DOWNLOAD_ALL_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE = (salesforceId: string, customerName: string, userId: number) => `${environment.api2Host}/api2/document/download-all-application-document-from-azure-storage/${salesforceId}/${customerName}/${userId}`; // get
const URL_GET_ACCREDITATION_WEB_SERVICES = (accreditationId: number)=> `${environment.api2Host}/api2/credit/get-accreditation-web-services/${accreditationId}`; //get
const URL_APPROVE_ACCREDITATION = () => `${environment.api2Host}/api2/credit/approve-accreditation`;
const URL_DECLINE_ACCREDITATION = () => `${environment.api2Host}/api2/credit/decline-accreditation`;
const URL_RUN_ACCREDITATION_CREDIT_FLOW = (accreditationId: number, startFromLastRun: boolean) => `${environment.api2Host}/api2/credit/run-accreditation-credit-flow/${accreditationId}/${startFromLastRun}`;
const URL_GET_ACCREDITATION_CREDIT_FLOW_RESULT = (accreditationId: number) => `${environment.api2Host}/api2/credit/get-accreditation-credit-flow-result/${accreditationId}`
const URL_GET_WEB_SERVICE_REPORT = (webServiceId: number) => `${environment.api2Host}/api2/credit/web-service-report/${webServiceId}`;
const URL_GET_ACCREDITATION_WEB_SERVICE_REPORT = (webServiceId: number) => `${environment.api2Host}/api2/credit/get-accreditation-web-service-report/${webServiceId}`;
@Injectable()
export class AccreditationService {

  constructor(private httpClient: HttpClient, private router: Router) {
  }



  submitAccreditation(data: SubmitAccreditationInput): Observable<PayloadApiResponse<SubmitAccreditationResult>> {
    return this.httpClient.post<PayloadApiResponse<SubmitAccreditationResult>>(URL_SUBMIT_ACCREDITATION(), data, httpOptions()).pipe();
  }


  aggregatorSearchFn: AggregatorSearchFn = (term: string) => {
    return this.aggregatorSearch(term).pipe(map(r => {
      return r.payload.map(rst => ({
        abn: rst.ABN,
        entityName: rst.EntityName,
        salesforceId: rst.SalesforceId
      }))
    }));
  }
  aggregatorSearch(name: string): Observable<PayloadApiResponse<AggregatorSearchResult[]>> {
    return this.httpClient.get<PayloadApiResponse<AggregatorSearchResult[]>>(URL_AGGREGATOR_SEARCH_BY_NAME(name), httpOptions()).pipe();
  }
  uploadAccreditationDocumentToAzureStorage(salesforceId: string, newFiles: Base64File[], deletedFileNames: string[] = []): Observable<ApiResponse> {
    const nf = newFiles.map(f => ({
      base64: f.base64,
      name: f.name.replace(/[^\t\x20-\x7e\x80-\xff]/, '_'),
      size: f.size,
      type: f.type,
      tags: f?.tags ?? [],
      metadata: f?.metadata ?? {}
    }));
    const rf = deletedFileNames.map(f => ({ name: f}));
    return this.httpClient.post<ApiResponse>(URL_UPDATE_ACCREDITATION_DOCUMENT_TO_AZURE_STORAGE(salesforceId), {
      new: nf,
      remove: rf,
    }, httpOptions());
  }

  uploadAccreditationDocumentToAzureStorageFn = (salesforceId: string, newFiles: Base64File[], deletedFileNames: string[] = []): Observable<ApiResponse> => {
    return this.uploadAccreditationDocumentToAzureStorage(salesforceId, newFiles, deletedFileNames);
  }

  getAccreditationList(opt: {
                         page: {
                           offset: number,
                           limit: number,
                         },
                         filter: string,
                         sorts: AccreditationListingSortType,
                       },
                       businessType: AllBusinessTypes,): Observable<PaginablePayloadApiResponse<Accreditation>> {
    return this.httpClient.post<PaginablePayloadApiResponse<Accreditation>>(URL_GET_ACCREDITATIONS_LIST(businessType,toPaginationInfo(opt)), {}, httpOptions());
  }

  getAccreditationById(accreditationId: number): Observable<PayloadApiResponse<Accreditation>> {
    return this.httpClient.get<PayloadApiResponse<Accreditation>>(URL_GET_ACCREDITATION(accreditationId),httpOptions()).pipe();
  }

  getAccreditationByIdFn = (accreditationId: number): Observable<Accreditation> => {
    return this.getAccreditationById(accreditationId).pipe(map(result => result.payload));
  }

  getNextAccreditationNumber(): Observable<PayloadApiResponse<NextAccreditationNumber>> {
    return this.httpClient.get<PayloadApiResponse<NextAccreditationNumber>>(URL_GET_LATEST_ACCREDITATION(), httpOptions()).pipe();
  }

 
  getDriverLicenceDetails(data: {data: string, type: string}): Observable<PayloadApiResponse<DriverLicenceDetails>> {
    return this.httpClient.post<PayloadApiResponse<DriverLicenceDetails>>(URL_FETCH_DRIVER_LICENCE(), data, httpOptions()).pipe();
  }

  getAccreditationDocuments(salesforceId: string): Observable<PayloadApiResponse<AzureStorageDocument[]>> {
    return this.httpClient.get<PayloadApiResponse<AzureStorageDocument[]>>(URL_GET_ACCREDITATION_DOCUMENTS(salesforceId), httpOptions());
  }

  getAccreditationDocumentsFn = (salesforceId: string, includeDeletedFile = false): Observable<AzureStorageDocument[]> => {
    return this.getAccreditationDocuments(salesforceId).pipe(
      map((r: PayloadApiResponse<AzureStorageDocument[]>) => r.payload),
      map((docs: AzureStorageDocument[]) => {
        if (!includeDeletedFile) {
          return docs.filter(doc => !(doc?.metadata?.isdeleted) || !(doc?.metadata?.isdeleted.toLowerCase() === 'true'));
        } else {
          return docs;
        }
      })
    )
  }

  updateDocumentMetadata = (path: string, fileName: string, metadata: Metadata): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DOCUMENT_METADATA_IN_AZURE_STORAGE(), {
      path: path,
      fileName: fileName,
      metadata: metadata,
      container: 'accreditation',
    }, httpOptions());
  }
  approveAccreditationDocumentFn = (path: string, fileName: string, tagName: string, userId: number | undefined): Observable<ApiResponse> => {
    return this.updateDocumentMetadata(path, fileName, { [tagName]: 'Accepted', statuschangedby: String(userId) });
  }

  declineAccreditationDocumentFn = (path: string, fileName: string, tagName: string, userId: number | undefined): Observable<ApiResponse> => {
    return this.updateDocumentMetadata(path, fileName, { [tagName]: 'Rejected', statuschangedby: String(userId) });
  }

  deleteAccreditationDocument = (path: string, fileName: string, tagName: string): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_DELETE_DOCUMENT_FROM_AZURE_STORAGE(), {
      path: path,
      fileName: fileName,
      tag: tagName,
      container: 'accreditation',
    }, httpOptions());
  }

  deleteAccreditationDocumentFn = (path: string, fileName: string, tagName: string): Observable<ApiResponse> => {
    return this.deleteAccreditationDocument(path, fileName, tagName);
  }

  downloadAccreditationDocument = (path: string, fileName: string): string => {
    const urlWithParams = new URL(URL_DOWNLOAD_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE());
    urlWithParams.searchParams.append("path", path);
    urlWithParams.searchParams.append("fileName", fileName);
    urlWithParams.searchParams.append("container", "accreditation");
    return urlWithParams.href;
  }

  downloadAccreditationDocumentUrlFn = (path: string, fileName: string): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(this.downloadAccreditationDocument(path, fileName), options)
  }

  updateAccreditationDocumentTags = (path: string, fileName: string, tags: string[]): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DOCUMENT_TAGS_IN_AZURE_STORAGE(), {
      path: path,
      fileName: fileName,
      tags: tags,
      container: "accreditation"
    }, httpOptions());
  }

  updateAccreditationDocumentTagsFn = (path: string, fileName: string, tags: string[]): Observable<ApiResponse> => {
    return this.updateAccreditationDocumentTags(path, fileName, tags);
  }

  downloadAllAccreditationDocument = (salesforceId: string, customerName: string, userId: number) => {
    const urlWithParams = new URL(URL_DOWNLOAD_ALL_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE(salesforceId, customerName, userId));
    urlWithParams.searchParams.append("container", "accreditation");
    return urlWithParams.href;
  }

  downloadAllAccreditationDocumentUrlFn = (salesforceId: string, customerName: string, userId: number): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(this.downloadAllAccreditationDocument(salesforceId, customerName, userId), options)
  }

  getAccreditationWebServicesFn = (accreditationId: number): Observable<AccreditationWebService[]> => {
    return this.httpClient.get<PayloadApiResponse<AccreditationWebService[]>>(URL_GET_ACCREDITATION_WEB_SERVICES(accreditationId), httpOptions())
      .pipe(map(response => response.payload));
  }

  approveAccreditationFn = (data: ApproveAccreditationBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_APPROVE_ACCREDITATION(), data, httpOptions());
  }

  declineAccreditationFn = (data: DeclineAccreditationBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_DECLINE_ACCREDITATION(), data, httpOptions());
  }

  getAccreditationCreditFlowResultFn = (accreditationId: number): Observable<AccreditationCreditFlow[]> => {
    return this.httpClient.get<PayloadApiResponse<AccreditationCreditFlow[]>>(URL_GET_ACCREDITATION_CREDIT_FLOW_RESULT(accreditationId), httpOptions())
      .pipe(map(response => response.payload));
  }

  runAccreditationCreditFlowFn = (accreditationId: number, startFromLastRun: boolean): Observable<ApiResponse> => {
    return this.httpClient.get<ApiResponse>(URL_RUN_ACCREDITATION_CREDIT_FLOW(accreditationId, startFromLastRun), httpOptions());
  }

  getWebServiceReportFn = (webServiceId: number): Observable<PayloadApiResponse<any>> => {
    return this.httpClient.get<PayloadApiResponse<any>>(URL_GET_WEB_SERVICE_REPORT(webServiceId), httpOptions());
  }

  getAccreditationReportFn = (webServiceId: number):Observable<GetWebServiceReportResponse> => {
    return this.httpClient.get<PayloadApiResponse<any>>(URL_GET_ACCREDITATION_WEB_SERVICE_REPORT(webServiceId), httpOptions())
    .pipe(map((res: PayloadApiResponse<GetWebServiceReportResponse>) => res.payload));
  }

  navigateToAccreditationDetails(accreditationId: string) {
    console.log('123123123132navigateToAccreditationDetails', accreditationId);

    this.router.navigate(navigationUrlForAccreditationTabbing(accreditationId, 'credit', ''));
  }
}
