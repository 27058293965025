<div class="application-page container-fluid">
  @if (!application) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <message-box type="danger" title="Not found">
          Unable to location application
        </message-box>
      </div>
    </div>
  }
  @if (application) {
    @if (application.ApplicationType === 'AssetFinance') {
      <asset-finance-application-details
        [assetSearchFn]="assetSearchFn"
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getApplicationPpsrInfoFn]="getApplicationPpsrInfoFn"
        [removeApplicationAssetFn]="removeApplicationAssetFn"
        [searchAssetFn]="searchAssetFn"
        [initialSelection]="initialApplicationSelectionType"
        (selectionEvent)="onApplicationSelectionType($event)"
        [submitPpsrFn]="submitPpsrFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [sendAssetToSfFn]="sendAssetToSfFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [addPpsrDetailFn]="addPpsrDetailFn"
        [updatePpsrDetailFn]="updatePpsrDetailFn"
        [updateApplicationAssetsFn]="updateApplicationAssetsFn"
        [submitApplicationAssetsFn]="submitApplicationAssetsFn"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDocumentsFn]="getPpsrDocumentsFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [apiUrl]="apiUrl"
        [searchSupplierFn]="searchSupplierFn"
        [saveSupplierFn]="saveSupplierFn"
        [bankStatementsUrl]="bankStatementsUrl"
        [idVerifyUrl]="idVerifyUrl"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="onApplicationDetailsEvent($event)"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [assetSettlementSearchAssetsFn]="searchAssetFn"
        [requestDocFn]="requestDocFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [sendContractEmailsFn]="sendContractEmailsFn"
        [updateDocumentMetadataFn]="updateDocumentMetadataFn"
        [getContractStatusFn]="getContractStatusFn"
        [generateContractFn]="generateContractFn"
        [deleteContractFileFn]="deleteContractFileFn"
        [getActiveContractForApplicationFn]="getActiveContractForApplicationFn"
        [declineContractFn]="declineContractFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [createNewDisbursementFn]="createNewDisbursementFn"
        [updateDisbursementFn]="updateDisbursementFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [syncDisbursementToSfFn]="syncDisbursementToSfFn"
        [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
        [syncDepositPaidToSfFn]="syncDepositPaidToSfFn"
        [syncPrivateSellerBankDetailsToSfFn]="syncPrivateSellerBankDetailsToSfFn"
        [syncContractDetailsToSfFn]="syncContractDetailsToSfFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [getBillerNameFn]="getBillerNameFn"
        [getInstitutionNameFn]="getInstitutionNameFn"
        [validateBpayFn]="validateBpayFn"
        [allowContractGeneration]="allowContractGeneration"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [settleLoanFn]="settleLoanFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [copyApplicationFn]="copyApplicationFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [ip]="ip"
        [getGeoLocationFn]="getGeoLocationFn"
        (navigateToApplications)="onNavigateToApplications()"
        (assetPricingEvents)="onAssetPricingEvents($event)"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [getUserFn]="getUserFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
        [getBankDetailsFromOpportunitySfFn]="getBankDetailsFromOpportunitySfFn"
        [extractTaxInvoiceFn]="extractTaxInvoiceFn"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [createAssetInspectionFn]="createAssetInspectionFn"
        [getAssetInspectionsForApplicationFn]="getAssetInspectionsForApplicationFn"
        [downloadVerimotoReportFn]="downloadVerimotoReportFn"
        [verimotoLender]="verimotoLender"
        [verimotoAssetInspectionTypes]="verimotoAssetInspectionTypes"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [getLvrCalculatorValueFn]="getLvrCalculatorValueFn"
        [updateLvrCalculatorValueFn]="updateLvrCalculatorValueFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [runCreditFlowFn]="runCreditFlowFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [getOriginatorBusinessByIdFn]="getOriginatorBusinessByIdFn"
        [addAuditLogFn]="addAuditLogFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [updateCreditOfficerFn]="updateCreditOfficerFn"
        [getActiveCreditUsersFn]="getActiveCreditUsersFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getWebServiceReportFn]="getWebServiceReportFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        (clickApplicationEvent)="onClickApplicationEvent($event)"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        [getApplicationDscrFn]="getApplicationDscrFn"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        [resendContractFn]="resendContractFn"
        [application]="$any(application)">
      </asset-finance-application-details>
    }
    @if (application.ApplicationType === 'BusinessLoans') {
      <business-finance-application-details
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [createNewDisbursementFn]="createNewDisbursementFn"
        [updateDisbursementFn]="updateDisbursementFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [syncDisbursementToSfFn]="syncDisbursementToSfFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
        [sendAssetToSfFn]="sendAssetToSfFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getLvrCalculatorValueFn]="getLvrCalculatorValueFn"
        [updateLvrCalculatorValueFn]="updateLvrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [initialSelection]="initialApplicationSelectionType"
        (selectionEvent)="onApplicationSelectionType($event)"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [idVerifyUrl]="idVerifyUrl"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="onApplicationDetailsEvent($event)"
        (businessPricingEvents)="onBusinessPricingEvents($event)"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [getContractStatusFn]="getContractStatusFn"
        [generateContractFn]="generateContractFn"
        [deleteContractFileFn]="deleteContractFileFn"
        [getActiveContractForApplicationFn]="getActiveContractForApplicationFn"
        [declineContractFn]="declineContractFn"
        [sendContractEmailsFn]="sendContractEmailsFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [syncContractDetailsToSfFn]="syncContractDetailsToSfFn"
        [getBillerNameFn]="getBillerNameFn"
        [getInstitutionNameFn]="getInstitutionNameFn"
        [validateBpayFn]="validateBpayFn"
        [allowContractGeneration]="allowContractGeneration"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [settleLoanFn]="settleLoanFn"
        [searchSupplierFn]="searchSupplierFn"
        [getBankDetailsFromOpportunitySfFn]="getBankDetailsFromOpportunitySfFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [copyApplicationFn]="copyApplicationFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [ip]="ip"
        [getGeoLocationFn]="getGeoLocationFn"
        (navigateToApplications)="onNavigateToApplications()"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
        [getUserFn]="getUserFn"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [getOriginatorBusinessByIdFn]="getOriginatorBusinessByIdFn"
        [addAuditLogFn]="addAuditLogFn"
        [runCreditFlowFn]="runCreditFlowFn" 
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [updateCreditOfficerFn]="updateCreditOfficerFn"
        [getActiveCreditUsersFn]="getActiveCreditUsersFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getWebServiceReportFn]="getWebServiceReportFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        (clickApplicationEvent)="onClickApplicationEvent($event)"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        [getApplicationDscrFn]="getApplicationDscrFn"
        [resendContractFn]="resendContractFn"
        [application]="$any(application)">
      </business-finance-application-details>
    }
    @if (application.ApplicationType ==='BusinessOverdraft') {
      <business-overdraft-application-details
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [createNewDisbursementFn]="createNewDisbursementFn"
        [updateDisbursementFn]="updateDisbursementFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [syncDisbursementToSfFn]="syncDisbursementToSfFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
        [sendAssetToSfFn]="sendAssetToSfFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getLvrCalculatorValueFn]="getLvrCalculatorValueFn"
        [updateLvrCalculatorValueFn]="updateLvrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [initialSelection]="initialApplicationSelectionType"
        (selectionEvent)="onApplicationSelectionType($event)"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [idVerifyUrl]="idVerifyUrl"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="onApplicationDetailsEvent($event)"
        (businessOverdraftPricingEvents)="onBusinessOverdraftPricingEvents($event)"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
        [getContractStatusFn]="getContractStatusFn"
        [generateContractFn]="generateContractFn"
        [deleteContractFileFn]="deleteContractFileFn"
        [getActiveContractForApplicationFn]="getActiveContractForApplicationFn"
        [declineContractFn]="declineContractFn"
        [sendContractEmailsFn]="sendContractEmailsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [syncContractDetailsToSfFn]="syncContractDetailsToSfFn"
        [allowContractGeneration]="allowContractGeneration"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [settleLoanFn]="settleLoanFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [copyApplicationFn]="copyApplicationFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [ip]="ip"
        [getGeoLocationFn]="getGeoLocationFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        (navigateToApplications)="onNavigateToApplications()"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [getUserFn]="getUserFn"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [getOriginatorBusinessByIdFn]="getOriginatorBusinessByIdFn"
        [runCreditFlowFn]="runCreditFlowFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [updateCreditOfficerFn]="updateCreditOfficerFn"
        [getActiveCreditUsersFn]="getActiveCreditUsersFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getWebServiceReportFn]="getWebServiceReportFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        (clickApplicationEvent)="onClickApplicationEvent($event)"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        [requestMoreInformationFn]="requestMoreInformationFn"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        [getApplicationDscrFn]="getApplicationDscrFn"
        [resendContractFn]="resendContractFn"
        [application]="$any(application)">
      </business-overdraft-application-details>
    }
    @if (application.ApplicationType === 'Consumer') {
      <consumer-finance-application-details
        [saveSupplierFn]="saveSupplierFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [assetSearchFn]="assetSearchFn"
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [removeApplicationAssetFn]="removeApplicationAssetFn"
        [getApplicationPpsrInfoFn]="getApplicationPpsrInfoFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [searchAssetFn]="searchAssetFn"
        [searchSupplierFn]="searchSupplierFn"
        [assetSettlementSearchAssetsFn]="searchAssetFn"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [initialSelection]="initialApplicationSelectionType"
        (selectionEvent)="onApplicationSelectionType($event)"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [apiUrl]="apiUrl"
        [requestDocFn]="requestDocFn"
        [bankStatementsUrl]="bankStatementsUrl"
        [idVerifyUrl]="idVerifyUrl"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="onApplicationDetailsEvent($event)"
        [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
         
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [application]="$any(application)"
        [submitPpsrFn]="submitPpsrFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [sendAssetToSfFn]="sendAssetToSfFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [addPpsrDetailFn]="addPpsrDetailFn"
        [updatePpsrDetailFn]="updatePpsrDetailFn"
        [updateApplicationAssetsFn]="updateApplicationAssetsFn"
        [submitApplicationAssetsFn]="submitApplicationAssetsFn"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDocumentsFn]="getPpsrDocumentsFn"
        [updateDocumentMetadataFn]="updateDocumentMetadataFn"
        [sendContractEmailsFn]="sendContractEmailsFn"
        [getContractStatusFn]="getContractStatusFn"
        [generateContractFn]="generateContractFn"
        [deleteContractFileFn]="deleteContractFileFn"
        [getActiveContractForApplicationFn]="getActiveContractForApplicationFn"
        [declineContractFn]="declineContractFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [createNewDisbursementFn]="createNewDisbursementFn"
        [updateDisbursementFn]="updateDisbursementFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [syncDisbursementToSfFn]="syncDisbursementToSfFn"
        [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
        [syncPrivateSellerBankDetailsToSfFn]="syncPrivateSellerBankDetailsToSfFn"
        [syncDepositPaidToSfFn]="syncDepositPaidToSfFn"
        [syncContractDetailsToSfFn]="syncContractDetailsToSfFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [allowContractGeneration]="allowContractGeneration"
        [getBillerNameFn]="getBillerNameFn"
        [getInstitutionNameFn]="getInstitutionNameFn"
        [validateBpayFn]="validateBpayFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [settleLoanFn]="settleLoanFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [getUserFn]="getUserFn"
        [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
        [getBankDetailsFromOpportunitySfFn]="getBankDetailsFromOpportunitySfFn"
        [extractTaxInvoiceFn]="extractTaxInvoiceFn"
        [updateApplicationFn]="updateApplicationFn"
        [createAssetInspectionFn]="createAssetInspectionFn"
        [getAssetInspectionsForApplicationFn]="getAssetInspectionsForApplicationFn"
        [downloadVerimotoReportFn]="downloadVerimotoReportFn"
        [verimotoLender]="verimotoLender"
        [verimotoAssetInspectionTypes]="verimotoAssetInspectionTypes"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [getConsumerDscrCalculatorValueFn]="getConsumerDscrCalculatorValueFn"
        [updateConsumerDscrCaculatorValueFn]="updateConsumerDscrCalculatorValueFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [getOriginatorBusinessByIdFn]="getOriginatorBusinessByIdFn"
        [addAuditLogFn]="addAuditLogFn"
        [consumerFinanceDscrServiceabilityCalculationFn]="consumerFinanceDscrServiceablilityCalculationFn"
        [runCreditFlowFn]="runCreditFlowFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [updateCreditOfficerFn]="updateCreditOfficerFn"
        [getActiveCreditUsersFn]="getActiveCreditUsersFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getWebServiceReportFn]="getWebServiceReportFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        [getApplicationDscrFn]="getApplicationDscrFn"
        [resendContractFn]="resendContractFn"
        (consumerPricingEvents)="onConsumerPricingEvents($event)"
        (clickApplicationEvent)="onClickApplicationEvent($event)"
        (navigateToApplications)="onNavigateToApplications()"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        >
      </consumer-finance-application-details>
    }
    @if (application.ApplicationType === 'InsurancePremium') {
      <insurance-premium-application-details
        [getApplicationByIdFn]="getApplicationByIdFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [initialSelection]="initialApplicationSelectionType"
        (selectionEvent)="onApplicationSelectionType($event)"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
         
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [idVerifyUrl]="idVerifyUrl"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="onApplicationDetailsEvent($event)"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [getUserFn]="getUserFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [settleLoanFn]="settleLoanFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [runCreditFlowFn]="runCreditFlowFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [updateCreditOfficerFn]="updateCreditOfficerFn"
        [getActiveCreditUsersFn]="getActiveCreditUsersFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getWebServiceReportFn]="getWebServiceReportFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        (clickApplicationEvent)="onClickApplicationEvent($event)"
        (navigateToApplications)="onNavigateToApplications()"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getApplicationDscrFn]="getApplicationDscrFn"
        [application]="$any(application)">
      </insurance-premium-application-details>
    }

    @if (application.ApplicationType === 'CorporateLoans') {
      <corporate-loans-application-details
      [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [initialSelection]="initialApplicationSelectionType"
        (selectionEvent)="onApplicationSelectionType($event)"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [idVerifyUrl]="idVerifyUrl"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        [copyApplicationFn]="copyApplicationFn"
        [getGeoLocationFn]="getGeoLocationFn"
        [ip]="ip"
        (navigateToApplications)="onNavigateToApplications()"
        (events)="onApplicationDetailsEvent($event)"
        (corporateLoansPricingEvents)="onCorporateLoansPricingEvent($event)"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
         
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [getUserFn]="getUserFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [getContractStatusFn]="getContractStatusFn"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [settleLoanFn]="settleLoanFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [allowContractGeneration]="allowContractGeneration"
        [generateContractFn]="generateContractFn"
        [sendContractEmailsFn]="sendContractEmailsFn"
        [deleteContractFileFn]="deleteContractFileFn"
        [syncContractDetailsToSfFn]="syncContractDetailsToSfFn"
        [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
        [getActiveContractForApplicationFn]="getActiveContractForApplicationFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [declineContractFn]="declineContractFn"
        [getOriginatorBusinessByIdFn]="getOriginatorBusinessByIdFn"
        [runCreditFlowFn]="runCreditFlowFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [updateCreditOfficerFn]="updateCreditOfficerFn"
        [getActiveCreditUsersFn]="getActiveCreditUsersFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getWebServiceReportFn]="getWebServiceReportFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        [deleteWebServicesFn]="deleteWebServicesFn"
        (clickApplicationEvent)="onClickApplicationEvent($event)"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        [getApplicationDscrFn]="getApplicationDscrFn"
        [resendContractFn]="resendContractFn"
        [application]="$any(application)">
      </corporate-loans-application-details>
    }
  }
</div>
