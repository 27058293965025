import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { httpOptions, paginationUrl, toPaginationInfo } from '@portal-workspace/grow-ui-library';
import { map, Observable } from 'rxjs';
import { PaginablePayloadApiResponse, PaginationInfo, PayloadApiResponse, ReportSortType, ReportStatus, ReportType, SortType, SortTypes } from '@portal-workspace/grow-shared-library';
import { ReportRecord } from '@portal-workspace/grow-shared-library';

const URL_REPORT_REQUEST = () => `${environment.api2Host}/api2/reporting/request-report`;
const URL_FETCH_REPORTS = (paginationInfo : PaginationInfo, reportTypeFilter: ReportType | 'allReportTypes', statusFilter: ReportStatus | 'allStatuses') => paginationUrl(`${environment.api2Host}/api2/reporting/fetch-reports`, paginationInfo) + `&reportTypeFilter=${reportTypeFilter}&statusFilter=${statusFilter}`;
const URL_DELETE_REPORT = (reportId: number) => `${environment.api2Host}/api2/reporting/delete-report/${reportId}`;
const URL_DOWNLOAD_REPORT = (reportPath: string) => `${environment.api2Host}/api2/reporting/download-report/${encodeURIComponent(reportPath)}`;

@Injectable()
export class ReportingService {

  constructor(private httpClient: HttpClient) {
  }

  requestReportFn = (reportType: string, startDate: string, endDate: string, programId: number): Observable<PayloadApiResponse<{ status: boolean }>> => {
    return this.httpClient.post<PayloadApiResponse<{ status: boolean }>>(URL_REPORT_REQUEST(), {
      reportType,
      startDate,
      endDate,
      programId,
    }, httpOptions());
  }

  fetchReports(opts: {
    page: {limit: number, offset: number},
    sorts: any
  },
    reportTypeFilter: ReportType | 'allReportTypes',
    statusFilter: ReportStatus | 'allStatuses'
): Observable<PaginablePayloadApiResponse<ReportRecord>> {
    return this.httpClient.get<PaginablePayloadApiResponse<ReportRecord>>(URL_FETCH_REPORTS(toPaginationInfo(opts), reportTypeFilter, statusFilter), httpOptions());
    

  }

  deleteReport(reportId: number): Observable<PayloadApiResponse<{ status: boolean }>> {
    return this.httpClient.delete<PayloadApiResponse<{ status: boolean }>>(URL_DELETE_REPORT(reportId), httpOptions());
  }

  downloadReport(reportPath: string): Observable<Blob> {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(URL_DOWNLOAD_REPORT(reportPath), options);
  }

}
