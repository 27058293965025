<div class="pismo-accounts-page container-fluid">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <input matInput placeholder="Search by Customer Name, Pismo Account" [formControl]="formControlSearch">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12 table-section">
      @if (loader.loadingAccounts.inProgress$ | async) {
        <custom-content-loader type="list"></custom-content-loader>
      }
      <div [ngClass]="{hidden: (loader.loadingAccounts.inProgress$ | async), 'table-section': true, 'pb-3': true}">
        <table mat-table [dataSource]="datasource" matSort (matSortChange)="onSortData($event)">
          <ng-container matColumnDef="pismoAccountNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pismo Account #</th>
            <td mat-cell *matCellDef="let element">{{ element.pismoAccountNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="EntityName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
            <td mat-cell *matCellDef="let element">{{ element.EntityName }}</td>
          </ng-container>
          <ng-container matColumnDef="programName">
            <th mat-header-cell *matHeaderCellDef>Pismo Program Name</th>
            <td mat-cell *matCellDef="let element">{{ element.PismoProgramName }}</td>
          </ng-container>
          <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
            <td mat-cell *matCellDef="let element">{{ element.creationDate | looseDate }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row class="cursor-pointer" (click)="onRowClick(row)"
          *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        @if (total<1) {
          <table class="width-100">
            <tr>
              <td class="not-found"> No data found</td>
            </tr>
          </table>
        }
        <app-custom-paginator
          [length]="total"
          [pageIndex]="offset"
          [pageSize]="limit"
          [pageSizeOptions]="[10,20,25,50,100]"
          (page)="onPagination($event)"
        ></app-custom-paginator>
      </div>
    </div>
  </div>
</div>
