import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationTypes, CopyApplicationDialogResult, CopyApplicationFn, CorporateLoanApplication, GetGeoLocationFn, GetUserFn, SaveApplicationResult, User, WithdrawApplicationFn, getAppInfoBrokerEntityName, getBrokerContact, getBrokerFlowId, getDirectorScoreRate, getFacilityFee, getFacilityFeePercent, getOpportunityOwnerEmail, getOrganisationTypeForTitle, getPreviousLoan } from '@portal-workspace/grow-shared-library';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake,
  getAssetType,
  getAssetYear,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName,
  getBrokerSalesforceId,
  getAppSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLimitRequest,
  getLoanAmount,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType,
  getPrimaryBusinessAddress,
  getApplicationPrimaryIndustry,
  getPrivateSales,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getPreviousBusiness,
  getPreviousBusinessName,
  getPreviousBusinessABN,
  getPreviousBusinessACN,
  getLtv,
  getSecurityType
} from '@portal-workspace/grow-shared-library';
import { getApplicationStatus, } from '@portal-workspace/grow-shared-library';
import { ApplicationDialogService, GetUsersFunc, PortalHotToastService, UserSelectionComponentEvent, getUser } from '@portal-workspace/grow-ui-library';
import { isInternalUser as utilIsInternalUser, } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../../pipes/loose-currency.pipe';
import { CorporateLoansBackgroundDetailsComponent } from './corporate-loans-background-details.component';
import { ReferencesDetailsComponent } from '../references-details.component';
import { PrimaryContactDetailsComponent } from '../primary-contact-details.component';
import { IndividualDetailsComponent } from '../individual-details.component';
import { RiskAnalysisComponent } from '../../risk-analysis-component/risk-analysis.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserSelectionComponent } from '../../user-selection-component/user-selection.component';
import { CellComponent } from '../../application-summary-component/cell.component';
import { StatusBoxComponent } from '../../message-box/status-box.component';
import { NgTemplateOutlet } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import {MatPrefix} from "@angular/material/form-field";
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'corporate-loans-app',
    templateUrl: './corporate-loans-app.component.html',
    styleUrls: ['./corporate-loans-app.component.scss'],
    standalone: true,
  imports: [
    MatCardModule,
    MatTabsModule,
    NgTemplateOutlet,
    StatusBoxComponent,
    CellComponent,
    UserSelectionComponent,
    MatDividerModule,
    RiskAnalysisComponent,
    IndividualDetailsComponent,
    PrimaryContactDetailsComponent,
    ReferencesDetailsComponent,
    CorporateLoansBackgroundDetailsComponent,
    LooseCurrencyPipe,
    MatButtonModule,
    FlexModule,
    MatPrefix,
  ]
})
export class CorporateLoansAppComponent implements OnInit {

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getAppSalesforceId = getAppSalesforceId;
  getBrokerName = getBrokerName;
  getAppInfoBrokerEntityName = getAppInfoBrokerEntityName;
  getOrganisationType = getOrganisationType;
  getOrganisationTypeForTitle = getOrganisationTypeForTitle;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getAssetCategory = getAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getApplicationPrimaryIndustry = getApplicationPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  getLimitRequest = getLimitRequest;
  getPreviousBusiness = getPreviousBusiness;
  getPreviousBusinessName = getPreviousBusinessName;
  getPreviousBusinessABN = getPreviousBusinessABN;
  getPreviousBusinessACN = getPreviousBusinessACN;
  getFacilityFeePercent = getFacilityFeePercent;
  getFacilityFee = getFacilityFee;
  getBrokerFlowId = getBrokerFlowId;
  getDirectorScoreRate = getDirectorScoreRate
  getPreviousLoan = getPreviousLoan
  getLtv = getLtv
  getSecurityType = getSecurityType
  getBrokerContact  =getBrokerContact
  getOpportunityOwnerEmail = getOpportunityOwnerEmail

  @Input({required: true}) application!: CorporateLoanApplication;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) getGeoLocationFn!: GetGeoLocationFn;
  @Input({required: true}) copyApplicationFn!: CopyApplicationFn;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Input({required: false}) ip: string = '';
  @Input({required: true}) getUserFn!: GetUserFn;
  @Output() events = new EventEmitter<UserSelectionComponentEvent & { type: 'submitter' | 'additional-correspondent' }>();
  @Output() navigateToApplications = new EventEmitter();

  applicationStatus: string | null = null;
  subscriptions: Subscription[] = [];
  loggedInUser: User | null = getUser();
  applicationUserId!:number;
  AdditionalBrokerCorrespondentUserId?:number;
  creditOfficer = '';
  constructor(private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private activatedRoute: ActivatedRoute,
  ){
  }

  ngOnInit(): void {
    console.log("this.application",this.application)
    this.applicationStatus = getApplicationStatus(this.application);
    this.applicationUserId= (this.activatedRoute.snapshot.data as any).application.UserId;
    this.AdditionalBrokerCorrespondentUserId = (this.activatedRoute.snapshot.data as any)?.application?.AdditionalBrokerCorrespondent?.UserId;
    if (this.application.CreditOfficerStatusUpdatedBy) {
      this.subscriptions.push(
        this.getUserFn(this.application.CreditOfficerStatusUpdatedBy, true).subscribe(r => {
          if (r) {
            this.creditOfficer = `${r.GivenName} ${r.FamilyName}`;
          }
        })
      )
    } else if (this.application.SystemCreditStatus) {
      this.creditOfficer = `System`;
    }
  }

  isInternalUser() {
    return utilIsInternalUser(getUser())
  }

  emitChangeSubmitterEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'submitter',
    });
  }

  emitChangeAdditionalCorrespondentEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'additional-correspondent',
    });
  }

  onCopyApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openCopyApplicationDialog({
        application: this.application
      }).afterClosed().subscribe(async (r: CopyApplicationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const applicationType = r.applicationType as ApplicationTypes;
          let geoLocation = {};
          try {
            geoLocation = await this.getGeoLocationFn();
          } catch (error) {
            console.log(error)
          }
          this.copyApplicationFn({
            newApplicationType: applicationType,
            application: this.application,
            user: this.loggedInUser as User,
            ip: this.ip,
            geoLocation: geoLocation
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((r: SaveApplicationResult) => {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: `Application ${r.BrokerApplicationId} has been created for you.`
            }).afterClosed().subscribe(() => {
              this.navigateToApplications.emit();
            })
          })
        }
      })
    )
  }

  onWithdrawApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Withdraw Application",
        subMessage: "Are you sure you want to withdraw this application?"
      }).afterClosed().subscribe(r => {
        if (r && r.readyForSubmission) {
          this.applicationDialogService.openWithdrawApplicationDialog({})
            .afterClosed().subscribe(result => {
              if (result && result.readyForSubmission) {
                this.withdrawApplicationFn({
                  salesforceId: this.application.AppInfoSalesforceID ?? '',
                  applicationId: this.application.ApplicationId,
                  reason: result.reason,
                  comment: result.comment,
                }).pipe(
                  this.toastService.spinnerObservable()
                ).subscribe(() => {
                  this.applicationDialogService.successDialog({
                    message: 'Success',
                    subMessage: `This application has been withdrawn successfully.`
                  }).afterClosed().subscribe(() => {
                    this.navigateToApplications.emit();
                  })
                })
              }
            })
        }
      })
    )
  }
}
