import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AllApplicationTypes,
  ApplicationSort,
  ApplicationTypes,
  LastRecentApplicationsBundle,
  PayloadApiResponse,
  SimplifiedApplication,
  STAGE_NAMES_APPROVED,
  STAGE_NAMES_CLOSED_WON,
  STAGE_NAMES_IN_SETTLEMENT,
  STAGE_NAMES_UNDER_REVIEW,
  StageNameType
} from '@portal-workspace/grow-shared-library';
import { httpOptions } from '@portal-workspace/grow-ui-library';
import escapeStringRegexp from 'escape-string-regexp';
import _ from 'lodash';
import loki from 'lokijs';
import moment from 'moment';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { ApplicationsGraphData } from '../page/applications-page/applications-graph';

const URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE = ()=>`${environment.api2Host}/api2/applications-last-recent-bundle`;

export type SettlementOfficer = { firstName?: string | null, lastName?: string | null, email?: string | null, count?: number}
export type OpportunityOwner = { firstName?: string | null, lastName?: string | null, email?: string | null, count?: number}
export type CreditOfficer = { firstName?: string | null, lastName?: string | null, email?: string | null, count?: number}
export type ApplicationStage = { name?: string | null, count?: number}
export type ApplicationType = { name?: string | null, count?: number}

type ReloadProps = {
  page: {offset: number, limit: number},
  forceReload: boolean,
  sorts?: ApplicationSort,
  filter?: string,
  applicationStage?: StageNameType[],
  applicationType?: ApplicationTypes[],
  filterOpportunityOwner?: string[],
  filterCreditOfficer?: string[],
  filterSettlementOfficer?: string[],
  dateFilter:{startDate: Date, endDate: Date},
  minDate?: number,
}

interface UniqueObjectData {
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
}

interface UniqueObjectDataWithCount extends UniqueObjectData {
  count: number;
}


@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class LocalApplicationsDbService {

  CACHE_EXPIRY_IN_MINUTES = 60;
  lastUpdate: moment.Moment = moment();  // last time it was updated
  loaded: boolean = false;  // loaded applications for the first time ?

  subscriptions: Subscription[] = [];

  private dataUpdateSubject = new Subject<any>();
  dataUpdates$ = this.dataUpdateSubject.asObservable();

  localDb = new loki('local-applications-db');

  allDbCollection: Collection<SimplifiedApplication>;

  constructor(private httpClient: HttpClient,private dbService: NgxIndexedDBService) {
    this.allDbCollection = this.localDb.addCollection('all');
  }

  getUniqueObject (
    apps: SimplifiedApplication[],
    data: UniqueObjectData,
    matchField: keyof SimplifiedApplication
  ) {
    const start: { [key: string]: UniqueObjectDataWithCount} = {};
    const keys = Object.keys(data);
    const objects = apps.reduce((acc, app) => {
      const key = app[matchField] as string;
      if (!key) return acc;

      if (!acc[key]) {
        acc[key] = {
          count: 0,
          ...Object.fromEntries(
            keys.map((k: string, index: number, array: string[]) => [k, app[data[k as keyof UniqueObjectData] as keyof SimplifiedApplication]])
          )
        } as UniqueObjectDataWithCount;
      }
      
      acc[key].count++;
      return acc;
    }, start);
    return Object.values(objects);
  };
  
  processReload(props: ReloadProps) {
    const r = this.getApplicationsByFilters(
      props.page,
      props.sorts,
      props.filter,
      props.applicationStage,
      props.applicationType,
      props.filterOpportunityOwner,
      props.filterCreditOfficer,
      props.filterSettlementOfficer,
      props.dateFilter
    );

    const preFilter = this.doDateFiltering(this.doFiltering(this.allDbCollection.chain(), props.filter), props.dateFilter);

    let filteredApps = this.doAllFiltering(preFilter.branch(), undefined,                    props.filterCreditOfficer, props.filterSettlementOfficer, props.applicationStage, props.applicationType);
    const opportunityOwners = this.getUniqueObject(filteredApps.data(), { email: "sfOwnerEmail", firstName: "sfOwnerFirstName", lastName: "sfOwnerLastname" }, 'sfOwnerEmail');

    filteredApps =     this.doAllFiltering(preFilter.branch(), props.filterOpportunityOwner, undefined,                 props.filterSettlementOfficer, props.applicationStage, props.applicationType);
    const creditOfficers = this.getUniqueObject(filteredApps.data(), { email: "creditOfficerEmail", firstName: "creditOfficerFirstName", lastName: "creditOfficerLastName" }, 'creditOfficerEmail');

    filteredApps =     this.doAllFiltering(preFilter.branch(), props.filterOpportunityOwner, props.filterCreditOfficer, undefined                    , props.applicationStage, props.applicationType);
    const settlementOfficers = this.getUniqueObject(filteredApps.data(), { email: "sfSettlmentUserEmail", firstName: "sfSettlmentUserFirstName", lastName: "sfSettlmentUserLastname" }, 'sfSettlmentUserEmail');

    filteredApps =     this.doAllFiltering(preFilter.branch(), props.filterOpportunityOwner, props.filterCreditOfficer, props.filterSettlementOfficer, undefined             , props.applicationType);
    const applicationStages = this.getUniqueObject(filteredApps.data(), { name: "StageName" }, 'StageName');

    filteredApps =     this.doAllFiltering(preFilter.branch(), props.filterOpportunityOwner, props.filterCreditOfficer, props.filterSettlementOfficer, props.applicationStage, undefined            );
    const applicationTypes = this.getUniqueObject(filteredApps.data(), { name: "ApplicationType" }, 'ApplicationType');

    return {
      totals: r.counts,
      opportunityOwners: opportunityOwners as OpportunityOwner[],
      creditOfficers: creditOfficers as CreditOfficer[],
      settlementOfficers: settlementOfficers as SettlementOfficer[],
      applicationStages: applicationStages as ApplicationStage[],
      applicationTypes: applicationTypes as ApplicationType[],
      ...r,
    }
  }

  reload (props: ReloadProps) : Observable<{
    totals: {
      underReview: number,
      inSettlement: number,
      closedWon: number,
      approved: number
    },
    opportunityOwners: OpportunityOwner[],
    creditOfficers: CreditOfficer[],
    settlementOfficers: SettlementOfficer[],
    applicationStages: ApplicationStage[],
    applicationTypes: ApplicationType[],
    total: number,   // total applications search / sorts / filter
    limit: number,   // limit of applications search / sorts / filter
    offset: number,  // offset of applications search / sorts / filter
    applications: SimplifiedApplication[],
    graphData: ApplicationsGraphData[]
  }> {
    return this.reloadFromIndexDB().pipe(
      map((res: any) => {
        return this.processReload(props);
      }),
      tap(() => {
        // Trigger `getNewDataFromApi()` as a side effect (background)
        if(props.forceReload){
          this.reloadFromRemoteSource(props.minDate ?? 0).subscribe({
            next: (newData) => {
              this.dataUpdateSubject.next(this.processReload(props));
            },
            error: (err) => console.error('Background API call failed', err),
          });
        }
      })
    );
  }

  private reloadFromIndexDB(forceReload: boolean = false): Observable<{
    all: Collection<SimplifiedApplication>,
  }> {
    return new Observable((observer) => {
      // Attempt to read data from IndexedDB
      this.readFromIndexedDB().subscribe((data) => {
        if (!_.isEmpty(data) && !_.isEmpty(data.all.data)) {
          observer.next(data);
          observer.complete();
        } else {
          this.reloadFromRemoteSource().subscribe((remoteData) => {
            observer.next(remoteData);
            observer.complete();
          });
        }
      });
    });
  }
  
  getGraphData(dateFilter: {startDate: Date, endDate: Date}, filterApplicationData: SimplifiedApplication[]): ApplicationsGraphData[] {

    const hours  = (n: number) => n * (1000 * 60 * 60);                 // 3600000
    const days   = (n: number) => n * (1000 * 60 * 60 * 24);            // 86400000
    const months = (n: number) => n * (1000 * 60 * 60 * 24 * 365 / 12); // 2629746000

    const roundUpToNearest = (n: number, roundTo: number) => Math.ceil((n + 0.00001) / roundTo) * roundTo;
    const roundDownToNearest = (n: number, roundTo: number) => Math.floor(n / roundTo) * roundTo;

    // Calculate date range in days
    const timeDiff = dateFilter.endDate.getTime() - dateFilter.startDate.getTime();
    
    // Choose optimal segmentation based on date range
    const segmentRules = [
      {maxDiff: hours(12),  unit: 'hours',   quantity: 1, nextIntervalFn: (date: moment.Moment) => date.add(1, 'hours'),                    labelFn: (date: Date) => date.getHours().toString() + ':00'},
      {maxDiff: days(1),    unit: 'hours',   quantity: 2, nextIntervalFn: (date: moment.Moment) => date.add(2, 'hours'),                    labelFn: (date: Date) => roundDownToNearest(date.getHours(), 2) + ':00'},
      {maxDiff: days(2),    unit: 'hours',   quantity: 4, nextIntervalFn: (date: moment.Moment) => date.add(4, 'hours'),                    labelFn: (date: Date) => moment(date).format('DD/MM') + "\n" + roundDownToNearest(date.getHours(), 4) + ':00'},
      {maxDiff: days(4),    unit: 'hours',   quantity: 8, nextIntervalFn: (date: moment.Moment) => date.add(8, 'hours'),                    labelFn: (date: Date) => moment(date).format('DD/MM') + "\n" + roundDownToNearest(date.getHours(), 8) + ':00'},
      {maxDiff: days(14),   unit: 'days',    quantity: 1, nextIntervalFn: (date: moment.Moment) => date.add(1, 'days'),                     labelFn: (date: Date) => moment(date).format('DD/MM')},
      {maxDiff: months(3),  unit: 'weeks',   quantity: 1, nextIntervalFn: (date: moment.Moment) => date.add(1, 'weeks'),                    labelFn: (date: Date) => moment(date).format('DD/MM')},
      {maxDiff: months(18), unit: 'months',  quantity: 1, nextIntervalFn: (date: moment.Moment) => date.add(1, 'months').endOf('month'),    labelFn: (date: Date) => moment(date).format('MMM')},
      {maxDiff: Infinity,   unit: 'quarter', quantity: 1, nextIntervalFn: (date: moment.Moment) => date.add(1, 'quarter').endOf('quarter'), labelFn: (date: Date) => `Q${Math.floor(date.getMonth() / 3) + 1} ${moment(date).format('YYYY')}`},
    ];

    const dateSortedApplications = filterApplicationData.sort((a, b) => Date.parse(a.CreateTime) - Date.parse(b.CreateTime));
    const segmentRule = segmentRules.find(t => {
      return timeDiff <= t.maxDiff;
    });
    if (!segmentRule) {
      return [];
    }

    let segmentEnd = segmentRule.nextIntervalFn(moment(dateFilter.startDate).startOf(segmentRule.unit as moment.DurationInputArg2).subtract(1, 'ms')); //.endOf(segmentRule.unit as moment.DurationInputArg2);
    let currentSegmentIdx = 0;

    const segments: ApplicationsGraphData[] = [];
    const findSegmentStart = (end: moment.Moment, count: number, rule: any) => {
      if (count === 0) {
        return moment(dateFilter.startDate);
      } else {
        return moment(end.toDate()).subtract(rule.quantity - 1, rule.unit as moment.DurationInputArg2).startOf(rule.unit);
      }
    }

    let count = 0;
    while (segmentEnd.isSameOrBefore(dateFilter.endDate)) {
      const start = findSegmentStart(segmentEnd, count, segmentRule);
      segments.push({
        id: uuidv4(),
        segmentStart: start,
        segmentEnd: moment(segmentEnd.toDate()),
        label: segmentRule.labelFn(start.toDate()),
        underReview: 0,
        inSettlement: 0,
        closedWon: 0,
        closedLost: 0,
        approved:0,
        total: 0,
      });
      if (segmentEnd.isBefore(moment(dateFilter.endDate))) {
        segmentEnd = segmentRule.nextIntervalFn(moment(segmentEnd.toDate()));
        count++;
        if (segmentEnd.isAfter(moment(dateFilter.endDate))) {
          segmentEnd = moment(dateFilter.endDate);
        } 
      } else if (segmentEnd.isSame(moment(dateFilter.endDate))) {
        break;
      }
    }

    segmentEnd = segments[0].segmentEnd;
    currentSegmentIdx = 0;

    for (let app of dateSortedApplications) {
      const appDate = moment(app.CreateTime);
      while (appDate.isAfter(segmentEnd)) {
        currentSegmentIdx++;
        segmentEnd = segments[currentSegmentIdx].segmentEnd;
      }
      if (STAGE_NAMES_UNDER_REVIEW.includes(app.StageName as StageNameType)) {
        segments[currentSegmentIdx].underReview++;
        segments[currentSegmentIdx].total++;
      } else if (STAGE_NAMES_IN_SETTLEMENT.includes(app.StageName as StageNameType)) {
        segments[currentSegmentIdx].inSettlement++;
        segments[currentSegmentIdx].total++;
      } else if (STAGE_NAMES_CLOSED_WON.includes(app.StageName as StageNameType)) {
        segments[currentSegmentIdx].closedWon++;
        segments[currentSegmentIdx].total++;
      }else if(STAGE_NAMES_APPROVED.includes(app.StageName as StageNameType)){
        segments[currentSegmentIdx].approved++;
        segments[currentSegmentIdx].total++;
      } else if (app.StageName === 'Closed Lost') {
        segments[currentSegmentIdx].closedLost++;
        segments[currentSegmentIdx].total++;
      }
    }

    return segments;
  }

  removeApplicationLocally(applicationId: number) {
    this.allDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
  }

  addOrUpdateApplicationLocally(app: SimplifiedApplication) {
    if (app && app.ApplicationId) {
      const appFound = this.allDbCollection.find({
        ApplicationId: app.ApplicationId
      });
      if ((appFound ?? []).length > 0) {
        this.removeApplicationLocally(app.ApplicationId);
      }
      this.allDbCollection.insert(app);
    }
  }

  private reloadFromRemoteSource(minDate: number = 0): Observable<{
    all: Collection<SimplifiedApplication>,
  }> {
    const obs = this.httpClient.post<PayloadApiResponse<LastRecentApplicationsBundle>>(URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE(), {
      minDate: minDate,
    }, httpOptions())
      .pipe(
        tap((response: PayloadApiResponse<LastRecentApplicationsBundle>) => {
          if (response.payload) {
            this.loaded = true;
            this.lastUpdate = moment();
           
            this.allDbCollection.clear({removeIndices: true});

            const records = response.payload.all.records;
            this.saveToIndexedDB(records, "all");

            (records ?? []).forEach((record: SimplifiedApplication) => {
              this.allDbCollection.insert(record);
            });
          }
        }),
        map((r: any) => {
          return {
            all: this.allDbCollection,
          }
        })
      );
    return obs;
  }
 
  private readFromIndexedDB():Observable<{
    all: Collection<SimplifiedApplication>,
  }> {
    return new Observable((observer) => {
      this.dbService.getAll('applications').subscribe((data: any[]) => {
        const all = data.filter((app) => app.status === 'all' || !app.status);
        
        this.allDbCollection.clear({removeIndices: true});
         
        (all[0]?.application_data ?? []).forEach((rec: any) => {
          const sanitizedRecord = { ...rec };
          delete sanitizedRecord.$loki; // Ensure `$loki` is removed
          this.allDbCollection.insert(sanitizedRecord);
        });
     
        observer.next({
          all: this.allDbCollection,
        });
        observer.complete();
       
      });
    });
  }
  
  private saveToIndexedDB(data: SimplifiedApplication[], status: string): void {
    const currentTime = new Date().toISOString();
    localStorage.setItem('lastUpdatedTime', currentTime);
    this.dbService.clear('applications').subscribe(() => {
      this.dbService.add('applications', {
        status: status,
        application_data: data,
      }).subscribe({
        // the add method is getting called twice despite 'saveToIndexedDB' being called once, so we ignore the error when it fails on second attempt
        error: (err: any) => {},
      });
    });
  }
 
  private doFiltering(chain: Resultset<SimplifiedApplication & LokiObj>, filter?: string) {
    if (!!filter) {
      const __filter = (filter ?? '').trim();
      const _filter = escapeStringRegexp(__filter);
      chain = chain.find({
        '$or': [
          {'BrokerAppId': {'$regex': [_filter, 'i']}},
          {'SalesforceId': {'$regex': [_filter, 'i']}},
          {'CompanyName': {'$regex': [_filter, 'i']}},
          {'BrokerName': {'$regex': [_filter, 'i']}},
          {'IndividualGivenName': {'$regex': [_filter, 'i']}},
          {'IndividualSurName': {'$regex': [_filter, 'i']}},
        ]
      });
    }
    return chain;
  }

  doAllFiltering(
    chain: Resultset<SimplifiedApplication & LokiObj>, 
    filterOpportunityOwner?: string[],
    filterCreditOfficer?: string[],
    filterSettlementOfficer?: string[],
    applicationStage?: StageNameType[],
    typeFilter?: AllApplicationTypes[]
  ) {
    return chain.find({
      '$and': [
        !!(filterOpportunityOwner ?? []).length ? {'$or': [...(filterOpportunityOwner ?? []).map((email) => ({'sfOwnerEmail': {'$regex': [email, 'i']}}))]} : {},
        !!(filterCreditOfficer ?? []).length ? {'$or': [...(filterCreditOfficer ?? []).map((email) => ({'creditOfficerEmail': {'$regex': [email, 'i']}}))]} : {},
        !!(filterSettlementOfficer ?? []).length ? {'$or': [...(filterSettlementOfficer ?? []).map((email) => ({'sfSettlmentUserEmail': {'$regex': [email, 'i']}}))]} : {},
        !!(applicationStage ?? []).length ? {'$or': [...(applicationStage ?? []).map((stage) => ({'StageName': { '$eq': stage }}))]} : {},
        !!(typeFilter ?? []).length ? {'$or': [...(typeFilter ?? []).map((type) => ({'ApplicationType': { '$eq': type }}))]} : {},
      ]
    });
  }    
  

  private doDateFiltering(chain: Resultset<SimplifiedApplication & LokiObj>, dateFilter?:{startDate: Date, endDate: Date}) {
    if (!!dateFilter) {
      chain = chain.find({
        'CreateTime': {
          '$and': [
            {'$lte': dateFilter.endDate.toISOString()},
            {'$gte': dateFilter.startDate.toISOString()}
          ]
        }
      });
    }
    return chain;
  }

  private doSorting(chain: Resultset<SimplifiedApplication & LokiObj>, sorts?: ApplicationSort) {
    const sortCriteria: [(keyof SimplifiedApplication | keyof LokiObj), boolean][] = [];
    if (sorts && sorts.length) {
      for (const sort of sorts) {
        switch(sort.prop) {
          case 'BrokerAppId': {
            sortCriteria.push(['BrokerAppId', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'BrokerName': {
            sortCriteria.push(['BrokerName', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'CompanyName': {
            sortCriteria.push(['CompanyName', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'CreateTime': {
            sortCriteria.push(['CreateTime', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'Status': {  //
            sortCriteria.push(['Status', (sort.dir == 'DESC' ? true : false)]);
            sortCriteria.push(['CreateTime', true]);
            break;
          }
          case 'AppInfoStageName': { //
            sortCriteria.push(['StageName', (sort.dir == 'DESC' ? true : false)]);
            sortCriteria.push(['CreateTime', true]);
            break;
          }
        }
      }
    }
    // sort by default by applicationId
    if (sortCriteria.length === 0) {
      sortCriteria.push(['ApplicationId', true]);
    }
    if (sortCriteria && sortCriteria.length) {
      chain = chain.compoundsort(sortCriteria);
    }
    return chain;
  }

  private doPagination(chain: Resultset<SimplifiedApplication & LokiObj>, page: {offset: number, limit: number}) {
    chain = chain
      .offset(page.offset * page.limit)
      .limit(page.limit)
    return chain;
  }

  private getApplicationsByFilters(
    page: {offset: number, limit: number},
    sorts?: ApplicationSort,
    filter: string = '',
    applicationStage?: StageNameType[],
    applicationType?: AllApplicationTypes[],
    filterOpportunityOwner?: string[],
    filterCreditOfficer?: string[],
    filterSettlementOfficer?: string[],
    dateFilter?:{startDate: Date, endDate: Date}
   ) {
       
    let chain: Resultset<SimplifiedApplication & LokiObj> = this.allDbCollection.chain();
    chain = this.doDateFiltering(chain, dateFilter);
    chain = this.doFiltering(chain, filter);
    chain = this.doAllFiltering(chain, filterOpportunityOwner, filterCreditOfficer, filterSettlementOfficer, applicationStage, applicationType);

    const underReview = chain.data().filter((app: SimplifiedApplication) => STAGE_NAMES_UNDER_REVIEW.includes(app.StageName as StageNameType)).length;
    const inSettlement = chain.data().filter((app: SimplifiedApplication) => STAGE_NAMES_IN_SETTLEMENT.includes(app.StageName as StageNameType)).length;
    const closedWon = chain.data().filter((app: SimplifiedApplication) => STAGE_NAMES_CLOSED_WON.includes(app.StageName as StageNameType)).length;
    const approved = chain.data().filter((app: SimplifiedApplication) => STAGE_NAMES_APPROVED.includes(app.StageName as StageNameType)).length;
    chain = this.doSorting(chain, sorts);
    const total = chain.data().length;
    const allApplications = chain.data();
    
    const graphData = this.getGraphData(dateFilter!, chain.data())
   
    chain = this.doPagination(chain, page);
    const result = chain.data();
    
    return {
      total,
      limit: page.limit,
      offset: page.offset,
      applications: result,
      counts: {underReview, inSettlement, closedWon, approved},
      allApplications,
      graphData
    };
  }

  getUnfilteredContacts() {
    
    const opportunityOwners = this.getUniqueObject(this.allDbCollection.data, { email: "sfOwnerEmail", firstName: "sfOwnerFirstName", lastName: "sfOwnerLastname" }, 'sfOwnerEmail');
    const creditOfficers = this.getUniqueObject(this.allDbCollection.data, { email: "creditOfficerEmail", firstName: "creditOfficerFirstName", lastName: "creditOfficerLastName" }, 'creditOfficerEmail');
    const settlementOfficers = this.getUniqueObject(this.allDbCollection.data, { email: "sfSettlmentUserEmail", firstName: "sfSettlmentUserFirstName", lastName: "sfSettlmentUserLastname" }, 'sfSettlmentUserEmail');

    return {
      opportunityOwners,
      creditOfficers,
      settlementOfficers
    }
  }
}
