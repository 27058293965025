import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import {BreadcrumbModule} from '../breadcrumb-component/breadcrumb.module';

import {MessageBoxModule} from '../message-box/message-box.module';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {AddressModule} from '../address-component/address.module';
import {DigitalIdModule} from '../digital-id-component/digital-id.module';
import {InputMaskModule} from '@ngneat/input-mask';

import {AssetCatAndTypeModule} from '../asset-cat-and-type-component/asset-cat-and-type.module';
import {DatepickerModule} from '../datepicker-component/datepicker.module';
import {MobileModule} from '../mobile-component/mobile.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { UploadFileModule } from '../upload-file-component/upload-file.module';
import {Observable} from 'rxjs';
import {Application, ApplicationTypes, RatecardDetails, riskRating} from '@portal-workspace/grow-shared-library';
import {UserSelectionModule} from '../user-selection-component/user-selection.module';
import { RiskAnalysisModule } from '../risk-analysis-component/risk-analysis.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
 
import {EmailModule} from '../common fields/email.module';
import {NameModule} from '../name-component/name.module';
import {CustomPaginatorModule} from '../custom-paginator-component/custom-paginator.module';
import {YesNoModule} from '../yes-no-component/yes-no.module';
import { AccreditationStageIconComponent } from './accreditation-stage-icon.component';
import { AccreditationDetailsComponent } from './accreditation-details.component';
import { AccreditationAccComponent } from './accreditation-acc.component';
import { AccreditationDocumentsComponent } from './accreditation-documents.component';
import { ApplicationSummaryModule } from '../application-summary-component/application-summary.module';
import { AccreditationDrivingLicenceComponent } from './accreditation-driving-licence.component';
import { ApplicationDetailsModule } from '../application-details-component/application-details.module';
import { AccreditationDocumentsSubTableComponent } from './accreditation-documents-sub-table.component';
import { AccreditationReportComponent } from './accreditation-report.component';
 
 

export type UpdateApplicationDocumentFn = (applicationId: number, files: File[], deleteFiles: string[]) => Promise<Observable<any>>;
export type GetRateCardDetailsFn = (userId: number, type: ApplicationTypes) => Observable<RatecardDetails>;

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        BreadcrumbModule,
        MessageBoxModule,
        TitleSelectionModule,
        AddressModule,
        DigitalIdModule,
        InputMaskModule,
        AssetCatAndTypeModule,
        DatepickerModule,
        MobileModule,
        ClipboardModule,
        UploadFileModule,
        UserSelectionModule,
        RiskAnalysisModule,
        AngularMaterialLibraryModule,
        PipeLibraryModule,
        DirectiveLibraryModule,
        EmailModule,
        NameModule,
        CustomPaginatorModule,
        YesNoModule,
        ApplicationSummaryModule,
        ApplicationDetailsModule,
        AccreditationDetailsComponent,
        AccreditationStageIconComponent,
        AccreditationAccComponent,
        AccreditationDocumentsComponent,
        AccreditationDrivingLicenceComponent,
        AccreditationDocumentsSubTableComponent,
        AccreditationReportComponent
    ],
    exports: [
        AccreditationDetailsComponent,
        AccreditationStageIconComponent,
        AccreditationDocumentsSubTableComponent,
        AccreditationReportComponent
    ]
})
export class AccreditationDetailsModule {

}
