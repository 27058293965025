import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ApplicationDialogService,
  PortalHotToastService,
  setupUntilDestroy,
  validateEmail,
  setStepper2StepConfig,
  getUser,
} from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';
import {
  Application,
  ApprovalNoticeDialogData,
  ApprovalNoticeDialogResult, DeleteApprovalNoticeFileFn,
  GenerateApprovalNoticeFn,
  GenerateContractResponse,
  getApprovalNoticeFileName,
  GroupedDocument,
  SendApprovalNoticeEmailFn, TermRateForBusinessOverdraft,
  GetApplicationOwnerFn,
  ApplicationOwner,
  User,
  GetUserFn,
} from '@portal-workspace/grow-shared-library';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FlexModule } from '@angular/flex-layout/flex';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgTemplateOutlet } from '@angular/common';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ApplicationStepper2Component } from '../application-stepper-component/application-stepper2.component';

@UntilDestroy()
@Component({
    templateUrl: './approval-notice.dialog.html',
    styleUrls: ['./approval-notice.dialog.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, CdkStepperModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatChipsModule, PdfViewerModule, FlexModule, MatDialogModule]
})
export class ApprovalNoticeDialog implements OnInit {

  application!: Application;
  subscriptions: Subscription[] = [];
  formGroupStep1!: FormGroup;
  formControlStep1Email!: FormControl<string | null>;
  formControlStep1CcEmail!: FormControl<string | null>;
  emailListStep1: string[] = [];
  ccEmailListStep1: string[] = ['finance@dynamoney.com'];
  emailListStep1Error = false;
  ccEmailListStep1Error = false;
  formGroupStep2!: FormGroup;
  formGroupStep3!: FormGroup;
  submitterName: string | null = null;
  salesContactEmail: string | null = null;
  salesContactName: string | null = null;
  brokerAppId: string = "";
  applicantName: string = "";

  approvalNoticeName: string = "";
  approvalNoticeBase64: string = "";
  approvalNoticeBuffer!: Uint8Array;
  addOnBlur = true;
  apiUrl: string;
  standardCondition: GroupedDocument[] = [];
  nonStandardCondition: GroupedDocument[] = [];
  businessLoanData!: TermRateForBusinessOverdraft | undefined;
  loggedInUser: User | null = getUser();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

   
  
  generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  getApplicationOwnerFn!: GetApplicationOwnerFn;
  getUserFn!: GetUserFn;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: ApprovalNoticeDialogData,
    private dialogRef: MatDialogRef<ApprovalNoticeDialog, ApprovalNoticeDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.application = data.application;
    this.generateApprovalNoticeFn = data.generateApprovalNoticeFn;
    this.sendApprovalNoticeEmailFn = data.sendApprovalNoticeEmailFn;
    this.deleteApprovalNoticeFileFn = data.deleteApprovalNoticeFileFn;
    this.getApplicationOwnerFn = data.getApplicationOwnerFn;
    this.getUserFn = data.getUserFn;
    this.apiUrl = data.apiUrl;
    this.standardCondition = data.standardCondition;
    this.nonStandardCondition = data.nonStandardCondition;
    this.businessLoanData = data.businessLoanData;
  }
  ngOnInit(): void {
    setupUntilDestroy(this);
    this.initStep1();
    this.initStep2();
    this.initStep3();

    if (this.application.AppInfoSalesforceID) {
      this.subscriptions.push(
        this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
        .pipe(this.toastService.spinnerObservable())
        .subscribe(
          (r: ApplicationOwner | null) => {
            if (r && r.Email) {
              this.ccEmailListStep1.push(r.Email);
            }
          }
        )
      )
    }
  }

  initStep1() {
    this.formControlStep1Email = this.formBuilder.control(null, Validators.email);
    this.formControlStep1CcEmail = this.formBuilder.control(null, Validators.email);
    this.formGroupStep1 = this.formBuilder.group({
      email: this.formControlStep1Email,
      ccEmail: this.formControlStep1CcEmail,
    });

    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: async stepper => {
          // if file exists, delete it
          this.deleteExistingApprovalNoticeFile();

          this.subscriptions.push(
            this.generateApprovalNoticeFn({
              application: this.application,
              standardCondition: this.standardCondition.map(g => g.description),
              nonStandardCondition: this.nonStandardCondition.map(g => g.description ? `${g.groupName} ${g.description}` : g.groupName),
              businessLoanData: this.businessLoanData,
            }).pipe(
              this.toastService.spinnerObservable(),
            ).subscribe((res: GenerateContractResponse) => {
              this.approvalNoticeName = res.fileName ?? "";
              this.approvalNoticeBase64 = res.base64 ?? "";
              // stepper.next();
              setTimeout(() => {
                this.base64ToArrayBuffer(this.approvalNoticeBase64);
                stepper.next();
              });
            })
          )
      },
      previousStepClickedFn: async (stepper) => {
        this.onClickClose()
      },
      previousStepButtonText:"Close"
    });

     

    this.subscriptions.push(
      this.formControlStep1Email.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.emailListStep1Error = false;
        }
      })
    )

    this.subscriptions.push(
      this.formControlStep1CcEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.ccEmailListStep1Error = false;
        }
      })
    )

    this.subscriptions.push(
      this.getUserFn(this.application.UserId,false).subscribe((user) => {
        if (user) {
          this.emailListStep1.push(user.Email)
        }
      })
    )
  }

  initStep2() {
    this.formGroupStep2 = this.formBuilder.group({});

    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        this.subscriptions.push(
          this.sendApprovalNoticeEmailFn({
            fileName: this.approvalNoticeName,
            emailList: this.emailListStep1,
            ccEmailList: this.ccEmailListStep1,
            application: this.application,
            userId: this.loggedInUser?.UserId ?? 0,
            submitterUserId: this.application.UserId,
            applicantName: this.applicantName,
            salesContactEmail: this.salesContactEmail,
            salesContactName: this.salesContactName,
            brokerAppId: this.application.BrokerAppId
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe(() => {
            stepper.next();
          })
        )
      },
      nextStepButtonText:"Send Approval Notice",
      showSecondarySubmitButton:true,
      secondaryButtonText:"Download Approval Notice",
      secondaryButtonClickedFn: stepper => {
        this.downloadApprovalNotice();
      },
    });
  }

  initStep3() {
    this.formGroupStep3 = this.formBuilder.group({});
    setStepper2StepConfig(this.formGroupStep3, {
      nextStepClickedFn: async stepper => {
        this.deleteExistingApprovalNoticeFile();
        this.dialogRef.close();
      },
      nextStepButtonText:"Got it"
    });
  }

  add(event: MatChipInputEvent, ccEmail = false): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        if (ccEmail) {
          this.ccEmailListStep1.push(value)
        } else {
          this.emailListStep1.push(value);
        }
      }
      // Clear the input value
      event.chipInput!.clear();
      if (ccEmail) {
        this.ccEmailListStep1Error = false;
      } else {
        this.emailListStep1Error = false;
      }
    } else if (value) {
      if (ccEmail) {
        this.ccEmailListStep1Error = true;
      } else {
        this.emailListStep1Error = true;
      }
    }
  }

  remove(email: string, ccEmail = false): void {
    if (ccEmail) {
      const index = this.ccEmailListStep1.indexOf(email);

      if (index >= 0) {
        this.ccEmailListStep1.splice(index, 1);
      }
    } else {
      const index = this.emailListStep1.indexOf(email);

      if (index >= 0) {
        this.emailListStep1.splice(index, 1);
      }
    }
  }

  onClickClose() {
    this.deleteExistingApprovalNoticeFile();
    this.dialogRef.close()
  }

  base64ToArrayBuffer(base64: string) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.approvalNoticeBuffer = (bytes.buffer as Uint8Array);
  }

  downloadApprovalNotice() {
    const newFileName = getApprovalNoticeFileName(this.application);
    const url = this.apiUrl + "/download-contract/" +  this.approvalNoticeName + "/" + newFileName;
    const res = window.open(url);
    if (!res || res.closed || typeof res.closed === 'undefined') {
      this.applicationDialogService.openAlertDialog({
        message: 'Warn',
        subMessage: 'Please disable Popup blocker and try again',
      });
      return;
    }
  }

  deleteExistingApprovalNoticeFile() {
    if (this.approvalNoticeName) {
      this.subscriptions.push(
        this.deleteApprovalNoticeFileFn({
          fileName: this.approvalNoticeName
        }).subscribe()
      )
    }
  }
}
