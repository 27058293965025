import {inject, Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {
  ApplicationConfirmationDialog,
} from './application-confirmation.dialog';
import {
  ApplicationConfirmationDialogData,
  PPSRConfirmationDialogData,
  PPSRConfirmationDialogResult,
  ApplicationConfirmationDialogResult,
  DocumentUploadDialogResult,
  ExtendTermsDialogData,
  ExtendTermsDialogResult,
  MarkAsSoldDialogData,
  MarkAsSoldDialogResult,
  SupplierDialogData,
  AssetSupplier,
  PPSRRegisterDialogResult,
  PPSRRegisterDialogData,
  PPSRCheckDialogData,
  PPSRCheckDialogResult,
  ConfirmationDialogData,
  ConfirmationDialogResult,
  CustomDocumentTagDialogResult,
  AssetSaveDialogResult,
  AssetSaveDialogData,
  SettleLoanDialogData,
  SettleLoanDialogResult,
  GenerateContractDialogData,
  GenerateContractDialogResult,
  ContractStatusDialogResult,
  ContractStatusDialogData,
  SuccessDialogData,
  SuccessDialogResult,
  InformationDialogData,
  InformationDialogResult,
  DateRangeDialogData,
  DateRangeDialogResult,
  CustomDocumentTagDialogData,
  AssetDisbursementPayeeDialogData,
  AssetDisbursementPayeeDialogResult,
  AssetDisbursementInvoicesDialogData,
  AssetDisbursementInvoicesDialogResult,
  DisbursementBankDetailsDialogData,
  DisbursementBankDetailsDialogResult,
  DisbursementAmountDialogData,
  DisbursementAmountDialogResult,
  EditAddressDialogResult,
  EditAddressDialogData,
  BusinessDisbursementPayeeDialogResult,
  BusinessDisbursementPayeeDialogData,
  BusinessDisbursementInvoiceDialogData,
  BusinessDisbursementInvoicesDialogResult,
  DisbursementDialogData,
  DisbursementDialogResult,
  BPayTransactionDialogData,
  BPayTransactionDialogResult,
  DirectPaymentTransactionDialogData,
  DirectPaymentTransactionDialogResult,
  DirectPaymentBatchDialogData,
  DirectPaymentBatchDialogResult,
  NewBatchPaymentDialogData,
  NewBatchPaymentDialogResult,
  BatchPayment,
  ApprovalNoticeDialogData,
  ApprovalNoticeDialogResult,
  ConfirmPasswordAdminDialogData,
  ConfirmPasswordAdminDialogResult,
  CopyApplicationDialogData,
  CopyApplicationDialogResult,
  CreateOverdraftAccountDialogData,
  CreateOverdraftAccountDialogResult,
  FirstOverdraftUserDialogData,
  FirstOverdraftUserDialogResult,
  AddCustomerUserFormDialogDialogData,
  AddCustomerUserFormDialogResult,
  EditKycStatusDialogData,
  EditKycStatusDialogResult,
  PismoAccountTransactionsDialogData,
  PismoAccountTransactionsDialogResult,
  PismoActivateOrDeactivateCardDialogData,
  PismoActivateOrDeactivateCardDialogResult,
  PismoPostManualTransactionDialogData,
  PismoEditAccountDialogData,
  PismoEditAccountDialogResult,
  PismoEditCustomerDialogData,
  PismoEditCustomerDialogResult,
  ManualBusinessSearchDialogData,
  ManualBusinessSearchDialogResult,
  Enable2FAPasswordDialogData,
  Enable2FADialogData,
  Enable2FADialogResult,
  Enable2FAPasswordDialogResult,
  GeneratePayoutDialogData,
  GeneratePayoutDialogResult,
  PayoutNotificationDialogData,
  PayoutNotificationDialogResult,
  YesNoDialogData,
  YesNoDialogResult,
  TFAActivatedDialogResult,
  TFAActivatedDialogData,
  PismoTransactionsIncludingPending,
  TaxInvoiceDetailsDialogData,
  TaxInvoiceDetailsDialogResult,
  TransferredbalanceDialogData,
  TransferredBalanceDialogResult,
  BankTransferDialogData,
  BankTransferDialogResult,
  DirectDebitDialogData,
  DirectDebitDialogResult,
  BpayDialogData,
  BpayDialogResult,
  PismoReissueCardDialogData,
  PismoReissueCardDialogResult,
  NewAssetInspectionDialogResult,
  NewAssetInspectionDialogData,
  PismoUpdateRequireFixedMonthlyInstallmentFlagDialogData,
  PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult,
  AssetPricingComparisonDialogData,
  AssetPricingComparisonDialogResult,
  BusinessPricingComparisonDialogData,
  BusinessPricingComparisonDialogResult,
  BusinessOverdraftPricingComparisonDialogData,
  BusinessOverdraftPricingComparisonDialogResult,
  IdentityVerificationLinkDialogData,
  IdentityVerificationLinkDialogResult,
  CreditUserListDialogResult,
  ManageApplicationDialogData,
  ManageApplicationDialogResult,
  AllocateUserToPismoAccountDialogResult,
  CreditUserListDialogData,
  ConsumerPricingComparisonDialogData,
  ConsumerPricingComparisonDialogResult,
  CorporateLoanPaymentBreakupDialogData2,
  InterestRateCalculationLogDialogData,
  InterestRateCalculationLogDialogResult,
  CorporateLoanPricingComparisonDialogData,
  CorporateLoanPricingComparisonDialogResult,
  EditBrokerageDialogData,
  EditBrokerageDialogResult,
  EditInvoiceAmountDialogData,
  EditInvoiceAmountDialogResult,
  EditLenderDialogData,
  EditLenderDialogResult,
  IndividualWithResult,
  ApplicantsPrivacyConsentDialogResult,
  ApplicantsPrivacyConsentDialogData,
  EditCreditFlowDialogData,
  EditCreditFlowDialogResult,
  EditCreditRuleMappingDialogData,
  EditCreditRuleMappingDialogResult,
  EditCreditFlowNodeDialogData,
  EditCreditFlowNodeDialogResult,
  EditCreditAlertDialogData,
  EditCreditAlertDialogResult,
  SelectableTagsDialogData,
  SelectableTagsDialogResult,
  PublishCreditFlowConfirmationDialogResult,
  PublishCreditFlowConfirmationDialogData,
  EditCreditRuleItemDialogData,
  EditCreditRuleItemDialogResult,
  CreditDecisioningReasonDialogData,
  CreditDecisioningReasonDialogResult,
  EnablePismoCustomerAccountDialogData,
  EnablePismoCustomerAccountDialogResult,
  PrefillDirectorsDialogData,
  PrefillDirectorsDialogResult,
  DocumentReviewDialogData,
  DocumentReviewDialogResult,
  SelectCreditRuleItemDialogData,
  SelectCreditRuleItemDialogResult,
  SelectWebServicesDialogData,
  SelectWebServicesDialogResult,
  EditMonthlyFacilityFeePercentageDialogData,
  EditMonthlyFacilityFeePercentageDialogResult,
  QuotationNotificationDialogResult,
  QuotationNotificationDialogData,
  MoreInformationDialogData,
  MoreInformationDialogResult,
  WebServiceJsonDialogData,
  WebServiceJsonDialogResult,
  CreditApprovedDialogData,
  CreditApprovedDialogResult,
  WithdrawApplicationDialogData,
  WithdrawApplicationDialogResult,
  EditAmortisedRepaymentDialogData,
  EditAmortisedRepaymentDialogResult,
  UploadFilesMultiTagsDialogResult,
  PismoUpdateStopDirectDebitFlagDialogResult,
  PismoUpdateStopDirectDebitFlagDialogData,
  CreditDeclineDialogData,
  CreditDeclineDialogResult,
  NewReportDialogData,
  NewReportDialogResult,
  ReportRequestDialogData,
  ReportRequestDialogResult,
} from '@portal-workspace/grow-shared-library';
import {ApplicationSubmittedDialog, } from './application-submitted.dialog';
import {
  ApplicationSubmittedDialogData,
  ApplicationSubmittedDialogResult, OverdraftAccountLimitIncreaseDialogData, OverdraftAccountLimitIncreaseDialogResult,
  OverdraftDirectDebitDialogData, OverdraftDirectDebitDialogResult
} from '@portal-workspace/grow-shared-library';
import { TotalPaymentBreakupDialog, } from './total-payment-breakup.dialog';
import { TotalPaymentBreakupDialogResult } from '@portal-workspace/grow-shared-library';
import {
  TotalPaymentBreakupDialogData2,
} from '@portal-workspace/grow-shared-library';
import {CreditInfoExchangeAlertDialog, } from './credit-info-exchange-alert.dialog';
import {CreditInfoExchangeAlertDialogResult} from '@portal-workspace/grow-shared-library';
import {AlertDialog, } from './alert.dialog';
import {AlertDialogData, AlertDialogResult} from '@portal-workspace/grow-shared-library';
import {IndividualFormDialog, } from './individual-form.dialog';
import {IndividualFormDialogData, IndividualFormDialogResult} from '@portal-workspace/grow-shared-library';
import {UploadFilesDialog} from './upload-files.dialog';
import {UploadFileDialogData, UploadFileDialogResult, } from '@portal-workspace/grow-shared-library';
import {CurrencyInputDialog,} from './currency-input.dialog';
import {CurrencyInputDialogData, CurrencyInputDialogResult} from '@portal-workspace/grow-shared-library';
import {ProgressSpinnerDialog} from './progress-spinner.dialog';
import { ConfirmPasswordDialog, } from './confirm-password.dialog';
import { ConfirmPasswordDialogData, ConfirmPasswordDialogResult } from '@portal-workspace/grow-shared-library';
import {OverdraftPaymentBreakupDialog, } from './overdraft-payment-breakup.dialog';
import {OverdraftPaymentBreakupDialogData2} from '@portal-workspace/grow-shared-library';
import { AddBrokerUserFormDialog, } from './add-broker-user-form.dialog';
import { AddBrokerUserFormDialogDialogData, AddBrokerUserFormDialogResult } from '@portal-workspace/grow-shared-library';
import { PPSRConfirmationDialog } from './ppsr-confirmation.dialog';
import { MarkAsSoldDialog } from './mark-as-sold.dialog';
import { PrivateSupplierFormDialog } from './private-supplier-form.dialog';
import { PPSRRegisterDialog } from './PPSR-register.dialog';
import { PPSRCheckDialog } from './PPSR-check.dialog';
import {ConfirmationDialog} from './confirmation.dialog';
import {CustomDocumentTagDialog} from './custom-document-tag.dialog';
import { AssetSaveDialog } from './asset-save.dialog';
import { SettleLoanDialog } from './settle-loan.dialog';
import {GenerateContractDialog} from './generate-contract.dialog';
import {ContractStatusDialog} from './contract-status.dialog';
import { SuccessDialog } from './success.dialog';
import { InformationDialog } from './information-dialog';
import { DateRangeDialog } from './date-range.dialog';
import { AssetDisbursementPayeeDialog } from './asset-disbursement-payee.dialog';
import { AssetDisbursementInvoicesDialog } from './asset-disbursement-invoices.dialog';
import { DisbursementBankDetailsDialog } from './disbursement-bank-details.dialog';
import {DisbursementAmountDialog} from './disbursement-amount.dialog';
import {EditAddressDialog} from './edit-address.dialog';
import { BusinessDisbursementPayeeDialog } from './business-disbursement-payee.dialog';
import { BusinessDisbursementInvoiceDialog } from './business-disbursement-invoice.dialog';
import { Enable2FAPasswordDialog } from './enable-2fa-password.dialog';
import { Enable2FADialog } from './enable-2fa.dialog';
import { TFAActivatedDialog } from './2fa-activated.dialog';
import { DisbursementDialog } from './disbursement.dialog';
import { BPayTransactionDialog } from './bpay-transaction.dialog';
import {DirectPaymentTransactionDialog} from './direct-payment-transaction.dialog';
import {DirectPaymentBatchDialog} from './direct-payment-batch.dialog';
import {NewBatchPaymentDialog} from './new-batch-payment.dialog';
import { ApprovalNoticeDialog } from './approval-notice.dialog';
import { ConfirmPasswordAdminDialog } from './confirm-password-admin.dialog';
import {CopyApplicationDialog} from './copy-application.dialog';
import { CreateOverdraftAccountDialog } from './create-overdraft-account.dialog';
import { FirstOverdraftUserDialog } from './first-overdraft-user.dialog';
import { AddCustomerUserFormDialog } from './add-customer-user-form.dialog';
import {pxToRem, setupUntilDestroy} from '../component-utils';
import { EditKycStatusDialog } from './edit-kyc-status.dialog';
import {PismoAccountTransactionsDialog} from './pismo-account-transactions.dialog';
import {PismoActivateOrDeactivateCardDialog} from './pismo-activate-or-deactivate-card-dialog.component';
import {PismoPostManualTransactionDialog} from './pismo-post-manual-transaction.dialog';
import {PismoEditAccountDialog} from './pismo-edit-account.dialog';
import {PismoEditCustomerDialog} from './pismo-edit-customer.dialog';
import {ManualBusinessSearchDialog} from './manual-business-search.dialog';
import { ShowUserLockDialog } from './show-user-lock.dialog';
import { TaxInvoiceDetailsDialog } from './tax-invoice-details.dialog';
import { TransferredBalanceDialog } from './transferred-balance.dialog';
import {BreakpointService} from "../../services/breakpoint.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import { BankTransferDialog } from './bank-transfer.dialog';
import { DirectDebitDialog } from './direct-debit.dialog';
import { BpayDialog } from './bpay.dialog';
import { PismoReissueCardDialog } from './pismo-reissue-card.dialog';
import { NewAssetInspectionDialog } from './new-asset-inspection.dialog';
import { PismoUpdateRequireFixedMonthlyInstallmentFlagDialog } from './pismo-update-require-fixed-monthly-installment-flag.dialog';
import { GeneratePayoutDialog } from './generate-payout.dialog';
import { PayoutNotificationDialog } from './payout-notification.dialog';
import { YesNoDialog } from './yes-no.dialog';
import {AssetPricingComparisonDialog} from "./asset-pricing-comparison.dialog";
import {BusinessPricingComparisonDialog} from "./business-pricing-comparison.dialog";
import {BusinessOverdraftPricingComparisonDialog} from "./business-overdraft-pricing-comparison.dialog";
import { IdentityVerificationLinkDialog } from './identity-verification-link.dialog';
import { CreditUserListDialog } from './credit-user-list.dialog';
import { ManageApplicationDialog } from './manage-application.dialog';
import { AllocateUserToPismoAccountDialogData } from "@portal-workspace/grow-shared-library";
import {AllocateUserToPismoAccountDialog} from "./allocate-user-to-pismo-account.dialog";
import { ConsumerPricingComparisonDialog } from './consumer-pricing-comparison.dialog';
import {CorporateLoanPaymentBreakupDialog} from "./corporate-loan-payment-breakup.dialog";
import {InterestRateCalculationLogDialog} from "./interest-rate-calculation-log.dialog";
import {CorporateLoanPricingComparisonDialog} from "./corporate-loan-pricing-comparison.dialog";
import { OverdraftAccountLimitIncreaseDialog } from './overdraft-account-limit-increase.dialog';
import { OverdraftDirectDebitDialog } from './overdraft-direct-debit.dialog';
import { EditBrokerageDialog } from './edit-brokerage.dialog';
import { EditInvoiceAmountDialog } from './edit-invoice-amount.dialog';
import { BankStatementSelectTransactionsDialog, BankStatementSelectTransactionsDialogData, BankStatementSelectTransactionsDialogResult } from './bank-statement-select-transactions.dialog';
import { EditLenderDialog } from './edit-lender.dialog';
import { DigitalIdVerifyDialog } from './digital-id-verify.dialog';
import { DigitalIdVerifyDialogData } from '../digital-id-component/digital-id.component';
import { UnsavedChangesDialog } from './unsaved-changes.dialog';
import { ApplicantsPrivacyConsentDialog } from './applicants-privacy-consent.dialog';
import { SelectableTagsDialog } from './selectable-tags.dialog';
import { UploadFilesMultiTagsDialog } from './upload-files-multi-tags.dialog';
import { EditCreditFlowDialog } from './edit-credit-flow.dialog';
import { EditCreditRuleMappingDialog } from './edit-credit-rule-mapping.dialog';
import { EditCreditFlowNodeDialog } from './edit-credit-flow-node.dialog';
import { EditCreditAlertDialog } from './edit-credit-alert.dialog';
import { PublishCreditFlowConfirmationDialog } from './publish-credit-flow-confirmation.dialog';
import { EditCreditRuleItemDialog } from './edit-credit-rule-item.dialog';
import { CreditDecisioningReasonDialog } from './credit-decisioning-reason.dialog';
import { EnablePismoCustomerAccountDialog } from './pismo-enable-account.dialog';
import { PrefillDirectorsDialog } from './prefill-directors.dialog';
import { DocumentReviewDialog } from './document-review.dialog';
import { SelectCreditRuleItemDialog } from './select-credit-rule-item.dialog';
import { SelectWebServicesDialog } from './select-web-services.dialog';
import { EditMonthlyFacilityFeePercentageDialog } from './edit-monthly-facility-fee-percentage.dialog';
import { QuotationNotificationDialog } from './quotation-notification.dialog';
import { MoreInformationDialog } from './more-information.dialog';
import { WebServiceJsonDialog } from './web-service-json.dialog';
import { CreditApprovedDialog } from './credit-approved.dialog';
import { WithdrawApplicationDialog } from './withdraw-application.dialog';
import { EditAmortisedRepaymentDialog } from './edit-amortised-repayment.dialog';
import { PismoUpdateStopDirectDebitFlagDialog } from './pismo-update-stop-direct-debit-flag.dialog';
import { CreditDeclineDialog } from './credit-decline.dialog';
import { NewReportDialog } from './new-report.dialog';
import { ReportRequestDialog } from './report-request.dialog';

@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class ApplicationDialogService {

  subscriptions: Subscription[] = [];

  isMobile = false;

  breakpointService: BreakpointService = inject(BreakpointService);

  constructor(private matDialog: MatDialog) {
    setupUntilDestroy(this);
    this.subscriptions.push(this.breakpointService.deviceSubject.pipe(
      tap(dev => {
        this.isMobile = dev === 'Mobile';
      })
    ).subscribe());
  }

  private toMatDialogConfig(config: MatDialogConfig) {
    return this.isMobile ? {
      ...config,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
    } : config;
  }

  openEnable2FAPasswordDialog(data: Enable2FAPasswordDialogData): MatDialogRef<Enable2FAPasswordDialog, Enable2FAPasswordDialogResult> {
    return this.matDialog.open(Enable2FAPasswordDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      disableClose: true,
      data
    }));
  }

  openEnable2FADialog(data: Enable2FADialogData): MatDialogRef<Enable2FADialog, Enable2FADialogResult> {
    return this.matDialog.open(Enable2FADialog, this.toMatDialogConfig({
      width: pxToRem(500),
      disableClose: true,
      data,
    }));
  }

  open2FAActivatedDialog(data: TFAActivatedDialogData): MatDialogRef<TFAActivatedDialog, TFAActivatedDialogResult> {
    return this.matDialog.open(TFAActivatedDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      disableClose: true,
      data
    }));
  }


  // NOTE: not used anymore
  // openApplicationErrorDialog(helpCenterUrl: string): MatDialogRef<ApplicationErrorDialog, ApplicationErrorDialogResult> {
  //   const data: ApplicationErrorDialogData = {
  //     message: `We have encountered a technical issue during the submission process`,
  //     subMessage: `Please try to apply again later or reach out to our support team for assistance.`,
  //     helpCenterUrl,
  //   };
  //   return this.matDialog.open(ApplicationErrorDialog, {
  //     maxWidth: '30vw',
  //     data
  //   });
  // }

  // NOTE: not used anymore
  // openTradeFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
  //   const data: ApplicationConfirmationDialogData = {
  //     messages: [
  //       {id: 'check1', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
  //       {id: 'check2', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
  //     ]
  //   }
  //   return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
  //     maxWidth: '30vw',
  //     data
  //   }));
  // }

  openCorporateLoanApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        { id: 'check1', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements' },
        // { id: 'check2', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission' },
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  // NOTE: not used anymore
  // openFloorplanFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
  //   const data: ApplicationConfirmationDialogData = {
  //     messages: [
  //       { id: 'check1', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements' },
  //       { id: 'check2', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission' },
  //     ]
  //   }
  //   return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
  //     maxWidth: '30vw',
  //     data
  //   }));
  // }

  openInvoiceFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        { id: 'check1', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements' },
        // { id: 'check2', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission' },
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openCommercialFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        {id: 'check1', message: 'The asset is for business use only'},
        {id: 'check2', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
        {id: 'check3', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openAssetFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        {id: 'check1', message: 'The asset is for business use only'},
        {id: 'check2', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
        // {id: 'check3', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openConsumerFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        {id: 'check1', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
        // {id: 'check2', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
      ]
    };
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data,
    }));
  }

  openBusinessFinanceApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        {id: 'check1', message: 'The Business Term Loan is for business use only'},
        {id: 'check2', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
        // {id: 'check3', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      width: '30vw',
      data,
    }));
  }

  openBusinessOverdraftApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        {id: 'check1', message: 'The business overdraft is for business use only'},
        {id: 'check2', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
        // {id: 'check3', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      width: '30vw',
      data,
    }));
  }

  openInsurancePremiumApplicationConfirmationDialog(): MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult> {
    const data: ApplicationConfirmationDialogData = {
      messages: [
        {id: 'check1', message: 'The applicant has performed a serviceability self-assessment and is able to meet ongoing repayment requirements'},
        // {id: 'check2', message: 'By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission'},
      ]
    }
    return this.matDialog.open(ApplicationConfirmationDialog, this.toMatDialogConfig({
      width: '30vw',
      data,
    }));
  }

  openPPSRConfirmationDialog(): MatDialogRef<PPSRConfirmationDialog, PPSRConfirmationDialogResult> {
    const data: PPSRConfirmationDialogData = {};
    return this.matDialog.open(PPSRConfirmationDialog, this.toMatDialogConfig({
      width: '30vw',
      data,
    }));
  }

  openApplicationSubmittedDialog(): MatDialogRef<ApplicationSubmittedDialog, ApplicationSubmittedDialogResult> {
    const data: ApplicationSubmittedDialogData = {};
    return this.matDialog.open(ApplicationSubmittedDialog, this.toMatDialogConfig({
      width: '30vw',
      data
    }));
  }

  openAssetQuotationDialog(data: TotalPaymentBreakupDialogData2): MatDialogRef<TotalPaymentBreakupDialog, TotalPaymentBreakupDialogResult> {
    return this.matDialog.open(TotalPaymentBreakupDialog, this.toMatDialogConfig({
      width: '50vw', // height: '95vh',
      data
    }));
  }

  openBusinessQuotationDialog(data: TotalPaymentBreakupDialogData2): MatDialogRef<TotalPaymentBreakupDialog, TotalPaymentBreakupDialogResult> {
    return this.matDialog.open(TotalPaymentBreakupDialog, this.toMatDialogConfig({
      width: '50vw', // height: '95vh',
      data
    }));
  }

  openBusinessOverdraftQuotationDialog(data: OverdraftPaymentBreakupDialogData2): MatDialogRef<OverdraftPaymentBreakupDialog, TotalPaymentBreakupDialogResult> {
    return this.matDialog.open(OverdraftPaymentBreakupDialog, this.toMatDialogConfig({
      width: '50vw', // height: '95vh',
      data
    }));
  }

  openCorporateLoanQuotationDialog(data: CorporateLoanPaymentBreakupDialogData2): MatDialogRef<CorporateLoanPaymentBreakupDialog, TotalPaymentBreakupDialogResult> {
    return this.matDialog.open(CorporateLoanPaymentBreakupDialog, this.toMatDialogConfig({
      width: '50vw', // height: '95vh',
      data
    }));
  }

  openCreditInfoExchangeAlertDialog(): MatDialogRef<CreditInfoExchangeAlertDialog, CreditInfoExchangeAlertDialogResult> {
    return this.matDialog.open(CreditInfoExchangeAlertDialog, this.toMatDialogConfig({
      width: '30vw', // height: '26vh',
    }));
  }

  openAlertDialog(data: AlertDialogData): MatDialogRef<AlertDialog, AlertDialogResult> {
    return this.matDialog.open(AlertDialog, this.toMatDialogConfig({
      disableClose: true,
      data,
    }));
  }


  openLockUserDialog(data: InformationDialogData): MatDialogRef<ShowUserLockDialog,InformationDialogResult> {
    return this.matDialog.open(ShowUserLockDialog,this.toMatDialogConfig({
      data,
      disableClose: true,
    }));
  }


  successDialog(data: SuccessDialogData): MatDialogRef<SuccessDialog, SuccessDialogResult> {
    return this.matDialog.open(SuccessDialog, this.toMatDialogConfig({
      data,
    }));
  }

  openInformationDialog(data: InformationDialogData): MatDialogRef<InformationDialog, InformationDialogResult> {
       return this.matDialog.open(InformationDialog, this.toMatDialogConfig({
        data,
     }));
  }

  openIndividualFormDialog(data: IndividualFormDialogData): MatDialogRef<IndividualFormDialog, IndividualFormDialogResult> {
    return this.matDialog.open(IndividualFormDialog, this.toMatDialogConfig({
      data,
      width: pxToRem(650),
    }));
  }

  openAddBrokerUserDialog(data: AddBrokerUserFormDialogDialogData): MatDialogRef<AddBrokerUserFormDialog, AddBrokerUserFormDialogResult> {
    return this.matDialog.open(AddBrokerUserFormDialog, this.toMatDialogConfig({
      width: '600px',
      data
    }));
  }

  openAddCustomerUserDialog(data: AddCustomerUserFormDialogDialogData): MatDialogRef<AddCustomerUserFormDialog, AddCustomerUserFormDialogResult> {
    return this.matDialog.open(AddCustomerUserFormDialog, this.toMatDialogConfig({
      width: '600px',
      data
    }));
  }


  openUploadFileDialog(data: UploadFileDialogData): MatDialogRef<UploadFilesDialog, UploadFileDialogResult> {
    return this.matDialog.open(UploadFilesDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openUploadFileMultiTagsDialog(data: UploadFileDialogData): MatDialogRef<UploadFilesMultiTagsDialog, UploadFilesMultiTagsDialogResult> {
    return this.matDialog.open(UploadFilesMultiTagsDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openDocumentReviewDialog(data: DocumentReviewDialogData): MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult> {
    return this.matDialog.open(DocumentReviewDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openCustomDocumentTagDialog(data: CustomDocumentTagDialogData): MatDialogRef<CustomDocumentTagDialog, CustomDocumentTagDialogResult> {
    return this.matDialog.open(CustomDocumentTagDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openCurrencyInputDialog(data: CurrencyInputDialogData): MatDialogRef<CurrencyInputDialog, CurrencyInputDialogResult> {
    return this.matDialog.open(CurrencyInputDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      data
    }));
  }

  openProgressSpinnerDialog(): MatDialogRef<ProgressSpinnerDialog, unknown> {
    return this.matDialog.open(ProgressSpinnerDialog, this.toMatDialogConfig({
      panelClass: 'progress-spinner-dialog',
      restoreFocus: false,
      disableClose: true,
    }));
  }

  openConfirmPasswordDialog(data:ConfirmPasswordDialogData): MatDialogRef<ConfirmPasswordDialog, ConfirmPasswordDialogResult>{
    return this.matDialog.open(ConfirmPasswordDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      data
    }));
  }

  openConfirmPasswordAdminDialog(data:ConfirmPasswordAdminDialogData): MatDialogRef<ConfirmPasswordAdminDialog, ConfirmPasswordAdminDialogResult>{
    return this.matDialog.open(ConfirmPasswordAdminDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      data
    }));
  }


  // openFloorplanMarkAsSoldDialog(data: MarkAsSoldDialogData): MatDialogRef<MarkAsSoldDialog, MarkAsSoldDialogResult> {
  //   return this.matDialog.open(MarkAsSoldDialog, this.toMatDialogConfig({
  //     width: pxToRem(500),
  //     data
  //   }));
  // }

  // application supplier
  openPrivateSupplierDialog(data: SupplierDialogData): MatDialogRef<PrivateSupplierFormDialog,AssetSupplier> {
    return this.matDialog.open(PrivateSupplierFormDialog, this.toMatDialogConfig({
      width:  pxToRem(500),
      data
    }))
  }

  openPPSRRegisterDialog(): MatDialogRef<PPSRRegisterDialog, PPSRRegisterDialogResult> {
    const data: PPSRRegisterDialogData = {
      message: `Are you sure want to register all PPSR?`,
      subMessage: `Please check all the ppsr detail before doing PPSR register`,
    };
    return this.matDialog.open(PPSRRegisterDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openPPSRCheckDialog(): MatDialogRef<PPSRCheckDialog, PPSRCheckDialogResult> {
    const data: PPSRCheckDialogData = {
      message: `PPSR Check`,
      subMessage: `Please ensure all asset details are correct before performing PPSR check.`,
    };
    return this.matDialog.open(PPSRCheckDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openAssetSaveDialog(): MatDialogRef<AssetSaveDialog, AssetSaveDialogResult> {
    const data: AssetSaveDialogData = {
      message: `Please complete adding all assets`,
      subMessage: `Please check all the asset detail before doing save asset`,
    };
    return this.matDialog.open(AssetSaveDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openSettleLoanDialog(internalUser: boolean = false): MatDialogRef<SettleLoanDialog, SettleLoanDialogResult> {
    const data: SettleLoanDialogData = {
      message: `Settlement`,
      subMessage: internalUser ? `Please ensure all data is correct before proceeding further. Incorrect data might lead to a significant delay for settlement.` :
      `Please ensure all required documents are attached and the contract has been signed. Failure to do so might lead to a significant delay for settlement.`,
    };
    return this.matDialog.open(SettleLoanDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data
    }));
  }

  openDigitalIDReverificationConfirmationDialog(): MatDialogRef<ConfirmationDialog, ConfirmationDialogResult> {
    const data: ConfirmationDialogData = {
      message: "Saving these changes will require reverification", 
      subMessage: "Are you sure you want to proceed?"
    }
    return this.matDialog.open(ConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '40vw',
      data,
    }));
  }

  openConfirmationDialog(data: ConfirmationDialogData): MatDialogRef<ConfirmationDialog, ConfirmationDialogResult> {
    return this.matDialog.open(ConfirmationDialog, this.toMatDialogConfig({
      maxWidth: '30vw',
      data,
    }));
  }

  openYesNoDialog(data: YesNoDialogData): MatDialogRef<YesNoDialog, YesNoDialogResult> {
    return this.matDialog.open(YesNoDialog, this.toMatDialogConfig({
      data,
    }));
  }

  openDisbursementDialog(data: DisbursementDialogData): MatDialogRef<DisbursementDialog, DisbursementDialogResult> {
    return this.matDialog.open(DisbursementDialog, this.toMatDialogConfig({
      data,
    }));
  }

  openGenerateContractDialog(data: GenerateContractDialogData): MatDialogRef<GenerateContractDialog, GenerateContractDialogResult> {
    return this.matDialog.open(GenerateContractDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
      disableClose: true
    }));
  }

  openContractStatusDialog(data: ContractStatusDialogData): MatDialogRef<ContractStatusDialog, ContractStatusDialogResult> {
    return this.matDialog.open(ContractStatusDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  // NOTE: not used anymore
  // openManuallyPayTradeInvoiceDialog(data: ManuallyPayTradeInvoiceDialogData): MatDialogRef<ManuallyPayTradeInvoiceDialog, ManuallyPayTradeInvoiceDialogResult> {
  //   return this.matDialog.open(ManuallyPayTradeInvoiceDialog, this.toMatDialogConfig({
  //     width: '70vw',
  //     data,
  //   }));
  // }

  openDateRangeDialog(data: DateRangeDialogData) : MatDialogRef<DateRangeDialog, DateRangeDialogResult> {
    return this.matDialog.open(DateRangeDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBusinessDisbursementPayeeDialog(data: BusinessDisbursementPayeeDialogData): MatDialogRef<BusinessDisbursementPayeeDialog, BusinessDisbursementPayeeDialogResult> {
    return this.matDialog.open(BusinessDisbursementPayeeDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBusinessDisbursementInvoicesDialog(data: BusinessDisbursementInvoiceDialogData): MatDialogRef<BusinessDisbursementInvoiceDialog, BusinessDisbursementInvoicesDialogResult> {
    return this.matDialog.open(BusinessDisbursementInvoiceDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }


  openAssetDisbursementPayeeDialog(data: AssetDisbursementPayeeDialogData): MatDialogRef<AssetDisbursementPayeeDialog, AssetDisbursementPayeeDialogResult> {
    return this.matDialog.open(AssetDisbursementPayeeDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openAssetDisbursementInvoicesDialog(data: AssetDisbursementInvoicesDialogData): MatDialogRef<AssetDisbursementInvoicesDialog, AssetDisbursementInvoicesDialogResult> {
    return this.matDialog.open(AssetDisbursementInvoicesDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openDisbursementBankDetailsDialog(data: DisbursementBankDetailsDialogData): MatDialogRef<DisbursementBankDetailsDialog, DisbursementBankDetailsDialogResult> {
    return this.matDialog.open(DisbursementBankDetailsDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openDisbursementAmountDialog(data: DisbursementAmountDialogData): MatDialogRef<DisbursementAmountDialog, DisbursementAmountDialogResult> {
    return this.matDialog.open(DisbursementAmountDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openEditAddressDialog(data: EditAddressDialogData): MatDialogRef<EditAddressDialog,  EditAddressDialogResult> {
    return this.matDialog.open(EditAddressDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }

  openBPayTransactionDialog(data: BPayTransactionDialogData): MatDialogRef<BPayTransactionDialog,  BPayTransactionDialogResult> {
    return this.matDialog.open(BPayTransactionDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  openDirectPaymentTransactionDialog(data: DirectPaymentTransactionDialogData): MatDialogRef<DirectPaymentTransactionDialog,  DirectPaymentTransactionDialogResult> {
    return this.matDialog.open(DirectPaymentTransactionDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  openDirectPaymentBatchDialog(data: DirectPaymentBatchDialogData): MatDialogRef<DirectPaymentBatchDialog,  DirectPaymentBatchDialogResult> {
    return this.matDialog.open(DirectPaymentBatchDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  openNewBatchPaymentDialog(data?: BatchPayment): MatDialogRef<NewBatchPaymentDialog,  NewBatchPaymentDialogResult> {
    return this.matDialog.open(NewBatchPaymentDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data
    }));
  }
  openApprovalNoticeDialog(data: ApprovalNoticeDialogData): MatDialogRef<ApprovalNoticeDialog, ApprovalNoticeDialogResult> {
    return this.matDialog.open(ApprovalNoticeDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
      disableClose: true
    }));
  }

  openCopyApplicationDialog(data: CopyApplicationDialogData): MatDialogRef<CopyApplicationDialog, CopyApplicationDialogResult> {
    return this.matDialog.open(CopyApplicationDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  // NOTE: not used anymore
  // openPaywayTransactionDialog(data: PaywayTransactionDialogData): MatDialogRef<PaywayTransactionDialog, PaywayTransactionDialogResult> {
  //   return this.matDialog.open(PaywayTransactionDialog, this.toMatDialogConfig({
  //     width: pxToRem(600), // height: '95vh',
  //     data
  //   }));
  // }

  openCreateOverdraftAccountDialog(data: CreateOverdraftAccountDialogData): MatDialogRef<CreateOverdraftAccountDialog, CreateOverdraftAccountDialogResult> {
    return this.matDialog.open(CreateOverdraftAccountDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
    }));
  }

  openFirstOverdraftUserDialog(data: FirstOverdraftUserDialogData): MatDialogRef<FirstOverdraftUserDialog, FirstOverdraftUserDialogResult> {
    return this.matDialog.open(FirstOverdraftUserDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
      disableClose: true
    }));
  }

  openEditKycStatusDialog(data: EditKycStatusDialogData): MatDialogRef<EditKycStatusDialog, EditKycStatusDialogResult> {
    return this.matDialog.open(EditKycStatusDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openPismoAccountTransactionsDialog(data: PismoTransactionsIncludingPending): MatDialogRef<PismoAccountTransactionsDialog, PismoAccountTransactionsDialogResult> {
    return this.matDialog.open(PismoAccountTransactionsDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openPismoActivateOrDeactivateCardDialog(data: PismoActivateOrDeactivateCardDialogData): MatDialogRef<PismoActivateOrDeactivateCardDialog, PismoActivateOrDeactivateCardDialogResult> {
    return this.matDialog.open(PismoActivateOrDeactivateCardDialog, this.toMatDialogConfig({
      width: pxToRem(700),
      data,
    }));
  }

  openPismoPostManualTransactionDialog(data: PismoPostManualTransactionDialogData): MatDialogRef<PismoPostManualTransactionDialog, PismoAccountTransactionsDialogResult> {
    return this.matDialog.open(PismoPostManualTransactionDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openPismoEditAccountDialog(data: PismoEditAccountDialogData): MatDialogRef<PismoEditAccountDialog, PismoEditAccountDialogResult> {
    return this.matDialog.open(PismoEditAccountDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }


  openEnablePismoCustomerAccountDialog(data: EnablePismoCustomerAccountDialogData): MatDialogRef<EnablePismoCustomerAccountDialog, EnablePismoCustomerAccountDialogResult> {
    return this.matDialog.open(EnablePismoCustomerAccountDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }
  
  openPismoEditCustomerDialog(data: PismoEditCustomerDialogData): MatDialogRef<PismoEditCustomerDialog, PismoEditCustomerDialogResult> {
    return this.matDialog.open(PismoEditCustomerDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openManualBusinessSearchDialog(data: ManualBusinessSearchDialogData): MatDialogRef<ManualBusinessSearchDialog, ManualBusinessSearchDialogResult> {
      return this.matDialog.open(ManualBusinessSearchDialog, this.toMatDialogConfig({
        width: pxToRem(600),
        data,
      }));
  }

  openTaxInvoiceDetailsDialog(data: TaxInvoiceDetailsDialogData): MatDialogRef<TaxInvoiceDetailsDialog, TaxInvoiceDetailsDialogResult> {
    return this.matDialog.open(TaxInvoiceDetailsDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openTransferredBalanceDialog(data: TransferredbalanceDialogData): MatDialogRef<TransferredBalanceDialog, TransferredBalanceDialogResult> {
    return this.matDialog.open(TransferredBalanceDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBankTransferDialog(data: BankTransferDialogData): MatDialogRef<BankTransferDialog, BankTransferDialogResult> {
    return this.matDialog.open(BankTransferDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openDirectDebitDialog(data: DirectDebitDialogData): MatDialogRef<DirectDebitDialog, DirectDebitDialogResult> {
    return this.matDialog.open(DirectDebitDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBpayDialog(data: BpayDialogData): MatDialogRef<BpayDialog, BpayDialogResult> {
    return this.matDialog.open(BpayDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openPismoReissueCardDialog(data: PismoReissueCardDialogData): MatDialogRef<PismoReissueCardDialog, PismoReissueCardDialogResult> {
    return this.matDialog.open(PismoReissueCardDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openNewAssetInspectionDialog(data: NewAssetInspectionDialogData): MatDialogRef<NewAssetInspectionDialog, NewAssetInspectionDialogResult> {
    return this.matDialog.open(NewAssetInspectionDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  openPismoUpdateRequireFixedMonthlyInstallmentFlagDialog(data: PismoUpdateRequireFixedMonthlyInstallmentFlagDialogData): MatDialogRef<PismoUpdateRequireFixedMonthlyInstallmentFlagDialog, PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult> {
    return this.matDialog.open(PismoUpdateRequireFixedMonthlyInstallmentFlagDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openPismoUpdateStopDirectDebitFlagDialog(data: PismoUpdateStopDirectDebitFlagDialogData): MatDialogRef<PismoUpdateStopDirectDebitFlagDialog, PismoUpdateStopDirectDebitFlagDialogResult> {
    return this.matDialog.open(PismoUpdateStopDirectDebitFlagDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openGeneratePayoutDialog(data: GeneratePayoutDialogData): MatDialogRef<GeneratePayoutDialog, GeneratePayoutDialogResult> {
    return this.matDialog.open(GeneratePayoutDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  openPayoutNotificationDialog(data: PayoutNotificationDialogData): MatDialogRef<PayoutNotificationDialog, PayoutNotificationDialogResult> {
    return this.matDialog.open(PayoutNotificationDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data
    }));
  }

  openAssetPricingComparisonDialog(data: AssetPricingComparisonDialogData): MatDialogRef<AssetPricingComparisonDialog, AssetPricingComparisonDialogResult> {
    return this.matDialog.open(AssetPricingComparisonDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBusinessPricingComparisonDialog(data: BusinessPricingComparisonDialogData): MatDialogRef<BusinessPricingComparisonDialog, BusinessPricingComparisonDialogResult> {
    return this.matDialog.open(BusinessPricingComparisonDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBusinessOverdraftComparisonDialog(data: BusinessOverdraftPricingComparisonDialogData): MatDialogRef<BusinessOverdraftPricingComparisonDialog, BusinessOverdraftPricingComparisonDialogResult> {
    return this.matDialog.open(BusinessOverdraftPricingComparisonDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openCorporateLoanPricingComparisonDialog(data: CorporateLoanPricingComparisonDialogData): MatDialogRef<CorporateLoanPricingComparisonDialog, CorporateLoanPricingComparisonDialogResult> {
    return this.matDialog.open(CorporateLoanPricingComparisonDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openConsumerPricingComparisonDialog(data: ConsumerPricingComparisonDialogData): MatDialogRef<ConsumerPricingComparisonDialog, ConsumerPricingComparisonDialogResult> {
    return this.matDialog.open(ConsumerPricingComparisonDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openIdentityVerificationLinkDialog(data: IdentityVerificationLinkDialogData): MatDialogRef<IdentityVerificationLinkDialog, IdentityVerificationLinkDialogResult> {
    return this.matDialog.open(IdentityVerificationLinkDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openCreditUserListDialog(data: CreditUserListDialogData): MatDialogRef<CreditUserListDialog, CreditUserListDialogResult> {
    return this.matDialog.open(CreditUserListDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openDirectSaleApplicationDialog(data: ManageApplicationDialogData): MatDialogRef<ManageApplicationDialog, ManageApplicationDialogResult> {
    return this.matDialog.open(ManageApplicationDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
      disableClose: true
    }));
  }

  openAllocateUserToPismoAccountDialog(data: AllocateUserToPismoAccountDialogData): MatDialogRef<AllocateUserToPismoAccountDialog, AllocateUserToPismoAccountDialogResult> {
    return this.matDialog.open(AllocateUserToPismoAccountDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openInterestRateCalculationLogDialog(data: InterestRateCalculationLogDialogData): MatDialogRef<InterestRateCalculationLogDialog, InterestRateCalculationLogDialogResult> {
    return this.matDialog.open(InterestRateCalculationLogDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openOverdraftAccountLimitIncreaseDialog(data: OverdraftAccountLimitIncreaseDialogData): MatDialogRef<OverdraftAccountLimitIncreaseDialog, OverdraftAccountLimitIncreaseDialogResult> {
    return this.matDialog.open(OverdraftAccountLimitIncreaseDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
    }));
  }

  openOverdraftDirectDebitDailog(data: OverdraftDirectDebitDialogData): MatDialogRef<OverdraftDirectDebitDialog, OverdraftDirectDebitDialogResult> {
    return this.matDialog.open(OverdraftDirectDebitDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openEditBrokerageDailog(data: EditBrokerageDialogData): MatDialogRef<EditBrokerageDialog, EditBrokerageDialogResult> {
    return this.matDialog.open(EditBrokerageDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openEditInvoiceAmountDailog(data: EditInvoiceAmountDialogData): MatDialogRef<EditInvoiceAmountDialog, EditInvoiceAmountDialogResult> {
    return this.matDialog.open(EditInvoiceAmountDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openBankStatementSelectTransactionsDialog(data: BankStatementSelectTransactionsDialogData): MatDialogRef<BankStatementSelectTransactionsDialog, BankStatementSelectTransactionsDialogResult> {
    return this.matDialog.open(BankStatementSelectTransactionsDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
    }));
  }

  openEditLenderDialog(data: EditLenderDialogData): MatDialogRef<EditLenderDialog, EditLenderDialogResult> {
    return this.matDialog.open(EditLenderDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openDigitalIdVerifyDialog(data: DigitalIdVerifyDialogData): MatDialogRef<DigitalIdVerifyDialog, IndividualWithResult> {
    return this.matDialog.open(DigitalIdVerifyDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openUnsavedChangesDialog(data: string[]): MatDialogRef<UnsavedChangesDialog, boolean> {
    return this.matDialog.open(UnsavedChangesDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      data,
    }));
  }

  openApplicantsPrivacyConsentDialog(data: ApplicantsPrivacyConsentDialogData): MatDialogRef<ApplicantsPrivacyConsentDialog, ApplicantsPrivacyConsentDialogResult> {
    return this.matDialog.open(ApplicantsPrivacyConsentDialog, this.toMatDialogConfig({
      width: pxToRem(500),
      data,
    }));
  }

  openSelectableTagsDialog(data: SelectableTagsDialogData): MatDialogRef<SelectableTagsDialog, SelectableTagsDialogResult> {
    return this.matDialog.open(SelectableTagsDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openEditCreditFlowDialog(data: EditCreditFlowDialogData): MatDialogRef<EditCreditFlowDialog, EditCreditFlowDialogResult> {
    return this.matDialog.open(EditCreditFlowDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openEditCreditRuleMappingDialog(data: EditCreditRuleMappingDialogData): MatDialogRef<EditCreditRuleMappingDialog, EditCreditRuleMappingDialogResult> {
    return this.matDialog.open(EditCreditRuleMappingDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openEditCreditFlowNodeDialog(data: EditCreditFlowNodeDialogData): MatDialogRef<EditCreditFlowNodeDialog, EditCreditFlowNodeDialogResult> {
    return this.matDialog.open(EditCreditFlowNodeDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openEditCreditAlertDialog(data: EditCreditAlertDialogData): MatDialogRef<EditCreditAlertDialog, EditCreditAlertDialogResult> {
    return this.matDialog.open(EditCreditAlertDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openPublishCreditFlowConfirmation(data: PublishCreditFlowConfirmationDialogData): MatDialogRef<PublishCreditFlowConfirmationDialog, PublishCreditFlowConfirmationDialogResult> {
    return this.matDialog.open(PublishCreditFlowConfirmationDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openEditCreditRuleItemDialog(data: EditCreditRuleItemDialogData): MatDialogRef<EditCreditRuleItemDialog, EditCreditRuleItemDialogResult> {
    return this.matDialog.open(EditCreditRuleItemDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openCreditDecisioningReasonsDialog(data: CreditDecisioningReasonDialogData): MatDialogRef<CreditDecisioningReasonDialog, CreditDecisioningReasonDialogResult> {
    return this.matDialog.open(CreditDecisioningReasonDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openCreditDeclineDialog(data: CreditDeclineDialogData): MatDialogRef<CreditDeclineDialog, CreditDeclineDialogResult> {
    return this.matDialog.open(CreditDeclineDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }
  
  openEditMonthlyFacilityFeePercentageDialog(data: EditMonthlyFacilityFeePercentageDialogData): MatDialogRef<EditMonthlyFacilityFeePercentageDialog, EditMonthlyFacilityFeePercentageDialogResult> {
    return this.matDialog.open(EditMonthlyFacilityFeePercentageDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openPrefillDirectorsDialog(data: PrefillDirectorsDialogData): MatDialogRef<PrefillDirectorsDialog, PrefillDirectorsDialogResult> {
    return this.matDialog.open(PrefillDirectorsDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openSelectCreditRuleItemDialog(data: SelectCreditRuleItemDialogData): MatDialogRef<SelectCreditRuleItemDialog, SelectCreditRuleItemDialogResult> {
    return this.matDialog.open(SelectCreditRuleItemDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openSelectWebServicesDialog(data: SelectWebServicesDialogData): MatDialogRef<SelectWebServicesDialog, SelectWebServicesDialogResult> {
    return this.matDialog.open(SelectWebServicesDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }
  
  openQuotationNotificationDialog(data: QuotationNotificationDialogData): MatDialogRef<QuotationNotificationDialog, QuotationNotificationDialogResult> {
    return this.matDialog.open(QuotationNotificationDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openMoreInformationDialog(data: MoreInformationDialogData): MatDialogRef<MoreInformationDialog, MoreInformationDialogResult> {
    return this.matDialog.open(MoreInformationDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openWebServiceJsonDialog(data: WebServiceJsonDialogData): MatDialogRef<WebServiceJsonDialog, WebServiceJsonDialogResult> {
    return this.matDialog.open(WebServiceJsonDialog, this.toMatDialogConfig({
      width: pxToRem(800),
      data,
    }));
  }

  openCreditApprovedDialog(data: CreditApprovedDialogData): MatDialogRef<CreditApprovedDialog, CreditApprovedDialogResult> {
    return this.matDialog.open(CreditApprovedDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openWithdrawApplicationDialog(data: WithdrawApplicationDialogData): MatDialogRef<WithdrawApplicationDialog, WithdrawApplicationDialogResult> {
    return this.matDialog.open(WithdrawApplicationDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }
  
  openEditAmortisedRepaymentDialog(data: EditAmortisedRepaymentDialogData): MatDialogRef<EditAmortisedRepaymentDialog, EditAmortisedRepaymentDialogResult> {
    return this.matDialog.open(EditAmortisedRepaymentDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }

  openNewReportDialog(data: NewReportDialogData): MatDialogRef<NewReportDialog, NewReportDialogResult> {
    return this.matDialog.open(NewReportDialog, this.toMatDialogConfig({
      width: pxToRem(1000),
      data,
      autoFocus: false,
    }));
  }

  openReportRequestDialog(data: ReportRequestDialogData): MatDialogRef<ReportRequestDialog, ReportRequestDialogResult> {
    return this.matDialog.open(ReportRequestDialog, this.toMatDialogConfig({
      width: pxToRem(600),
      data,
    }));
  }
}

