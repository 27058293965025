import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../service/auth.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable, Subscription, tap} from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library'
import {
  EnableDisable2FA,
  EnableDisable2faFn,
  Add2faSecretKeyFn,
  Get2faTotpVerificationCodeUriFn,
  GetUserFn,
  LogoutFn,
  MatchConfirmPasswordFn,
  ProfileComponentCancelEvent,
  ProfileComponentEvent,
  ProfileComponentSavePasswordEvent,
  ProfileComponentSaveProfileEvent,
  Verify2faTokenFn,
  ProfileComponentLogoutEvent,
  ChangePortalThemeFn,
  PortalThemeServiceEvent,
  GetHeartbeatLogsFn
} from '@portal-workspace/grow-shared-library';
import {navigationUrlForLogin, navigationUrlForNewApplication} from '../../service/navigation-urls';
import { Router } from '@angular/router';
import { ProfileComponent } from '@portal-workspace/grow-ui-library';
import {TwoFactorAuthService} from '../../service/2fa.service';
import {PortalThemeService} from "../../service/portal-theme.service";
import { HeartbeatService } from '../../service/heartbeat.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './profile.page.html',
    styleUrls: ['./profile.page.scss'],
    standalone: true,
    imports: [ProfileComponent]
})
export class ProfilePage implements OnInit {

  subscriptions: Subscription[] = [];
  isLoadingProfile : boolean = false
  isLoadingPassword : boolean = false

  getUserFn!: GetUserFn;
  logoutFn!: LogoutFn;
  matchConfirmPasswordFn!: MatchConfirmPasswordFn;
  enableDisable2FaFn!: EnableDisable2faFn;
  add2faSecretKeyFn!: Add2faSecretKeyFn;
  verify2faTokenFn!: Verify2faTokenFn;
  get2faTotpVerificationCodeFn!: Get2faTotpVerificationCodeUriFn;
  changePortalThemeFn!: ChangePortalThemeFn;
  portalThemeObservable!: Observable<PortalThemeServiceEvent>;
  getHeartbeatLogsFn!: GetHeartbeatLogsFn;

  constructor(private authService: AuthService,
              private twoFaService: TwoFactorAuthService,
              private toastService: PortalHotToastService,
              private portalThemeService: PortalThemeService,
              private heartbeatService: HeartbeatService,
              protected router:Router) {
    this.getUserFn = authService.getUserFn;
    this.logoutFn = authService.logoutFn;
    this.matchConfirmPasswordFn = twoFaService.matchConfirmPasswordFn;
    this.enableDisable2FaFn = twoFaService.enableDisable2faFn;
    this.add2faSecretKeyFn = twoFaService.add2faSecretKeyFn;
    this.verify2faTokenFn = twoFaService.verify2faTokenFn;
    this.get2faTotpVerificationCodeFn = twoFaService.get2faTotpVerificationCodeUriFn;
    this.changePortalThemeFn = portalThemeService.changePortalThemeFn;
    this.portalThemeObservable = portalThemeService.subject.asObservable();
    this.getHeartbeatLogsFn = heartbeatService.getHeartbeatLogsFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  onProfileEvent($event: ProfileComponentEvent) {
    switch($event.type) {
      case 'save-password': {
        this.savePassword($event);
        break;
      }
      case 'save-profile': {
        this.saveProfile($event);
        break;
      }
      case 'cancel':{
        this.cancelProfile($event);
        break;
      }
      case 'logout': {
        this.logoutProfile($event);
        break;
      }
    }
  }

  logoutProfile($event: ProfileComponentLogoutEvent) {
    this.router.navigate(navigationUrlForLogin());
  }

  cancelProfile($event: ProfileComponentCancelEvent) {
    this.router.navigate(navigationUrlForNewApplication());
  }

  private saveProfile($event: ProfileComponentSaveProfileEvent) {
    this.isLoadingProfile = true;
    this.subscriptions.push(this.authService.updateProfileData({
      FirstName: $event.FirstName,
      LastName: $event.LastName,
      MiddleName: $event.MiddleName,
      Email: $event.Email,
      MobileNumber: $event.MobileNumber,
    }).pipe(this.toastService.retryableMessage({
      successMessage: 'Profile Saved',
      errorMessage: 'Failed to save the Profile',
      retryFn: ()=> {
        console.log('**** retry ', this);
        this.saveProfile($event)
      }
    }),
    tap(r => {
      console.log('****** rrrr', r);
    })
  ).subscribe(
    (res) =>{
        if(res){
          this.isLoadingProfile = false;
        }
      }, (err) => {
        if(err){
          this.isLoadingProfile = false;
        }
      })
    )
  }

  private savePassword($event: ProfileComponentSavePasswordEvent) {
    this.isLoadingPassword = true
    this.subscriptions.push(this.authService.updateProfilePassword({
      password: $event.password,
    }).pipe(this.toastService.retryableMessage({
      successMessage: 'Password Saved',
      errorMessage: 'Failed to save the Password',
      retryFn: ()=> {
        console.log('**** retry ', this);
        this.savePassword($event)
      }
    }),
    tap(r => {
      console.log('****** rrrr', r);
    })
  ).subscribe(
    (res) =>{
        if(res){
          this.isLoadingPassword = false;
        }
      }, (err) => {
        if(err){
          this.isLoadingPassword = false;
        }
      })
    )
  }
}
