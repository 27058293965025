<div class="date-range-dialog">
  <mat-dialog-content>
    <daterange [formControl]="formControlDateRangePicker" [mandatory]="data.mandatory"></daterange>
    <br>
      @if (data.message) {
        <div class="row">
          <div class="col">
            <mat-hint class="ml-3">{{data.message}}</mat-hint>
          </div>
        </div>
      }
      @if (programSelectable) {

          <div class="col">
            <mat-form-field>
              <mat-label>Program</mat-label>
              <mat-select  placeholder="Select Program..." [formControl]="formControlPismoProgram">
                <!-- @if (!programSelections.length) {
                  <mat-option [value]="null">No existing program found</mat-option>
                } -->
                  <mat-option [value]="allProgramSelection">All</mat-option>
                @for (option of programSelections; track option) {
                  <mat-option [value]="option">{{option.programId}} Name: {{option.name}}</mat-option>
                }
              </mat-select>
              <mat-hint>Please select a PISMO Program</mat-hint>
            </mat-form-field>
          </div>

      }
      <br>
        <div class="row">
          <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-stroked-button color="primary" class="ml-3" (click)="cancel($event)">Cancel</button>
            <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Request Report</button>
          </div>
        </div>
      </mat-dialog-content>
    </div>
