import { AfterViewInit, Component, EventEmitter, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { ApplicationDialogService, PortalHotToastService, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { Subscription, tap } from 'rxjs';
import { DEFAULT_LIMIT, DEFAULT_OFFSET, CreditFlowNode, CreditRuleMapping, CreditFlowDetails, CreditFlowNodeDetails, CreditFlowNodeWithLevel, CreditAlert, GetCreditRuleItemsFn } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { ApplicationService } from '../../service/application.service';
import { CreditFlowsComponent, EditCreditFlowComponent } from '@portal-workspace/grow-ui-library';
import { ActivatedRoute, Router } from '@angular/router';
import { Edge, Node, VflowModule, Connection, ViewportState, NodeChange} from 'ngx-vflow'
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { loadingFor } from '@ngneat/loadoff';
import { CustomContentLoaderComponent, CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DatePipe, AsyncPipe, NgClass } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { navigationUrlForCreditManagement } from '../../service/navigation-urls';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { MatSelectModule } from '@angular/material/select';
import moment from 'moment';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './edit-credit-flow.page.html',
    styleUrls: ['./edit-credit-flow.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatCardModule, MatTabsModule, MatSelectModule, TagBoxComponent, VflowModule, MatTooltipModule, MatButtonToggleModule, NgClass, NameComponent, DatePipe, AsyncPipe, MatSortModule, CustomContentLoaderComponent, CustomPaginatorComponent, CreditFlowsComponent, MatTableModule, EditCreditFlowComponent, FlexModule, MatChipsModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class EditCreditFlowPage implements OnInit {
   nodes: Node[] = [
    // {
    //   id: '1',
    //   point: { x: 10, y: 200 },
    //   type: 'default',
    //   text: '1'
    // },
    // {
    //   id: '2',
    //   point: { x: 200, y: 100 },
    //   type: 'default',
    //   text: '2'
    // },
    // {
    //   id: '3',
    //   point: { x: 200, y: 300 },
    //   type: 'default',
    //   text: '3'
    // },
  ]

  edges: Edge[] = [
    // {
    //   id: '1 -> 2',
    //   source: '1',
    //   target: '2'
    // },
    // {
    //   id: '1 -> 3',
    //   source: '1',
    //   target: '3'
    // },
  ]

  moment = moment;

  subscriptions: Subscription[] = [];
  creditFlow!: CreditFlowDetails;
  startNodeOptions: CreditFlowNodeDetails[] = [];
  formControlName!: FormControl<string | null>;
  formControlStartNode!: FormControl<number | null>;
  formControlView!: FormControl<'table' | 'graph' | null>;
  formControlAssetFinance!: FormControl<boolean | null>;
  formControlBusinessLoans!: FormControl<boolean | null>;
  formControlBusinessOverdraft!: FormControl<boolean | null>;
  formControlInsurancePremium!: FormControl<boolean | null>;
  formControlCorporateLoans!: FormControl<boolean | null>;
  formControlConsumer!: FormControl<boolean | null>;
  formControlAccreditation!: FormControl<boolean | null>;
  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: CreditFlowNodeDetails[] = [];
  filteredDataSource: CreditFlowNodeDetails[] = [];
  displayedData: CreditFlowNodeDetails[] = [];
  creditAlerts: CreditAlert[] = [];
  columnsToDisplay = ['name', 'type', 'nextNode', 'ruleMappings' ,'actions']; //id
  getCreditRuleItemsFn!: GetCreditRuleItemsFn;

  graphViewX = 1000;
  graphViewY = 800;
  defaultNodeX = 0;
  defaultNodeY = 150;
  gapX = 150;
  gapY = 240;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.formControlName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStartNode = this.formBuilder.control(null, [Validators.required]);
    this.formControlView = this.formBuilder.control('graph');
    this.formControlAssetFinance = this.formBuilder.control(null);
    this.formControlBusinessLoans = this.formBuilder.control(null);
    this.formControlBusinessOverdraft = this.formBuilder.control(null);
    this.formControlInsurancePremium = this.formBuilder.control(null);
    this.formControlCorporateLoans = this.formBuilder.control(null);
    this.formControlConsumer = this.formBuilder.control(null);
    this.formControlAccreditation = this.formBuilder.control(null);
    this.getCreditRuleItemsFn = this.applicationService.getCreditRuleItemsFn;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.creditFlow = (this.route.snapshot.data as any).creditFlow;
    if (this.creditFlow) {
      console.log('===this.creditFlow: ', this.creditFlow);
      this.startNodeOptions = this.creditFlow.nodes ?? [];
    }
    this.initTableData();
    this.initGraph();
  }

  reload() {
    this.subscriptions.push(
      this.applicationService.getCreditFlowByIdFn(this.creditFlow.id).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(result => {
        this.creditFlow = result;
        this.startNodeOptions = this.creditFlow.nodes ?? [];
        console.log('=======new credit flow: ', this.creditFlow)
        this.initTableData();
        this.initGraph();
      })
    )
  }

  initTableData() {
    this.formControlName.setValue(this.creditFlow?.name ?? '');
    this.formControlStartNode.setValue(this.creditFlow?.startNode ?? null);
    this.formControlAssetFinance.setValue(!!this.creditFlow?.AssetFinance);
    this.formControlBusinessLoans.setValue(!!this.creditFlow?.BusinessLoans);
    this.formControlBusinessOverdraft.setValue(!!this.creditFlow?.BusinessOverdraft);
    this.formControlInsurancePremium.setValue(!!this.creditFlow?.InsurancePremium);
    this.formControlCorporateLoans.setValue(!!this.creditFlow?.CorporateLoans);
    this.formControlConsumer.setValue(!!this.creditFlow?.Consumer);
    this.formControlAccreditation.setValue(!!this.creditFlow?.Accreditation);

    this.dataSource = this.creditFlow.nodes;
    this.filteredDataSource = this.dataSource;
    this.initPagination();

    this.subscriptions.push(
      this.applicationService.getCreditAlertsFn().pipe(
        this.toastService.spinnerObservable()
      ).subscribe(alerts => {
        this.creditAlerts = alerts;
      })
    )
  }

  initGraph() {
    this.nodes = [];
    this.edges = [];
    let allNodes = [...this.creditFlow.nodes];
    const startNode = allNodes.find(node => node.id === this.creditFlow.startNode);
    if (startNode) {
      // bfs to traverse the tree
      const queue: CreditFlowNodeWithLevel[] = [{
        ...startNode,
        level: 0
      }];

      let x = 0;
      let y = this.defaultNodeY / 2;
      let numberOfNodesOnThisLevel = 0;
      let currentLevel = 0

      while (queue.length) {
        const currentNode = queue.shift() as CreditFlowNodeWithLevel;
        if (currentNode.level === currentLevel) {
          numberOfNodesOnThisLevel ++;
        } else {
          numberOfNodesOnThisLevel = 1;
          currentLevel = currentNode.level;
        }

        if (!this.nodes.find(node => node.id === `${currentNode.id}`)) { // prevent any duplicates
          this.nodes.push({
            id: `${currentNode.id}`,
            point: { y: x + numberOfNodesOnThisLevel * this.gapX, x: y + currentNode.level * this.gapY },
            // type: 'default',
            // text: `${currentNode.name}`
            type: 'html-template',
            data: {
              text: `${currentNode.name}`,
              class: currentNode.type === 'route' ? 'route-node' : 
                     currentNode.type === 'rule set' ? 'rule-set-node' : 
                     currentNode.type === 'web service' ? 'web-service-node' : 'default-node',
              element: currentNode,
            }
          })
        }

        if (currentNode.type === 'web service') {
          if (currentNode.nextNode) {
            const nextNode = allNodes.find(node => node.id === currentNode.nextNode as number);
            if (nextNode) {
              if (!this.nodes.find(node => node.id === `${nextNode.id}`)) { // prevent any loop
                queue.push({
                  ...nextNode,
                  level: currentNode.level + 1
                });
              }
              this.edges.push({
                id: `${currentNode.id} => ${nextNode.id}`,
                source: `${currentNode.id}`,
                target: `${nextNode.id}`,
              })
            }
          }
        } else if (currentNode.type === 'rule set') {
          if (currentNode.nextNode) {
            const nextNode = allNodes.find(node => node.id === currentNode.nextNode as number);
            if (nextNode) {
              if (!this.nodes.find(node => node.id === `${nextNode.id}`)) { // prevent any loop
                queue.push({
                  ...nextNode,
                  level: currentNode.level + 1
                });
              }
              this.edges.push({
                id: `${currentNode.id} => ${nextNode.id}`,
                source: `${currentNode.id}`,
                target: `${nextNode.id}`,
                edgeLabels: {
                  center: {
                    type: 'html-template',
                    data: { text: '' }
                  }
                }
              })
            }
          } else {
            queue.push({
              level: currentNode.level + 1,
              type: 'end',
              id: `End ${currentNode.id}`,
              name: 'End'
            } as any)
            this.edges.push({
              id: `${currentNode.id} => End ${currentNode.id}`,
              source: `${currentNode.id}`,
              target: `End ${currentNode.id}`,
              edgeLabels: {
                center: {
                  type: 'html-template',
                  data: { text: '' }
                }
              }
            })
          }
        } else if (currentNode.type === 'route') {
          for (const mapping of currentNode.creditRuleMappings) {
            if (mapping.nextNode) {
              const nextNode = allNodes.find(node => node.id === mapping.nextNode as number);
              if (nextNode) {
                if (!this.nodes.find(node => node.id === `${nextNode.id}`)) { // prevent any loop
                  queue.push({
                    ...nextNode,
                    level: currentNode.level + 1
                  });
                }
                this.edges.push({
                  id: `${currentNode.id} => ${nextNode.id}`,
                  source: `${currentNode.id}`,
                  target: `${nextNode.id}`,
                  edgeLabels: {
                    center: {
                      type: 'html-template',
                      data: { text: mapping.name }
                    }
                  }
                })
              }
            } else {
              queue.push({
                level: currentNode.level + 1,
                type: 'end',
                id: `End Mapping ${mapping.id}`,
                name: 'End'
              } as any)
              this.edges.push({
                id: `${currentNode.id} => End Mapping ${mapping.id}`,
                source: `${currentNode.id}`,
                target: `End Mapping ${mapping.id}`,
                edgeLabels: {
                  center: {
                    type: 'html-template',
                    data: { text: mapping.name }
                  }
                }
              })
            }
          }
        }
      }

      console.log('nodes: ', this.nodes);
      console.log('edges: ', this.edges);

    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'id': return 'ID';
      case 'name': return 'Name';
      case 'type': return 'Type';
      case 'nextNode': return 'Next Node';
      case 'ruleMappings': return 'Rules';
      case 'lastUpdated': return 'Last Updated';
      case 'actions': return '';
      default: return column;
    }
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }
  
  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  onAddRuleMapping(element: CreditFlowNode) {
    this.subscriptions.push(
      this.dialogService.openEditCreditRuleMappingDialog({
        creditFlowNodeId: element.id,
        creditRuleMapping: null,
        nextNodeList: this.creditFlow.nodes,
        creditAlerts: this.creditAlerts,
        nodeType: element.type,
        getCreditRuleItemsFn: this.getCreditRuleItemsFn,
      }).afterClosed().subscribe(result => {
        if (result?.readyForSubmission && result?.creditRuleMapping) {
          // api call
          this.applicationService.addCreditRuleMappingFn(result.creditRuleMapping)
            .pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Mapping added!`),
            ).subscribe(() => {
              this.reload();
            })
        }
      })
    )
  }

  onAddCreditFlowNode() {
    this.subscriptions.push(
      this.dialogService.openEditCreditFlowNodeDialog({
        creditFlowNode: null,
        creditFlowId: this.creditFlow.id,
        nextNodeList: this.creditFlow.nodes,
        getCreditRuleItemsFn: this.getCreditRuleItemsFn,
      }).afterClosed().subscribe(result => {
        if (result?.readyForSubmission && result?.creditFlowNode) {
          this.applicationService.addCreditFlowNodeFn(result.creditFlowNode)
            .pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Node added!`),
            ).subscribe(() => {
              this.reload();
            })
        }
      })
    )
  }

  onEditCreditFlowNode(element: CreditFlowNode) {
    this.subscriptions.push(
      this.dialogService.openEditCreditFlowNodeDialog({
        creditFlowNode: element,
        creditFlowId: this.creditFlow.id,
        nextNodeList: this.creditFlow.nodes.filter(node => node.id !== element.id),
        getCreditRuleItemsFn: this.getCreditRuleItemsFn,
      }).afterClosed().subscribe(result => {
        if (result?.readyForSubmission && result?.creditFlowNode) {
          this.applicationService.updateCreditFlowNodeFn(result.creditFlowNode, element.id)
            .pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Node updated!`),
            ).subscribe(() => {
              this.reload();
            })
        }
      })
    )
  }

  onDeleteCreditFlowNode(element: CreditFlowNode) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: 'Delete Credit Node',
        subMessage: 'Are you sure to delete this credit node?'
      }).afterClosed().pipe(
        tap(result => {
          if (result && result.readyForSubmission) {
            this.applicationService.updateCreditFlowNodeFn({isDeleted: true}, element.id)
            .pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Node deleted!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        })
      ).subscribe()
    )
  }

  onEditRuleMapping(element: CreditRuleMapping, node: CreditFlowNode) {
    this.subscriptions.push(
      this.dialogService.openEditCreditRuleMappingDialog({
        creditRuleMapping: element,
        creditFlowNodeId: element.creditFlowNodeId,
        nextNodeList: this.creditFlow.nodes.filter(node => node.id !== element.creditFlowNodeId),
        creditAlerts: this.creditAlerts,
        nodeType: node.type,
        getCreditRuleItemsFn: this.getCreditRuleItemsFn,
      }).afterClosed().subscribe(result => {
        if (result?.readyForSubmission && result?.creditRuleMapping) {
          this.applicationService.updateCreditRuleMappingFn(result.creditRuleMapping, element.id)
            .pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Mapping updated!`),
            ).subscribe(() => {
              this.reload();
            })
        }
      })
    )
  }

  onDeleteRuleMapping(element: CreditRuleMapping) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: 'Delete Credit Rul',
        subMessage: 'Are you sure to delete this credit rule?'
      }).afterClosed().pipe(
        tap(result => {
          if (result && result.readyForSubmission) {
            this.applicationService.updateCreditRuleMappingFn({isDeleted: true}, element.id)
              .pipe(
                this.toastService.spinnerObservable(),
                this.toastService.snackBarObservable(`Mapping deleted!`),
              ).subscribe(() => {
                this.reload();
              })
            }
          }
        )
      ).subscribe()
      
    )
  }

  save() {
    if (this.formControlName.valid) {
      this.subscriptions.push(
        this.applicationService.updateCreditFlowFn({
          name: this.formControlName.value ?? '',
          startNode: this.formControlStartNode.value ?? undefined,
          AssetFinance: !!this.formControlAssetFinance.value,
          BusinessLoans: !!this.formControlBusinessLoans.value,
          BusinessOverdraft: !!this.formControlBusinessOverdraft.value,
          InsurancePremium: !!this.formControlInsurancePremium.value,
          CorporateLoans: !!this.formControlCorporateLoans.value,
          Consumer: !!this.formControlConsumer.value,
          Accreditation: !!this.formControlAccreditation.value,
          published: this.creditFlow.published,
        }, this.creditFlow.id).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((response) => {
          console.log('=====response: ', response);
          if(response && response.payload && response.payload.length){
            this.dialogService.openPublishCreditFlowConfirmation({
              conflictedFlows: response.payload
            }).afterClosed().subscribe(
              (result) => {
                if (result && result.force) {
                // loader on
                this.toastService.spinnerObservable()
                this.applicationService.updateCreditFlowFn({
                  name: this.formControlName.value ?? '',
                  startNode: this.formControlStartNode.value ?? undefined,
                  AssetFinance: !!this.formControlAssetFinance.value,
                  BusinessLoans: !!this.formControlBusinessLoans.value,
                  BusinessOverdraft: !!this.formControlBusinessOverdraft.value,
                  InsurancePremium: !!this.formControlInsurancePremium.value,
                  CorporateLoans: !!this.formControlCorporateLoans.value,
                  Consumer: !!this.formControlConsumer.value,
                  Accreditation: !!this.formControlAccreditation.value,
                  published: this.creditFlow.published,
                  force: true,
                }, this.creditFlow.id).pipe(
                    this.toastService.spinnerObservable(),
                    this.toastService.snackBarObservable(`${response.message ?? result, 'Credit flow is updated successfully'}`)
                  ).subscribe(() => { 
                    this.cancel(); 
                  })
                }
              }
            )
          } else {
            this.toastService.snackBarObservable(`Credit flow is updated successfully`);
            this.cancel(); 
          }
        })
      )
    }
    
  }

  async cancel() {
    await this.router.navigate(navigationUrlForCreditManagement());
  }

  displayNodeName(nodeId: number): string {
    if (!nodeId) {
      return 'No';
    }

    const node = this.creditFlow.nodes.find(node => node.id === nodeId);
    if (node) {
      return node.name;
    } else {
      return `${nodeId}`;
    }
  }

  displayNodeId(nodeId: number): string {
    return `0`.repeat(Math.max(0, 5 - `${nodeId}`.length)) + `${nodeId}`;
  }

  onClickNode(element: CreditFlowNodeWithLevel) {
    this.onEditCreditFlowNode(element);
  }
}
