import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ReportRequestDialogData, ReportRequestDialogResult, DateRangeValue, PismoProgram, getPismoProgramsFn} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { setupUntilDestroy } from '../component-utils';
import {Moment} from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DateRangeComponent } from '../date-range-component/date-range.component';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './report-request.dialog.html',
    styleUrls: ['./report-request.dialog.scss'],
    standalone: true,
    imports: [DateRangeComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, MatOptionModule, MatSelectModule]
})
export class ReportRequestDialog implements OnInit {

  disableControls = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    dateRange : FormControl<DateRangeValue>,
    pismoProgram: FormControl<PismoProgram | null>
  }>;
  allProgramSelection: PismoProgram = { programId: -1, name: 'All' };
  programSelections: PismoProgram[] = [];
  getPismoProgramsFn!: getPismoProgramsFn;
  programSelectable!: boolean;

  formControlDateRangePicker: FormControl<DateRangeValue>;
  formControlPismoProgram!: FormControl<PismoProgram | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ReportRequestDialogData,
              private formBuilder: FormBuilder,
              private toastService: PortalHotToastService,
              private dialogRef: MatDialogRef<ReportRequestDialog, ReportRequestDialogResult>) {
    this.programSelectable = this.data.programSelectable;
    this.getPismoProgramsFn = this.data.getPismoProgramsFn;
    this.formControlDateRangePicker = formBuilder.control(null, data.mandatory ? [Validators.required] : []);
    this.formControlPismoProgram = this.formBuilder.control(this.allProgramSelection, this.programSelectable ? [Validators.required] : []);
    this.formGroup = this.formBuilder.group({
      dateRange : this.formControlDateRangePicker,
      pismoProgram: this.formControlPismoProgram,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.populateProgram();
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
    ).subscribe(r => {
      if (r.dateRange && r.dateRange.startDate && r.dateRange.endDate)
        this.disableControls = true;
      else
        this.disableControls = false;
    });
    this.disableControls = false
    this.subscriptions.push(sub);
  }

  populateProgram() {
    this.subscriptions.push(
      this.getPismoProgramsFn().pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((programs: PismoProgram[] ) => {
        this.programSelections = programs;
      })
    )
  }

  submit() {
    if (this.formGroup.valid) {
      const v = this.formControlDateRangePicker.value;
      const p = this.formControlPismoProgram.value;
      const data: ReportRequestDialogResult = {
        type: 'ok',
        startDate: v?.startDate ?? null,
        endDate: v?.endDate ?? null,
        pismoProgram: p ?? null,
      };
      this.dialogRef.close(data)
    }

  }

  cancel($event: Event) {
    this.dialogRef.close();
  }
}
