<div class="pismo-account-page container-fluid">
  <button class="arrow-left mb-2" (click)="onClickBack()">
    <span class="mdi mdi-arrow-left"></span>
  </button>

  @if (stage == 'error') {
    <message-box type="danger">
      Unable to find and load account
    </message-box>
  }

  @if (stage != 'error') {
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab >
        <ng-template mat-tab-label label="DETAILS">
          <span class="mdi mdi-information-outline"></span>
          DETAILS
        </ng-template>
        <ng-container *ngTemplateOutlet="details"></ng-container>
      </mat-tab>
      <mat-tab >
        <ng-template mat-tab-label label="USERS">
          <span class="mdi mdi-account-circle-outline"></span>
          USERS
        </ng-template>
        <ng-container *ngTemplateOutlet="users"></ng-container>
      </mat-tab>
      <mat-tab label="TRANSACTIONS">
        <ng-template mat-tab-label>
          <span class="mdi mdi-swap-horizontal"></span>
          TRANSACTIONS
        </ng-template>
        <ng-container *ngTemplateOutlet="transactions"></ng-container>
      </mat-tab>
      <mat-tab label="STATEMENTS">
        <ng-template mat-tab-label>
          <span class="mdi mdi-file-document-outline"></span>
          STATEMENTS
        </ng-template>
        <ng-container *ngTemplateOutlet="statements"></ng-container>
      </mat-tab>details
      <mat-tab label="TIMELINE">
        <ng-template mat-tab-label>
          <span class="mdi mdi-account-details-outline"></span>
          TIMELINE
        </ng-template>
        <ng-container *ngTemplateOutlet="timeline"></ng-container>
      </mat-tab>
      <mat-tab label="NOTES">
        <ng-template mat-tab-label>
          <span class="mdi mdi-pencil-circle-outline"></span>
         NOTES
         @if(total>0){ (<span>{{total}}</span>) }
        </ng-template>
        <ng-container *ngTemplateOutlet="notes"></ng-container>
      </mat-tab>
    </mat-tab-group>
  }
</div>

<!--
### Details template
-->
<ng-template #details>
  <mat-card appearance="outlined">
    <mat-card-content>
      @if (customer && existingPismoAccountMappings) {
        <overdraft-account-details
          [customer]="customer"
          [existingPismoAccountMappings]="existingPismoAccountMappings"
          [updatePismoAccountPaymentLimitFn]="updatePismoAccountPaymentLimitFn"
          [getOpportunitiesForCustomerFn]="getOpportunitiesForCustomerFn"
          [getPismoAccountAndOpportunityDetailsFn]="getPismoAccountAndOpportunityDetailsFn"
          [validEmailCheckFn]="validEmailCheckFn"
          [getUserByEmailFn]="getUserByEmailFn"
          [getApplicationIndividualsFn]="getApplicationIndividualsFn"
          [createOverdraftUserFn]="createOverdraftUserFn"
          [createAccountInterestRateFn]="createAccountInterestRateFn"
          [postOverdraftAccountFeeFn]="postOverdraftAccountFeeFn"
          [getPismoTransactionTypeByIdFn]="getPismoTransactionTypeByIdFn"
          [postManualTransactionFn]="postManualTransactionFn"
          [getPismoTransactionFlowFn]="getPismoTransactionFlowFn"
          [getPismoCardsForAccountFn]="getPismoCardsForAccountFn"
          [getPismoTransactionTypesFn]="getPismoTransactionTypesFn"
          [updatePismoAccountLimitFn]="updatePismoAccountLimitFn"
          [getAllPismoAccountStatusFn]="getAllPismoAccountStatusFn"
          [getPismoAccountStatusReasonsFn]="getPismoAccountStatusReasonsFn"
          [updatePismoAccountStatusFn]="updatePismoAccountStatusFn"
          [rollbackPismoAccountStatusFn]="rollbackPismoAccountStatusFn"
          [pismoCheckEmailExistsFn]="pismoCheckEmailExistsFn"
          [pismoGetBuiltInTransactionTypesFn]="pismoGetBuiltInTransactionTypesFn"
          [pismoUpdateRequireMonthlyFixedInstallmentFlagFn]="pismoUpdateRequireMonthlyFixedInstallmentFlagFn"
          [pismoUpdateStopDirectDebitFlagFn]="pismoUpdateStopDirectDebitFlagFn"
          (events)="onPismoAccountDetailsEvent($event)"
          [showDisplayTransactions]="false"
          [pismoGetPaidFacilityFeeFn]="pismoGetPaidFacilityFeeFn"
          [pismoGetPendingInterestFn]="pismoGetPendingInterestFn"
          [getPismoCustomerForAccountFn]="getPismoCustomerForAccountFn"
          [pismoSendPayoutNotificationFn]="pismoSendPayoutNotificationFn"
          [pismoGeneratePayoutFn]="pismoGeneratePayoutFn"
          [getPendingPaymentsFn]="getPendingPaymentsFn"
          [pismoGetAccountInterestRateFn]="pismoGetAccountInterestRateFn"
          [getDefaultRatecardDetailsFn]="getDefaultRatecardDetailsFn"
          [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
          [getRbaRateFn]="getRbaRateFn"
          [getCustomerFn]="getCustomerFn"
          [overdraftCustomerSfRecordId]="environment.OverdraftCustomerRecordTypeId"
          [overdraftAccountLimitIncreaseFn]="overdraftAccountLimitIncreaseFn"
          [manualDirectDebitFn]="manualDirectDebitFn"
          [updateMonthlyFacilityFeeFn]="updateMonthlyFacilityFeeFn"
          [updateAmortisedRepaymentFn]="updateAmortisedRepaymentFn"
          
          >
        </overdraft-account-details>
      }
    </mat-card-content>
  </mat-card>
</ng-template>

<!--
###### Users Template
-->
<ng-template #users>
  <mat-card appearance="outlined">
    <mat-card-content class="px-0">
      <overdraft-account-users
        [enableOverdraftUserFn]="enableOverdraftUserFn"
        [disableOverdraftUserFn]="disableOverdraftUserFn"
        [existingPismoAccountMappings]="existingPismoAccountMappings"
        [getPismoCustomerForAccountFn]="getPismoCustomerForAccountFn"
        [updateUserKycStatusFn]="updateUserKycStatusFn"
        [sendForgotPasswordFn]="sendForgotPasswordFn"
        [pismoUpdateCustomerFn]="pismoUpdateCustomerFn"
        [getPismoCardsForCustomerFn]="getPismoCardsForCustomerFn"
        [pismoChangeCardStatusFn]="pismoChangeCardStatusFn"
        [pismoGetCardReissueReasonsFn]="pismoGetCardReissueReasonsFn"
        [pismoReissueCardFn]="pismoReissueCardFn"
        [getTokenInfoFn]="getTokenInfoFn"
      >
      </overdraft-account-users>
    </mat-card-content>
  </mat-card>
</ng-template>

<!--
##### Transactions Template
-->
<ng-template #transactions>
  <mat-card appearance="outlined">
    <overdraft-account-transactions
      [pismoAccountNumber]="pismoAccountNumber"
      [getPismoTransactionsFn]="getPismoTransactionsFn"
      [getTransactionDetailsFn]="getTransactionDetailsFn"
      [getPismoAccountDetailsFn]="getPismoAccountDetailsFn"
      [getCardFn]="getCardFn"
      [getTransactionFromTimelineFn]="getTransactionFromTimelineFn"
    ></overdraft-account-transactions>

  </mat-card>
</ng-template>

<!--
##### Statements Template
-->
<ng-template #statements>
  <mat-card appearance="outlined">
    <overdraft-account-statements
      [pismoAccountNumber]="pismoAccountNumber"
      [getPismoStatementsFn]="getPismoStatementsFn"
      [downloadStatementInCSV]="downloadStatementInCSV"
      [downloadStatementInOFX]="downloadStatementInOFX"
      [downloadStatementInPDF]="downloadStatementInPDF"
      (events)="onPismoAccountStatementsEvent($event)"
    ></overdraft-account-statements>

  </mat-card>
</ng-template>

<ng-template #notes>
  <mat-card appearance="outlined" class="p-10">
    <mat-card-content>

      <div class="row">
        <div class="col-12">
          <custom-angular-editor [formControl]="formControlAccountNotes" (click)="show()" style="resize: none;"
            [placeholder]="'New Note'" [height]="showButton? 250 : 70"></custom-angular-editor>

          @if (showButton) {
          <div class="position mt-3">
            <span class="md4 mr-3">
              <button mat-stroked-button (click)="cancel()">Cancel</button>
            </span>
            <span>
              <button mat-stroked-button (click)="saveNotes()" [disabled]="!formControlAccountNotes.valid">Save</button>
            </span>
          </div>
          }
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
    <mat-tab label="NOTES">
      <div>
        <pismo-notes
          [pismoNotes]="pismoNotes" [total]="total"
          [getPismoNotesByAccountNumberFn]="getPismoNotesByAccountNumberFn"
          [removePismoAccountNoteByNoteIdFn]="removePismoAccountNoteByNoteIdFn"
          (totalNotesChange)="onTotalNotesChange($event)"
        ></pismo-notes>
      </div>
    </mat-tab>
  
  </mat-tab-group>
</ng-template>

<ng-template #timeline>
  <!-- <mat-card appearance="outlined"> --> 
    <!-- Remove white background / border from timeline search button -->
    <overdraft-account-timeline
      [pismoAccountNumber]="pismoAccountNumber"
      [getPismoAccountTimelineFn]="getPismoAccountTimelineFn"
    ></overdraft-account-timeline>
  <!-- </mat-card> -->
</ng-template>