import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '../component-utils';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApplicationWebService, CreditorWatchReportData, GetWebServiceReportFn } from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'accreditation-report',
    templateUrl: './accreditation-report.component.html',
    styleUrls: ['./accreditation-report.component.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, PdfViewerModule]
})
export class AccreditationReportComponent implements OnInit {
  subscriptions: Subscription[] = [];
  pdf!: Uint8Array;
  reportHtml!: SafeHtml;
  webService!: ApplicationWebService;
  webServiceId!: number;
  webServiceType!: string;

  @Input({required: true}) getWebServiceReportFn!: GetWebServiceReportFn;
  @Input({required: true}) apiUrl!: string;
  @Output() leaveReport: EventEmitter<void> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.webServiceId = this.route.snapshot.params['innerTabParam'];
    // console.log('======= Web Service ID Populate Check', this.webServiceId);

    if (this.webServiceId) {
    // console.log('Report Component: Fetching report for web service ID', this.webServiceId);
    this.subscriptions.push(
      this.getWebServiceReportFn(this.webServiceId).pipe(
        this.toastService.spinnerObservable()
        ).subscribe(response => {
          console.log('======= Response', response);
          if (response && response.data) {
            this.webService = response.webService;
            this.webServiceType = this.webService.type;
            if (this.webServiceType === 'EquifaxFraudCheck') {
              this.reportHtml = this.sanitizer.bypassSecurityTrustHtml(response.data);
            }
            if (this.webServiceType !== 'EquifaxFraudCheck') {
              this.base64ToArrayBuffer(response.data);
            }
        } else {
            this.onLeaveReport();
            const errorMessage = 'Report not found';
            this.toastService.error(errorMessage);
        }
      })
    );
    }

  }

  onLeaveReport(): void {
    this.leaveReport.emit();
  }

  onDownloadReport(): void {
    if (this.webService) {
      if (this.webService.type === 'CreditorWatch') {
        const pdfId = (this.webService.json as CreditorWatchReportData).pdfRequestId;
        const url = `${this.apiUrl}/credit/download-creditorwatch-report/${pdfId}`;
        window.open(url);
      } else if (this.webService.type === 'EquifaxConsumer') {
        const url = `${this.apiUrl}/credit/download-equifax-consumer-report/${this.webService.id}`;
        window.open(url);
      } else if (this.webService.type === 'EquifaxFraudCheck') {
        const url = `${this.apiUrl}/credit/download-equifax-fraud-check-report/${this.webService.id}`;
        window.open(url);
      }
    }
  }

  base64ToArrayBuffer(base64: string) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
      
    }
    this.pdf = (bytes.buffer as Uint8Array);
  }
} 