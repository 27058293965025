import {AfterViewInit, Component, Inject, Injectable, Input, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {
  CreateHotToastRef, HotToastRef, HotToastService as NgNeatHotToastService,
  ObservableLoading,
  ObservableMessages, ObservableSuccessOrError, resolveValueOrFunction, Toast, ToastOptions, ToastType, UpdateToastOptions, ValueOrFunction
} from '@ngneat/hot-toast';
import {tap} from 'rxjs/operators';
import {ApiResponse, WithOptional} from '@portal-workspace/grow-shared-library';
import {HOT_TOAST_DEFAULT_TIMEOUTS} from '@ngneat/hot-toast/lib/constants';
import {defer, Observable, of, Subject} from 'rxjs';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import {ProgressSpinnerDialog} from '../application-dialog-component/progress-spinner.dialog';
import {MatDialogRef} from '@angular/material/dialog';
import {TopMenuService} from '../topmenu-component/top-menu.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import { ErrorNotification } from '../message-box/error-notification-component/error-notification.component';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    template: `
    <div class="hot-toast-info" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span fxFlex="0 0 auto" class="mdi mdi-information-outline"></span>
      <div fxLayout="column">
        <span fxFlex="1 0 auto" class="title">{{toastRef.data.title}}</span>
        <span fxFlex="1 0 auto" class="message" [innerHTML]="toastRef.data.message"></span>
      </div>
      <div fxLayout="column" (click)="toastRef.close({ dismissedByAction: true })">
        <span fxFlex="0 0 auto" class="mdi mdi-close"></span>
      </div>
    </div>
  `,
    styles: [],
    standalone: true,
    imports: [FlexModule]
})
export class PortalHotToastInfoTemplate {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) { }
}
@Component({
    template: `
    <div class="hot-toast-warn" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span fxFlex="0 0 auto" class="mdi mdi-help-circle-outline"></span>
      <div fxLayout="column">
        <span fxFlex="1 0 auto" class="title">{{toastRef.data.title}}</span>
        <span fxFlex="1 0 auto" class="message" [innerHTML]="toastRef.data.message"></span>
      </div>
    </div>
  `,
    standalone: true,
    imports: [FlexModule]
})
export class PortalHotToastWarnTemplate {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) { }
}
@Component({
    template: `
    <div class="hot-toast-success" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span fxFlex="0 0 auto" class="mdi mdi-account-circle-outline"></span>
      <div fxLayout="column">
        <span fxFlex="1 0 auto" class="title">{{toastRef.data.title}}</span>
        <span fxFlex="1 0 auto" class="message" [innerHTML]="toastRef.data.message"></span>
      </div>
    </div>
  `,
    standalone: true,
    imports: [FlexModule]
})
export class PortalHotToastSuccessTemplate {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) { }
}
@Component({
    template: `
    <div class="hot-toast-error" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span fxFlex="0 0 auto" class="mdi mdi-alert-box-outline"></span>
      <div fxLayout="column">
        <span fxFlex="1 0 auto" class="title">{{toastRef.data.title}}</span>
        <span fxFlex="1 0 auto" class="message" [innerHTML]="toastRef.data.message"></span>
      </div>
    </div>
  `,
    standalone: true,
    imports: [FlexModule]
})
export class PortalHotToastErrorTemplate {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) { }
}


@Component({
    template: `
    <div class="hot-toast-success-notification" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="70px">
      <div fxLayout="row" fxLayoutGap="5px">
        <span fxFlex="1 0 auto" class="title">{{toastRef.data.message}}</span>
        <span class="message" (click)="toastRef.close({ dismissedByAction: true })">DONE</span>
      </div>
    </div>
  `,
    standalone: true,
    imports: [FlexModule]
})
export class PortalHotToastRetryableSuccessTemplate {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) { }
}

@Component({
    template: `
    <div class="hot-toast-success-notification" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="70px">
      <div fxLayout="row" fxLayoutGap="5px">
        <span fxFlex="1 0 auto" class="title">{{toastRef.data.message}}</span>
        <span class="message" (click)="toastRef.close({ dismissedByAction: true }); retryError()">RETRY</span>
      </div>
    </div>
  `,
    standalone: true,
    imports: [FlexModule]
})
export class PortalHotToastRetryableErrorTemplate {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) { }
  retryError() {
    (this.toastRef.data as any).retryFn();
  }
}

@Component({
    template: `
    <error-notification
      (onRetry)="retryError()"
      [errorTitle]="errorTitle()"
      [errorMessage]="errorMessage()"
      [errorSolution]="errorSolution()">
    </error-notification>
  `,
    styles: [`
    ::ng-deep {
      .error-notification {
        width: 60vw;
      }
    }
  `],
    standalone: true,
    imports: [ErrorNotification]
})
export class PortalHotToastRetryableErrorTemplate2 {
  constructor(@Optional() @Inject(HotToastRef) public toastRef: HotToastRef) {}
  errorTitle() {
    return (this.toastRef.data as any).errorTitle;
  }
  errorMessage() {
    return (this.toastRef.data as any).errorMessage;
  }
  errorSolution() {
    return (this.toastRef.data as any).errorSolution;
  }
  retryError() {
    (this.toastRef.data as any).retryFn();
  }
}


export interface PortalHotToastErrorNotification {
  type: 'error',
  errorTitle: string,
  errorMessage: string,
  errorSolution: string,
  retryFn: ()=>void,
}
export interface PortalHotToastSuccessNotification {
  type: 'success'
}
export type PublishedNotification = PortalHotToastErrorNotification | PortalHotToastSuccessNotification;

const DEFAULT_HOT_TOAST_ID = 'default-hot-toast-id';
const DEFAULT_HOT_TOAST_DURATION = 800;
const REGULAR_HOT_TOAST_DURATION = 3000;
const LONG_HOT_TOAST_DURATION = 5000;

export const markHideErrorInErrorHandler = (err: Error) => {
  (err as any).stopErrorHandler = true;
}

export const isHideErrorInErrorHandler = (err: Error): boolean => {
  return ((err as any).stopErrorHandler ?? false);
}

@Injectable()
export class PortalHotToastService {

  publishNotificationSubject = new Subject<PublishedNotification>();

  constructor(private s: NgNeatHotToastService,
              private t: TopMenuService,
              private m: MatSnackBar,
              private d: ApplicationDialogService,
              private domSanitizer: DomSanitizer){}


  loadingWithMessage<T>(loadingMessage: string = 'Processing, please be patient.') {
    const refs: CreateHotToastRef<any>[] = [];
    return (source: Observable<T>) => {
      return defer(()=> {
        return source.pipe(
          tap({
            subscribe: () => {
              const ref = this.s.loading(loadingMessage, { id: 'uid', autoClose: false });
              refs.push(ref);
            },
            complete: () => {
              if (refs && refs.length) {
                refs.forEach(r => r && r.close());
              }
            },
            finalize: () => {
              if (refs && refs.length) {
                refs.forEach(r => r && r.close());
              }
            },
          })
        )
      })
    };
  }


  // observe<T, DataType>(messages: ObservableMessages<T, DataType>): (source: Observable<T>) => Observable<T> {
  //   return (source) => {
  //     let toastRef: CreateHotToastRef<DataType | unknown>;
  //     let start = 0;

  //     const loadingContent = messages.loading ?? 'loading';
  //     const errorContent = messages.error;

  //     return defer(() => {
  //       if (loadingContent) {
  //         toastRef = this.s.show('loading');
  //         start = Date.now();
  //       }
  //       return source.pipe(
  //         tap({
  //           next: (val) => {
  //             toastRef.updateMessage(`xxxx`);
  //             toastRef = this.createOrUpdateToast<T, DataType | unknown>(
  //               messages,
  //               val,
  //               toastRef,
  //               'success',
  //               start === 0 ? start : Date.now() - start
  //             );
  //           },
  //           ...(errorContent && {
  //             error: (e) => {
  //               toastRef = this.createOrUpdateToast<T, DataType | unknown>(
  //                 messages,
  //                 e,
  //                 toastRef,
  //                 'error',
  //                 start === 0 ? start : Date.now() - start
  //               );
  //             },
  //           }),
  //         })
  //       );
  //     });
  //   };
  // }
  publishErrorNotificationObservable<T>(opt: WithOptional<Omit<PortalHotToastErrorNotification, 'type'>, 'errorSolution'>) {
    return (source: Observable<T>) => {
      return defer(()=>{
        return source.pipe(
          tap({
            complete:() => {
              this.publishNotificationSubject.next({type: 'success'});
            },
            error: (err) => {
              console.log('publishErrorNotification: ', err);
              markHideErrorInErrorHandler(err);
              this.publishNotificationSubject.next({
                ...opt,
                errorSolution: opt.errorSolution ?? '',
                type: 'error',
              });
            }
          })
        );
      })
    };
  }

  publishErrorNotification(n: PortalHotToastSuccessNotification | WithOptional<PortalHotToastErrorNotification, 'errorSolution'>) {
    this.publishNotificationSubject.next(n.type === 'success' ? n : {
      ...n,
      errorSolution: n.errorSolution ?? '',
    });
  }


  retryableErrorMessage<T>(opt: {
    errorTitle: string,
    errorMessage: string | ((err: Error) => string),
    retryFn: ()=>void,
  }) {
    return (source: Observable<T>) => {
      return defer(() => {
        return source.pipe(
          tap({
            error: (err) => {
              markHideErrorInErrorHandler(err);
              this.s.error(
                PortalHotToastRetryableErrorTemplate,
                {
                  // className: 'hot-toast-error-base',
                  theme: 'snackbar',
                  position: 'bottom-center',
                  dismissible: false,
                  duration: LONG_HOT_TOAST_DURATION,
                  data: {
                    message: (typeof opt.errorMessage == 'function') ? opt.errorMessage(err) : opt.errorMessage,
                    retryFn: opt.retryFn,
                  },
                });
            }
          })
        );
      });
    }
  }


  retryableMessage<T>(opt: {
    successMessage: string,
    errorMessage: string | ((err: Error) => string),
    retryFn: ()=>void,
  }) {
    return (source: Observable<T>) => {
      return defer(() => {
        const o = source.pipe(
          tap({
            complete: ()=>{
              this.s.success(
                PortalHotToastRetryableSuccessTemplate,
                {
                  // className: 'hot-toast-success-base',
                  dismissible: false,
                  duration: LONG_HOT_TOAST_DURATION,
                  theme: 'snackbar',
                  position: 'bottom-center',
                  data: {
                    message: opt.successMessage,
                  },
                });
            },
            error: (err)=>{
              markHideErrorInErrorHandler(err);
              this.s.error(
                PortalHotToastRetryableErrorTemplate,
                {
                  // className: 'hot-toast-error-base',
                  theme: 'snackbar',
                  position: 'bottom-center',
                  dismissible: false,
                  duration: LONG_HOT_TOAST_DURATION,
                  data: {
                    message: (typeof opt.errorMessage == 'function') ? opt.errorMessage(err) : opt.errorMessage,
                    retryFn: opt.retryFn,
                  },
                });
            },
          }),
        );
        return o;
      });
    }
  }


  topMenuLoadingObservable<T>()  {
    return (source: Observable<T>) => {
      return defer(()=> {
        const o = source.pipe(
          tap({
            subscribe: ()=> {
              this.t.showProgress();
            },
            finalize: ()=> {
              this.t.hideProgress();
            }
          })
        );
        return o;
      })
    }
  }

  snackBarObservable<T>(msg: string) {
    return (source: Observable<T>) => {
      return defer(()=> {
        return source.pipe(
          tap({
            complete: ()=> {
              this.m.open(msg, 'Close', { duration: REGULAR_HOT_TOAST_DURATION});
            }
          })
        );
      });
    }
  }

  spinnerObservable<T>() {
    return (source: Observable<T>) => {
      return defer(() => {
        let ref: MatDialogRef<ProgressSpinnerDialog> | null = null;
        const o =  source.pipe(
          tap({
            subscribe: () => {
              ref = this.d.openProgressSpinnerDialog();
            },
            finalize: () => {
              if (ref) {
                ref.close();
              }
            }
          })
        );
        return o;
      });
    };

    // return (source: Observable<T>) => {
    //   let ref: MatDialogRef<ProgressSpinnerDialog> | null = null;
    //   return defer(()=> source).pipe(
    //     tap(r => {
    //        ref = this.d.openProgressSpinnerDialog();
    //     }),
    //     finalize(()=> {
    //       if (ref) {
    //         ref.close();
    //       }
    //     })
    //   );
    // };
  }



  //////////////////////////////////////////////////////////////

  // toastObserver2<T>(context: string = '') {
    // return (source: Observable<T>) => source;
    // return this.s.observe<T, string>({
    //   loading: {
    //     id: context ?? DEFAULT_HOT_TOAST_ID,
    //     duration: DEFAULT_HOT_TOAST_DURATION,
    //     content: `processing ${context} ...` ,
    //   },
    //   success: {
    //     id: context ?? DEFAULT_HOT_TOAST_ID,
    //     duration: DEFAULT_HOT_TOAST_DURATION,
    //     content: `done ${context}`
    //   },
    //   error: {
    //     id: context ?? DEFAULT_HOT_TOAST_ID,
    //     content: (error) => {
    //       return `Error: ${error.message ?? ''}`;
    //     }
    //   },
    // })
  // }

  // toastObserver<T extends ApiResponse>(context: string = '') {
    // return (source: Observable<T>) => source;
    // return this.s.observe({
    //   loading: {
    //     id: context ?? DEFAULT_HOT_TOAST_ID,
    //     duration: DEFAULT_HOT_TOAST_DURATION,
    //     content: `processing ${context} ...`
    //   },
    //   success: {
    //     id: context ?? DEFAULT_HOT_TOAST_ID,
    //     duration: DEFAULT_HOT_TOAST_DURATION,
    //     content: (result: T) => {
    //       console.log('**************** result', result);
    //       if (result.status) {
    //         return result.message && result.message.trim().length ? result.message : `${context} success`;
    //       } else {
    //         return result.message && result.message.trim().length ? result.message : `${context} Failed`;
    //       }
    //       return 'done';
    //       }
    //   },
    //   error: {
    //     id: context ?? DEFAULT_HOT_TOAST_ID,
    //     content: (error) => {
    //       return `Error: ${error.message ?? ''}`;
    //     },
    //   }
    // });
  // }

  processMessages<T extends ApiResponse>() {
    return tap((t: T) => {
      if (t.status) {
        const msg = t.message && t.message.length ? t.message[0] : undefined;
        if (msg) {
          this.success(msg);
        }
      } else {
        const msg = t.message && t.message.length ? t.message[0] : undefined;
        if (msg) {
          this.error(msg);
        }
      }
    });
  }

  uniqueInfo<DT>(code: string): CreateHotToastRef<DT | unknown> {
    const title = ``;
    const message = `(Code: ${code}) There was an issue with one of our services. Please either try again shortly, or contact us on 1300 001 420.`;
    return this.s.show(
      PortalHotToastInfoTemplate,
      { id: 'UNIQUE_INFO_ID', className: 'hot-toast-info-base', dismissible: true, duration: LONG_HOT_TOAST_DURATION, data: {
          message: this.domSanitizer.bypassSecurityTrustHtml(message),
          title,
        },
      });
  }


  uniqueOfflineInfo<DT>(): CreateHotToastRef<DT | unknown> {
    const title = `Offline`;
    const message = `Offline detected: unable to connect to internet`;
    return this.s.show(
      PortalHotToastInfoTemplate,
      { id: 'UNIQUE_OFFLINE_INFO_ID', className: 'hot-toast-info-base', dismissible: false, duration: REGULAR_HOT_TOAST_DURATION, data: {
          message: this.domSanitizer.bypassSecurityTrustHtml(message),
          title,
        },
        closeStyle: {
          color: 'red',
        }});
  }

  uniqueDuplicatedRequestInfo<DT>(): CreateHotToastRef<DT | unknown> {
    const title = ``;
    const message = `You already have an ongoing request. Please wait.`;
    return this.s.show(
      PortalHotToastInfoTemplate,
      { id: 'UNIQUE_DUPLICATED_INFO_ID', className: 'hot-toast-info-base', dismissible: true, duration: LONG_HOT_TOAST_DURATION, data: {
          message: this.domSanitizer.bypassSecurityTrustHtml(message),
          title,
        },
      });
  }

  info<DT>(message: string, title: string = '', options?: ToastOptions<DT>): CreateHotToastRef<DT | unknown> {
    return this.s.show(
      PortalHotToastInfoTemplate,
      { className: 'hot-toast-info-base', dismissible: true, duration: REGULAR_HOT_TOAST_DURATION, data: {
        message: this.domSanitizer.bypassSecurityTrustHtml(message),
        title,
      },
    });
  }

  warn<DT>(message: string, title: string = '', options?: ToastOptions<DT>): CreateHotToastRef<DT | unknown> {
    return this.s.show(
      PortalHotToastWarnTemplate,
      { className: 'hot-toast-warn-base', dismissible: true, duration: REGULAR_HOT_TOAST_DURATION, data: {
        message: this.domSanitizer.bypassSecurityTrustHtml(message),
        title
      } });
  }

  error<DT>(message: string, title: string = '', options?: ToastOptions<DT>): CreateHotToastRef<DT | unknown> {
    return this.s.show(
      PortalHotToastErrorTemplate,
      { className: 'hot-toast-error-base', dismissible: true, duration: REGULAR_HOT_TOAST_DURATION, data: {
        message: this.domSanitizer.bypassSecurityTrustHtml(message),
        title
      } });
  }

  success<DT>(message: string, title: string = '', options?: ToastOptions<DT>): CreateHotToastRef<DT | unknown> {
    return this.s.show(
      PortalHotToastSuccessTemplate,
      { className: 'hot-toast-success-base', dismissible: true, duration: REGULAR_HOT_TOAST_DURATION, data: {
        message: this.domSanitizer.bypassSecurityTrustHtml(message),
        title } });
  }


  simpleSuccess(message:string) {
     this.s.success(
      PortalHotToastRetryableSuccessTemplate,
      {
        dismissible: false,
        duration: LONG_HOT_TOAST_DURATION,
        theme: 'snackbar',
        position: 'bottom-center',
        data: {
          message: message,
        },
      });
  }

  snackbar(message: string, action: string = 'Close', options: MatSnackBarConfig = {}): MatSnackBarRef<TextOnlySnackBar> {
    return this.m.open(message, action, { duration: REGULAR_HOT_TOAST_DURATION, ...options });
  }

  quickInfoToast(message: string) {
    this.s.info(message, {
      duration: DEFAULT_HOT_TOAST_DURATION,
    });
  }

  quickWarnToast(message: string) {
    this.s.warning(message, {
      duration: DEFAULT_HOT_TOAST_DURATION,
    });
  }

  quickSuccessToast(message: string) {
    this.s.success(message, {
      duration: DEFAULT_HOT_TOAST_DURATION,
    });
  }

  quickErrorToast(message: string) {
    this.s.error(message, {
      duration: DEFAULT_HOT_TOAST_DURATION,
    });
  }
}
