import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest, of, Subscription} from 'rxjs';
import {
  applicationStageAllowGenerateContract,
  BusinessOverdraftApplication,
  ContractDetails,
  CreateApplicationNoteFn,
  CreateNewDisbursementFn,
  DeclineContractFn,
  DeleteContractFileFn,
  GenerateContractFn,
  GetActiveContractForApplicationFn,
  getBrokerageAmount,
  GetDisbursementByApplicationIdFn,
  SyncBankDetailsToSfFn,
  SyncDisbursementToSfFn,
  UpdateDisbursementFn,
  GetContractStatusFn,
  getMargin,
  RatecardDetails,
  SendContractEmailsFn,
  SyncContractDetailsToSfFn,
  RemoveApplicationNoteByNoteIdFn,
  UpdateApplicationStageInSfFn,
  GetApplicationByIdFn,
  formGroupedDocumentData,
  unclassifiedDocuments,
  AzureStorageDocument,
  Application,
  GroupedDocument,
  ApiResponse,
  Disbursement,
  validateDisbursementData,
  SettleLoanFn,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  CopyApplicationFn,
  GetApplicationOwnerFn,
  GetGeoLocationFn,
  RefreshBankStatementFn,
  GetUserFn,
  GetAccountDetailsFromSfFn,
  GetLvrCalculatorValueFn,
  UpdateLvrCalculatorValueFn,
  isAdminOrCreditUserOrSalesAM,
  UpdateApplicationFn,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  constants,
  groupStandardCondition,
  getCreditRateAdjustment,
  UpdateApplicationSfFn,
  OverdraftPaymentBreakupDialogData,
  calculateBusinessOverdraftEstimation, LoanTermType, isAdminOrCreditUser, DownloadDocumentFromAzureFn,
  GetBankStatementsAnalysisFn,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  RunCreditFlowFn, GetCreditWebServicesFn, GetApplicationCreditFlowResultFn, DownloadCreditorWatchReportFn, UpdateCreditStatusFn, GetCreditAuditLogFn, ApplicationSelectionObject, ApplicationWebService, GetWebServiceReportFn, GetDuplicatedApplicationsFn, DuplicatedApplication, DeleteWebServicesFn,
  GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, RefreshBasiqConnectionsFn, GetBankStatementAndBasiqDataStatusFn,
  SendPrivacyConsentEmailFn, AssetSettlementGetApplicationAssetFn, GetBsaLenderListFn, GetBsaExcludedLenderListFn, SaveBsaCalculatorFn, GetBsaCalculatorFn, GetDscrCalculatorHistoryFn, GetOriginatorBusinessByIdFn, RequestMoreInformationFn, WithdrawApplicationFn,
  isNonSalesInternalUser,
  GetApplicationDscrFn,
  UpdateCreditOfficerFn,
  GetActiveCreditUsersFn,
  ResendContractFn,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent, BreadcrumbComponent } from '../../breadcrumb-component/breadcrumb.component';
import {
  applicationDefaultDocuments,
  ApplicationDialogService,
  ApproveApplicationDocumentFn,
  AssetSettlementSendAssetToSfFn,
  BusinessNumberSearchFn,
  BusinessSearchFn,
  checkContractIndividuals,
  CompleteDocumentWorklistFn,
  CreateDocumentWorklistFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn,
  DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn,
  DownloadAllApplicationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn,
  GetApplicationAuditLogsFn,
  GetNotesByApplicationIdFn,
  GetOriginatorByIdFn,
  GetUsersFunc,
  ListApplicationDocumentFn,
  PortalHotToastService,
  SearchSupplierFn,
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  UserSelectionComponentEvent,
  UndoApplicationDocumentFn,
  AssetSettlementGetPpsrDetailsFn,
  AssetSettlementSearchGrantorsFn,
  applicationToLoanTerms,
  applicationToLoanAmount,
  applicationToPropertyOwner,
  applicationToAdverseOnFile,
  applicationToEquifaxScoreAboveThreshold,
  applicationToDirectorScore,
  applicationToFacilityEstablishmentFee,
  applicationToFacilityEstablishmentFeePercent,
  applicationToBrokerageAmount,
  applicationToDocFee,
  AssetDocumentsComponent,
  DscrCalculatorComponent,
} from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import {ApplicationSelectionType} from '@portal-workspace/grow-shared-library';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {getUser} from '@portal-workspace/grow-ui-library';
import {yesNoToBoolean, primaryCommercialEntity} from '@portal-workspace/grow-shared-library';
import {switchMap, tap} from 'rxjs/operators';
import {AppCalculator, FormDataForBusinessFinance,FormDataForBusinessOverdraft, TermRateForBusinessOverdraft} from '@portal-workspace/grow-shared-library';
import {setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import {isInternalUser, DigitalIdGetApplicationIndividualsFn, GetRateCardDetailsFn} from '@portal-workspace/grow-shared-library';
import {LvrCalculatorComponent} from '../lvr-calculator.component';
import { MatExpansionModule } from "@angular/material/expansion";

import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes, getAssetAvgRetail,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake, getAssetNewPrice,
  getAssetType, getAssetVehicle,
  getAssetYear, getBalloonPayment,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName, getBrokerOriginationFee,
  getBrokerSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDirectorScoreRate,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLoanAmount,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType, getPrimaryBusinessAddress,
  getPrimaryIndustry,
  getPrivateSales,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getApplicationStage,
  applicationStageAllowSettleLoan,
} from '@portal-workspace/grow-shared-library';
import { User } from '@portal-workspace/grow-shared-library';
import { BusinessOverdraftDocumentsComponent } from './business-overdraft-documents.component';
import { BusinessOverdraftCreditComponent } from './business-overdraft-credit.component';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { KycVerificationComponent } from '../kyc-verification.component';
import { BusinessOverdraftSettlementComponent } from '../business-overdraft/business-overdraft-settlement.component';
import { BusinessOverdraftAppComponent } from './business-overdraft-app.component';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { ApplicationStageIconComponent } from '../application-stage-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import {BusinessOverdraftPricingComponent, BusinessOverdraftPricingComponentEvent} from "./business-overdraft-pricing.component";
import {BankComponent} from "../bank.component";
import {ApplicationTypeIconComponent} from '../../application-type-icon/application-type-icon.component';
import { CreditReportComponent } from '../../credit-component/credit-report.component';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import { ActivatedRoute } from '@angular/router';

export type BusinessOverdraftTab = {selectionTypes: ApplicationSelectionType[], iconClass: string, name: string, index: number};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'business-overdraft-application-details',
  templateUrl: './business-overdraft-application-details.component.html',
  styleUrls: ['./business-overdraft-application-details.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    BreadcrumbComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    ApplicationStageIconComponent,
    MatTabsModule,
    BusinessOverdraftAppComponent,
    BusinessOverdraftSettlementComponent,
    KycVerificationComponent,
    BusinessOverdraftDocumentsComponent,
    LvrCalculatorComponent,
    BusinessOverdraftPricingComponent,
    BankComponent,
    AssetDocumentsComponent,
    MatExpansionModule,
    DscrCalculatorComponent,
    BusinessOverdraftCreditComponent,
    ApplicationTypeIconComponent,
    CreditReportComponent,
    MessageBoxComponent,
]
})
export class BusinessOverdraftApplicationDetailsComponent {

  tabs: BusinessOverdraftTab[] = [];

  ratecard: RatecardDetails | null = null;

  // possible selections that can be access directly through URL
  possibleSelections: ApplicationSelectionType[] = ['app', 'quote', 'bank-statement', 'documents', 'kyc-aml','credit', 'asset', 'pricing', 'credit-report'];

  subscriptions: Subscription[] = [];
  loggedInUser: User | null = getUser();
  // expandedIndex = 1; // Default expansion index

  @Input({required: true}) createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input({required: true}) updateDisbursementFn!: UpdateDisbursementFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input({required: true}) sendAssetToSfFn!: AssetSettlementSendAssetToSfFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn
  @Input({required: true}) getLvrCalculatorValueFn!: GetLvrCalculatorValueFn;
  @Input({required: true}) updateLvrCalculatorValueFn!: UpdateLvrCalculatorValueFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;

  @Input({required: true}) getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input({required: true}) addIndividualFn!: DigitalIdAddIndividualFn;
  @Input({required: true}) verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input({required: true}) verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input({required: true}) sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input({required: true}) updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input({required: true}) printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input({required: true}) setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
   
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({required: true}) generateContractFn!: GenerateContractFn;
  @Input({required: true}) deleteContractFileFn!: DeleteContractFileFn;
  @Input({required: true}) getActiveContractForApplicationFn!: GetActiveContractForApplicationFn;
  @Input({required: true}) declineContractFn!: DeclineContractFn;
  @Input({required: true}) sendContractEmailsFn!: SendContractEmailsFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) bankStatementsUrl!: string;
  @Input({required: true}) idVerifyUrl!: string;
  @Input({required: true}) application!: BusinessOverdraftApplication;
  @Input({required: true}) copyApplicationFn!: CopyApplicationFn;
  @Input({required: true}) getGeoLocationFn!: GetGeoLocationFn;
  @Input({required: false}) ip: string = '';
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input({required: true}) getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input({required: true}) sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input({required: true}) bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input({required: true}) deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  @Input({required: true}) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({required: true}) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({required: true}) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  @Input({required: true}) getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) updateCreditOfficerFn!: UpdateCreditOfficerFn;
  @Input({required: true}) getActiveCreditUsersFn!: GetActiveCreditUsersFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Input({required: true}) getApplicationDscrFn!: GetApplicationDscrFn;
  @Input({required: true}) resendContractFn!: ResendContractFn;

  @Output() navigateToApplications = new EventEmitter();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;
  breadcrumbTrails: string[] = ['Applications'];
  activeContract: ContractDetails | null = null;




  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: false}) initialSelection: ApplicationSelectionType | null = null;
  @Output() selectionEvent: EventEmitter<ApplicationSelectionObject> = new EventEmitter<ApplicationSelectionObject>();

  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};
  @Input({required: true}) allowContractGeneration!: boolean;
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();
  @Output() businessOverdraftPricingEvents = new EventEmitter<BusinessOverdraftPricingComponentEvent>();

  // repaymentEstimationData: RepaymentEstimationEntry[] = [];
  // amortizationScheduleData: AmortisationScheduleEntry[] = [];
  // paymentChartData: PaymentChartData = {
  //   amountFinanced: 0,
  //   totalInterest: 0,
  //   emiAmt: 0,
  //   paymentFrequency: 'Monthly',
  //   principalAmt: 0,
  //   interestAmt: 0,
  //   totalAmt: 0,
  //   loanTerm: 0,
  //   lvr: 0,
  //   rv: 0,
  //   brokerageAmount: 0,
  //   docFee: 0,
  //   brokerOriginationFee: 0,
  //   applicationType:'BusinessLoans'
  // };
  // amortizationChartData: AmortizationChartData = {
  //   estimatedDrawdownDate: moment(),
  //   annualData: [],
  //   quarterlyData: [],
  // };

  currentSection: ApplicationSelectionType = 'app';
  currentSectionIndex: number = 0;

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getBrokerName = getBrokerName;
  getOrganisationType = getOrganisationType;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getAssetCategory = getAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getPrimaryIndustry = getPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  isInternalUser = isInternalUser;
  getApplicationStage = getApplicationStage;
  applicationStageAllowGenerateContract = applicationStageAllowGenerateContract;
  applicationStageAllowSettleLoan = applicationStageAllowSettleLoan;
  formGroupedDocumentData = formGroupedDocumentData;
  unclassifiedDocuments = unclassifiedDocuments;
  groupStandardCondition = groupStandardCondition;
  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;
  @Input({required: true}) getWebServiceReportFn!: GetWebServiceReportFn;

  data!:TermRateForBusinessOverdraft
  rst!: OverdraftPaymentBreakupDialogData

  isAdminOrCreditUser = false;
  isAdminOrCreditUserOrSalesAM = false;

  constructor(
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
    private applicationService: ApplicationService,
    private activatedRoute: ActivatedRoute,
  ){}

  initTabs() {
    this.tabs = [];
    this.tabs.push({selectionTypes: ['app'], iconClass: 'mdi-view-grid-outline', name: 'APPLICATION', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['bank-statement'], iconClass: 'mdi-bank-outline', name: 'BANK STATEMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['kyc-aml'], iconClass: 'mdi-check-decagram-outline', name: 'CUSTOMER DETAILS(KYC/AML)', index: (this.tabs.length)});
    if (isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['credit', 'credit-report'], iconClass: 'mdi-calculator', name: 'CREDIT', index: (this.tabs.length)});
    }
    this.tabs.push({selectionTypes: ['documents'], iconClass: 'mdi-folder-outline', name: 'DOCUMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['asset'], iconClass: 'mdi-handshake-outline', name: 'CONTRACT', index: (this.tabs.length)});

    if (this.isAdminOrCreditUserOrSalesAM || isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['pricing'], iconClass: 'mdi-currency-usd', name: 'PRICING', index: (this.tabs.length)});
    }
  }

  // getApplicationTabIndex(tab: ApplicationSelectionType) {
  //   const tabFound = this.tabs.find((t) => t.selectionTypes.includes(tab));
  //   return tabFound ? tabFound.index : 0 /* default tab index if not found */;
  //   // switch (tab) {
  //   //   case 'app':
  //   //     return 0;
  //   //   case 'bank-statement':
  //   //     return 1;
  //   //   case 'kyc-aml':
  //   //     return 2
  //   //   case 'documents':
  //   //     return 3
  //   //   case 'asset':
  //   //   case 'add-asset':
  //   //     return 4
  //   //   case 'pricing':
  //   //     return 5;
  //   //   case 'lvr-calculator':
  //   //     return 6;
  //   //   default:
  //   //     return 0;
  //   // }
  // }

  async onSelectedTabChange(event: MatTabChangeEvent) {
    // const isInternalUser = this.isInternalUser(this.loggedInUser);
    // let selection: ApplicationSelectionType = 'app';
    // switch (tabIndex) {
    //   case 0:
    //     selection = 'app';
    //     break;
    //   case 1:
    //     selection = 'bank-statement';
    //     break;
    //   case 2:
    //     selection = 'kyc-aml';
    //     break;
    //   case 3:
    //     selection = 'documents';
    //     break;
    //   case 4:
    //     selection = 'asset';
    //     break;
    //   case 5:
    //     selection = 'pricing';
    //     break;
    //   case 6:
    //     selection = 'lvr-calculator';
    //     break;
    // }
    const tabFound = this.tabs.find(t => t.index == event.index);
    const selection = tabFound ? tabFound.selectionTypes[0] : 'app' /* default selection if not found */ ;
    this.currentSection = selection;
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  ngOnInit() {
    this.isAdminOrCreditUser = isAdminOrCreditUser(this.loggedInUser);
    this.isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM(this.loggedInUser);
    this.initTabs();
    const routeTab = this.initialSelection;
    if (routeTab && this.possibleSelections.includes(routeTab)) {
      const _tab = this.tabs.find(tab => tab.selectionTypes.includes(routeTab));
      if (_tab) {
        this.currentSection = routeTab;
        this.currentSectionIndex = _tab.index;
      }
    }
    setupUntilDestroy(this);
    this.getActiveContract();

    const commercialEntity = primaryCommercialEntity(this.application);
    if (commercialEntity) {
      this.breadcrumbTrails.push(commercialEntity.LegalName);
    }
    const sub = this.getRatecardDetailsFn(this.application.UserId, 'BusinessOverdraft').pipe(
      this.toastService.spinnerObservable(),
      tap((r: RatecardDetails) => {
        this.ratecard = r;
        this.recalculateEstimation();
        // const ratecard = r;
        // const date = moment().add(1, 'day');

        // {
        //   const loanTermValue = applicationToLoanTerms(this.application);
        //   const loanTermType = loanTermValue?.type ?? null;
        //   const loanAmount = applicationToLoanAmount(this.application) ?? 0;
        //   const propertyOwner =  applicationToPropertyOwner(this.application);
        //   const adverseOnFile = applicationToAdverseOnFile(this.application);
        //   const lowEquifaxScore = applicationToEquifaxScoreAboveThreshold(this.application);
        //   const directorScore = applicationToDirectorScore(this.application);
        //   const facilityEstablishmentFee = applicationToFacilityEstablishmentFee(this.application) ?? 0;
        //   const facilityEstablishmentFeePercentValue = applicationToFacilityEstablishmentFeePercent(this.application);
        //   const facilityEstablishmentFeePrecent = facilityEstablishmentFeePercentValue ? Number(facilityEstablishmentFeePercentValue.type) : 0;
        //   const docFeeSf = getDocFeeSf(this.application);
        //   const rateSf = getRateSf(this.application);
        //   const facilityEstablishmentFeePercentSf = getFacilityEstablishmentFeePercentSf(this.application);
        //   const facilityEstablishmentFeeSf = getFacilityEstablishmentFeeSf(this.application);
        //   const creditRateAdjustment = getCreditRateAdjustment(this.application);
        //   const brokerageAmount = applicationToBrokerageAmount(this.application);


        //   const rst = calculateBusinessOverdraftEstimation(date, {
        //     loanTermType: loanTermType,
        //     loanAmount: loanAmount,
        //     propertyOwner: propertyOwner,
        //     adverseOnFile: adverseOnFile,
        //     lowEquifaxScore: lowEquifaxScore,
        //     directorScore: directorScore,
        //     rateCard: ratecard,
        //     facilityEstablishmentFee: facilityEstablishmentFee,
        //     facilityEstablishmentFeePercent: facilityEstablishmentFeePrecent,
        //     docFeeSf: docFeeSf,
        //     rateSf: rateSf,
        //     brokerageAmount: brokerageAmount,
        //     facilityEstablishmentFeePercentSf: facilityEstablishmentFeePercentSf,
        //     facilityEstablishmentFeeSf: facilityEstablishmentFeeSf,
        //     creditRateAdjustment: creditRateAdjustment,
        //   });

        //   this.data = rst.term;
        //   this.rst = rst;
        // }


        // const loanTermValue = getLoanTerms(this.application);
        // const loanAmount = getLoanAmount(this.application);
        // const lowEquifaxScore = yesNoToBoolean(getEquifaxScoreAboveThresold(this.application));
        // const adverseOnFile = yesNoToBoolean(getAdverseOnFile(this.application));
        // const propertyOwner = yesNoToBoolean(getPropertyOwner(this.application));
        // const facilityEstablishmentFeePercent = this.application.PricingDetails.FacilityEstablishmentFeePercent
        // const facilityEstablishmentFee = getLoanAmount(this.application);
        // const directorScore = yesNoToBoolean(getDirectorScoreRate(this.application));
        // const brokerageAmount = getBrokerageAmount(this.application);
        // const docFeeSf = getDocFeeSf(this.application);
        // const rateSf = getRateSf(this.application);
        // const facilityEstablishmentFeePercentSf = getFacilityEstablishmentFeePercentSf(this.application);
        // const facilityEstablishmentFeeSf = getFacilityEstablishmentFeeSf(this.application);
        // const margin = getMargin(this.application);
        // const creditRateAdjustment = getCreditRateAdjustment(this.application);

        // const formData: FormDataForBusinessOverdraft = {
        //   facilityEstablishmentFee,
        //   facilityEstablishmentFeePercent,
        //   loanTerms: loanTermValue,
        //   propertyOwner,
        //   adverseOnFile,
        //   lowEquifaxScore: lowEquifaxScore,
        //   directorScore: directorScore,
        //   brokerageAmount,
        //   docFeeSf,
        //   facilityEstablishmentFeePercentSf,
        //   facilityEstablishmentFeeSf,
        //   margin,
        //   creditRateAdjustment,
        // };
        // const calculator = new AppCalculator();
        // const term = calculator.getCalcualteBusinessOverdraft(ratecard,formData)
        // this.data = term;
        // this.rst = { term: term }
      })).subscribe();


      this.subscriptions.push(this.businessOverdraftPricingEvents.subscribe((evt) => {
        switch(evt.type) {
          case "business-overdraft-pricing-saved": {
            this.data = evt.rst.term;
            this.rst = evt.rst;
            break;
          }
        }
      }));

    this.subscriptions.push(sub);
  }


  // NOTE: not used
  // isPanelExpanded(index: number): boolean {
  //   return this.expandedIndex === index;
  // }
  // expandPanel(index: number) {
  //   this.expandedIndex = index;
  // }

  recalculateEstimation() {
    if (this.ratecard) {
      const ratecard = this.ratecard;
      const date = moment().add(1, 'day');

      {
        const loanTermValue = applicationToLoanTerms(this.application);
        const loanTermType = loanTermValue?.type ?? null;
        const loanAmount = applicationToLoanAmount(this.application) ?? 0;
        const propertyOwner =  applicationToPropertyOwner(this.application);
        const adverseOnFile = applicationToAdverseOnFile(this.application);
        const lowEquifaxScore = applicationToEquifaxScoreAboveThreshold(this.application);
        const directorScore = applicationToDirectorScore(this.application);
        const facilityEstablishmentFee = applicationToFacilityEstablishmentFee(this.application) ?? 0;
        const facilityEstablishmentFeePercentValue = applicationToFacilityEstablishmentFeePercent(this.application);
        const facilityEstablishmentFeePrecent = facilityEstablishmentFeePercentValue ? Number(facilityEstablishmentFeePercentValue.type) : 0;
        const creditRateAdjustment = getCreditRateAdjustment(this.application);
        const brokerageAmount = applicationToBrokerageAmount(this.application) ?? undefined;
        const docFee = applicationToDocFee(this.application) as number;


        const rst = calculateBusinessOverdraftEstimation(date, {
          type: 'BusinessOverdraft',
          loanTermType: loanTermType,
          // loanAmount: loanAmount,
          propertyOwner: propertyOwner,
          adverseOnFile: adverseOnFile,
          lowEquifaxScore: lowEquifaxScore,
          directorScore: directorScore,
          rateCard: ratecard,
          facilityLimit: loanAmount,
          facilityEstablishmentFeePercent: facilityEstablishmentFeePrecent,
          brokerageAmount: brokerageAmount,
          docFee: docFee,
          creditRateAdjustment: creditRateAdjustment,
        });

        this.data = rst.term;
        this.rst = rst;
      }
    }
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  getActiveContract() {
    this.subscriptions.push(
      this.getActiveContractForApplicationFn(this.application.ApplicationId)
        .subscribe((r: ContractDetails | null) => {
          this.activeContract = r;
        })
    )
  }

  onViewReport(element: ApplicationWebService) {
    this.currentSection = 'credit-report';
    console.log(this.currentSection)
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: String(element.id),
    });
  }

  onLeaveReport(event: void) {
    this.currentSection = 'credit';
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  openGenerateContractDialog() {
    const ok = checkContractIndividuals(this.application, this.dialogService);
    if (ok) {
      this.subscriptions.push(
        this.dialogService.openGenerateContractDialog({
          sendContractEmailsFn: this.sendContractEmailsFn,
          getRatecardDetailsFn: this.getRatecardDetailsFn,
          getApplicationIndividualsFn: this.getApplicationIndividualsFn,
          generateContractFn: this.generateContractFn,
          deleteContractFileFn: this.deleteContractFileFn,
          application: (this.activatedRoute.snapshot.data as any).application,
          apiUrl: this.apiUrl,
          syncContractDetailsToSfFn: this.syncContractDetailsToSfFn,
          getApplicationOwnerFn: this.getApplicationOwnerFn,
          getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
          updateApplicationFn: this.updateApplicationFn,
          getOriginatorBusinessByIdFn: this.getOriginatorBusinessByIdFn,
        }).afterClosed().subscribe(() => {
          this.getActiveContract();
        })
      );
    }
  }

  openContractStatusDialog() {
    this.subscriptions.push(
      this.dialogService.openContractStatusDialog({
        getContractStatusFn: this.getContractStatusFn,
        applicationId: this.application.ApplicationId,
        declineContractFn: this.declineContractFn,
        envelopeId: this.activeContract?.envelopeId ?? "",
        resendContractFn: this.resendContractFn,
      }).afterClosed().subscribe(() => {
        this.getActiveContract();
      })
    )
  }


  onSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.dialogService.openSettleLoanDialog()
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.doSendDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      // check documents first
      this.subscriptions.push(
        combineLatest([
          this.listApplicationDocumentFn(this.application.ApplicationId),
          this.getApplicationByIdFn(this.application.ApplicationId)
        ]).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
          if (r) {
            let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
              app?.DocumentTypes?.length ? app?.DocumentTypes
              : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
              '',
            );
            docs = this.groupStandardCondition(docs, this.application);
            docs = this.displayRequiredDocumentsOnly(docs);
            const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
            const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
            const standardConditionDocs = docs.filter(d => d?.standardCondition);
            const pendingGroups = [
              ...standardDocs.filter(group => group.status === 'Pending'),
              ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
              ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
            ];
            console.log(pendingGroups);
            if (pendingGroups.length) {
              // do not allow settle loan
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: 'You cannot settle loan as you still have pending documents',
              }).afterClosed().subscribe();
            } else {
              this.doUpdateApplicationStage();
            }
          }
        })
      )
    }
  }

  doUpdateApplicationStage() {
    this.subscriptions.push(
      this.updateApplicationStageFn({
        salesforceId: this.application.AppInfoSalesforceID ?? "",
        stageName: 'QA'
      }).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response.status) {
          this.dialogService.successDialog({
            message: 'Success',
            subMessage: "Application sent to QA queue",
          }).afterClosed().subscribe();
        } else {
          this.dialogService.openAlertDialog({
            message: 'Error',
            subMessage: response.message,
          }).afterClosed().subscribe();
        }
      })
    )
  }

  doSendDisbursementToSf() {
    this.subscriptions.push(
      this.getDisbursementByApplicationIdFn(this.application.ApplicationId).pipe(
        switchMap((disbursements: Disbursement[]) => {
          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }
          return this.settleLoanFn({
            assets: [],
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }
      })
    )
  }

  private displayRequiredDocumentsOnly(docs: GroupedDocument[]) {
    return docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })
  }

  onBusinessOverdraftPricingEvents($event: BusinessOverdraftPricingComponentEvent) {
    this.businessOverdraftPricingEvents.emit($event)
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }

  refreshCreditTab(event: Application) {
    this.refreshCreditTabEvent.emit(event);
  }
}
