<div class="new-report-dialog">
  <div mat-dialog-title>
    <div class="mat-subtitle-1 header">Report List</div>
  </div>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <table mat-table
          [dataSource]="dataSource"
          multiTemplateDataRows>
          <ng-container matColumnDef="dir">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              @if (isTableRowExpanded(element)) {
                <span class="mdi mdi-chevron-up"></span>
              }
              @if (!isTableRowExpanded(element)) {
                <span class="mdi mdi-chevron-down"></span>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
              {{element.description}}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="mdi mdi-database-export-outline cursor-pointer" 
                    (click)="generateReport($event, element)" 
                    [matTooltip]="getActionButtonText(element)">
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="element-detail" [@detailExpand]="isTableRowExpanded(element) ? 'expanded' : 'collapsed'">
                @if (element.type == 'reporting-banking-transactions') {
                  <ng-container *ngTemplateOutlet="reportingBankingTransactions; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-brokerage-transactions') {
                  <ng-container *ngTemplateOutlet="reportingBrokerageTransactions; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-accounting-events') {
                  <ng-container *ngTemplateOutlet="reportingPismoAccountingEvents; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-accounts-balances') {
                  <ng-container *ngTemplateOutlet="reportingPismoAccountsBalances; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-accounts-limits') {
                  <ng-container *ngTemplateOutlet="reportingPismoAccountsLimits; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-transactions-balance') {
                  <ng-container *ngTemplateOutlet="reportingPismoTransactionsBalances; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-transactions-denied') {
                  <ng-container *ngTemplateOutlet="reportingPismoTransactionsDenied; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-portfolio-balance') {
                  <ng-container *ngTemplateOutlet="reportingPismoPortfolioBalance; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-accounting-raw') {
                  <ng-container *ngTemplateOutlet="reportingPismoRawAccounting; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-accounting-aggregated') {
                  <ng-container *ngTemplateOutlet="reportingPismoAggregatedAccounting; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-internal-accounts') {
                  <ng-container *ngTemplateOutlet="reportingPismoInternalAccounts; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-arrears') {
                  <ng-container *ngTemplateOutlet="reportingPismoArrears; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-authorization-clearing') {
                  <ng-container *ngTemplateOutlet="reportingAuthorizationClearing; context: {element}"></ng-container>
                }
                @if (element.type == 'reporting-pismo-applepay') {
                  <ng-container *ngTemplateOutlet="reportingPismoApplepay; context: {element}"></ng-container>
                }
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay"
            [class.expanded-row]="isTableRowExpanded(element)"
            (click)="expandTableRow(element)"
            class="cursor-pointer">
          </tr>
          <tr class="detail-row"
            mat-row
            *matRowDef="let element; columns: ['expandedDetail']"
            [ngClass]="{'detail-row-expanded': isTableRowExpanded(element)}"
            [ngStyle]="{'display': isTableRowExpanded(element) ? null : 'none'} ">
          </tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="row pl-4 pr-4 mb-4 width-100">
      <div class="col" fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button color="primary" class="mr-3" (click)="onClose()">Close</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>

<!-- Reporting: report list -->
<ng-template #reportingReportList let-element="element">
  <p>{{element.description}}</p>
  <ul>
    <li>Request Accounting Aggregated Report </li>
  </ul>
</ng-template>

<!-- Reporting: past request report -->
<ng-template #reportingPastRequest let-element="element">
  <p>{{element.description}}</p>
  <ul>
    <li>View all past requested reports </li>
  </ul>
</ng-template>

<!-- Reporting: banking transactions report -->
<ng-template #reportingBankingTransactions let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all banking transactions (Transaction Type, Amount, Date, Status, Pismo Account No, Pismo Customer No, Last Updated Time etc.)</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: brokerage transactions report -->
<ng-template #reportingBrokerageTransactions let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all brokerage transactions (Transaction Type, Amount, Date, Status, Pismo Account No, Pismo Customer No, Last Updated Time etc.)</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo accounting events report -->
<ng-template #reportingPismoAccountingEvents let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all accounting events from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo accounts balances report -->
<ng-template #reportingPismoAccountsBalances let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all accounts balances from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo accounts limits report -->
<ng-template #reportingPismoAccountsLimits let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all accounts limits from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo transactions balance report -->
<ng-template #reportingPismoTransactionsBalances let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all transactions balance from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo transactions denied report -->
<ng-template #reportingPismoTransactionsDenied let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all transactions denied from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo portfolio balance report -->
<ng-template #reportingPismoPortfolioBalance let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all portfolio balance from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo raw accounting report -->
<ng-template #reportingPismoRawAccounting let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all raw accounting report from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo aggregated accounting report -->
<ng-template #reportingPismoAggregatedAccounting let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all aggregated accounting report from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo internal accounts -->
<ng-template #reportingPismoInternalAccounts let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all internal accounts transactions from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>


<!-- Reporting: pismo arrears report -->
<ng-template #reportingPismoArrears let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all arrears from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: Mastercard transaction report -->
<ng-template #reportingAuthorizationClearing let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all Mastercard transactions</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo applepay report -->
<ng-template #reportingPismoApplepay let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>List all activated apple pay tokens within the selected date range</li>
    <li>List all apple pay transactions within the selected date range</li>
    <li>Generates two reports in csv files for the selected period</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>
