import {
  AccessLevel,
  Customer,
  CustomerAccessLevel,
  LoginStatus,
  MobilePlatformType,
  NotNullable,
  PortalLoginUser,
  User,
  UserandCustomerPayway,
  UserandPriviledges
} from './types.domain';
import {PismoAccountMappingWithCustomer, PismoCustomerMapping, PismoIUser} from './pismo.domain';
import { Observable } from 'rxjs';
import { Address2ComponentValue } from './component/address2-component.domain';
import {DigitalIdResponse, DigitalIdResult, DigitalIdResultError} from './digital-id-service.domain';

// export type Priviledge = 'admin' | 'analyst' | 'companyadmin' | 'customer' | 'externalbroker' | 'internalbroker' | 'lg';
// export type Priviledges = Priviledge[];


export type GetUserFn = (userId: number, includesDisable:boolean) => Observable<UserandPriviledges|null>;
export type ApproveOrRejectUserFn = (userId: number, approve: boolean) => Observable<void>;
export type UpdateUserFn = (userId: number, data: UpdateUserData) => Observable<UserandPriviledges| null>;
export type DisableUsersFn = (userIds: number[]) => Observable<void>;
export type EnableUsersFn = (userIds: number[]) => Observable<void>;
export type LogoutFn = () => Observable<LogoutPayload>;

export type GetUserByEmailFn = (email: string) => Observable<UserandCustomerPayway>;

export type PismoAccountMappingWithCustomerListFn = () => Observable<PismoAccountMappingWithCustomer[]>;

export interface GetUser extends User {
  OriginatorBusinessName?: string,
  CustomerBusinessName?: string,
}

export interface JwtTokenPayload {
  id: number,
  email: string,
  refreshKey: string,
  accesslevel: AccessLevel,
  iat?: number,
  exp?: number,
  AppId?: string,
}

export const HTTP_X_GROW_AUTH_HEADER = `x-grow-auth`;
export type HttpXGrowAuthHeaderValue = 'jwt-expired' | 'invalid-expo-push-notification-token' | 'account-disabled' | 'not-overdraft-customer' | 'account-locked' | 'invalid-username-password' | 'jwt-invalid';



export interface RefreshTokenPayload {
  accessToken: string,
  refreshToken: string,
  salesforceid: string | null,
  user: PortalLoginUser,
  pismoCustomerMappings: PismoCustomerMapping[];
}

export interface LoginPayload {
  type: 'login',
  user: PortalLoginUser | null,
  salesforceid: string | null,
  accessToken: string | null,
  refreshToken: string | null,
  status: LoginStatus,
};

export interface MobileLoginPayload extends LoginPayload {
  pismoCustomerMappings: PismoCustomerMapping[];
  storeAppIdSuccess?: boolean;
}

export interface MobileLoginBody {
  Email: string;
  Password: string;
  LoginExpoPushNotificationToken?: string;
  DeviceDescription: string;
  AppId: string | null;
  IPAddress: string;
  AppVersion?: string | null;
  platform?: MobilePlatformType | null;
}

export interface PismoLoginPayload {
  status: LoginStatus,
  type: 'pismo-login',
  user: User | null,
  pismoUser: PismoIUser | null,
  salesforceid?: string | null,
  accessToken?: string | null,
  refreshToken: string | null,
}

export interface LogoutPayload {
  type: 'logout',
  user?: User,
  // accessToken?: string,
  // refreshToken?: string,
};

export interface CustomerUser extends Customer, User {
  CustomerId: number;
};


export interface CreateCustomerUserData { // NOTE: different from CustomerUser interface above
  GivenName: string,
  FamilyName: string,
  customerAccessLevel: CustomerAccessLevel,
  RoleAccess: RoleAccess,
  Email: string,
  CustomerId: number,
  IsCustomer: boolean,
  // Name: string,
  MiddleName:string,
  MobileNumber: string,
  Password?: string,
  dob?: string, // YYYY-MM-DD
  ResidentialAddress?: Address2ComponentValue,
  PostalAddress?: Address2ComponentValue,
  kycStatus?: DigitalIdResult['status'],
  kycVerificationResult?: DigitalIdResponse,
  kycVerificationError?: string,
};


export interface RoleAccess {
  isAllTransaction:boolean,
  isThirdPartyPayment:boolean,
  isBpay:boolean
}
export interface CreateBrokerUserData {
  GivenName: string,
  FamilyName: string,
  AccessLevel: AccessLevel,
  Email: string,
  // AggregatorId: number,
  OriginatorBusinessId: number,
  // Name: string,
  MobileNumber: string,
  Password?: string,
  is2FA?: boolean,
};

export interface UpdateUserData {
  GivenName?: string,
  FamilyName?: string,
  MiddleName?: string,
  dob?: string, // YYYY-MM-DD
  ResidentialAddress?: Address2ComponentValue,
  PostalAddress?: Address2ComponentValue,
  OriginatorBusinessId?: number | null,
  // AggregatorId?: number | null,
  Password?: string,
  // PhoneNumber?: string,
  Email?: string,
  AccessLevel?: AccessLevel,
  customerAccessLevel?: CustomerAccessLevel,
  RoleAccess?: RoleAccess | null,
  IsCustomer?: boolean,
  CustomerId?: number,
  MobileNumber?: string | null
  kycStatus?: DigitalIdResult['status'],
  kycVerificationResult?: DigitalIdResponse,
  kycVerificationError?: string,
  PismoPhoneId?: number,
  driverLicenceNumber?: string,
  driverCardNumber?: string,
  driverCardExpiryDate?: string,
  is2FA?: boolean
}

export interface ApproveRejectUserData {
  approval: boolean,
  rejected: boolean,
  // aggregatorId?: number,
  originatorBusinessId?: number,
  givenName?: string,
  familyName?: string,
  email?: string,
  // phoneNumber?: string,
  mobileNumber?: string,
  accessLevel?: AccessLevel,
  Password?: string | null,
}

// NOTE: not used
// export interface UpdateProfileData {
//
// }

export interface ForgotPasswordFormData {
  MobileNumber: string,
  Email: string,
  GivenName: string,
  FamilyName: string
}

export interface UpdateUserAddressData {
  address?: {
    unitNumber?: string,  // optional
    streetNumber: string,
    streetName: string,
    streetType: string,
    locality: string,  // suburb
    region: string,  // state
    postCode: string,
  },
  postalAddress?: {  // postal address is optional (if do not exists will be the same as address)
    unitNumber?: string,  // optional
    streetNumber: string,
    streetName: string,
    streetType: string,
    locality: string,  // suburb
    region: string,  // state
    postCode: string,
    address?: string,
  }
}


