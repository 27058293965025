<div class="contract-status-dialog">
  <mat-dialog-content>
    <div class="header mat-subtitle-1">
      Signer Status
    </div>

    <div class="body">
      <div class="row">
        <div class="col-12">
          <table mat-table [dataSource]="[0]" class="width-100 signer-table">
            <ng-container matColumnDef="envelopeId">
              <th mat-header-cell *matHeaderCellDef>Docusign Envelope ID</th>
              <td mat-cell *matCellDef="let element">{{envelopeId}}</td>
            </ng-container>
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>Sent By</th>
              <td mat-cell *matCellDef="let element">{{userName}}</td>
            </ng-container>
            <ng-container matColumnDef="createdTime">
              <th mat-header-cell *matHeaderCellDef>Sent Time</th>
              <td mat-cell *matCellDef="let element">{{getContractSentTime()}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['envelopeId', 'userName', 'createdTime']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['envelopeId', 'userName', 'createdTime']"></tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table mat-table [dataSource]="dataSource" class="width-100 signer-table">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <tag-box [state]="getSignerStatus(element)"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef>Last Updated Time</th>
              <td mat-cell *matCellDef="let element">
                {{getLastUpdatedTime(element)}}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                @if(element.status !== 'signed' && element.status !== 'completed') {
                  <span class="mdi mdi-email-sync-outline cursor-pointer icon-margin date-text" matTooltip="Resend" (click)="resend(element)"></span>
                }
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
      <div class="footer col-12" fxLayoutGap="10px">
        @for(item of presentDocusignStatus; track item){
        <span class="date-text line-height">
          <b><sup>*</sup>{{getStatusInfo(item).title}}:
            {{getStatusInfo(item).description}}
          </b>
        </span><br />
        }
      </div>
    </div>

    <div class="footer" fxLayoutGap="10px" fxLayoutAlign="space-between center">
      <div>
        @if (envelopeId) {
          <button color="primary"
            fxFlexAlign="start"
            type="submit"
            (click)="declineContract()"
            mat-stroked-button>
            Cancel Contract
          </button>
        }
      </div>
      <button color="primary"
        fxFlexAlign="end"
        type="submit"
        (click)="close()"
        mat-flat-button>
        Close
      </button>
    </div>
  </mat-dialog-content>
</div>
