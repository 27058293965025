import {Component, OnInit} from '@angular/core';
import {
  filterDocumentworklist,
  getUser,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationService} from '../../service/application.service';
import {loadingFor} from '@ngneat/loadoff';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {
  compare,
  DocumentWorklist, DocumentWorklistCategory,
  DocumentWorklists,
  DocumentWorklistTableData,
  PayloadApiResponse, SideMenuComponentTreeNodeType,
  User, DEFAULT_LIMIT, DEFAULT_OFFSET,
} from '@portal-workspace/grow-shared-library';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { Sort, MatSortModule } from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {ActivatedRoute, Data, Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {navigationUrlForApplication} from '../../service/navigation-urls';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { TagBoxComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';

export class InternalDataSource extends DataSource<DocumentWorklist> {

  subject = new BehaviorSubject<DocumentWorklist[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<DocumentWorklist[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(worklists: DocumentWorklist[]) {
    this.subject.next(worklists);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './document-worklist.page.html',
    styleUrls: ['.//document-worklist.page.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, FlexModule, MatCheckboxModule, NgClass, ExtendedModule, MatTableModule, MatSortModule, TagBoxComponent, MatMenuModule, MatDividerModule, CustomPaginatorComponent, AsyncPipe, DatePipe]
})
export class DocumentWorklistPage implements OnInit {
  loader = loadingFor('tableLoading');
  subscriptions: Subscription[] = [];
  worklist: DocumentWorklists = [];
  filteredWorklist: DocumentWorklists = [];
  displayedWorklist: DocumentWorklists = [];
  dataSource = new InternalDataSource();
  formControlSearch!: FormControl<string | null>;
  formControlFilter!: FormControl<DocumentWorklistCategory | null>;
  formControlAutoAssignment: FormControl<boolean | null>;
  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  sorts: {prop: string, dir: 'asc' | 'desc'} | null = null;
  user = getUser() as User;
  autoAssignmentKey = 'document-worklist-auto-assignment-key';
  ENABLE_AUTO_ASSIGNMENT = 'enable-auto-assignment';

  displayColumns = ['applicationId', 'createdTime', 'name', 'stage', 'userName', 'actions'];
  filterDocumentworklist = filterDocumentworklist;
  menuItemId!: SideMenuComponentTreeNodeType;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private router: Router,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private applicationDialogService: ApplicationDialogService
  ) {
    this.formControlSearch = formBuilder.control(null);
    this.formControlFilter = formBuilder.control("All" as DocumentWorklistCategory);
    this.formControlAutoAssignment = formBuilder.control(false);
  }

  ngOnInit() {
    setupUntilDestroy(this);

    this.subscriptions.push(
      this.route.data.subscribe((data: Data) => {
        console.log('here data', data)
        this.menuItemId = (data as { menuItemId: SideMenuComponentTreeNodeType }).menuItemId;
        this.reload();
      })
    )

    const autoAssignment = this.cookie.get(this.autoAssignmentKey);
    if (autoAssignment && autoAssignment === this.ENABLE_AUTO_ASSIGNMENT) {
      this.formControlAutoAssignment.setValue(true);
    } else {
      this.formControlAutoAssignment.setValue(false);
    }

    // on detect search inputs changes
    this.subscriptions.push(
      this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((r: string  | null) => {
          this.onSearch(r);
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.formControlAutoAssignment.valueChanges.pipe(
        tap((r: boolean | null) => {
          if (r) {
            this.cookie.set(this.autoAssignmentKey, this.ENABLE_AUTO_ASSIGNMENT);
          } else {
            this.cookie.set(this.autoAssignmentKey, '');
          }
        })
      ).subscribe()
    )
  }

  private reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  private onSearch(r: string | null) {
    if (r) {
      const text = r.trim().toLowerCase();
      this.filteredWorklist = this.worklist.filter(d => {
        const identifier = [d.brokerAppId, d.name, d.userName].join(' ').toLowerCase();
        return identifier.includes(text);
      });
    } else {
      this.filteredWorklist = this.worklist;
    }
    this.total = this.filteredWorklist.length;
    this.reset();
    this.updatedDisplayedData();
  }

  reload(triggerSearch = false) {
    this.subscriptions.push(
      this.applicationService.getDocumentWorklist().pipe(
        this.loader.tableLoading.track(),
        tap((r: PayloadApiResponse<DocumentWorklists>) => {
          this.worklist = filterDocumentworklist(this.worklistFilter, r.payload);
          this.filteredWorklist = this.worklist;
          this.total = this.filteredWorklist?.length ?? 0;
          this.updatedDisplayedData();
          console.log(this.worklist)
        })
      ).subscribe(
        () => {
          if (triggerSearch) {
             this.onSearch(this.formControlSearch.value);
          }
        }
      )
    );
  }

  get worklistFilter(): DocumentWorklistCategory {
    switch (this.menuItemId) {
      case 'client-services-worklist':
        return 'client-services';
      case 'credit-worklist':
        return 'credit';
      case 'settlements-worklist':
        return 'settlements';
      default:
        return 'all'
    }
  }

  // onFilterWorklist(filter: DocumentWorklistCategory | null) {
  //   this.filteredWorklist = filterDocumentworklist(filter ?? "All", this.worklist);
  //   this.updatedDisplayedData();
  // }

  updatedDisplayedData() {
    this.displayedWorklist = this.filteredWorklist.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
    this.dataSource.update(this.displayedWorklist);
  }

  onSortData(sort: Sort) {
    const data = this.filteredWorklist.slice();

    if (sort.direction && sort.active) {
      this.filteredWorklist = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        const prop = sort.active as "brokerAppId" | "name" | "stage" | "userName" | "createdTime";

        if (prop === "createdTime") {
          return compare(a.createdTime.toLocaleString(), b.createdTime.toLocaleString(), isAsc);
        } else if (["brokerAppId", "name", "stage", "userName"].includes(prop)) {
          return compare(a[prop] ?? '', b[prop] ?? '', isAsc);
        }
        return 0;
      });

      this.updatedDisplayedData();
    }
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.updatedDisplayedData();
  }

  async autoAssign(data: DocumentWorklist) {
    if (this.formControlAutoAssignment.value) {
      this.takeOwnership(data);
    }
    await this.router.navigate(navigationUrlForApplication(String(data.applicationId), 'documents'));
  }

  takeOwnership(data: DocumentWorklist) {
    const tableData: DocumentWorklistTableData = {
      documentWorklistId: data.documentWorklistId,
      applicationId: data.applicationId,
      createdTime: data.createdTime,
      userId: this.user?.UserId,
      status: data.status,
      entityName: data.name,
      brokerAppId: data.brokerAppId
    };

    this.subscriptions.push(
      this.applicationService.updateDocumentWorklist(tableData)
        .subscribe(() => {
          this.reload(true);
        })
    )
  }

  onAssign(data: DocumentWorklist){
    console.log('will fetch credit user data', data);
    this.applicationDialogService.openCreditUserListDialog({
      // documentWorklist: data
      getActiveCreditUsersFn: this.applicationService.getActiveCreditUsers
    })
        .afterClosed()
        .pipe(
          tap(async (r) => {
            console.log('r: ', r);
            if(r && r.userId){
              const tableData: DocumentWorklistTableData = {
                documentWorklistId: data.documentWorklistId,
                applicationId: data.applicationId,
                createdTime: data.createdTime,
                userId: r.userId,
                status: data.status,
                entityName: data.name,
                brokerAppId: data.brokerAppId
              };
              console.log('tableData: ', tableData);
              this.subscriptions.push(
                this.applicationService.updateDocumentWorklist(tableData)
                  .subscribe(() => {
                    this.reload(true);
                  })
              )
            }
          })
        ).subscribe();
  }

  unassign(data: DocumentWorklist) {
    const tableData: DocumentWorklistTableData = {
      documentWorklistId: data.documentWorklistId,
      applicationId: data.applicationId,
      createdTime: data.createdTime,
      userId: null,
      status: data.status,
      entityName: data.name,
      brokerAppId: data.brokerAppId
    };

    this.subscriptions.push(
      this.applicationService.updateDocumentWorklist(tableData)
        .subscribe(() => {
          this.reload(true);
        })
    )
  }

  complete(data: DocumentWorklist) {
    this.subscriptions.push(
      this.applicationService.completeDocumentWorklist(data.applicationId)
        .subscribe(() => {
          this.reload(true);
        })
    )
  }
}
