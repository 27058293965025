import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationService, } from './application.service';
import { ApplicationTypes, RatecardDetails } from '@portal-workspace/grow-shared-library';
import { Observable, of } from 'rxjs';
import { User } from '@portal-workspace/grow-shared-library';
import { getUser } from '@portal-workspace/grow-ui-library';
import { catchError, map } from 'rxjs/operators';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { Router } from '@angular/router';
import { navigationUrlForNewApplication } from './navigation-urls';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { inject } from '@angular/core';

export abstract class AbstractRatecardResolver  {

  hotToastService: PortalHotToastService = inject(PortalHotToastService);

  constructor(private applicationService: ApplicationService,
    private dialogService: ApplicationDialogService,
    private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RatecardDetails | null> | Promise<RatecardDetails | null> | RatecardDetails | null {
    const user: User | null = getUser();
    if (user) {
      // if (user.OriginatorBusinessId !== null || user.AggregatorId !== null) {
      if (user.OriginatorBusinessId !== null) {
        return this.applicationService.getRatecardDetails(user.UserId, this.type()).pipe(
          this.hotToastService.spinnerObservable(),
          map(r => {
            console.log(`******* ratecard ${this.type()} resolver`, r.payload);
            return r.payload
          }),
        );
      } else {
        return this.applicationService.getDefaultRatecardDetails(this.type()).pipe(
          map(r => {
            console.log(`******* ratecard ${this.type()} resolver`, r.payload);
            return r.payload
          }),
        );
        // this.dialogService.openAlertDialog({
        //   message: 'Ratecard not found',
        //   subMessage: 'There is No associated company linked to your account, Please contact Dynamoney for more details',
        // });
        // this.router.navigateByUrl(navigationUrlForNewApplication());
      }
    }
    return null;
  }

  abstract type(): ApplicationTypes;

}
