import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { Accreditation, AccreditationCreditFlow, AccreditationCreditFlowNodeDetailTableData, AddCreditAlertFn, Application, ApplicationCreditFlow, ApplicationCreditFlowNodeDetail, ApplicationCreditFlowNodeDetailTableData, CreditAlert, DEFAULT_LIMIT, DEFAULT_OFFSET, GetCreditAlertsFn, GetCreditFlowsFn, UpdateCreditAlertFn, UpdateCreditFlowFn } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout';
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DatePipe, AsyncPipe, } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { TagBoxComponent } from '../message-box/tag-box.component';

@Component({
    selector: 'accreditation-alerts',
    templateUrl: './accreditation-alerts.component.html',
    styleUrls: ['./accreditation-alerts.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
      FlexModule,
      CustomContentLoaderComponent,
      CustomPaginatorComponent,
      MatSortModule,
      DatePipe,
      AsyncPipe,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      TagBoxComponent,
    ]
})
export class AccreditationAlertsComponent implements OnInit, OnChanges {
  @Input({required: true}) creditFlowResult!: AccreditationCreditFlow[];
  @Input({required: true}) accreditation!: Accreditation;

  subscriptions: Subscription[] = [];
  moment = moment;
  formControlSearch!: FormControl<string | null>;

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: AccreditationCreditFlowNodeDetailTableData[] = [];
  columnsToDisplay = ['creditFlowNodeName', 'runId', 'alertCode', 'alert', 'alertStatus'];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reload();
  }

  reload() {
    this.dataSource = this.creditFlowResult.map(result => {
      return result.flow.map(alert => { 
        return {
          ...alert,
          runId: result.id,
          lastUpdatedTime: result.lastUpdatedTime,
        } as AccreditationCreditFlowNodeDetailTableData;
      })
    }).reduce((a, b) => [...a, ...b],[]).filter(r => r.alertStatus);
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'alert': return 'Description';
      case 'alertStatus': return 'Status';
      case 'runId': return 'Run ID';
      case 'alertCode': return 'Code';
      case 'creditFlowNodeName': return 'Node'
      default: return column;
    }
  }
}
