
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy } from '@ngneat/until-destroy';

export type ApplicationsGraphData = {
  id: string;
  segmentStart: moment.Moment;
  segmentEnd: moment.Moment;
  label: string;
  underReview: number;
  inSettlement: number;
  closedWon: number;
  closedLost: number;
  approved: number;
  total: number;
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'applications-graph',
    templateUrl: './applications-graph.html',
    styleUrls: ['./applications-graph.scss'],
    standalone: true,
    imports: [
      MatFormFieldModule,
      MatSelectModule, 
      FormsModule,
      ReactiveFormsModule,
      MatIconModule,
      MatMenuModule,
      FlexModule,
      MatCardModule,
      MatTooltipModule,
    ],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ApplicationsGraphComponent),
        multi: true
      }
    ]
})
export class ApplicationsGraphComponent implements OnInit, ControlValueAccessor {

  @Input() formControl!: FormControl<ApplicationsGraphData[] | null>;
  @Output() onColumnClick: EventEmitter<ApplicationsGraphData> = new EventEmitter<ApplicationsGraphData>();

  data: ApplicationsGraphData[] = [];
  biggest: number = 0;

  // These are used for implementing ControlValueAccessor
  private onChange: (value: ApplicationsGraphData[] | null) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnInit(): void {
    this.biggest = Math.max(...this.data.map(d => d.total));
    this.formControl.markAsTouched();
    this.formControl.valueChanges.subscribe(value => {
      if (value) {
        this.data = value;
        this.biggest = Math.max(...this.data.map(d => d.total));
      }
    });
  }

  // ControlValueAccessor methods
  writeValue(value: ApplicationsGraphData[] | null): void {
    if (value) {
      this.data = value;
      this.biggest = Math.max(...this.data.map(d => d.total));
    }
  }

  registerOnChange(fn: (value: ApplicationsGraphData[] | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Call this method to notify the parent component when the value changes.
  onValueChange(): void {
    this.onChange(this.data);
  }

  getTooltip(column: ApplicationsGraphData): string {
    return `Closed Lost: ${column.closedLost}\nClosed Won: ${column.closedWon}\nIn Settlement: ${column.inSettlement}\nApproved: ${column.approved}\nUnder Review: ${column.underReview}\nTotal: ${column.total}`;
  }

  _onColumnClick(column: ApplicationsGraphData): void {
    if (column.segmentEnd.diff(column.segmentStart, 'minutes') >= 1439) { // 1439 minutes = 23 hours 59 minutes
      this.onColumnClick.emit(column);
    }
  }
}
