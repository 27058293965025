<div class="portal-theme-component">
  @if (type == 'short') {
    <div
      [matTooltip]="'Swap between light and dark mode'"
      fxLayout="row" fxLayoutAlign="start center" [fxLayoutGap]="pxToRem(1)">
      @if(formControl.value == true) {
        <span class="icon mdi mdi-moon-waning-crescent"></span>
      }
      @if(formControl.value == false) {
        <span class="icon mdi mdi-white-balance-sunny"></span>
      }
      <mat-slide-toggle [formControl]="formControl" />
    </div>
  } @else {
    <div
      [matTooltip]="'Swap between light and dark mode'"
      fxLayout="row" fxLayoutAlign="start center" [fxLayoutGap]="pxToRem(17)">
      @if(formControl.value == true) {
        <span class="icon mdi mdi-moon-waning-crescent"></span>
      }
      @if(formControl.value == false) {
        <span class="icon mdi mdi-white-balance-sunny"></span>
      }
      <div fxLayout="row" fxLayoutAlign="start center" [fxLayoutGap]="pxToRem(5)">
        <span>Change Mode</span>
        <mat-slide-toggle [formControl]="formControl" />
      </div>
    </div>
  }
</div>
