import {AfterViewInit, Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {
  ChangePortalThemeFn,
  GetAllNotificationsFn,
  HeartbeatEvent,
  PortalLoginUser, PortalThemeServiceEvent,
  SideMenuComponentFlatTreeNode,
  SideMenuComponentMenuType,
  WhitelabelEntityDetails, WhitelabelServiceEvent
} from '@portal-workspace/grow-shared-library';
import { ActivationStart, Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import {
  navigationUrlForAggregators,
  navigationUrlForApplications,
  navigationUrlForAssetFinance,
  navigationUrlForAssetFinanceCalculator,
  navigationUrlForBusinessFinance,
  navigationUrlForBusinessOverdraft,
  navigationUrlForCommercialFinance,
  navigationUrlForCompanies,
  navigationUrlForConsumerAssetFinance,
  navigationUrlForCustomers,
  navigationUrlForInsurancePremium,
  navigationUrlForLgRebateCalculator,
  navigationUrlForLogin,
  navigationUrlForManualPayment,
  navigationUrlForNotifications,
  navigationUrlForPendingApprovals,
  navigationUrlForProfile,
  navigationUrlForRateCards,
  navigationUrlForSetMaintenance,
  navigationUrlForNewApplication,
  navigationUrlForUnsingedInvoices,
  navigationUrlForUnverifiedUsers,
  navigationUrlForUsers,
  navigationUrlForOauth,
  navigationUrlForConsumerFinance,
  navigationUrlForNotificationsManagement,
  navigationUrlForFAQ,
  navigationUrlForAuditLogs,
  navigationUrlForDashboard,
  navigationUrlForBankStatementsAnalysis,
  navigationUrlForBankStatementsStatus,
  navigationUrlForAccreditation,
  navigationUrlForDocuments,
  navigationUrlForDocumentWorklist,
  navigationUrlForReporting,
  navigationUrlForCreditWorklist,
  navigationUrlForClientServicesWorklist,
  navigationUrlForAccreditationListing,
  navigationUrlForPromotion,
  navigationUrlForSettlementsWorklist,
  navigationUrlForBanking,
  navigationUrlForRedbook,
  navigationUrlForTransactions,
  navigationUrlForPismoAccounts,
  navigationUrlForBusinessLoansCalculator,
  navigationUrlForCreditManagement, navigationUrlForEditCreditFlow,
  navigationUrlForOverdraftCalculator,
  navigationUrlForBusinessAndOverdraftBorrowingEstimator,
  navigationUrlForBsaSettings,
  navigationUrlForSendPrivacyConsent,
  navigationUrlForPendingApprovalTransactions,
  navigationUrlForOverdraftProduct,
  navigationUrlForWhitelabelSettings,
} from '../../service/navigation-urls';
import {
  setBrowseHeightAsCssVariable,
  setupUntilDestroy,
  ToolbarComponentEvent
} from '@portal-workspace/grow-ui-library';
import { AuthService } from '../../service/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import { getUser } from '@portal-workspace/grow-ui-library';
import { NotificationService } from '../../service/notification.service';
import { environment } from '../../../environments/environment';
import { LayoutService } from '../../service/layout.service';
import { ErrorNotification2 } from '@portal-workspace/grow-ui-library';
import { SideMenuComponent } from '@portal-workspace/grow-ui-library';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import { TopMenuComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import {BreakpointService} from "@portal-workspace/grow-ui-library";
import {MatIconModule} from "@angular/material/icon";
import { NgxIndexedDBService, ObjectStoreMeta } from 'ngx-indexed-db';
import {MatTooltipModule} from "@angular/material/tooltip";
import {WhitelabelService} from "../../service/whitelabel.service";
import {PortalThemeService} from "../../service/portal-theme.service";
import { HeartbeatService } from '../../service/heartbeat.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './sidemenu.layout.html',
    styleUrls: ['./sidemenu.layout.scss'],
    providers: [
        { provide: LayoutService, useClass: LayoutService },
    ],
    standalone: true,
    imports: [FlexModule, TopMenuComponent, MatSidenavModule, SideMenuComponent, ErrorNotification2, RouterOutlet, MatIconModule, MatTooltipModule]
})
export class SidemenuLayout implements OnInit, AfterViewInit {
  mode: 'Mobile' | 'Desktop' | null  = null;

  unreadNotificationsCountObservable!: Observable<number>;
  whitelevelServiceEventObserver!: Observable<WhitelabelServiceEvent>;

  changePortalThemeFn!: ChangePortalThemeFn;
  portalThemeObservable!: Observable<PortalThemeServiceEvent>;
  heartbeatObservable!: Observable<HeartbeatEvent>;
  getAllNotificationsFn!: GetAllNotificationsFn;
  subscriptions: Subscription[] = [];
  sideNavGap = 100;

  gitTagOrVersion = environment.gitTag ?? environment.gitHash;

  menuItemId: any = '';

  breakpointService!:BreakpointService;
  portalThemeService!: PortalThemeService;
  whitelabelService!: WhitelabelService;
  heartbeatService!: HeartbeatService;



  @ViewChild('sidenav') matSidenav!: MatSidenav;

  user: PortalLoginUser | null = null;

  constructor(
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              private dbService: NgxIndexedDBService,
              private authService: AuthService) {
    this.breakpointService = inject(BreakpointService);
    this.whitelabelService = inject(WhitelabelService);
    this.portalThemeService = inject(PortalThemeService);
    this.heartbeatService = inject(HeartbeatService);
    this.getAllNotificationsFn = this.notificationService.getAllNotifications;
  }

  async onSidemenuEvents($event: SideMenuComponentFlatTreeNode) {
    switch($event.treeNode.type) {
      case 'lg-rebate-calculator':
        await this.router.navigate(navigationUrlForLgRebateCalculator());
        this.closeSideNav();
        break;
      case 'asset-finance-calculator':
        await this.router.navigate(navigationUrlForAssetFinanceCalculator());
        this.closeSideNav();
        break;
      case 'business-loans-calculator':
        await this.router.navigate(navigationUrlForBusinessLoansCalculator());
        this.closeSideNav();
        break;
      case 'business-overdraft-calculator':
        await this.router.navigate(navigationUrlForOverdraftCalculator());
        this.closeSideNav();
        break;
      // case 'asset-finance':
      //   await this.router.navigate(navigationUrlForAssetFinance());
      //   this.closeSideNav();
      //   break;

      // case 'business-finance':
      //   await this.router.navigate(navigationUrlForBusinessFinance());
      //   this.closeSideNav();
      //   break;
      // case 'business-overdraft':
      //   await this.router.navigate(navigationUrlForBusinessOverdraft());
      //   this.closeSideNav();
      //   break;
      // case 'insurance-premium':
      //   await this.router.navigate(navigationUrlForInsurancePremium());
      //   this.closeSideNav();
      //   break;
      // case 'consumer-asset-finance':
      //   await this.router.navigate(navigationUrlForConsumerAssetFinance());
      //   this.closeSideNav();
      //   break;
      // case 'commercial-finance':
      //   await this.router.navigate(navigationUrlForCommercialFinance());
      //   this.closeSideNav();
      //   break;
      case 'applications':
        await this.router.navigate(navigationUrlForApplications());
        this.closeSideNav();
        break;
      case 'customers':
        await this.router.navigate(navigationUrlForCustomers());
        this.closeSideNav();
        break;
      case 'unsigned-invoices':
        await this.router.navigate(navigationUrlForUnsingedInvoices());
        this.closeSideNav();
        break;
      case 'manual-payment':
        await this.router.navigate(navigationUrlForManualPayment());
        this.closeSideNav();
        break;
      case 'users':
        await this.router.navigate(navigationUrlForUsers());
        this.closeSideNav();
        break;
      case 'pending-approvals':
        await this.router.navigate(navigationUrlForPendingApprovals());
        this.closeSideNav();
        break;
      case 'aggregators':
        await this.router.navigate(navigationUrlForAggregators());
        this.closeSideNav();
        break;
      case 'companies':
        await this.router.navigate(navigationUrlForCompanies());
        this.closeSideNav();
        break;
      case 'rate-cards':
        await this.router.navigate(navigationUrlForRateCards());
        this.closeSideNav();
        break;
      case 'set-maintenance':
        await this.router.navigate(navigationUrlForSetMaintenance());
        this.closeSideNav();
        break;
      case 'transactions':
        await this.router.navigate(navigationUrlForTransactions());
        this.closeSideNav();
        break;
      case 'pending-approval-transactions':
        await this.router.navigate(navigationUrlForPendingApprovalTransactions());
        this.closeSideNav();
        break;
      case 'oauth':
        await this.router.navigate(navigationUrlForOauth());
        this.closeSideNav();
        break;
      case 'promotion':
        await this.router.navigate(navigationUrlForPromotion());
        this.closeSideNav();
      break;
      case 'unverified-users':
        await this.router.navigate(navigationUrlForUnverifiedUsers());
        this.closeSideNav();
        break;
      case 'new-application':
        await this.router.navigate(navigationUrlForNewApplication());
        this.closeSideNav();
        break;
      case 'consumer-finance':
        await this.router.navigate(navigationUrlForConsumerFinance());
        this.closeSideNav();
        break;
      case 'notifications-management':
        await this.router.navigate(navigationUrlForNotificationsManagement());
        this.closeSideNav();
        break;
      case 'audit-logs':
        await this.router.navigate(navigationUrlForAuditLogs());
        this.closeSideNav();
        break;
      case 'dashboard':
        await this.router.navigate(navigationUrlForDashboard());
        this.closeSideNav();
        break;
      case 'overdraft-product':
        await this.router.navigate(navigationUrlForOverdraftProduct());
        this.closeSideNav();
        break;
      case 'pismo-accounts':
        await this.router.navigate(navigationUrlForPismoAccounts());
        this.closeSideNav();
        break;
      case 'bank-statements-analysis':
        await this.router.navigate(navigationUrlForBankStatementsAnalysis());
        this.closeSideNav();
        break;
      case 'bank-statements-status':
        await this.router.navigate(navigationUrlForBankStatementsStatus());
        this.closeSideNav();
        break;
      case 'accreditation':
        await this.router.navigate(navigationUrlForAccreditation());
        this.closeSideNav();
        break;
      case 'documents':
        await this.router.navigate(navigationUrlForDocuments());
        this.closeSideNav();
        break;
      case 'document-worklist':
        await this.router.navigate(navigationUrlForDocumentWorklist());
        this.closeSideNav();
        break;
      case 'reporting':
        await this.router.navigate(navigationUrlForReporting());
        this.closeSideNav();
        break;
      case 'credit-worklist':
        await this.router.navigate(navigationUrlForCreditWorklist());
        this.closeSideNav();
        break;
      case 'client-services-worklist':
        await this.router.navigate(navigationUrlForClientServicesWorklist());
        this.closeSideNav();
        break;
      case 'settlements-worklist':
        await this.router.navigate(navigationUrlForSettlementsWorklist());
        this.closeSideNav();
        break;
      case 'banking':
        await this.router.navigate(navigationUrlForBanking());
        this.closeSideNav();
        break;
      case 'redbook':
        await this.router.navigate(navigationUrlForRedbook());
        this.closeSideNav();
        break;
      case 'business-and-overdraft-borrowing-estimator':
        await this.router.navigate(navigationUrlForBusinessAndOverdraftBorrowingEstimator());
        this.closeSideNav();
        break;
      case 'bsa-settings':
        await this.router.navigate(navigationUrlForBsaSettings());
        this.closeSideNav();
        break;
      case 'send-privacy-consent':
        await this.router.navigate(navigationUrlForSendPrivacyConsent());
        this.closeSideNav();
        break;
      case 'credit-management':
        await this.router.navigate(navigationUrlForCreditManagement());
        this.closeSideNav();
        break;
      case 'whitelabel-settings':
        await this.router.navigate(navigationUrlForWhitelabelSettings());
        break;
    }
  }

  async onSidemenuEvents2($event: SideMenuComponentMenuType) {
    switch($event) {
      case 'profile':
        await this.router.navigate(navigationUrlForProfile());
        this.closeSideNav();
        break;
      case 'contact-us':
        try {
          window.open(`${environment.contactUsUrl}`, '_blank');
          this.closeSideNav();
        } catch(err) {
          console.log(`error opening window to ${environment.contactUsUrl}`);
        }
        break;
      case 'faq':
        await this.router.navigate(navigationUrlForFAQ())
        this.closeSideNav();
        break;
      case 'product-guide':
        try {
          window.open(`${environment.brokerProductGuideUrl}`, '_blank');
          this.closeSideNav();
        } catch(err) {
          console.log(`error opening window to ${environment.brokerProductGuideUrl}`);
        }
        break;
      case 'notifications':
        await this.router.navigate(navigationUrlForNotifications());
        this.closeSideNav();
        break;
      case 'accreditation-listing':
        await this.router.navigate(navigationUrlForAccreditationListing());
        this.closeSideNav();
        break;
      case 'send-privacy-consent':
        await this.router.navigate(navigationUrlForSendPrivacyConsent());
        this.closeSideNav();
        break;
    }
  }

  async onTopMenuEvent($event: ToolbarComponentEvent) {
    switch($event.type) {
      case 'logout':
        this.authService.logout().pipe(
          finalize(async () => {
            //window.indexedDB.deleteDatabase('dynamoney');
              this.dbService.count("applications").subscribe((peopleCount) => {
              if(peopleCount){
                this.dbService.clear("applications").subscribe((successDeleted) => {
                  console.log('success? ', successDeleted);
                });
              }
            });
            await this.router.navigate(navigationUrlForLogin());
          })
        ).subscribe();
        break;
    }
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.unreadNotificationsCountObservable = this.notificationService.unreadNotificationsCount.asObservable();
    this.whitelevelServiceEventObserver = this.whitelabelService.subject.asObservable();
    this.changePortalThemeFn = this.portalThemeService.changePortalThemeFn;
    this.portalThemeObservable = this.portalThemeService.subject.asObservable();
    this.heartbeatObservable = this.heartbeatService.subject.asObservable();
    this.getAllNotificationsFn = this.notificationService.getAllNotifications;

    setTimeout(() => {
      this.sideNavGap = (document.querySelector('.top-menu-component') as HTMLElement)?.offsetHeight ?? 0;
    });
    this.user = getUser();
    this.menuItemId = (this.route.snapshot.data as any).menuItemId;
    const sub = this.router.events.pipe(
      tap( r => {
        if (r instanceof ActivationStart) {
          this.menuItemId = (r.snapshot.data as any).menuItemId;
        }
      })
    ).subscribe();

  }

  ngAfterViewInit() {
    setBrowseHeightAsCssVariable();
    this.subscriptions.push(this.breakpointService.deviceSubject.subscribe(r => {
      setTimeout(async () => {
        this.mode = r;
        if (r === 'Mobile') {
          await this.matSidenav.close();
        } else {
          await this.matSidenav.open();
        }
      })
    }));
  }

  closeSideNav() {
    if (this.mode === 'Mobile') {
      this.matSidenav.close();
    }
  }
}
