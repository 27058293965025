<span
  class="application-type-icon-component"
  [matTooltip]="
    applicationType === 'AssetFinance'
      ? 'Asset Finance'
      : applicationType === 'InsurancePremium'
      ? 'Insurance Premium'
      : applicationType === 'BusinessLoans'
      ? 'Business Term Loan'
      : applicationType === 'BusinessOverdraft'
      ? 'Business Overdraft'
      : applicationType === 'Consumer'
      ? 'Consumer Asset Finance'
      : applicationType === 'CorporateLoans'
      ? 'Corporate Loan'
      : applicationType === 'Accreditation'
      ? 'Accreditation'
      : ''
  "
  matTooltipPosition="above"
  matTooltipClass="icon-toolTip"
  [ngClass]="[
    'mdi',
    'application-type-icon',
    applicationType === 'AssetFinance'
      ? 'mdi-truck'
      : applicationType === 'InsurancePremium'
      ? 'mdi-shield-star-outline'
      : applicationType === 'BusinessLoans'
      ? 'mdi-cash-multiple'
      : applicationType === 'BusinessOverdraft'
      ? 'mdi-calculator'
      : applicationType === 'Consumer'
      ? 'mdi-car'
      : applicationType === 'CorporateLoans'
      ? 'mdi-domain'
      : applicationType === 'All'
      ? 'mdi-view-grid-outline'
      : applicationType === 'Accreditation'
      ? 'mdi-file-settings-outline'
      : ''
  ]"
></span>
