import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationTypes, PortalLoginUser, Priviledges, User} from '@portal-workspace/grow-shared-library';
import { AdminService } from '../../service/admin.service';
import {NotificationService} from '../../service/notification.service';
import {getUser, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';
import {ApplicationService} from '../../service/application.service';
import { ApplicationTilesComponent } from '@portal-workspace/grow-ui-library';
import { MessageBox2Component } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';

import { RegistrationService } from '../../service/registration.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './new-application.page.html',
    styleUrls: ['./new-application.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, MessageBox2Component, ApplicationTilesComponent]
})
export class NewApplicationPage implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  user: PortalLoginUser | null = null;
  isApproved : number | null | undefined;
  accessLevel: string|undefined;
  isDealer: boolean | undefined = false;
  environment = environment;


  constructor(private router: Router,
              private adminService: AdminService,
              private registerService: RegistrationService,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              private applicationService: ApplicationService,
              private notificationService: NotificationService) {}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser();
    this.accessLevel=this.user?.AccessLevel
    this.isApproved = this.user?.approval
    this.isDealer = this.user?.priviledges?.includes('dealer');
    const subscription = this.notificationService.updateUnreadNotificationsCount();
    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onResendEmailClicked($event: MouseEvent) {
      this.registerService.sendVerificationEmail({
        fname: this.user?.Name ?? '',
        UserId: this.user?.UserId,
        mobile: this.user?.MobileNumber,
        email: this.user?.Email ?? '',
      }).pipe(
        this.toastService.snackBarObservable('Sending Email'),
      ).subscribe()
  }

  navigationHandler(event: ApplicationTypes) {
    this.applicationService.applicationTilesComponentNavigationHandler(event);
  }
}
