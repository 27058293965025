<div class="credit-management-page container-fluid">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="mat-h3">Credit Flow</span>
          </div>
          <div class="col-lg-6 col-md-12"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="end start"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <button mat-flat-button class="active w-lt-md-100" (click)="onAddCreditFlowNode()"><span class="mdi mdi-plus"></span>Add Node</button>
            <button mat-flat-button class="active w-lt-md-100" (click)="save()">Save</button>
            <button mat-flat-button class="inactive w-lt-md-100" (click)="cancel()">Cancel</button>
          </div>
        </div>  

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <name title="Credit Flow Name" [includeDigits]="true" [formControl]="formControlName"></name>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Start Node</mat-label>
              <mat-select [formControl]="formControlStartNode">
                @for (option of startNodeOptions; track option) {
                  <mat-option [value]="option.id">{{option.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlAssetFinance">Asset Finance</mat-checkbox>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlBusinessLoans">Business Term Loan</mat-checkbox>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlBusinessOverdraft">Business Overdraft</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlInsurancePremium">Insurance Premium</mat-checkbox>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlCorporateLoans">Corporate Loan</mat-checkbox>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlConsumer">Consumer Asset Finance</mat-checkbox>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-lg-3 col-md-12">
            <mat-checkbox color="primary" [formControl]="formControlAccreditation">Accreditation</mat-checkbox>
          </div>
        </div>

        <div class="row" fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="end start"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <div class="col-lg-3 col-md-12">
            <mat-button-toggle-group
                [hideSingleSelectionIndicator]="true"
                [hideMultipleSelectionIndicator]="true"
                [formControl]="formControlView">
                <mat-button-toggle [value]="'graph'">Graph</mat-button-toggle>
                <mat-button-toggle [value]="'table'">Table</mat-button-toggle>
              </mat-button-toggle-group>
          </div>
        </div>

        @if (formControlView.value !== 'graph') {
          <div class="row">
            <div class="col-12">
                @if (loader.tableLoading.inProgress$ | async) {
                    <custom-content-loader type="list"></custom-content-loader>
                  }
                  <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
                    <table mat-table [dataSource]="displayedData" class="width-100 mb-2">
                      @for (column of columnsToDisplay; track column) {
                        <ng-container matColumnDef="{{column}}">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="{
                        'column-width-1': column === 'id' || column === 'actions',
                        'column-width-2': column === 'name' || column === 'type' || column === 'nextNode',
                        'column-width-3': column === 'ruleMappings'
                      }"> 
                        <div class="cell">{{getColumnTitles(column)}}</div> </th>
                            <td mat-cell *matCellDef="let element">
                              <div class="cell">
                                @if (column == 'actions') {
                                  <div fxLayout="row wrap"
                                    fxLayoutAlign="center center"
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign.lt-md="center center"
                                    >
                                    <span class="mdi mdi-pencil-outline cursor-pointer icon-margin date-text" matTooltip="Edit" (click)="onEditCreditFlowNode(element)"></span>
                                    <span class="mdi mdi-trash-can-outline cursor-pointer icon-margin date-text" matTooltip="Delete" (click)="onDeleteCreditFlowNode(element)"></span>
                                  </div>
                                }
                                @else if (column === 'ruleMappings' && (element.type === 'rule set' || element.type === 'route')) {
                                  <div class="cell">
                                    @for (mapping of element.creditRuleMappings; track mapping) {
                                      <div fxLayout="row"
                                        fxLayoutGap="10px"
                                        fxLayoutAlign="space-between start"
                                        fxLayout.lt-md="column"
                                        fxLayoutAlign.lt-md="center start">
                                        <div>
                                          <div>{{mapping.name}}</div>
                                          <div class="date-text">Level: {{mapping.level}}</div>
                                          @if (element.type === 'route') {
                                            <div class="date-text">Next Node: {{displayNodeName(mapping.nextNode)}}</div>
                                          }
                                        </div>
                                        <div class="date-text" fxLayout="row wrap"
                                          fxLayoutAlign="start center"
                                          fxLayout.lt-md="column"
                                          fxLayoutAlign.lt-md="center center">
                                          <span class="mdi mdi-pencil-outline cursor-pointer icon-margin hide" matTooltip="Edit" (click)="onEditRuleMapping(mapping, element)"></span>
                                          <span class="mdi mdi-trash-can-outline cursor-pointer icon-margin hide" matTooltip="Delete" (click)="onDeleteRuleMapping(mapping)"></span>
                                        </div>
                                      </div>
                                      <mat-divider [inset]="true"></mat-divider>
                                    }
                                    <span class="mdi mdi-plus cursor-pointer icon-margin date-text" matTooltip="Add Rule" (click)="onAddRuleMapping(element)">
                                    </span>
                                  </div>
                                }
                                @else if (column === 'lastUpdated') {
                                  <div class="mb-2">{{element.GivenName ?? ''}} {{element.FamilyName ?? ''}}</div>
                                  <div class="date-text">{{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}</div>
                                }
                                @else if (column === 'type') {
                                  <tag-box [state]="element[column]"></tag-box>                    
                                } 
                                @else if (column === 'name') {
                                  <div>{{element[column]}}</div>
                                  @if (creditFlow.startNode === element.id) {
                                    <tag-box [state]="'Start Node'"></tag-box>
                                  }
                                }
                                @else if (column === 'nextNode') {
                                  @if (element.type !== 'route') {
                                    {{displayNodeName(element.nextNode)}}
                                  }
                                }
                                @else if (column === 'id') {
                                  {{displayNodeId(element.id)}}
                                }
                                @else {
                                  {{element[column]}}
                                }
                              </div>
                            </td>
                          </ng-container>
                        }
                
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                        </tr>
                    </table>
                    @if (total) {
                      <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
                      [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
                    }
                  </div>
            </div>
        </div>
       }

       @if (formControlView.value === 'graph') {
        <div class="row">
          <div class="col-12">
            <vflow 
              [nodes]="nodes" 
              [edges]="edges" 
              [handlePositions]="{ source: 'right', target: 'left' }" 
              [view]="[graphViewX, graphViewY]"
            >
            <ng-template edgeLabelHtml let-ctx>
              <div class="label"
                >{{ctx.label.data.text}}</div>
            </ng-template>
            <ng-template nodeHtml let-ctx>
              <div [ngClass]="{
                'default-node': ctx.node.data.class == 'default-node', 
                'web-service-node': ctx.node.data.class == 'web-service-node', 
                'route-node': ctx.node.data.class == 'route-node', 
                'rule-set-node': ctx.node.data.class == 'rule-set-node'
              }" (dblclick)="onClickNode(ctx.node.data.element)">
                {{ ctx.node.data.text }}
                <handle type="target" position="left"/>
                <handle type="source" position="right"/>
              </div>
            </ng-template>

            </vflow>
          </div>
        </div>
       }
      </mat-card-content>
    </mat-card>
</div>
