<div class="asset-credit-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="Credit Decisioning"><div *ngTemplateOutlet="creditDecisioningTab"></div></mat-tab>
      @if (isAdminOrCreditUser(loggedInUser)) {
        <mat-tab label="DSCR"><div *ngTemplateOutlet="dscrTab"></div></mat-tab>
        <mat-tab label="LVR"><div *ngTemplateOutlet="lvrTab"></div></mat-tab>
      }
    </mat-tab-group>
  </mat-card>
</div>

<ng-template #dscrTab>
  <dscr-calculator
    [application]="application"
    [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
    [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn">
  </dscr-calculator>
</ng-template>

<ng-template #lvrTab>
  <lvr-calculator
    [applicationId]="application.ApplicationId"
    [getLvrCalculatorValueFn]="getLvrCalculatorValueFn"
    [updateLvrCalculatorValueFn]="updateLvrCalculatorValueFn">
  </lvr-calculator>
</ng-template>

<ng-template #creditDecisioningTab>
  <credit-overview
    [application]="application"
    [runCreditFlowFn]="runCreditFlowFn"
    [getCreditWebServicesFn]="getCreditWebServicesFn"
    [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
    [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
    [updateCreditStatusFn]="updateCreditStatusFn"
    [updateCreditOfficerFn]="updateCreditOfficerFn"
    [getActiveCreditUsersFn]="getActiveCreditUsersFn"
    [getCreditAuditLogFn]="getCreditAuditLogFn"
    [getApplicationByIdFn]="getApplicationByIdFn"
    [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
    [deleteWebServicesFn]="deleteWebServicesFn"
    [apiUrl]="apiUrl"
    [requestMoreInformationFn]="requestMoreInformationFn"
    [getUserFn]="getUserFn"
    [getApplicationOwnerFn]="getApplicationOwnerFn"
    [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
    [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
    [createApplicationNoteFn]="createApplicationNoteFn"
    [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
    [getApplicationDscrFn]="getApplicationDscrFn"
    (viewReport)="onViewReport($event)"
    (clickApplicationEvent)="onClick($event)"
    (refreshCreditTabEvent)="refreshCreditTab($event)"
  ></credit-overview>
</ng-template>
