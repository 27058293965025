import { ApplicationRef, DestroyRef, inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { HttpRequest, HttpHandlerFn, HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { Observable, interval } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { environment } from "../../environments/environment";
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';


const DEBOUNCE_TIME = 10000; // 10 seconds -- min time between update checks when intercepting API calls
const UPDATE_CHECK_TIME = 300000; // 5 minutes -- background timer checks for updates at this interval
let lastCheck = 0;
let timerHandle: ReturnType<typeof setTimeout> | null = null;
const DEBUG_LOGGING = false;

function doUpdateCheck(swUpdate: SwUpdate, reason: string) {
  if(environment.enableServiceWorker) {
    swUpdate.checkForUpdate().then(() => {
      if(DEBUG_LOGGING) console.log('UpdateService Update check triggered.', reason);
    }).catch(err => {
      console.warn('UpdateService Update check failed:', err);
    });
  }
}


// We check for updates on a 5 min timer interval, and whenever an API request is made (if we havn't checked in the last 10 seconds)
// When an update is detected, we automatically install it with activateUpdate. We also prompt the user to refresh the page as
// the docs mention that activateUpdate may break the page in some cases.
// https://angular.dev/ecosystem/service-workers
@Injectable()
export class UpdateService {
  swUpdate: SwUpdate = inject(SwUpdate);
  applicationRef: ApplicationRef = inject(ApplicationRef);
  destroyDef: DestroyRef = inject(DestroyRef);
  portalHotToastService: PortalHotToastService = inject(PortalHotToastService);
  constructor() {
    if(DEBUG_LOGGING) console.log('UpdateService created');
    if(environment.enableServiceWorker) {
      this.setupVersionUpdatedCallback();
    } else {
      if(DEBUG_LOGGING) console.log('UpdateService environment.enableServiceWorker == false');
    }
  }
  public init(): void {
    if(environment.enableServiceWorker) {
      if(DEBUG_LOGGING) console.log('UpdateService init');
      this.setupUpdateTimer();
      this.setupUnrecoverableHandler();
    }
  }
  private setupVersionUpdatedCallback(): void {
    this.swUpdate.versionUpdates.subscribe((event) => {
      if (event) {
        switch(event.type) {
          case "NO_NEW_VERSION_DETECTED": {
            break;
          }
          case "VERSION_DETECTED": {
            // Called when a new ngsw.json file is found on the server
            // Any assets that have changed (as per their hash in ngsw.json) will begin to download in the background
            console.log(`UpdateService VERSION_DETECTED `, event);
            break;
          }
          case "VERSION_INSTALLATION_FAILED": {
            console.warn(`UpdateService VERSION_INSTALLATION_FAILED `, event);
            break;
          }
          case "VERSION_READY": {
            console.log(`UpdateService VERSION_READY `, event);
            this.applyUpdate();
            break;
          }
        }
      }
    });
  }
  private setupUpdateTimer(): void {
    // The angular docs suggest waiting for applicationRef.isStable before using timers.
    // However, it was found that isStable is never being set to true so the timer never fired.
    // Instead of isStable, the timer isn't setup until we call updateService.init() from appInitializerFn in main.ts
    interval(UPDATE_CHECK_TIME).pipe(
      takeUntilDestroyed(this.destroyDef),
    ).subscribe((i) => {
      doUpdateCheck(this.swUpdate, "timer");
    });
  }
  private setupUnrecoverableHandler(): void {
    this.swUpdate.unrecoverable.pipe(
        takeUntilDestroyed(this.destroyDef),
    ).subscribe((event) => {
        console.warn(`UpdateService service worker unrecoverable state`, event);
        document.location.reload();
    });
  }
  private applyUpdate() {
    if (environment.enableServiceWorker) {
      console.log("UpdateService applyUpdate")

      // testing note: This was copied from a previous iteration but no snackbar is visible
      this.portalHotToastService.snackbar('A new version of the app is ready.');
      // activateUpdate should update the app, but depending on what's changed a page refresh could be required.
      // Prompt the user before the page refresh part so they don't lose any transient data
      this.swUpdate.activateUpdate();
      if(confirm("A new version is ready and a page refresh is recommended. Press OK to refresh the page now")) {
        document.location.reload();
      }
    }
  }
}


// Check for updates every time we make an API request. Debounce the update check to not occur more than once every 10 seconds
// https://angular.dev/guide/http/interceptors
function updateInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  if(DEBUG_LOGGING) console.log('UpdateService updateInterceptor');
  if (environment.enableServiceWorker) {
    const swUpdate = inject(SwUpdate);
    //console.log('UpdateService 1-- ' + environment.enableServiceWorker + ' ' + swUpdate.isEnabled);
    if (swUpdate.isEnabled) {
      const now = Date.now();
      const previousLastCheck = lastCheck;
      lastCheck = now;
      //console.log('UpdateService 2-- ' + (now - previousLastCheck));
      if ((now - previousLastCheck) >= DEBOUNCE_TIME) {
        doUpdateCheck(swUpdate, "interceptor");
      } else {
        if (!timerHandle) {
          const timeUntilNextCheck = DEBOUNCE_TIME - (now - previousLastCheck);
          timerHandle = setTimeout(() => {
            timerHandle = null;
            if ((Date.now() - previousLastCheck) >= DEBOUNCE_TIME) {
              doUpdateCheck(swUpdate, "interceptor debounce");
            }
          }, timeUntilNextCheck);
        }
      }
    }
  }
  return next(req);
}

export function UpdateInterceptorFn(): HttpInterceptorFn {
  if(DEBUG_LOGGING) console.log('UpdateService interceptor created');
  return updateInterceptor;
}
