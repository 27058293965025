import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { getUser, httpOptions, storeTokens } from '@portal-workspace/grow-ui-library';
import { GetHeartbeatLogsFn, HeartbeatEvent, HeartbeatLog, PayloadApiResponse, RefreshTokenPayload } from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

const URL_REFRESH_TOKEN = () => `${environment.api2Host}/api2/refresh-token`;

@Injectable()
export class HeartbeatService implements OnDestroy {


    HEARTBEAT_INTERVAL = 1000 * 60 * 1; // 1 minutes
    MAX_LOGS = 10;

    httpClient = inject(HttpClient);

    ref: any;

    logs: HeartbeatLog[] = [];
    logsSubject: Subject<HeartbeatLog[]> = new BehaviorSubject<HeartbeatLog[]>(this.logs);
    subject: Subject<HeartbeatEvent> = new Subject<HeartbeatEvent>();

    init() {
        console.log('heartbeat init');
        this.ref = setInterval(() => {
            try {
                this.heartbeat();
            } catch(err) {
                console.error('heartbeat error', err);   
            }
        }, this.HEARTBEAT_INTERVAL);
    }

    ngOnDestroy(): void {
        if (this.ref) {
            clearInterval(this.ref);
        }
        console.log('heartbeat destroy');
    }


    getHeartbeatLogsFn: GetHeartbeatLogsFn = () => {
        return this.logsSubject.asObservable();
    }

    heartbeat() {
        const currentUser = getUser();
        const refreshToken = currentUser?.refreshToken;
        if (!refreshToken) {
            return;
        }
        this.httpClient.post<PayloadApiResponse<RefreshTokenPayload | undefined>>(URL_REFRESH_TOKEN(), {
            refreshToken,
            source: 'heartbeat',
        }, httpOptions()).subscribe((res) => {
            if (res.status && res.payload) {
                console.log('heartbeat success');
                const newUser = res.payload.user;
                const refreshToken = res.payload.refreshToken;
                const accessToken = res.payload.accessToken;
                const token = {
                  user: newUser,
                  refreshToken,
                  accessToken
                };
                storeTokens(token);
                this.addLog({date: moment().format('DD/MM/YYYY hh:mm:ss a'), message: `heartbeat success`});

                // fire events
                if (currentUser) {
                    if (newUser.AccessLevel && 
                        currentUser.AccessLevel && 
                        newUser.AccessLevel !== currentUser.AccessLevel) {
                        this.subject.next({
                            type: 'HeartbeatAccessLevelChangedEvent',
                            userId: currentUser.UserId,
                            oldAccessLevel: currentUser.AccessLevel,
                            newAccessLevel: newUser.AccessLevel,
                        });
                    }
                    if (!currentUser.isDisabled  && 
                        newUser.isDisabled) {
                        this.subject.next({
                            type: 'HeartbeatUserDisabledEvent',
                            userId: currentUser.UserId,
                            isDisabled: true,
                        });
                    }
                }
            } else {
                console.log('heartbeat failed');
                this.addLog({date: moment().format('DD/MM/YYYY HH:mm:ss'), message: `heartbeat failed - ${res.message}`});
            }
        });
    }

    addLog(log: HeartbeatLog) {
        this.logs.push(log);
        if (this.logs.length > this.MAX_LOGS) {
            this.logs.shift();
        }
        this.logsSubject.next(this.logs);
    }
}


